const WhatWeOffer = () => {
  return (
    <div className="whatWeOffer">
      <div className="whatWeOffer__main">
        <h2>What We Offer</h2>
        <p>
          We offer our cryptocurrencies to crypto enthusiasts and crypto users
          alike, as well as an opportunity to unlock more than $12,500 worth of
          Ultainfinity cryptocurrencies. We are offering a $300 billion giveaway
          without hassle or complexities, by far, the biggest giveaway ever
          offered. This size of giveaway only corroborates how much of, and the
          efficacy of our wealth creation paradigm. We are without a doubt,
          leading the charge of a wealth FREE renaissance.
        </p>
      </div>
    </div>
  );
};

export default WhatWeOffer;
