// ModeContext.tsx

// ModeContext.tsx

import React, { createContext, useState, useContext, useEffect } from 'react';

type ModeType = 'signup' | 'signin';

interface ModeContextProps {
  mode: ModeType;
  setMode: React.Dispatch<React.SetStateAction<ModeType>>;
}

const ModeContext = createContext<ModeContextProps | undefined>(undefined);

export const useModeContext = () => {
  const context = useContext(ModeContext);
  if (!context) {
    throw new Error('useModeContext must be used within a ModeContextProvider');
  }
  return context;
};

export const ModeContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [mode, setMode] = useState<ModeType>(() => {
    const storedMode = localStorage.getItem('mode');
    return storedMode ? (storedMode as ModeType) : 'signup'; // Default mode is 'signup' if not stored
  });

  useEffect(() => {
    localStorage.setItem('mode', mode); // Store the mode in localStorage
  }, [mode]);

  return (
    <ModeContext.Provider value={{ mode, setMode }}>
      {children}
    </ModeContext.Provider>
  );
};

