import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import walletSevice from "../services/wallet.service";
import { setMessage } from "./message";

export const getWallet: any = createAsyncThunk(
  "wallet/wallet",
  async (id, thunkAPI) => {
    try {
      const data = await walletSevice.getWallet();
      return { wallet: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      // return thunkAPI.rejectWithValue();
    }
  }
);

export const registerWallet: any = createAsyncThunk(
  "wallet/registerWallet",
  async (data: any, thunkAPI: any) => {
    try {
      const dataMain = await walletSevice.registerWallet(data);
      return { registerWallet: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState: any = { wallet: null, registerWallet: false };

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
  extraReducers: {
    [registerWallet.fulfilled]: (state, action) => {
      state.registerWallet = true;
    },
    [registerWallet.rejected]: (state, action) => {
      state.registerWallet = false;
    },
    [getWallet.fulfilled]: (state, action) => {
      state.wallet = action.payload.wallet;
    },
    [getWallet.rejected]: (state, action) => {
      state.wallet = null;
    },
  },
});

const { reducer } = walletSlice;
export default reducer;
