import arrowIcon from "../../assets/image/svg/arrowAlt2.svg";
import referIcon from "../../assets/image/svg/refer.svg";
import investIcon from "../../assets/image/svg/invest.svg";
import submitIcon from "../../assets/image/svg/submit.svg";
import subscribeIcon from "../../assets/image/svg/subscribe.svg";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <section id="about" className="about">
      <div className="about__main">
        <h2 className="about__main__title">Learn More About</h2>
        <h3 className="about__main__subtitle">
          Ultainfinity Airdrops & Bounties Programs
        </h3>
        <p className="about__main__disc">
          All airdrop and bounty users get automatically a locked wallet, with a
          minimum credit balance of $3,000. To semi-unlock, each batch of $3,000
          will require 3 referrals or 6 Ultainfinity community subscriptions or
          non-Ultainfinity crypto submissions while the batch of $3,500 will
          require crypto investment in the Ultainfinity projects. All airdropped
          new users shall have a SPECIAL AIRDROP UTTA TOKENS referral code and a
          unique ID which the new registrant will use in their registration
          process. The Airdrops and Bounties are poised to bring about
          superfluous earnings and this is possible in 4 ways
        </p>
        <svg
          className="about__main__curve"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#F7F7F7"
            fillOpacity="1"
            d="M0,224L120,186.7C240,149,480,75,720,80C960,85,1200,171,1320,213.3L1440,256L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div className="about__list">
        <div className="about__programsList">
          <div className="about__programsList__item">
            <div className="about__programsList__item__left">
              <h4>
                Refer THREE friends, <br /> <b>Get $3000</b>
              </h4>
              <p>
                The Ultainfinity vision is open to everyone and so as you
                have...
              </p>
              <a href="#refer">
                <button>
                  Click to view <img src={arrowIcon} alt="" />
                </button>
              </a>
            </div>
            <div className="about__programsList__item__right">
              <img src={referIcon} alt="" />
            </div>
          </div>
          <div className="about__programsList__item">
            <div className="about__programsList__item__left">
              <h4>
                Invest in Ultainfinity Projects,{" "}
                <b>Get 4X Return Plus, Get $3500</b>
              </h4>
              <p>Ultainfinity has kicked off with…</p>
              <a href="#invest">
                <button>
                  Click to view <img src={arrowIcon} alt="" />
                </button>
              </a>
            </div>
            <div className="about__programsList__item__right">
              <img src={investIcon} alt="" />
            </div>
          </div>
          <div className="about__programsList__item">
            <div className="about__programsList__item__left">
              <h4>
                Subscribe to{" "}
                <b>6 Ultainfinity Social Media Channels, Get $3000</b>
              </h4>
              <p>By subscribing to Ultainfinity...</p>
              <a href="#subscribe">
                <button>
                  Click to view <img src={arrowIcon} alt="" />
                </button>
              </a>
            </div>
            <div className="about__programsList__item__right">
              <img src={subscribeIcon} alt="" />
            </div>
          </div>
          <div className="about__programsList__item">
            <div className="about__programsList__item__left">
              <h4>
                Submit non-Ultainfinity Crypto, Get{" "}
                <b>10X Return Plus, Get $3000</b>
              </h4>
              <p>By subscribing to Ultainfinity...</p>
              <a href="#submit">
                <button>
                  Click to view <img src={arrowIcon} alt="" />
                </button>
              </a>
            </div>
            <div className="about__programsList__item__right">
              <img src={submitIcon} alt="" />
            </div>
          </div>
        </div>

        <div className="about__programsFullList">
          <section id="refer" className="about__programsFullList__item">
            <div className="about__programsFullList__item__main">
              <h3>Earn $3,000 By Referring THREE Friends</h3>
              <p>
                <b>
                  {" "}
                  There is no limit to how many $ 3,000s you could earn just as
                  there is no limit to how many people you know.
                </b>{" "}
                <br />
                Wealth creation has never been easier. With Ultainfinity, your
                first step to humongous wealth could be $3,000 without a sweat.
                Ultainfinity is where real wealth, better than ordinary exists.
                Start now by referring three friends to Ultainfinity to join the
                wealth wagon as you have joined, and earn $3,000. Ultainfinity
                earning is without limits, and unending. This means you can earn
                multiple $3,000s. Upon successfully completing your registration
                process, you will get a unique referral code, which you should
                share with friends and all the people you know. The more
                three’s, the more $3,000.
              </p>
              <p>
                <b>An ideal illustration is User James</b> <br />
                James heard about this opportunity from another user and
                immediately signed up. He has so far announced Ultainfinity to
                his community and has gotten $24,000 from referrals in just over
                a week after he joined. How? For every three successful
                referrals, user James gets a $3,000 credit and since he has
                successfully referred 24 users, he has received (24/3) * $3,000
                = $24,000. Credits are automatically and immediately made after
                every three successful referrals.
              </p>

              <ul>
                You should remember that:
                <li>
                  You are an important part of this wealth spree. Spread the
                  wealth and earn a huge reward{" "}
                </li>
                <li>
                  You automatically get $3,000 for bringing in three friends{" "}
                </li>
                <li> No complexities involved, only earnings </li>
                <li> Unlimited entries and uncapped earnings</li>
              </ul>

              <Link to="signup">
                <button className="about__programsFullList__item__btn">
                  Sign up Now
                </button>
              </Link>
            </div>

            {/* <div></div> */}
          </section>

          <section id="invest" className="about__programsFullList__item alt">
            <div className="about__programsFullList__item__main">
              <h3>
                Invest in Ultainfinity Projects, with $250 Minimum. Get 4x
                Return Plus $3,500
              </h3>
              <p>
                <b>
                  {" "}
                  Ultainfinity investments are second to none, and here is a
                  bigger shot at exceeding supercharged wealth.
                </b>{" "}
                <br />
                Investors advise people to invest, and Ultainfinity investors
                have always been at the forefront. An investment opportunity
                like none other, incomparable in size, returns and pace. What we
                offer is a kind of wealth that shatters average wealth. The
                Ultainfinity Airdrop 2 presents the platform for easy,
                guaranteed and efficient means to an exceeding supercharged
                wealth. Invest in Ultainity projects and get a 4x Return on
                Investment and an additional $3,500.
              </p>
              <p>
                <b>An ideal illustration is User Elizabeth.</b> <br />
                User Elizabeth invests $2,500 in the Ultainfinity project and
                because of the Ultainfinity Bounties, she receives $2,500 x 4 =
                $10,000. User Elizabeth also receives $3,500 unlocked.
                Ultainfinity cryptocurrency making her total earnings, $13,500.
                User Elizabeth is allowed to hold, trade or even invest this
                amount. There is no better place to grow your investment than at
                Ultainfinity.
              </p>

              <ul>
                You should remember that:
                <li>You get 4x your investment</li>
                <li>
                  {" "}
                  You get an additional $3,500 for completing this airdrop
                  process
                </li>
                <li> No better place to invest than Ultainfinity</li>
                <li> Unlimited entries and uncapped earnings</li>
              </ul>
              <Link to="signup">
                <button className="about__programsFullList__item__btn">
                  Sign up Now
                </button>
              </Link>
            </div>
          </section>

          <section id="subscribe" className="about__programsFullList__item">
            <div className="about__programsFullList__item__main">
              <h3>
                Earn $3,000 by subscribing to, or following SIX Ultainfinity
                Social Media Channels
              </h3>

              <p>
                <b>
                  {" "}
                  Get unlimited earnings through social media subscriptions.
                </b>{" "}
                <br />
                This is your chance to make money from being an Ultainfinity
                supporter. You can earn $3,000 and continue to earn more just by
                following or subscribing to Ultainfinity social media channels.
                <br />
                By subscribing to Ultainfinity social media channels, you are
                actively participating in thrusting the Ultainfinity wealth
                vision beyond your borders and you deserve to be rewarded like
                an elite. You will automatically receive a $3,000 credit - no
                complexities are involved. Sign in and see your dashboard for
                our social media platforms to subscribe. Click, subscribe/follow
                and start earning like an elite
              </p>
              <ul>
                You should remember that:
                <li>Being a part of Ultainfinity makes you a wealth member</li>
                <li>
                  You should subscribe to 6 Ultainfinity channels to get your
                  $3,000 reward
                </li>
                <li> Spread Ultainfinity, gain Ultainfinity wealth</li>
                <li> Unlimited entries and uncapped earnings</li>
              </ul>
              <Link to="signup">
                <button className="about__programsFullList__item__btn">
                  Sign up Now
                </button>
              </Link>
            </div>
          </section>

          <section id="submit" className="about__programsFullList__item alt">
            <div className="about__programsFullList__item__main">
              <h3>
                Submit non-Ultainfinity Crypto, Get 10x Return and Unlock
                $3,000. No Limits.
              </h3>

              <p>
                <b>
                  {" "}
                  Unlimited entries. Uncapped earnings. Limited period. Limited
                  offer. Hurry.
                </b>{" "}
                <br />
                Become ultra-wealthy with Ultainfinity. You can get a 10x return
                if you own any of these eleven cryptocurrencies - APE, BTC, BCH,
                DAI, DOGE, ETH, LTC, SHIB, USDT, USDC, and SOL. All you need to
                do is support and give Ultainfinity your other cryptos. This
                program is a limited and once-in-a-lifetime opportunity. By
                submitting to Ultainfinity a minimum worth of $25 of any of the
                eleven cryptocurrencies, you will receive 10x your
                cryptocurrency investment. This investment also unlocks a $3,000
                giveaway, in addition.
              </p>
              <p>
                <b>As an illustration:</b> <br />
                User John submits BTC and unconditionally gives Ultainfinity 10
                Bitcoins worth $200,000. Immediately User John will get $2
                Million worth of Ultainfinity token, UTTA Token, plus a $3,000
                giveaway in his Ultainfinity Airdrop & Bounty Account Dashboard.
              </p>

              <p>
                You can repeat this 10x returns program and, indeed, any of the
                other programs again and again. You will get $3000 plus the 10x
                returns each time you participate.
              </p>
              <ul>
                Summary, Key Points
                <li> Minimum $25 crypto Investment.</li>
                <li>
                  {" "}
                  10x Return on whatever $USD equivalent amount that you invest.
                </li>
                <li>
                  {" "}
                  Unlock a $3,000 Giveaway for each investment each time that
                  you invest.
                </li>
                <li>
                  {" "}
                  Unlimited investment submissions, with uncapped earnings of
                  10x returns.
                </li>
              </ul>
              <Link to="signup">
                <button className="about__programsFullList__item__btn">
                  Sign up Now
                </button>
              </Link>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default About;
