import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import leaderboardSevice from "../services/leaderBoard.service";
import { setMessage } from "./message";

export const getLeaderboard: any = createAsyncThunk(
  "leaderboard/getLeaderboard",
  async (country: string, thunkAPI) => {
    try {
      const data = await leaderboardSevice.getLeaderboard(country);
      return { leaderboard: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState: any = {
  leaderboard: null,
};

export const leaderboardSlice = createSlice({
  name: "leaderboard",
  initialState,
  reducers: {},
  extraReducers: {
    [getLeaderboard.fulfilled]: (state, action) => {
      state.leaderboard = action.payload.leaderboard;
    },
    [getLeaderboard.rejected]: (state, action) => {
      state.leaderboard = null;
    },
  },
});

const { reducer } = leaderboardSlice;
export default reducer;
