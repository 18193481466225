import { ButtonAlt } from "./styledComponent/form/Buttons";
import ReactLoading from "react-loading";
import Modal from "react-modal";
import { useState } from "react";
import UnlockModal from "./UnlockModal";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { generateReference } from "../store/slices/bounties";

interface ProgramsInfoType {
  info: {
    title: string;
    steps: string[];
  };
  handleUnlock: (data: any) => void;
  loading?: boolean;
  program: string;
}
const ProgramsInfo = ({
  info,
  handleUnlock,
  loading,
  program,
}: ProgramsInfoType) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loadingGen, setLoadingGen] = useState(false);

  const dispatch = useAppDispatch();
  const { reference } = useAppSelector((state) => state.bounties);

  const openModal = (): void => {
    setModalIsOpen(true);
  };
  function closeModal(): void {
    setModalIsOpen(false);
  }

  const handleGenerateReference = () => {
    setLoadingGen(true);
    dispatch(generateReference())
      .unwrap()
      .then(() => {
        setLoadingGen(false);
        openModal();
      })
      .catch((err: any) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoadingGen(false);
      });
  };

  return (
    <div className="programsInfo">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Application Modal"
      >
        <UnlockModal
          reference={reference}
          program={program}
          close={closeModal}
          handleUnlock={handleUnlock}
          loading={loading}
        />
      </Modal>
      <div className="programsInfo__header">
        <h3 className="programsInfo__header__title">{info.title}</h3>
        <ButtonAlt onClick={handleGenerateReference} $fill>
          {loadingGen ? (
            <ReactLoading color="white" width={30} height={30} type="spin" />
          ) : (
            "Unlock"
          )}
        </ButtonAlt>
      </div>

      <ol>
        {info.steps.map((step, i) => {
          return <li key={i}>{step}</li>;
        })}
      </ol>

      <div className="programsInfo__mission">
        <h4 className="programsInfo__mission__title">
          ULTAINFINITY MISSION ON EARTH?
        </h4>
        <p className="programsInfo__mission__disc">
          Ultainfinity has a existential mission to create, transfer and
          distribute wealth to over 300 million people in the World. Please
          adopt our mission, become part of this movement and support
          Ultainfinity as we seek to change the World. The rich gets richer and
          the poor gets poorer and that’s all wrong. It must stop. Help to stop
          it.
        </p>
      </div>
    </div>
  );
};

export default ProgramsInfo;
