import axios from "axios";
import authHeader from "./authHeader";

const API_URL = process.env.REACT_APP_API_URL + "/airdrop-bounty/";

const getSubscription = async () => {
  return await axios({
    url: API_URL + "app/bounty-subscription",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const getBrand = async () => {
  return await axios({
    url: API_URL + "brand",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const getBalance = async () => {
  return await axios({
    url: API_URL + "app/reward/balance",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const getChartBalance = async (period: string) => {
  return await axios({
    url: API_URL + "app/reward/balance/" + period,
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const generateReference = async () => {
  return await axios({
    url: API_URL + "app/generate/reference",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const checkBountyInvestUnlock = async () => {
  return await axios({
    url: API_URL + "app/check-bounty-4x",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const reactiveBountyInvest = async (data: any) => {
  return await axios({
    url: API_URL + "app/bounty4x-reactivation",
    headers: authHeader(),
    data,
    method: "post",
  }).then((response) => {
    return response.data;
  });
};

const checkBountySubmitUnlock = async () => {
  return await axios({
    url: API_URL + "app/bounty-two/locked-status",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const checkBountySubmitReactivate = async () => {
  return await axios({
    url: API_URL + "app/bounty-two/reactivation-status",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const checkBountyInvestReactivate = async () => {
  return await axios({
    url: API_URL + "app/check-bounty-reactivation",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const checkUnlockAll = async () => {
  return await axios({
    url: API_URL + "app/unlock-airdrop-bounty",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const reactiveBountySubmit = async (data: any) => {
  return await axios({
    url: API_URL + "app/bounty-two/reactivate-unlock",
    headers: authHeader(),
    data,
    method: "post",
  }).then((response) => {
    return response.data;
  });
};

const getTransaction = async () => {
  return await axios({
    url: API_URL + "app/show-reward",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const bountiesSevice = {
  getSubscription,
  getBrand,
  getBalance,
  generateReference,
  checkBountyInvestUnlock,
  reactiveBountyInvest,
  getChartBalance,
  reactiveBountySubmit,
  checkBountySubmitUnlock,
  checkBountySubmitReactivate,
  checkBountyInvestReactivate,
  checkUnlockAll,
  getTransaction,
};

export default bountiesSevice;
