import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import whatsAppIcon from "../assets/image/svg/Whats app.svg";
import messangerIcon from "../assets/image/svg/Messanger.svg";
import viberIcon from "../assets/image/svg/Viber.svg";
import telegramIcon from "../assets/image/svg/Telegram.svg";
import twitterIcon from "../assets/image/svg/twitter-icon.svg";
import lineIcon from "../assets/image/svg/line.svg";
import { useModeContext } from "../store/modeContext";
import { NavLink, useNavigate  } from "react-router-dom";
import { useSocialMediaContext } from "../store/socialmediaContext";

const SocialLinks = () => {

  // const location = useLocation();

  // const links = getRequest("bots/ultainfinity");

  let navigate = useNavigate();
  const { updateSelectedSocialMedia } = useSocialMediaContext();
  const handleTwitterLogin = async (e:any) => {
    e.stopPropagation();
    const apiUrl =process.env.REACT_APP_SOCIALS_BOTS_TWITTER
    const callback_url = process.env.REACT_APP_ENV === 'staging'
  ? 'https://staging.ultainfinityairdropandbounty.com/auth/verify-token'
  : 'https://ultainfinityairdropandbounty.com/auth/verify-token';
    try {

      const response = await fetch( `${apiUrl}/v1/onestep/twitter/user/login`, 
      {method:'POST',  
      headers: {
        'Content-Type': 'application/json'
    },
   
    body: JSON.stringify({ 
          
      callback_url: callback_url
     }),

    }) 

      if (response.ok) {
        const  resData  = await response.json();
        console.log(resData.data.redirect_to)

       // window.open( resData.data.redirect_to, '_blank')
        window.location.href = resData.data.redirect_to;

     
      } else {
        console.log('Error initiating Twitter login');
      }
    } catch (error) {
        console.log('Network error');
    }
  };
  const handleTwitterClick = (e:any) => {
    const apiUrl = process.env.REACT_APP_SOCIALS_BOTS_TWITTER;
    const callback_url =
      process.env.REACT_APP_ENV === "staging"
        ? "https://staging.ultainfinityairdropandbounty.com/auth/verify-token"
        : "https://ultainfinityairdropandbounty.com/auth/verify-token";
        navigate("/auth/verify-token");
   /* fetch(`${apiUrl}/v1/onestep/twitter/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        callback_url: callback_url,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error initiating Twitter login");
        }
      })
      .then((resData) => {
        console.log(resData.data.redirect_to);
        // Process the response data as needed
        window.location.href = resData.data.redirect_to;
      })
      .catch((error) => {
        console.log("Network error", error);
      });*/
  };
  
  // Inside the return statement
 
  

  const links = [
    {
      image: telegramIcon,
      title: "telegram",
      href: "https://t.me/" + process.env.REACT_APP_SOCIALS_BOTS_TELEGRAM,
    },
    // {
    //   image: viberIcon,
    //   title: "Viber",
    //   href: "https://viber.com/pa?ChatURI=" +  process.env.REACT_APP_SOCIALS_BOTS_TELEGRAM,
    // },

    {
      image: whatsAppIcon,
      title: "twilio-whatsapp",
      href: "http://wa.me/" + process.env.REACT_APP_SOCIALS_BOTS_WHATSAPP,
    },

    {
      image: messangerIcon,
      title: "facebook-messenger",
      href:
        "https://www.facebook.com/" +
        process.env.REACT_APP_SOCIALS_BOTS_MESSENGER,
    },
    {
      image: lineIcon,
      title: "line",
      href:
        "https://linevoom.line.me/user/" +
        process.env.REACT_APP_SOCIALS_BOTS_LINE,
    },

  ];

  /**
   * go to Code Confirm Page
   */
  const goToConfirmCodePage = (e: any) => {
    const channelUrl = e.target.dataset.href;
    const channelTitle = e.target.alt
    updateSelectedSocialMedia(channelTitle);
    // sessionStorage.setItem("channelTitle", e.target.alt);
    // sessionStorage.setItem("channelUrl", channelUrl);

    // // Open in new tab target page
    // console.log("channelUrl", channelUrl);
    window.open(channelUrl, "_blank")?.focus();

    // history.push("/registration/verify-code/id");
  };
  /*const handleTwitterClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    handleTwitterLogin(e);
    updateSelectedSocialMedia("twitter");
  };
  
  const handleTwitterTouch = (e:any) => {
    e.preventDefault(); // Prevents the default touch behavior
    handleTwitterLogin(e);
    updateSelectedSocialMedia("twitter");
  };
  const handleTwitterInteraction = (e:any) => {
    e.preventDefault(); // Prevent the default behavior
    console.log("testing")

    if (e.type === 'click' || e.type === 'touchend') {
      handleTwitterLogin(e);
      updateSelectedSocialMedia("twitter");
    }
  };*/

  return (

  
    <div  style={{ display: "flex", justifyContent: "center", gap: "20px", cursor: "pointer" }}>
      {links.map((v, index) => {
        // let href = "";

        // if (isMobile) {
        //   href = v.hrefMobile;
        // } else {
        //   href = v.href;
        // }

        return (
          <div
            style={{ cursor: "pointer", display: "flex" }}
            key={index}
            onClick={(e) => {
              goToConfirmCodePage(e);
              updateSelectedSocialMedia(v.title);
            }}
          >
            <img src={v.image} alt={v.title} data-href={v.href} />
          </div>
        );
      })}
      <div
            style={{ cursor: "pointer", display: "flex" }}
            onClick={(e) => {
              handleTwitterLogin(e);
              updateSelectedSocialMedia("twitter");
            }}>
          
            <img src={twitterIcon}  alt={"twitter"} style={{ cursor: "pointer" }} />
            
          </div>
       
    </div>

  );
};

export default SocialLinks;
/*function useState(arg0: string): [any, any] {
  throw new Error("Function not implemented.");
}*/

