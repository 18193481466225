import Carousel from "../components/Carousel";
import ReferAction from "../components/ReferAction";
import { PageTitle } from "../components/styledComponent/headers/styles";

const IntroducerCFC = () => {
  return (
    <div className="introducerCFC">
      <PageTitle>Cash for Charity</PageTitle>
      <Carousel cfc={true} program="cfc" />
      <ReferAction cfc={true} />
    </div>
  );
};

export default IntroducerCFC;
