import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Area,
} from "recharts";
import { colorTheme } from "../assets/colors";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { getChartBalance } from "../store/slices/bounties";
import ReactLoading from "react-loading";
import noDataIcon from "../assets/image/svg/noData.svg";

const Chart = ({ theme }: { theme: string }) => {
  const [loading, setLoading] = useState(false);
  const [period] = useState("monthly");
  const [data, setData] = useState([
    { month: "Dec", count: 0 },
    { month: "Nov", count: 0 },
    { month: "Oct", count: 0 },
    { month: "Sep", count: 0 },
    { month: "Aug", count: 0 },
    { month: "Jul", count: 0 },
    { month: "Jun", count: 0 },
    { month: "May", count: 0 },
    { month: "Apr", count: 0 },
    { month: "Mar", count: 0 },
    { month: "Feb", count: 0 },
    { month: "Jan", count: 0 },
  ]);

  const { chartBalance }: any = useAppSelector((state) => state.bounties);

  const dispatch = useAppDispatch();

  const numberToMonth = (num: number) => {
    switch (num) {
      case 0:
        return "Jan";
      case 1:
        return "Feb";
      case 2:
        return "Mar";
      case 3:
        return "Apr";
      case 4:
        return "May";
      case 5:
        return "Jun";
      case 6:
        return "Jul";
      case 7:
        return "Aug";
      case 8:
        return "Sep";
      case 9:
        return "Oct";
      case 10:
        return "Nov";
      case 11:
        return "Dec";

      default:
        return "Jan";
    }
  };
  const numberToDay = (num: number) => {
    switch (num) {
      case 1:
        return num + "st";
      case 2:
        return num + "nd";
      case 3:
        return num + "rd";

      default:
        return num + "th";
    }
  };

  useEffect(() => {
    const handleGetBalance = () => {
      setLoading(true);
      dispatch(getChartBalance(period))
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    };

    handleGetBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  useEffect(() => {
    if (chartBalance) {
      if (period === "daily") {
        const newData = chartBalance?.map((item: number, i: number) => {
          return { month: numberToDay(i + 1), count: item };
        });
        setData([...newData].reverse());
      }
      if (period === "monthly") {
        const newData = chartBalance?.map((item: number, i: number) => {
          return { month: numberToMonth(i), count: item };
        });

        setData([...newData].reverse());
      }
      if (period === "weekly") {
        const newData = chartBalance?.map((item: number, i: number) => {
          return { month: numberToMonth(i), count: item };
        });

        setData([...newData].reverse());
      }
    }
  }, [chartBalance, period]);

  const mainData = [...data].reverse();
  const CustomTooltip = ({
    active,
    payload,
    label,
  }: {
    active?: any;
    payload?: any;
    label?: any;
  }) => {
    if (active && payload && payload.length) {
      return (
        <div className="chart__customTooltip">
          <p className="chart__customTooltip__label">{`${label}`}</p>
          <p className="chart__customTooltip__intro">
            earnings :{" "}
            <span style={{ color: theme === "light" ? "#042711" : "#fff" }}>
              ${payload[0].value}
            </span>
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="chart">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // marginTop: "40px",
          }}
        >
          <ReactLoading
            color={colorTheme.primary}
            width={50}
            height={50}
            type="spin"
          />
        </div>
      ) : chartBalance ? (
        <>
          <div className="chart__header">
            <h3 className="chart__header__title">Total Earnings</h3>
            <div className="chart__header__action">
              {/* <button className="chart__header__action__btn ">Today</button> */}
              {/* <button className="chart__header__action__btn">Weekly</button> */}
              <button className="chart__header__action__btn active">
                Monthly
              </button>
              {/* <button className="chart__header__action__btn">Yearly</button> */}
            </div>
          </div>

          <div className="chart__main">
            <ResponsiveContainer width="100%" aspect={4 / 1}>
              <ComposedChart width={800} height={300} data={mainData}>
                <CartesianGrid
                  stroke="rgba(55, 58, 66, 0.48)"
                  vertical={false}
                />
                <XAxis
                  dataKey="month"
                  axisLine={false}
                  tickMargin={10}
                  tickLine={false}
                  tick={{
                    fill: theme === "light" ? "#042711" : "#fff",
                    fontSize: "11px",
                    fontWeight: "700",
                  }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tickMargin={10}
                  domain={[() => 0, (dataMax: number) => dataMax]}
                  tick={{
                    fill: theme === "light" ? "#042711" : "#fff",
                    fontSize: "11px",
                    fontWeight: "700",
                  }}
                />
                <Tooltip cursor={false} content={<CustomTooltip />} />
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor="rgba(85, 92, 233, 0.5)"
                      stopOpacity={0.7}
                    />
                    <stop
                      offset="95%"
                      stopColor="rgba(97, 104, 248, 0)"
                      stopOpacity={0.7}
                    />
                  </linearGradient>
                </defs>
                <Line
                  dot={false}
                  type="monotone"
                  dataKey="count"
                  stroke="#666DFF"
                  strokeWidth={2.5}
                  strokeLinecap="round"
                  fill="url(#colorUv)"
                  fillOpacity={1}
                />
                <Area
                  type="monotone"
                  dataKey="count"
                  stroke="none"
                  fill="url(#colorUv)"
                  fillOpacity={1}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // marginTop: "40px",
          }}
        >
          <img src={noDataIcon} alt="" />
        </div>
      )}
    </div>
  );
};

export default Chart;
