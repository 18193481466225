import { Link } from "react-router-dom";
import logo from "../assets/image/svg/logo.svg";
import logoLight from "../assets/image/svg/logoLight.svg";
import { Button } from "../components/styledComponent/form/Buttons";
import faceIdIcon from "../assets/image/svg/faceId.svg";
import fingerPrintIcon from "../assets/image/svg/fingerPrint.svg";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { registerBiometric } from "../store/slices/auth";
import { toast } from "react-toastify";
import axios from "axios";
import ReactLoading from "react-loading";
import GetHelpBio from "../components/GetHelpBio";

const BiometricSignup = ({ theme }: { theme: string }) => {
  const [biometricSupported, setBiometricSupported] = useState(false);
  const [biometricRegisterSuc, setBiometricRegisterSuc] = useState(false);
  const [bioLoading, setBioLoading] = useState(false);

  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  function checkBiometricSupport() {
    if (
      window.PublicKeyCredential &&
      navigator.credentials &&
      (navigator.credentials.create || navigator.credentials.get)
    ) {
      setBiometricSupported(true);
    } else {
      setBiometricSupported(false);
    }
  }

  const handleBiometric = async (
    challenge: any,
    id: any,
    challengeStr: string
  ) => {
    try {
      const credentialOptions: any = {
        rp: {
          name: "Ultainfinity",
          id: window.location.hostname,
        },
        user: {
          displayName: user?.display_name
            ? user?.display_name
            : "Ultainfinity User",
          id,
          name: user?.display_name ? user?.display_name : "Ultainfinity User",
        },
        challenge,
        pubKeyCredParams: [
          {
            type: "public-key",
            alg: -7,
          },
        ],
        timeout: 1800000,
        attestation: "direct",
        authenticatorSelection: {
          userVerification: "required",
          authenticatorAttachment: "platform",
          requireResidentKey: true,
          residentKey: "required",
          authenticatorTransport: ["usb", "nfc", "ble"],
          extensions: {
            "ultainfinity:biometric-authentication": {
              supportedModes: ["fingerprint", "face"],
            },
          },
        },
        // authenticatorSelection: {
        //   residentKey: "required",
        //   userVerification: "required",
        //   authenticatorAttachment: "platform",
        // },
      };
      const credential = await navigator.credentials.create({
        publicKey: credentialOptions,
      });

      // console.log(credential);
      if (typeof credential?.id === "string") {
        // setBiometricRegisterSuc(credential?.id);
        const data = {
          // sid: user.id,
          // challenge_id: challengeStr,
          biometrics_id: credential?.id,
        };
        handleRegisterBiometric(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRegisterBiometric = async (data: any) => {
    setBioLoading(true);

    dispatch(registerBiometric(data))
      .unwrap()
      .then(() => {
        setBioLoading(false);
        toast.success("Successfully Registered Biometrics", {
          position: toast.POSITION.TOP_RIGHT,
        });

        setBiometricRegisterSuc(true);
      })
      .catch((err: any) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setBioLoading(false);
      });
  };

  const handleGenerateChallange = async () => {
    setBioLoading(true);

    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/users/v1/biometrics/generate-challenge`
      );
      setBioLoading(false);
      // console.log("response.data.type", response.data.type);
      const challenge = Uint8Array.from(atob(response.data?.challenge), (c) =>
        c.charCodeAt(0)
      );
      const id = Uint8Array.from(atob(response.data?.random_id), (c) =>
        c.charCodeAt(0)
      );

      handleBiometric(challenge, id, response.data?.challenge);
    } catch (err: any) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      setBioLoading(false);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    checkBiometricSupport();
  }, []);

  return (
    <div className="authLayout">
      <div className="authLayout__header">
        <img
          src={theme === "light" ? logoLight : logo}
          alt=""
          className="authLayout__header__logo"
        />
      </div>
      <div className="authLayout__main">
        <div className="authForm__biom">
          <h2 className="authForm__biom__title">Biometrics Registration</h2>
          <div className="authForm__biom__add">
            REGISTER YOUR BIOMETRICS FOR FASTER LOGIN, DO IT NOW OR LATER
          </div>

          <div className="authForm__biom__biometric">
            <p className="authForm__biom__disc">
              Register with Biometrics: Touch ID and Face ID for enhanced Login
              and Account Security. Enabling your Biometrics will help you to
              unlock a better experience.across Ultainfinity.
            </p>

            <div className="authForm__biom__biometric__illus">
              <div className="authForm__biom__biometric__illus__item">
                <img src={fingerPrintIcon} alt="" />
                <span>Touch ID</span>
              </div>
              <div className="authForm__biom__biometric__illus__item">
                <img src={faceIdIcon} alt="" />
                <span>Face ID</span>
              </div>
            </div>

            {!biometricSupported ? (
              <span className="authForm__biom__biometric__err">
                Biometrics is not supported on this device
              </span>
            ) : biometricRegisterSuc ? (
              <Link to="/dashboard">
                <Button width="250px" $fill>
                  Go to Dashboard
                </Button>
              </Link>
            ) : (
              <Button onClick={handleGenerateChallange} width="250px" $fill>
                {bioLoading ? (
                  <ReactLoading
                    color="white"
                    width={30}
                    height={30}
                    type="spin"
                  />
                ) : (
                  "Register Biometrics"
                )}
              </Button>
            )}

            <GetHelpBio />
          </div>
          <Link to="/dashboard">
            <div className="authForm__biom__skip">Skip to Dashboard</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BiometricSignup;
