const OverView = () => {
  return (
    <div className="overView">
      <div className="overView__header">
        <h2 className="overView__header__title">Overview</h2>
        <h3 className="overView__header__subtitle">
          Giveaways Like Never Seen. This Airdrop & Bounty Experience Comes With
          A Huge Smile
        </h3>
      </div>

      <div className="overView__list">
        <div className="overView__list__item">
          The Ultainfinity Airdrops and Bounties programs have been tipped to be
          the biggest dual crypto airdrops and bounties programs ever. A titanic
          amount of $300 billion is available for crypto users and enthusiasts
          from all over the world. It is an ideal representation of the twin
          mission of the Ultainfinity Global Group to raise a horde of
          Ultainfinity millionaires and to end the scourge and pangs of
          generational poverty for good.
        </div>
        <div className="overView__list__item">
          Every user has the opportunity to earn $12,500 from the Airdrops and
          Bounties programs by Ultainfinity - $3,000 and $3,500 apiece from the
          4 subcategories in the Ultainfinity Airdrops and Bounties. These
          amounts are paid out in our supercalifragilistic Ultainfinities token,
          particularly the UTTA token. Additionally, active cryptocurrency users
          of BTC, ETH, and other cryptocurrencies that are willing to choose the
          better side of crypto earning have a chance to earn x10 in the
          Airdrops and Bounties Programs.
        </div>
        <div className="overView__list__item">
          The Ultainfinity Airdrops and Bounties is made possible and even
          better by the BARRS Model, a super invention that propels endemic user
          and financial growth spur and overall humongous success for the
          Ultainfinity Group, the whole of the Cryptocurrencies community,
          sector, and, more importantly, for millions of poor people, worldwide
          - the essence of Ultainfinity's existence.
        </div>
      </div>
    </div>
  );
};

export default OverView;
