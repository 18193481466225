import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bountiesSevice from "../services/bounties.service";
import { setMessage } from "./message";

export const getSubscription: any = createAsyncThunk(
  "bounties/getSubscription",
  async (id, thunkAPI) => {
    try {
      const data = await bountiesSevice.getSubscription();
      return { subscription: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBrand: any = createAsyncThunk(
  "bounties/getBrand",
  async (id, thunkAPI) => {
    try {
      const data = await bountiesSevice.getBrand();
      return { brand: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBalance: any = createAsyncThunk(
  "bounties/getBalance",
  async (id, thunkAPI) => {
    try {
      const data = await bountiesSevice.getBalance();
      return { balance: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const generateReference: any = createAsyncThunk(
  "bounties/generateReference",
  async (id, thunkAPI) => {
    try {
      const data = await bountiesSevice.generateReference();
      return { reference: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const checkBountyInvestUnlock: any = createAsyncThunk(
  "bounties/checkBountyInvestUnlock",
  async (id, thunkAPI) => {
    try {
      const data = await bountiesSevice.checkBountyInvestUnlock();
      return { bountyInvestUnlock: data.message };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reactiveBountyInvest: any = createAsyncThunk(
  "bounties/reactiveBountyInvest",
  async (data: any, thunkAPI) => {
    try {
      const dataMain = await bountiesSevice.reactiveBountyInvest(data);
      return { bountyInvestReactivate: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getChartBalance: any = createAsyncThunk(
  "bounties/getChartBalance",
  async (period: string, thunkAPI) => {
    try {
      const dataMain = await bountiesSevice.getChartBalance(period);
      return { chartBalance: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const checkBountySubmitUnlock: any = createAsyncThunk(
  "bounties/checkBountySubmitUnlock",
  async (id, thunkAPI) => {
    try {
      const data = await bountiesSevice.checkBountySubmitUnlock();
      return { bountySubmitUnlock: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const checkBountySubmitReactivate: any = createAsyncThunk(
  "bounties/checkBountySubmitReactivate",
  async (id, thunkAPI) => {
    try {
      const data = await bountiesSevice.checkBountySubmitReactivate();
      return { bountySubmitReactivateCheck: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const checkBountyInvestReactivate: any = createAsyncThunk(
  "bounties/checkBountyInvestReactivate",
  async (id, thunkAPI) => {
    try {
      const data = await bountiesSevice.checkBountyInvestReactivate();
      return { bountyInvestReactivateCheck: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reactiveBountySubmit: any = createAsyncThunk(
  "bounties/reactiveBountySubmit",
  async (data: any, thunkAPI) => {
    try {
      const dataMain = await bountiesSevice.reactiveBountySubmit(data);
      return { bountySubmitReactivate: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const checkUnlockAll: any = createAsyncThunk(
  "bounties/checkUnlockAll",
  async (id, thunkAPI) => {
    try {
      const dataMain = await bountiesSevice.checkUnlockAll();
      return { unlockStatusAll: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTransaction: any = createAsyncThunk(
  "bounties/getTransaction",
  async (id, thunkAPI) => {
    try {
      const dataMain = await bountiesSevice.getTransaction();
      return { transaction: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState: any = {
  subscription: null,
  brand: null,
  balance: null,
  chartBalance: null,
  reference: null,
  bountyInvestUnlock: null,
  bountyInvestReactivate: null,
  bountySubmitUnlock: null,
  bountySubmitReactivate: null,
  bountySubmitReactivateCheck: null,
  bountyInvestReactivateCheck: null,
  unlockStatusAll: null,
  transaction: null,
};

export const bountiesSlice = createSlice({
  name: "bounties",
  initialState,
  reducers: {},
  extraReducers: {
    [getSubscription.fulfilled]: (state, action) => {
      state.subscription = action.payload.subscription;
    },
    [getSubscription.rejected]: (state, action) => {
      state.subscription = null;
    },
    [getBrand.fulfilled]: (state, action) => {
      state.brand = action.payload.brand;
    },
    [getBrand.rejected]: (state, action) => {
      state.brand = null;
    },
    [getBalance.fulfilled]: (state, action) => {
      state.balance = action.payload.balance;
    },
    [getBalance.rejected]: (state, action) => {
      state.balance = null;
    },
    [generateReference.fulfilled]: (state, action) => {
      state.reference = action.payload.reference;
    },
    [generateReference.rejected]: (state, action) => {
      state.reference = null;
    },
    [checkBountyInvestUnlock.fulfilled]: (state, action) => {
      state.bountyInvestUnlock = action.payload.bountyInvestUnlock;
    },
    [checkBountyInvestUnlock.rejected]: (state, action) => {
      state.bountyInvestUnlock = null;
    },
    [reactiveBountyInvest.fulfilled]: (state, action) => {
      state.bountyInvestReactivate = action.payload.bountyInvestReactivate;
    },
    [reactiveBountyInvest.rejected]: (state, action) => {
      state.bountyInvestReactivate = null;
    },
    [checkBountySubmitUnlock.fulfilled]: (state, action) => {
      state.bountySubmitUnlock = action.payload.bountySubmitUnlock;
    },
    [checkBountySubmitUnlock.rejected]: (state, action) => {
      state.bountySubmitUnlock = null;
    },
    [reactiveBountySubmit.fulfilled]: (state, action) => {
      state.bountySubmitReactivate = action.payload.bountySubmitReactivate;
    },
    [reactiveBountySubmit.rejected]: (state, action) => {
      state.bountySubmitReactivate = null;
    },
    [getChartBalance.fulfilled]: (state, action) => {
      state.chartBalance = action.payload.chartBalance;
    },
    [getChartBalance.rejected]: (state, action) => {
      state.chartBalance = null;
    },
    [checkBountyInvestReactivate.fulfilled]: (state, action) => {
      state.bountyInvestReactivateCheck =
        action.payload.bountyInvestReactivateCheck;
    },
    [checkBountyInvestReactivate.rejected]: (state, action) => {
      state.bountyInvestReactivateCheck = null;
    },
    [checkBountySubmitReactivate.fulfilled]: (state, action) => {
      state.bountySubmitReactivateCheck =
        action.payload.bountySubmitReactivateCheck;
    },
    [checkBountySubmitReactivate.rejected]: (state, action) => {
      state.bountySubmitReactivateCheck = null;
    },
    [checkUnlockAll.fulfilled]: (state, action) => {
      state.unlockStatusAll = action.payload.unlockStatusAll;
    },
    [checkUnlockAll.rejected]: (state, action) => {
      state.unlockStatusAll = null;
    },
    [getTransaction.fulfilled]: (state, action) => {
      state.transaction = action.payload.transaction;
    },
    [getTransaction.rejected]: (state, action) => {
      state.transaction = null;
    },
  },
});

const { reducer } = bountiesSlice;
export default reducer;
