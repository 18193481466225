import { useEffect } from "react";
import About from "../components/landingPage/About";
import BARRS from "../components/landingPage/BARRS";
import Blog from "../components/landingPage/Blog";
// import CFC from "../components/landingPage/CFC";
import ChartL from "../components/landingPage/ChartL";
import Hero from "../components/landingPage/Hero";
import Mission from "../components/landingPage/Mission";
import OverView from "../components/landingPage/OverView";
import Wealth from "../components/landingPage/Wealth";
import WhatWeOffer from "../components/landingPage/WhatWeOffer";

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="homepage">
      <Hero />
      <WhatWeOffer />
      <Mission />
      <OverView />
      <About />
      {/* <CFC /> */}
      <ChartL />
      <BARRS />
      <Blog />
      <Wealth />
    </div>
  );
};

export default Homepage;
