import { NavbarType } from "../../types/index";
import logo from "../../assets/image/svg/logoLight.svg";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Navbar = ({ openDrawer, setOpenDrawer }: NavbarType) => {
  const { pathname } = useLocation();

  return (
    <nav className={pathname === "/" ? "navbar white" : "navbar"}>
      <Link to="/" className="navbar__logo">
        <img src={logo} alt="" />
      </Link>
      <div className="navbar__main">
        <div className="navbar__main__links">
          <HashLink smooth to="/#mission">
            Mission
          </HashLink>
          <HashLink smooth to="/#about">
            About
          </HashLink>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://brochure.ultainfinityglobalgroup.com/"
          >
            Investor Brochure
          </a>
          {/* <a href="#blog">Blog</a> */}
          <Link
            className={
              pathname.includes("blog")
                ? " navbar__main__links__item active"
                : "navbar__main__links__item"
            }
            to="/blog"
          >
            {" "}
            Blog
          </Link>
          <Link
            className={
              pathname.includes("faq")
                ? " navbar__main__links__item active"
                : "navbar__main__links__item"
            }
            to="/faq"
          >
            {" "}
            FAQ
          </Link>
        </div>
        <div
          className={
            openDrawer ? "navbar__main__icon open" : "navbar__main__icon"
          }
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="navbar__buttons">
        <Link to="/login">
          <button className="navbar__buttons__alt">Login</button>
        </Link>
        <Link to="/signup">
          <button className="navbar__buttons__main">Sign up</button>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
