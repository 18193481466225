import safeIcon from "../assets/image/Safe.png";
import closeIcon from "../assets/image/svg/close.svg";

const PaymentCongratModal = ({
  id,
  close,
}: {
  id?: string;
  close: () => void;
}) => {
  return (
    <div className="paymentCongratModal">
      <div onClick={close} className="invoice__closeBtn">
        <img src={closeIcon} alt="" />
      </div>
      <div className="paymentCongratModal__header">
        Congratulations <img src={safeIcon} alt="" />
      </div>
      <p>
        Your payment is being processed and you will receive the amount once
        payment is successful <br />
        <span>${id}</span>
      </p>
    </div>
  );
};

export default PaymentCongratModal;
