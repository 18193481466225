import { useEffect, useState } from "react";
import PersonalDetail from "./PersonalDetail";
import checkIcon from "../../assets/image/svg/check.svg";
import Verify from "./Verify";
import SelfieWithId from "./SelfieWithId";
import VerificationStatus from "./VerificationStatus";
import { useAppSelector } from "../../hooks/reduxHooks";

const KYC = ({ setShowProfileDetails }: any) => {
  const [currStage, setCurrStage] = useState(1);
  const { progress }: any = useAppSelector((state) => state.settings);

  const stages = [
    {
      name: "Personal Details",
      step: 1,
    },
    {
      name: "Verify Your Identity",
      step: 2,
    },
    {
      name: "Photo Selfie with ID",
      step: 3,
    },
    {
      name: "Verification Status",
      step: 4,
    },
  ];

  useEffect(() => {
    if (progress) {
      if (progress?.profile) {
        setCurrStage(2);
        if (progress?.identity_verification) {
          setCurrStage(3);
          if (progress?.selfie_with_id) {
            setCurrStage(4);
            if (progress?.verification_status?.overall_status) {
              setCurrStage(5);
            }
          }
        }
      }
    }
  }, [progress]);

  return (
    <div className="kyc">
      {currStage === 4 && (
        <div className="kyc__back" onClick={() => setShowProfileDetails(false)}>
          <span>{"<"}</span> Back to profile{" "}
        </div>
      )}
      <div className="kyc__header">
        {stages.map((stage) => {
          return (
            <div
              key={stage.step}
              className={
                currStage === stage.step
                  ? "kyc__header__item active"
                  : "kyc__header__item"
              }
            >
              <div
                className={
                  currStage <= stage.step
                    ? "kyc__header__item__num"
                    : "kyc__header__item__num complete"
                }
              >
                {currStage <= stage.step ? (
                  stage.step
                ) : (
                  <img src={checkIcon} alt="" />
                )}
              </div>
              <span className={currStage <= stage.step ? "" : "complete"}>
                {stage.name}
              </span>
            </div>
          );
        })}
      </div>

      <div className="kyc__main">
        {currStage === 1 && <PersonalDetail setCurrStage={setCurrStage} />}
        {currStage === 2 && <Verify setCurrStage={setCurrStage} />}
        {currStage === 3 && <SelfieWithId setCurrStage={setCurrStage} />}
        {currStage === 4 && (
          <VerificationStatus
            statusData={progress?.verification_status}
            setCurrStage={setCurrStage}
          />
        )}
        {currStage === 5 && (
          <VerificationStatus approved={true} setCurrStage={setCurrStage} />
        )}
      </div>
    </div>
  );
};

export default KYC;
