

import React, { createContext, useContext,  ReactNode } from "react";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/bundle";
import "./assets/styles/scss/main.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import AuthLayout from "./layouts/AuthLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import Home from "./pages/dashboard/Home";
import { ToastContainer } from "react-toastify";
import Signup from "./pages/Signup";
import { useIdleTimer } from "react-idle-timer";
import "react-tooltip/dist/react-tooltip.css";
import "react-circular-progressbar/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import Refer from "./pages/dashboard/Refer";
import Subscribe from "./pages/dashboard/Subscribe";
import Submit from "./pages/dashboard/Submit";
import Invest from "./pages/dashboard/Invest";
import Referral from "./pages/Referral";
import Leaderboard from "./pages/Leaderboard";
import Balance from "./pages/Balance";
import FormLayout from "./layouts/FormLayout";
import Introducer from "./pages/Introducer";
import Beneficiary from "./pages/Beneficiary";
import { useState } from "react";
import Theme from "./Theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./components/styledComponent/globalStyles";
import Recovery from "./pages/Recovery";
import Settings from "./pages/Settings";
import "react-toggle/style.css";
import CreditLine from "./pages/CreditLine";
import Exchange from "./pages/Exchange";
import CashForCharity from "./pages/CashForCharity";
import Wallet from "./pages/Wallet";
import SignupSuccess from "./pages/SignupSuccess";
import ReferralCode from "./pages/ReferralCode";
import IntroducerCFC from "./pages/IntroducerCFC";
import Homepage from "./pages/Homepage";
import LandingLayout from "./layouts/LandingLayout";
import BlogPage from "./pages/BlogPage";
import BlogItemPage from "./pages/BlogItemPage";
import FaqPage from "./pages/FaqPage";
import FaqItemPage from "./pages/FaqItemPage";
import "react-html5-camera-photo/build/css/index.css";
import "./assets/styles/scss/main.scss";
import BiometricSignup from "./pages/BiometricSignup";
import HelpCentre from "./pages/HelpCentre";
import VerifyToken from "./components/VerifyToken";
import { ModeContextProvider } from "./store/modeContext";
import { SocialMediaProvider } from "./store/socialmediaContext";



function App() {
  const localStorage = window.localStorage;
  const savedThemeLocal = localStorage.getItem("globalTheme");

  const [sessionTimeOut, setSessionTimeOut] = useState(false);
  const [theme, setTheme] = useState(
    savedThemeLocal ? savedThemeLocal : "dark"
  );

  const changeTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  const lightTheme = {
    text: "#333",
    textAlt: "#333",
    bg: "#f5f6fd",
    input: "#ededed",
  };
  const darkTheme = {
    text: "#fff",
    textAlt: "#b1b5c3",
    bg: "#111114",
    input: "#232324",
  };

  const onIdle = () => {
    setSessionTimeOut(true);
    setTimeout(() => {
      setSessionTimeOut(false);
    }, 2000);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 600000,
    crossTab: true,
  });

  console.log("getRemainingTime", getRemainingTime);

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <GlobalStyles />

      <Theme theme={theme} setTheme={setTheme}>
      <ModeContextProvider>
        <SocialMediaProvider>
        <div className="app">
          <ToastContainer />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LandingLayout />}>
                <Route index element={<Homepage />} />
                <Route path="blog" element={<BlogPage />} />
                <Route path="blog/:id" element={<BlogItemPage />} />
                <Route path="faq" element={<FaqPage />} />
                <Route path="faq/:slug" element={<FaqItemPage />} />
              </Route>
              <Route path="/" element={<AuthLayout theme={theme} />}>
               
                <Route path="/:id" element={<Signup />} />
                <Route path="login" element={<Login theme={theme} />} />
                <Route path="auth/verify-token" element={<VerifyToken theme={theme} />} />
                <Route path="signup" element={<Signup />} />

                <Route
                  path="recovery/messenger"
                  element={<Recovery type="messenger" />}
                />
                <Route path="help-centre" element={<HelpCentre />} />
                {/* <Route
                  path="recovery/username"
                  element={<Recovery type="username" />}
                /> */}
                {/* <Route
                  path="recovery/phone"
                  element={<Recovery type="phone" />}
                /> */}
              </Route>
              <Route
                path="/signup/biometric"
                element={<BiometricSignup theme={theme} />}
              />
              <Route
                path="/signup/success"
                element={<SignupSuccess theme={theme} />}
              />
              <Route
                path="/enter-referral-code"
                element={<ReferralCode theme={theme} />}
              />

              <Route
                path="/dashboard"
                element={
                  <DashboardLayout
                    sessionTimeOut={sessionTimeOut}
                    changeTheme={changeTheme}
                    theme={theme}
                  />
                }
              >
                <Route index element={<Home />} />
                <Route path="program/refer" element={<Refer />} />
                <Route path="program/subscribe" element={<Subscribe />} />
                <Route
                  path="program/submit/processing/:id"
                  element={<Submit />}
                />
                <Route path="program/submit/" element={<Submit />} />
                <Route path="program/invest" element={<Invest />} />
                <Route
                  path="program/invest/processing/:id"
                  element={<Invest />}
                />
                <Route path="referral" element={<Referral />} />
                <Route path="leaderboard" element={<Leaderboard />} />
                <Route path="balances" element={<Balance theme={theme} />} />
                <Route path="wallet" element={<Wallet />} />
                <Route path="instant-creditline" element={<CreditLine />} />
                <Route path="premarket-exchange" element={<Exchange />} />
                <Route path="cash-for-charity" element={<CashForCharity />} />
                <Route
                  path="cash-for-charity/introducer"
                  element={<IntroducerCFC />}
                />
                <Route
                  path="settings"
                  element={<Settings theme={theme} changeTheme={changeTheme} />}
                />
              </Route>
              <Route
                path="/application"
                element={
                  <FormLayout sessionTimeOut={sessionTimeOut} theme={theme} />
                }
              >
                <Route path="introducer" element={<Introducer />} />
                <Route path="applicant" element={<Beneficiary />} />
              </Route>
              <Route path="/404" element={<div>Page not found</div>} />
              <Route path="*" element={<div>Page not found</div>} />
            </Routes>
          </BrowserRouter>
        </div>
        </SocialMediaProvider>
      </ModeContextProvider>
      </Theme>
    </ThemeProvider>
  );
}

export default App;
