import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("userUlta") || "{}");

export const login: any = createAsyncThunk(
  "auth/login",
  async ({ sid, username }: { sid: string; username: string }, thunkAPI) => {
    try {
      const data = await AuthService.login(sid, username);
      return { user: data.data.user };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const signup: any = createAsyncThunk(
  "auth/signup",
  async (
    {
      sid,
      username,
      phone,
      role,
    }: { sid: string; username: string; phone: string; role: string },
    thunkAPI
  ) => {
    try {
      const data = await AuthService.signup(sid, username, phone, role);
      return { user: data.data.user };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const registerBiometric: any = createAsyncThunk(
  "auth/registerBiometric",
  async (
    {
      sid,
      challenge_id,
      biometrics_id,
    }: { sid: string; challenge_id: any; biometrics_id: string },
    thunkAPI
  ) => {
    try {
      const data = await AuthService.registerBiometric(
        sid,
        challenge_id,
        biometrics_id
      );
      return { biometric: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const loginBiometric: any = createAsyncThunk(
  "auth/loginBiometric",
  async (
    {
      challenge_id,
      biometrics_id,
    }: { sid: string; challenge_id: any; biometrics_id: string },
    thunkAPI
  ) => {
    try {
      const data = await AuthService.loginBiometric(
        challenge_id,
        biometrics_id
      );
      return { biometricData: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const checkBiometric: any = createAsyncThunk(
  "auth/checkBiometric",
  async (id, thunkAPI) => {
    try {
      const data = await AuthService.checkBiometric();
      return { checkBiometric: data.message };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState: any = user.token
  ? {
      isLoggedIn: true,
      user,
      biometric: null,
      biometricData: null,
      checkBiometric: null,
    }
  : {
      isLoggedIn: false,
      user: null,
      biometric: null,
      biometricData: null,
      checkBiometric: null,
    };

console.log("user", user);
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginToken: (state, action) => {
      localStorage.setItem("userUlta", JSON.stringify(action.payload));
      state.user = action.payload.user;
      state.isLoggedIn = true;
    },
    logout: (state) => {
      localStorage.removeItem("userUlta");
      state.user = null;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [signup.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [signup.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [registerBiometric.fulfilled]: (state, action) => {
      // state.isLoggedIn = true;
      state.biometric = action.payload.biometric;
    },
    [registerBiometric.rejected]: (state, action) => {
      // state.isLoggedIn = false;
      state.biometric = null;
    },
    [loginBiometric.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.biometricData;
      // state.biometricData = action.payload.biometricData;
    },
    [loginBiometric.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [checkBiometric.fulfilled]: (state, action) => {
      state.checkBiometric = action.payload.checkBiometric;
      // state.biometricData = action.payload.biometricData;
    },
    [checkBiometric.rejected]: (state, action) => {
      state.checkBiometric = null;
    },
  },
});

export const { logout, loginToken } = authSlice.actions;
const { reducer } = authSlice;
export default reducer;
