import logo from "../assets/image/svg/logo.svg";
import logoLight from "../assets/image/svg/logoLight.svg";
import { Button } from "../components/styledComponent/form/Buttons";
import { InputPin } from "../components/styledComponent/form/textFields/styles";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { checkUserReferral, storeReferralCode } from "../store/slices/airdrops";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import ReactLoading from "react-loading";
import { colorTheme } from "../assets/colors";
import noDataIcon from "../assets/image/svg/noData.svg";

const ReferralCode = ({ theme }: { theme: string }) => {
  const [referral_id, setReferral_id] = useState("");
  const [disableBtn, setDisableBtn] = useState(true);
  // const [error, setError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [registeredWithCode, setregisteredWithCode] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const { userReferral }: any = useAppSelector((state) => state.airdrops);

  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const handleClick = () => {
    if (referral_id.length === 8) {
      setLoading(true);
      dispatch(storeReferralCode({ referral_id }))
        .unwrap()
        .then(() => {
          toast.success("Successful", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          navigate("/dashboard");
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const handleCheckUserReferral = () => {
      setLoadingMain(true);
      dispatch(checkUserReferral())
        .unwrap()
        .then(() => {
          setLoadingMain(false);
        })
        .catch((err: any) => {
          setLoadingMain(false);
          if (err === "User signed up through Referral") {
            setregisteredWithCode(true);
          } else {
            setregisteredWithCode(false);
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    };
    handleCheckUserReferral();
  }, [dispatch]);

  useEffect(() => {
    if (referral_id.length === 8) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [referral_id]);

  return (
    <div className="authLayout">
      <div className="authLayout__header">
        <Link to="/dashboard">
          <img
            src={theme === "light" ? logoLight : logo}
            alt=""
            className="authLayout__header__logo"
          />
        </Link>
      </div>

      <div className="authLayout__main">
        {loadingMain ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <ReactLoading
              color={colorTheme.primary}
              width={50}
              height={50}
              type="spin"
            />
          </div>
        ) : userReferral ? (
          <>
            <div className="authForm__user">
              <h2 className="authForm__user__title">Input Referral Code</h2>
              <p className="authForm__user__disc">
                If you have been introduced, please enter code of referral below
              </p>

              <InputPin
                onChange={(e) => setReferral_id(e.target.value)}
                type="text"
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              {referral_id.length > 0 ? (
                <Button
                  disabled={disableBtn}
                  $disable={disableBtn}
                  onClick={handleClick}
                  $fill
                >
                  {loading ? (
                    <ReactLoading
                      color={"white"}
                      width={30}
                      height={30}
                      type="spin"
                    />
                  ) : (
                    "Continue"
                  )}
                </Button>
              ) : (
                <Button onClick={() => navigate("/dashboard")} $fill>
                  Skip
                </Button>
              )}
            </div>
          </>
        ) : registeredWithCode ? (
          <>
            <h2 style={{ textAlign: "center" }}>
              Already Submitted a referral code
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button onClick={() => navigate("/dashboard")} $fill>
                Skip
              </Button>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // marginTop: "40px",
            }}
          >
            <img src={noDataIcon} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferralCode;
