import axios from "axios";
import authHeader from "./authHeader";

const API_URL = process.env.REACT_APP_API_URL + "/airdrop-bounty/";

const registerWallet = async (data: any) => {
  return await axios({
    url: API_URL + "app/crypto-wallet/add-user-wallet-address",
    headers: authHeader(),
    data,
    method: "post",
  }).then((response) => {
    return response.data;
  });
};

const getWallet = async () => {
  return await axios({
    url: API_URL + "app/crypto-wallet/get-user-wallet-address",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const walletSevice = {
  registerWallet,
  getWallet,
};

export default walletSevice;
