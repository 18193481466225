// import logo from "../assets/image/svg/logoMain.svg";
import logo from "../assets/image/svg/logo.svg";
import logoLight from "../assets/image/svg/logoLight.svg";
import profileIcon from "../assets/image/svg/Profile.svg";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import sunIcon from "../assets/image/svg/sun.svg";
import { ReactComponent as BurgerIcon } from "../assets/image/svg/Mask.svg";
import moonIcon from "../assets/image/svg/moon.svg";

import { TopbarType } from "../types";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../store/slices/auth";
import { useEffect } from "react";
import { getSettings } from "../store/slices/settings";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

const Topbar = ({
  openDrawer,
  setOpenDrawer,
  theme,
  changeTheme,
}: TopbarType) => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };
  const { settings }: any = useAppSelector((state) => state.settings);

  useEffect(() => {
    const handleGetSettings = () => {
      dispatch(getSettings())
        .unwrap()
        .then(() => {})
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };
    handleGetSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="topbar">
      <Tooltip id="my-tooltip" />
      <div className="topbar__left">
        <img src={theme === "light" ? logoLight : logo} alt="" />
      </div>
      <div className="topbar__right">
        <img
          onClick={changeTheme}
          className="topbar__right__themeIcon"
          src={theme === "light" ? moonIcon : sunIcon}
          alt=""
        />
        <Link to="/dashboard/settings">
          <div className="topbar__right__profile">
            <div className="topbar__right__profile__icon">
              <img
                src={
                  settings?.[0]?.avatar ? settings?.[0]?.avatar : profileIcon
                }
                alt=""
              />
            </div>
            <span>{user?.display_name}</span>
          </div>
        </Link>
        <div
          onClick={() => setOpenDrawer(!openDrawer)}
          className="topbar__right__burgerIcon"
        >
          <BurgerIcon fill={theme === "light" ? "black" : "white"} />
        </div>
        {/* <button onClick={handleLogout} className="topbar__right__logoutBtn">
          logout
        </button> */}
        {/* <img
         
          src={burgerIcon}
          alt=""
          
        /> */}
        {/* <button className="topbar__right__btn">Connect Wallet</button> */}
      </div>
    </div>
  );
};

export default Topbar;
