import creditLineIcon from "../assets/image/svg/creditLine.svg";
import coinbaseIcon from "../assets/image/svg/Coinbase.svg";
import binanceIcon from "../assets/image/svg/Binance.svg";
import cloverIcon from "../assets/image/svg/Clover.svg";
import metamaskIcon from "../assets/image/svg/Metamask.svg";
import coinPaymentsIcon from "../assets/image/svg/CoinPayments.svg";
import zenGoIcon from "../assets/image/svg/Zengo.svg";
import trustWalletIcon from "../assets/image/svg/Trust Wallet.svg";
import cryptoIcon from "../assets/image/crypto.png";
import ledgerIcon from "../assets/image/svg/Ledger.svg";
import guardaIcon from "../assets/image/guarda.png";
import lunoIcon from "../assets/image/svg/Luno.svg";
import exodusIcon from "../assets/image/svg/Exodus.svg";
import phantomIcon from "../assets/image/svg/Phantom.svg";
import solletIcon from "../assets/image/svg/Sollet.svg";
import solflareIcon from "../assets/image/svg/Solflare.svg";
import faq1Icon from "../assets/image/svg/faq1.svg";
import faq2Icon from "../assets/image/svg/faq2.svg";
import atomicIcon from "../assets/image/atomic.png";
import { TextField } from "../components/styledComponent/form/textFields";
import { Button } from "../components/styledComponent/form/Buttons";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import WalletModal from "../components/WalletModal";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { registerWallet } from "../store/slices/wallet";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { checkUserReferral, storeReferralCode } from "../store/slices/airdrops";

const Wallet = () => {
  const [wallet, setWallet] = useState({
    address: "",
    type: "Coinbase Commerce",
    name: "Coinbase",
    img: coinbaseIcon,
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [inputError, setInputError] = useState({ address: "" });
  const [loading, setLoading] = useState(false);
  const [showCodeLink, setShowCodeLink] = useState(true);

  const code = localStorage.getItem("code");
  const dispatch = useAppDispatch();
  const { userReferral }: any = useAppSelector((state) => state.airdrops);

  const openModal = (): void => {
    setModalIsOpen(true);
  };
  function closeModal(): void {
    setModalIsOpen(false);
  }

  // console.log("userReferral", userReferral);

  const coinbaseWallet = [
    { name: "Coinbase", img: coinbaseIcon },
    { name: "Binance", img: binanceIcon },
    { name: "Clover", img: cloverIcon },
    { name: "Metamask", img: metamaskIcon },
    { name: "CoinPayments", img: coinPaymentsIcon },
    { name: "ZenGo", img: zenGoIcon },
    { name: "Trust Wallet", img: trustWalletIcon },
    { name: "Crypto.com", img: cryptoIcon },
    { name: "Ledger", img: ledgerIcon },
    { name: "Guarda", img: guardaIcon },
    { name: "Luno", img: lunoIcon },
    { name: "Exodus", img: exodusIcon },
  ];

  const solanaWallet = [
    { name: "Phantom", img: phantomIcon },
    { name: "Sollet", img: solletIcon },
    { name: "Solflare", img: solflareIcon },
    { name: "Atomic", img: atomicIcon },
  ];

  const validate = () => {
    let addressError = "";

    if (!wallet.address) {
      addressError = "address is required";
    }

    if (addressError) {
      setInputError({ address: addressError });

      return false;
    }
    return true;
  };

  const handleClick = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setLoading(true);
      setInputError({ address: "" });
      const data = {
        platform: wallet.name,
        address: wallet.address,
      };
      setLoading(true);
      dispatch(registerWallet(data))
        .unwrap()
        .then(() => {
          dispatch(checkUserReferral())
            .unwrap()
            .then(() => {
              setLoading(false);
              openModal();
              toast.success("Register Wallet Successful", {
                position: toast.POSITION.TOP_RIGHT,
              });
              if (code) {
                dispatch(storeReferralCode({ rac_id: code }))
                  .unwrap()
                  .then(() => {})
                  .catch((err: any) => {});
              }
            })
            .catch((err: any) => {
              if (err === "User signed up through Referral") {
                setShowCodeLink(false);
              } else {
                setShowCodeLink(true);
              }
              setLoading(false);
              openModal();
              toast.success("Register Wallet Successful", {
                position: toast.POSITION.TOP_RIGHT,
              });
            });

          // openModal();
          // setLoading(false);
          // toast.success("Register Wallet Successful", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userReferral) {
      setShowCodeLink(true);
    }
  }, [userReferral]);

  return (
    <div className="wallet">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Application Information"
      >
        <WalletModal
          wallet={wallet}
          closeModal={closeModal}
          showCodeLink={showCodeLink}
        />
      </Modal>
      <h1 className="wallet__title">
        <div className="wallet__title__img">
          <img src={creditLineIcon} alt="" />
        </div>
        Wallet
      </h1>

      <div className="wallet__main">
        <h2 className="wallet__main__title">Be ‘Airdropped’ In a SNAP!</h2>
        <p className="wallet__main__disc">
          Select one of the under-listed wallets to receive your airdropped
          token. Although we highly recommend the Ultainfinity wallet, these 16
          wallets are utilitarian in unlocking your Ultainfinity Airdrops and
          Bounties credits, Ultainfinities tokens worth. Enter your correct
          wallet ID/address to complete the airdrop giveaway process
        </p>
        <div className="wallet__main__coinbase">
          <h3 className="wallet__main__coinbase__title">Coinbase Commerce</h3>
          <div className="wallet__main__coinbase__wallets">
            {coinbaseWallet.map((item, i) => {
              return (
                <div
                  onClick={() =>
                    setWallet((curr) => {
                      return {
                        ...curr,
                        name: item.name,
                        img: item.img,
                        type: "Coinbase Commerce",
                      };
                    })
                  }
                  className={
                    wallet.name === item.name
                      ? "wallet__main__solana__wallets__item active"
                      : "wallet__main__solana__wallets__item"
                  }
                  key={i}
                >
                  {i + 1}. <img src={item.img} alt="" />{" "}
                  <span>{item.name}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="wallet__main__solana">
          <h3 className="wallet__main__solana__title">Solana Pay</h3>
          <div className="wallet__main__solana__wallets">
            {solanaWallet.map((item, i) => {
              return (
                <div
                  key={i}
                  onClick={() =>
                    setWallet((curr) => {
                      return {
                        ...curr,
                        name: item.name,
                        img: item.img,
                        type: "Solana Pay",
                      };
                    })
                  }
                  className={
                    wallet.name === item.name
                      ? "wallet__main__solana__wallets__item active"
                      : "wallet__main__solana__wallets__item"
                  }
                >
                  {i + 1}. <img src={item.img} alt="" />{" "}
                  <span>{item.name}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="wallet__main__address">
          <div className="wallet__main__address__inputCon">
            <TextField
              name="walletAddress"
              label="Enter your wallet address"
              onChange={(e) =>
                setWallet((curr) => {
                  return {
                    ...curr,
                    address: e.target.value,
                  };
                })
              }
              value={wallet.address}
              inputError={inputError?.address}
            />
          </div>

          <Button
            style={{ marginTop: "29px" }}
            $fill
            radius="5px"
            width="200px"
            onClick={handleClick}
          >
            {loading ? (
              <ReactLoading color="white" width={30} height={30} type="spin" />
            ) : (
              "Register Wallet"
            )}
          </Button>
        </div>
      </div>

      <h2 className="wallet__title">FAQ</h2>
      <div className="wallet__faq">
        <div className="wallet__faq__item">
          <div className="wallet__faq__item__main">
            <img src={faq1Icon} alt="" />
            <div className="wallet__faq__item__main__disc">
              <span>Video tutorial</span>
              <p>HOW TO DOWNLOAD AND OPERATE A CRYPTO HOT WALLET </p>
            </div>
          </div>
          <Button>Watch now</Button>
        </div>
        <div className="wallet__faq__item">
          <div className="wallet__faq__item__main">
            <img src={faq2Icon} alt="" />
            <div className="wallet__faq__item__main__disc">
              <span>Video tutorial</span>
              <p>HOW TO DOWNLOAD AND OPERATE A CRYPTO COLD WALLET </p>
            </div>
          </div>
          <Button>Watch now</Button>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
