import axios from "axios";
import authHeader from "./authHeader";

const API_URL = process.env.REACT_APP_API_URL + "/airdrop-bounty/";

const saveSettings = async (data: any) => {
  return await axios({
    url: API_URL + "app/accounts/save-settings",
    headers: authHeader(),
    data,
    method: "post",
  }).then((response) => {
    return response.data;
  });
};

const getSettings = async () => {
  return await axios({
    url: API_URL + "app/accounts/get-settings",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const getProfileDetails = async () => {
  return await axios({
    url: process.env.REACT_APP_API_URL + "/users/v1/user-profile",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const postProfileDetails = async (data: any) => {
  return await axios({
    url: API_URL + "/app/profile/update-profile",
    headers: authHeader(),
    data,
    method: "post",
  }).then((response) => {
    return response.data;
  });
};

const getProgress = async () => {
  return await axios({
    url: API_URL + "app/profile/progress",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const postDocument = async (data: any) => {
  return await axios({
    url: API_URL + "/app/profile/document-selfie-upload",
    headers: authHeader(),
    data,
    method: "post",
  }).then((response) => {
    return response.data;
  });
};

const postSelfie = async (data: any) => {
  return await axios({
    url: API_URL + "/app/profile/selfie-id-upload",
    headers: authHeader(),
    data,
    method: "post",
  }).then((response) => {
    return response.data;
  });
};

const getStat = async () => {
  return await axios({
    url: API_URL + "app/profile/statistics",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const settingsSevice = {
  saveSettings,
  getSettings,
  getProfileDetails,
  postProfileDetails,
  getProgress,
  postDocument,
  postSelfie,
  getStat,
};

export default settingsSevice;
