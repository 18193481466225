import { useEffect, useState } from "react";
import SocialLinks from "../components/SocialLinks";
import { Button } from "../components/styledComponent/form/Buttons";
import {
  InputPin,
  InputUser,
} from "../components/styledComponent/form/textFields/styles";
import profileIcon from "../assets/image/svg/Profile.svg";
import axios from "axios";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { useAppDispatch } from "../hooks/reduxHooks";
import { signup } from "../store/slices/auth";
import GetHelp from "./GetHelp";
import { Link } from "react-router-dom";
import { useModeContext } from "../store/modeContext";
import { useSocialMediaContext } from "../store/socialmediaContext";

const AuthFormSignup = () => {
  const [step, setstep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [btnDisable, setbtnDisable] = useState(true);
  const [userAuth, setUserAuth] = useState({
    username: "",
    phone: "",
    role: "investor",
    sid: "",
    referral_code: "",
  });
  const { setMode } = useModeContext();
  const { selectedSocialMedia } = useSocialMediaContext();
  const { updateSelectedSocialMedia } = useSocialMediaContext();
  const dispatch = useAppDispatch();
  const [channel, setChannel] = useState("");

  useEffect(() => {
    // Retrieve value from local storage during component mount
    const storedSocialMedia = localStorage.getItem("selectedSocialMedia");
    if (storedSocialMedia) {
      // Update state with the retrieved value
      setChannel(storedSocialMedia);
      console.log("getting a new channel")
      
    }
  }, [updateSelectedSocialMedia]);

  // const { message }: any = useAppSelector((state) => state.message);

  const navigate = useNavigate();
  const { id } = useParams();

  const handleVerifyOtp = async () => {
    const code = otp.toUpperCase();
    setLoading(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/users/v1/user-account/otp-verify`,
        { code, channel }
      );
      setLoading(false);
      if (response.data.type === "success") {
        if (response.data?.data?.user_status !== "creating") {
          toast.warning("User Already exist, Login", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/login");
        } else {
          setUserAuth((curr) => {
            return { ...curr, sid: response.data?.data?.sid };
          });
          // setUserStatus(response.data?.data?.user_status);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setstep(3);
        }
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err: any) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      setLoading(false);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSignup = async () => {
    setLoading(true);

    // setLoading(true);
    dispatch(signup(userAuth))
      .unwrap()
      .then(() => {
        setLoading(false);
        toast.success("Successfully Signed up", {
          position: toast.POSITION.TOP_RIGHT,
        });

        navigate("/signup/biometric");
      })
      .catch((err: any) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  };

  const handleClick = () => {
    if (step === 2) {
      handleVerifyOtp();
      // setstep(3);
    }
    if (step === 3) {
      handleSignup();
      // navigate("/signup/biometric");
    }
  };

  useEffect(() => {
    setMode('signup');
    if (otp && otp.length === 6) {
      setbtnDisable(false);
    } else {
      setbtnDisable(true);
    }
  }, [otp]);

  useEffect(() => {
    if (id) {
      localStorage.setItem("code", id);
    }
  }, [id]);

  useEffect(() => {
    if (userAuth.username.length > 7 && userAuth.phone.length > 4) {
      setbtnDisable(false);
    } else {
      setbtnDisable(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <div className="authForm">
      {step === 1 && (
        <div className="authForm__otp">
          <h2 className="authForm__otp__title">
            Complete Onestep Verification
          </h2>
          <p className="authForm__otp__disc">
            Complete the Onestep verification to proceed. it is important for
            account verification
          </p>
          <h3 style={{ marginBottom: 20 }}>SIGN UP</h3>

          {/* <div className="authForm__otp__choice">
            Select a messenger of your choice
          </div> */}

          <div className="authForm__otp__messanger">
            <p>Kindly select a messenger</p>
            <div className="authForm__otp__socialLinksCon">
              <div onClick={() => setstep(2)}>
                <SocialLinks />
              </div>
            </div>
            <div className="getHelp__main">
              <p className="getHelp__disc">
                Having trouble using OneStep Verification?
              </p>
              <Link to="/help-centre">
                <span className="getHelp__btn">Help Centre</span>
              </Link>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="authForm__verify">
          <h2 className="authForm__verify__title">OTP Verification</h2>
          <h3>channel: {channel}</h3>
          <p className="authForm__verify__subTitle">
            Complete the Onestep verification to proceed. it is important for
            account verification
          </p>

          <p className="authForm__verify__disc">
            Enter the OTP verification code received in your messenger. 2 Factor
            Authentications are essential identification and verification
            measures, that OneStep ID by Ultainfinity uses to protect you and
            your account 24/7.
          </p>

          <InputPin onChange={(e) => setOtp(e.target.value)} type="text" />
        </div>
      )}

      {step === 3 && (
        <div className="authForm__user">
          <h2 className="authForm__user__title">Username & Phone Number</h2>
          <p className="authForm__user__disc">
            Complete the Onestep verification to proceed. it is important for
            account verification
          </p>
          <div className="authForm__user__biometric">
            <p className="authForm__user__disc">
              Create Username and Enter Phone Number
            </p>
            <div className="authForm__user__input">
              <div className="authForm__user__input__icon">
                <img src={profileIcon} alt="profile icon" />
              </div>
              <InputUser
                onChange={(e) =>
                  setUserAuth((curr) => {
                    return { ...curr, username: e.target.value };
                  })
                }
                type="text"
                placeholder="Enter Username"
              />
              <span style={{ fontSize: 12, position: "relative", top: -5 }}>
                Minimum of 8 character
              </span>
              <PhoneInput
                country={"us"}
                value={userAuth.phone}
                onChange={(phone) => setUserAuth({ ...userAuth, phone: phone })}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <Button
                disabled={btnDisable}
                $disable={btnDisable}
                onClick={handleClick}
                width="250px"
                $fill
              >
                {loading ? (
                  <ReactLoading
                    color="white"
                    width={30}
                    height={30}
                    type="spin"
                  />
                ) : (
                  "Confirm OneStep ID"
                )}
              </Button>
            </div>
          </div>

          {/* <span
            className="authForm__user__biometric"
            onClick={() => setModalIsOpen(true)}
          >
            Register Biometrics For Easy Login & Account Security
          </span> */}
        </div>
      )}

      <div className="authForm__btnCon">
        {step === 2 && (
          <Button
            disabled={btnDisable}
            $disable={btnDisable}
            onClick={handleClick}
            width="250px"
            $fill
          >
            {loading ? (
              <ReactLoading color="white" width={30} height={30} type="spin" />
            ) : (
              "Continue"
            )}
          </Button>
        )}
      </div>
      <p className="authForm__terms">
        <b style={{ fontSize: 16, marginBottom: 10, display: "inline-block" }}>
          Already Registered for OneStep?
        </b>
        <br />
        If you’ve previously registered as a OneStep User on another
        Ultainfinity Plattform, then you simply need to proceed to Login page.
        You do not need to Sign up again for OneStep on this Ultainfinity’
        Platform.
        <br />
        <Link to="/login">Login</Link>
      </p>

      <p className="authForm__terms">
        By Signing Up you agree to our{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://ultainfinitywealthlaunchpad.com/privacy"
        >
          Terms & Privacy Policy.
        </a>{" "}
      </p>
    </div>
  );
};

export default AuthFormSignup;
