import { Link } from "react-router-dom";
import uttaCoin from "../assets/image/uttaCoinAlt.png";
import { Button } from "./styledComponent/form/Buttons";

const ApplicationResponse = ({ type }: { type?: string }) => {
  return (
    <div className="applicationResponse">
      <img src={uttaCoin} alt="" />
      <h3 className="applicationResponse__title">
        {type === "success"
          ? "Congratulations! ✅"
          : type === "fail"
          ? "Oops! 😢"
          : "Application in Progress...please check back later."}
      </h3>
      {type === "success" && (
        <h4 className="applicationResponse__subTitle">
          Your Application has been approved
        </h4>
      )}
      {type === "fail" && (
        <h4 className="applicationResponse__subTitle">
          Thank you for your interest
        </h4>
      )}
      <p className="applicationResponse__disc">
        {type === "success" ? (
          "After careful consideration, we are happy to let you know we will be moving forward with your application for Ultainfinity Cash for Charity Introducers Role. Thank you again for your patience"
        ) : type === "fail" ? (
          "After careful consideration, we unfortunaately will not be moving forward with your application for Ultainfinity Cash for Charity Introducers Role. Thank you again for your time"
        ) : (
          <>
            We will review your application and provide feedback shortly. In the
            meantime, please look through our projects and wealth creation
            opportunities on{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ultainfinitywealthlaunchpad.com/"
            >
              https//ultainfinitywealthlaunchpad.com.
            </a>
          </>
        )}
      </p>

      <div className="applicationResponse__btn">
        <Link to="/dashboard">
          <Button $fill width="auto" radius="10px">
            Continue to the General Airdrops & Bounties Dashboard.
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ApplicationResponse;
