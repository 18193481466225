import { PageTitle } from "../components/styledComponent/headers/styles";
import userIcon from "../assets/image/svg/userAlt.svg";
import keyboardIcon from "../assets/image/svg/keyboard.svg";
// import laptopIcon from "../assets/image/svg/laptop.svg";
import uploadIcon from "../assets/image/svg/uploadAlt.svg";
// import barcodeIcon from "../assets/image/svg/barcode.svg";
import Profile from "../components/Profile";
import profileIcon from "../assets/image/svg/Profile.svg";
import { useEffect, useState } from "react";
import Appearances from "../components/Appearances";
import Notification from "../components/Notification";
// import Session from "../components/Session";
// import OneStepId from "../components/OneStepId";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { toast } from "react-toastify";
import { getProgress, getSettings } from "../store/slices/settings";
import noDataIcon from "../assets/image/svg/noData.svg";
import ReactLoading from "react-loading";
import { colorTheme } from "../assets/colors";
// import ProfileComplete from "../components/profileMain/ProfileComplete";
import { CircularProgressbar } from "react-circular-progressbar";

const Settings = ({
  theme,
  changeTheme,
}: {
  theme: string;
  changeTheme: () => void;
}) => {
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingPro, setLoadingPro] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showProfileDetails, setShowProfileDetails] = useState(false);
  const [progressPer, setProgressPer] = useState(10);

  const { user }: any = useAppSelector((state) => state.auth);
  const { settings }: any = useAppSelector((state) => state.settings);
  const { progress }: any = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  const fetchNewSettings = () => {
    setRefresh(!refresh);
  };

  const tabData = [
    { name: "Profile", img: userIcon },
    { name: "Notifications", img: uploadIcon },
    { name: "Appearnces", img: keyboardIcon },
    // { name: "Sessions & login history", img: laptopIcon },
    // { name: "OneStepID", img: barcodeIcon },
  ];

  useEffect(() => {
    const handleGetSettings = () => {
      setLoading(true);
      dispatch(getSettings())
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((err: any) => {
          // toast.error(err, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          setLoading(false);
        });
    };
    handleGetSettings();

    const handleGetProgress = () => {
      setLoadingPro(true);
      dispatch(getProgress())
        .unwrap()
        .then(() => {
          setLoadingPro(false);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingPro(false);
        });
    };

    handleGetProgress();

    // const handleGetStat = () => {
    //   setLoadingPro(true);
    //   dispatch(getStat())
    //     .unwrap()
    //     .then(() => {
    //       setLoadingPro(false);
    //     })
    //     .catch((err: any) => {
    //       toast.error(err, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //       setLoadingPro(false);
    //     });
    // };
    // handleGetStat();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (progress) {
      let total = 0;

      if (progress?.profile) total = total + 25;
      if (progress?.identity_verification) total = total + 25;
      if (progress?.selfie_with_id) total = total + 25;
      if (progress?.verification_status?.overall_status) total = total + 25;

      setProgressPer(total === 0 ? 10 : total);
    }
  }, [progress]);

  return (
    <>
      {/* <ProfileComplete /> */}
      <div className="settings">
        <div className="settings__left">
          <PageTitle>Settings</PageTitle>

          <ul className="settings__left__links">
            {tabData.map((item, i) => {
              return (
                <div style={{ minWidth: i === 3 ? "180px" : "auto" }} key={i}>
                  <li
                    onClick={() => setTab(i + 1)}
                    className={
                      tab === i + 1
                        ? "settings__left__links__item active"
                        : "settings__left__links__item"
                    }
                  >
                    <img src={item.img} alt="" /> {item.name}
                  </li>
                  {/* {i === 2 && <div className="settings__left__links__line"></div>} */}
                </div>
              );
            })}
          </ul>
          <div className="settings__left__circle">
            {loadingPro ? (
              <ReactLoading
                color={colorTheme.primary}
                width={50}
                height={50}
                type="spin"
              />
            ) : (
              <>
                <CircularProgressbar
                  value={progressPer}
                  text={`${progressPer}%`}
                />
                <p>Profile Completion</p>
              </>
            )}
          </div>
        </div>
        <div className="settings__right">
          {loading ? (
            <ReactLoading
              color={colorTheme.primary}
              width={50}
              height={50}
              type="spin"
            />
          ) : settings ? (
            <>
              {tab <= 3 && !showProfileDetails && (
                <div className="settings__right__user">
                  <div className="settings__right__user__img">
                    <img
                      src={
                        settings?.[0]?.avatar
                          ? settings?.[0]?.avatar
                          : profileIcon
                      }
                      alt=""
                    />
                  </div>
                  <div className="settings__right__user__info">
                    <h3>
                      {user?.display_name} <span>User</span>
                    </h3>
                    <p>{user?.phone}</p>
                  </div>
                </div>
              )}

              {tab === 1 && (
                <Profile
                  setShowProfileDetails={setShowProfileDetails}
                  showProfileDetails={showProfileDetails}
                  fetchNewSettings={fetchNewSettings}
                />
              )}
              {tab === 2 && (
                <Notification fetchNewSettings={fetchNewSettings} />
              )}
              {tab === 3 && (
                <Appearances theme={theme} changeTheme={changeTheme} />
              )}
              {/* {tab === 4 && <Session />} */}
              {/* {tab === 5 && <OneStepId />} */}
            </>
          ) : (
            <img src={noDataIcon} alt="" />
          )}
        </div>
      </div>
    </>
  );
};

export default Settings;
