import { useState } from "react";
import getHelpSvgIcon from "../assets/image/svg/getHelpSvg.svg";
import { Button } from "../components/styledComponent/form/Buttons";
import Modal from "react-modal";
import GetHelp from "../components/GetHelp";

const HelpCentre = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenOS, setModalIsOpenOS] = useState(false);
  const [modalIsOpenBD, setModalIsOpenBD] = useState(false);

  return (
    <div className="helpCentre">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="How to Register Biometrics (Apple devices)"
      >
        <video style={{ width: "90vw", maxWidth: "800px" }} controls>
          <source
            src="https://d16wxdh43v8ajq.cloudfront.net/Skype_Video11.mp4"
            type="video/mp4"
          />
        </video>
      </Modal>
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpenBD}
        onRequestClose={() => setModalIsOpenBD(false)}
        contentLabel="How to Register Biometrics (Desktop)"
      >
        <video style={{ width: "90vw", maxWidth: "800px" }} controls>
          <source
            src="https://d16wxdh43v8ajq.cloudfront.net/Skype_Video10.mp4"
            type="video/mp4"
          />
        </video>
      </Modal>
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpenOS}
        onRequestClose={() => setModalIsOpenOS(false)}
        contentLabel="reactivate payment"
      >
        <GetHelp closeModal={() => setModalIsOpenOS(false)} />
      </Modal>
      <img className="helpCentre__img" src={getHelpSvgIcon} alt="" />

      <div className="helpCentre__main">
        <h1 className="helpCentre__main__title">Help Centre</h1>

        <div className="helpCentre__main__list">
          <div className="helpCentre__main__list__item">Recovery Centre</div>
          <div
            className="helpCentre__main__list__item"
            onClick={() => setModalIsOpenOS(true)}
          >
            Get help with OneStep Verification
          </div>
          <div
            onClick={() => setModalIsOpenBD(true)}
            className="helpCentre__main__list__item"
          >
            How to Register Biometrics (Desktop)
          </div>
          <div className="helpCentre__main__list__item">
            How to Register Biometrics (Andriod devices)
          </div>
          <div
            onClick={() => setModalIsOpen(true)}
            className="helpCentre__main__list__item"
          >
            How to Register Biometrics (Apple devices)
          </div>

          <div className="helpCentre__main__list__item">
            Setup Biometrics in Settings page
          </div>
        </div>

        {/* <Button width="100%" $fill radius="10px">
          Continue
        </Button> */}
      </div>
    </div>
  );
};

export default HelpCentre;
