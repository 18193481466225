import { Button } from "./styledComponent/form/Buttons";
import { TextField } from "./styledComponent/form/textFields";
import uploadIcon from "../assets/image/svg/upload.svg";
import { ErrorMessage } from "./styledComponent/form/textFields/styles";
import { useRef } from "react";

const BeneficiaryPart2 = ({
  setPart,
  filesName,
  inputError,
  handleChange,
  setFormData,
  formData,
}: any) => {
  const cvInput: any = useRef(null);
  const handleClick = (reference: any) => {
    reference.current.click();
  };

  const handleInputChangeRO = (value: string, num: number, name: string) => {
    let newData = [...formData.registered_officers];

    newData[num][name] = value;

    setFormData({ ...formData, registered_officers: newData });
  };
  const handleInputChangeOR = (value: string, num: number, name: string) => {
    let newData = [...formData.organisation_referees];

    newData[num][name] = value;

    setFormData({ ...formData, organisation_referees: newData });
  };

  return (
    <div className="beneficiary__form__part2">
      <h3 className="beneficiary__form__part2__title">Part Two</h3>
      <h4>Operating Executives</h4>

      <div className="beneficiary__form__part2__operating">
        <TextField
          onChange={(e) =>
            setFormData((curr: any) => {
              return {
                ...curr,
                operating_executive: {
                  ...curr.operating_executive,
                  name: e.target.value,
                },
              };
            })
          }
          inputError={inputError.operating_executive?.name}
          value={formData.operating_executive.name}
          name="name"
          label="Name*"
        />
        <TextField
          onChange={(e) =>
            setFormData((curr: any) => {
              return {
                ...curr,
                operating_executive: {
                  ...curr.operating_executive,
                  position: e.target.value,
                },
              };
            })
          }
          inputError={inputError.operating_executive?.position}
          value={formData.operating_executive.position}
          name="position"
          label="Position*"
        />
        <TextField
          onChange={(e) =>
            setFormData((curr: any) => {
              return {
                ...curr,
                operating_executive: {
                  ...curr.operating_executive,
                  linkedin: e.target.value,
                },
              };
            })
          }
          inputError={inputError.operating_executive?.linkedin}
          value={formData.operating_executive.linkedin}
          name="linkedIn"
          label="LinkedIn Profile*"
          // value={formData.email}
        />
        <TextField
          onChange={(e) =>
            setFormData((curr: any) => {
              return {
                ...curr,
                operating_executive: {
                  ...curr.operating_executive,
                  other_social_media: e.target.value,
                },
              };
            })
          }
          // inputError={inputError.operating_executive?.other_social_media}
          value={formData.operating_executive.other_social_media}
          name="social"
          label="Social Media Profile (if any)"
        />
        <TextField
          onChange={(e) =>
            setFormData((curr: any) => {
              return {
                ...curr,
                operating_executive: {
                  ...curr.operating_executive,
                  website: e.target.value,
                },
              };
            })
          }
          // inputError={inputError.operating_executive?.website}
          value={formData.operating_executive.website}
          name="onlinePresence"
          label="Online Presence (personal website or linktree page)"
        />

        <div>
          <div className="beneficiary__form__part2__upload">
            <p>CV/Resume*</p>
            <div className="beneficiary__form__part2__upload__main">
              <div
                onClick={() => handleClick(cvInput)}
                className="beneficiary__form__part2__upload__main__click"
              >
                <input
                  type="file"
                  accept=".png,.jpeg,.jpg,.doc,.docx,.pdf"
                  ref={cvInput}
                  onChange={(e) => handleChange(e, "resume")}
                  style={{ display: "none" }}
                />
                <img src={uploadIcon} alt="" /> Click to Upload
              </div>
              <div className="beneficiary__form__part2__upload__main__name">
                {filesName.resume}
              </div>
            </div>
          </div>
          <ErrorMessage show={inputError?.operating_executive?.resume}>
            resume is required
          </ErrorMessage>
        </div>
      </div>
      <h4>Registered Officers</h4>
      <div className="beneficiary__form__part2__officers">
        <h5>1</h5>
        <div className="beneficiary__form__part2__officers__main">
          <TextField
            onChange={(e) => handleInputChangeRO(e.target.value, 0, "name")}
            inputError={inputError?.registered_officers?.name}
            value={formData?.registered_officers?.[0]?.name}
            name="name"
            label="Name*"
          />
          <TextField
            onChange={(e) => handleInputChangeRO(e.target.value, 0, "position")}
            inputError={inputError?.registered_officers?.position}
            value={formData?.registered_officers?.[0]?.position}
            name="position"
            label="Position*"
          />
          <TextField
            onChange={(e) =>
              handleInputChangeRO(e.target.value, 0, "phone_number")
            }
            inputError={inputError?.registered_officers?.phone_number}
            value={formData?.registered_officers?.[0]?.phone_number}
            name="phone"
            label="Phone Number*"
          />
          <TextField
            onChange={(e) => handleInputChangeRO(e.target.value, 0, "linkedin")}
            inputError={inputError?.registered_officers?.linkedin}
            value={formData?.registered_officers?.[0]?.linkedin}
            name="linkedIn"
            label="LinkedIn Profile*"
          />
          <TextField
            onChange={(e) =>
              handleInputChangeRO(e.target.value, 0, "other_social_media")
            }
            // inputError={inputError?.registered_officers?.other_social_media}
            value={formData?.registered_officers?.[0]?.other_social_media}
            name="social"
            label="Social Media Profile (if any)"
          />
          <TextField
            onChange={(e) => handleInputChangeRO(e.target.value, 0, "website")}
            // inputError={inputError?.registered_officers?.website}
            value={formData?.registered_officers?.[0]?.website}
            name="onlinePresence"
            label="Online Presence (personal website or linktree page)"
          />
        </div>
      </div>
      <div className="beneficiary__form__part2__officers">
        <h5>2</h5>
        <div className="beneficiary__form__part2__officers__main">
          <TextField
            onChange={(e) => handleInputChangeRO(e.target.value, 1, "name")}
            // inputError={inputError?.registered_officers?.name}
            value={formData?.registered_officers?.[1]?.name}
            name="name"
            label="Name"
          />
          <TextField
            onChange={(e) => handleInputChangeRO(e.target.value, 1, "position")}
            // inputError={inputError?.registered_officers?.position}
            value={formData?.registered_officers?.[1]?.position}
            name="position"
            label="Position"
          />
          <TextField
            onChange={(e) =>
              handleInputChangeRO(e.target.value, 1, "phone_number")
            }
            // inputError={inputError?.registered_officers?.phone_number}
            value={formData?.registered_officers?.[1]?.phone_number}
            name="phone"
            label="Phone Number"
          />
          <TextField
            onChange={(e) => handleInputChangeRO(e.target.value, 1, "linkedin")}
            // inputError={inputError?.registered_officers?.linkedin}
            value={formData?.registered_officers?.[1]?.linkedin}
            name="linkedIn"
            label="LinkedIn Profile*"
          />
          <TextField
            onChange={(e) =>
              handleInputChangeRO(e.target.value, 1, "other_social_media")
            }
            // inputError={inputError?.registered_officers?.other_social_media}
            value={formData?.registered_officers?.[1]?.other_social_media}
            name="social"
            label="Social Media Profile (if any)"
          />
          <TextField
            onChange={(e) => handleInputChangeRO(e.target.value, 1, "website")}
            // inputError={inputError?.registered_officers?.website}
            value={formData?.registered_officers?.[1]?.website}
            name="onlinePresence"
            label="Online Presence (personal website or linktree page)"
          />
        </div>
      </div>
      <h4>Referees for the Organisation</h4>
      <div className="beneficiary__form__part2__officers">
        <h5>1</h5>
        <div className="beneficiary__form__part2__officers__main">
          <TextField
            onChange={(e) => handleInputChangeOR(e.target.value, 0, "name")}
            inputError={inputError?.organisation_referees?.[0]?.name}
            value={formData?.organisation_referees?.[0]?.name}
            name="name"
            label="Name*"
          />
          <TextField
            onChange={(e) => handleInputChangeOR(e.target.value, 0, "email")}
            inputError={inputError?.organisation_referees?.[0]?.email}
            value={formData?.organisation_referees?.[0]?.email}
            name="email"
            label="Email address*"
          />
          <TextField
            onChange={(e) =>
              handleInputChangeOR(e.target.value, 0, "phone_number")
            }
            inputError={inputError?.organisation_referees?.[0]?.phone_number}
            value={formData?.organisation_referees?.[0]?.phone_number}
            name="phone"
            label="Phone Number*"
          />
          <TextField
            onChange={(e) => handleInputChangeOR(e.target.value, 0, "linkedin")}
            inputError={inputError?.organisation_referees?.[0]?.linkedin}
            value={formData?.organisation_referees?.[0]?.linkedin}
            name="linkedIn"
            label="LinkedIn Profile*"
            // value={formData.email}
          />
          <TextField
            onChange={(e) =>
              handleInputChangeOR(e.target.value, 0, "other_social_media")
            }
            //  inputError={inputError?.organisation_referees?.[0]?.other_social_media}
            value={formData?.organisation_referees?.[0]?.other_social_media}
            name="social"
            label="Social Media Profile (if any)"
          />
          <TextField
            onChange={(e) => handleInputChangeOR(e.target.value, 0, "website")}
            // inputError={inputError?.organisation_referees?.[0]?.website}
            value={formData?.organisation_referees?.[0]?.website}
            name="onlinePresence"
            label="Online Presence (personal website or linktree page)"
          />
        </div>
      </div>
      <div className="beneficiary__form__part2__officers">
        <h5>2</h5>
        <div className="beneficiary__form__part2__officers__main">
          <TextField
            onChange={(e) => handleInputChangeOR(e.target.value, 1, "name")}
            inputError={inputError?.organisation_referees?.[1]?.name}
            value={formData?.organisation_referees?.[1]?.name}
            name="name"
            label="Name*"
          />
          <TextField
            onChange={(e) => handleInputChangeOR(e.target.value, 1, "email")}
            inputError={inputError?.organisation_referees?.[1]?.email}
            value={formData?.organisation_referees?.[1]?.email}
            name="email"
            label="Email address*"
          />
          <TextField
            onChange={(e) =>
              handleInputChangeOR(e.target.value, 1, "phone_number")
            }
            inputError={inputError?.organisation_referees?.[1]?.phone_number}
            value={formData?.organisation_referees?.[1]?.phone_number}
            name="phone"
            label="Phone Number*"
          />
          <TextField
            onChange={(e) => handleInputChangeOR(e.target.value, 1, "linkedin")}
            inputError={inputError?.organisation_referees?.[1]?.linkedin}
            value={formData?.organisation_referees?.[1]?.linkedin}
            name="linkedIn"
            label="LinkedIn Profile*"
            // value={formData.email}
          />
          <TextField
            onChange={(e) =>
              handleInputChangeOR(e.target.value, 1, "other_social_media")
            }
            //  inputError={inputError?.organisation_referees?.[1]?.other_social_media}
            value={formData?.organisation_referees?.[1]?.other_social_media}
            name="social"
            label="Social Media Profile (if any)"
          />
          <TextField
            onChange={(e) => handleInputChangeOR(e.target.value, 1, "website")}
            // inputError={inputError?.organisation_referees?.[1]?.website}
            value={formData?.organisation_referees?.[1]?.website}
            name="onlinePresence"
            label="Online Presence (personal website or linktree page)"
          />
        </div>
      </div>
      <div className="beneficiary__form__part2__officers">
        <h5>3</h5>
        <div className="beneficiary__form__part2__officers__main">
          <TextField
            onChange={(e) => handleInputChangeOR(e.target.value, 2, "name")}
            inputError={inputError?.organisation_referees?.[2]?.name}
            value={formData?.organisation_referees?.[2]?.name}
            name="name"
            label="Name*"
          />
          <TextField
            onChange={(e) => handleInputChangeOR(e.target.value, 2, "email")}
            inputError={inputError?.organisation_referees?.[2]?.email}
            value={formData?.organisation_referees?.[2]?.email}
            name="email"
            label="Email address*"
          />
          <TextField
            onChange={(e) =>
              handleInputChangeOR(e.target.value, 2, "phone_number")
            }
            inputError={inputError?.organisation_referees?.[2]?.phone_number}
            value={formData?.organisation_referees?.[2]?.phone_number}
            name="phone"
            label="Phone Number*"
          />
          <TextField
            onChange={(e) => handleInputChangeOR(e.target.value, 2, "linkedin")}
            inputError={inputError?.organisation_referees?.[2]?.linkedin}
            value={formData?.organisation_referees?.[2]?.linkedin}
            name="linkedIn"
            label="LinkedIn Profile*"
            // value={formData.email}
          />
          <TextField
            onChange={(e) =>
              handleInputChangeOR(e.target.value, 2, "other_social_media")
            }
            //  inputError={inputError?.organisation_referees?.[2]?.other_social_media}
            value={formData?.organisation_referees?.[2]?.other_social_media}
            name="social"
            label="Social Media Profile (if any)"
          />
          <TextField
            onChange={(e) => handleInputChangeOR(e.target.value, 2, "website")}
            // inputError={inputError?.organisation_referees?.[2]?.website}
            value={formData?.organisation_referees?.[2]?.website}
            name="onlinePresence"
            label="Online Presence (personal website or linktree page)"
          />
        </div>
      </div>
      <div className="beneficiary__form__part2__btn">
        <Button onClick={() => setPart(1)} $fill radius="10px">
          Back
        </Button>
        <Button onClick={() => setPart(3)} $fill radius="10px">
          Next
        </Button>
      </div>
    </div>
  );
};

export default BeneficiaryPart2;
