import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cashForCharitySevice from "../services/cashForCharity.service";
import { setMessage } from "./message";

export const postIntroducerApply: any = createAsyncThunk(
  "cashForCharity/postIntroducerApply",
  async (data: any, thunkAPI) => {
    try {
      const dataMain = await cashForCharitySevice.postIntroducerApply(data);
      return { introducerApply: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifytIntroducerStatus: any = createAsyncThunk(
  "cashForCharity/verifytIntroducerStatus",
  async (id, thunkAPI) => {
    try {
      const dataMain = await cashForCharitySevice.verifytIntroducerStatus();
      return { introducerStatus: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const verifytApplicantStatus: any = createAsyncThunk(
  "cashForCharity/verifytApplicantStatus",
  async (id, thunkAPI) => {
    try {
      const dataMain = await cashForCharitySevice.verifytApplicantStatus();
      return { applicantStatus: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const postApplicantApply: any = createAsyncThunk(
  "cashForCharity/postApplicantApply",
  async (data: any, thunkAPI) => {
    try {
      const dataMain = await cashForCharitySevice.postApplicantApply(data);
      return { applicantApply: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getIntroducerReferralCode: any = createAsyncThunk(
  "cashForCharity/getIntroducerReferralCode",
  async (id: any, thunkAPI) => {
    try {
      const dataMain = await cashForCharitySevice.getIntroducerReferralCode();
      return { introducerCode: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState: any = {
  introducerApply: null,
  introducerStatus: null,
  applicantApply: null,
  applicantStatus: null,
  introducerCode: null,
};

export const cashForCharitySlice = createSlice({
  name: "cashForCharity",
  initialState,
  reducers: {},
  extraReducers: {
    [postIntroducerApply.fulfilled]: (state, action) => {
      state.introducerApply = action.payload.introducerApply;
    },
    [postIntroducerApply.rejected]: (state, action) => {
      state.introducerApply = null;
    },
    [verifytIntroducerStatus.fulfilled]: (state, action) => {
      state.introducerStatus = action.payload.introducerStatus;
    },
    [verifytIntroducerStatus.rejected]: (state, action) => {
      state.introducerStatus = null;
    },
    [postApplicantApply.fulfilled]: (state, action) => {
      state.applicantApply = action.payload.applicantApply;
    },
    [postApplicantApply.rejected]: (state, action) => {
      state.applicantApply = null;
    },
    [verifytApplicantStatus.fulfilled]: (state, action) => {
      state.applicantStatus = action.payload.applicantStatus;
    },
    [verifytApplicantStatus.rejected]: (state, action) => {
      state.applicantStatus = null;
    },
    [getIntroducerReferralCode.fulfilled]: (state, action) => {
      state.introducerCode = action.payload.introducerCode;
    },
    [getIntroducerReferralCode.rejected]: (state, action) => {
      state.introducerCode = null;
    },
  },
});

const { reducer } = cashForCharitySlice;
export default reducer;
