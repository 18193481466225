import sunIcon from "../assets/image/svg/sun.svg";
import moonIcon from "../assets/image/svg/moon.svg";
import Toggle from "react-toggle";
// import { Button } from "./styledComponent/form/Buttons";

const Appearances = ({
  theme,
  changeTheme,
}: {
  theme: string;
  changeTheme: () => void;
}) => {
  return (
    <div className="apperances">
      <h2 className="apperances__title">Appearances</h2>
      <div className="apperances__mode">
        <div className="apperances__mode__item">
          <div className="apperances__mode__item__left">
            <img src={sunIcon} alt="" /> Light Mode
          </div>
          <Toggle
            checked={theme === "light" ? true : false}
            icons={false}
            onChange={changeTheme}
          />
        </div>
        <div className="apperances__mode__item">
          <div className="apperances__mode__item__left">
            <img src={moonIcon} alt="" /> Dark Mode
          </div>
          <Toggle
            checked={theme === "dark" ? true : false}
            icons={false}
            onChange={changeTheme}
          />
        </div>
      </div>

      {/* <div className="apperances__btn">
        <Button radius="90px" $fill height="48px" width="150px">
          Save settings
        </Button>
      </div> */}
    </div>
  );
};

export default Appearances;
