import { useState } from "react";
import Camera from "react-html5-camera-photo";
import { toast } from "react-toastify";
import arrowIcon from "../../assets/image/svg/arrowLeft.svg";
import selfieIcon from "../../assets/image/svg/selfie.svg";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { postSelfie } from "../../store/slices/settings";
import { Button } from "../styledComponent/form/Buttons";
import { ErrorMessage } from "../styledComponent/form/textFields/styles";
import ReactLoading from "react-loading";

const SelfieWithId = ({ setCurrStage }: any) => {
  const [showCamera, setshowCamera] = useState(false);
  const [photoUrl, setphotoUrl] = useState("");
  const [inputError, setInputError] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleTakePhoto = (dataUri: any) => {
    // Do stuff with the photo...
    setphotoUrl(dataUri);
    setshowCamera(false);
  };

  const validate = () => {
    let selfieError = "";
    if (!photoUrl) {
      selfieError = "selfie with id is required";
    }

    if (selfieError) {
      setInputError((curr: any) => {
        return {
          selfie: selfieError,
        };
      });

      return false;
    }
    return true;
  };

  const handleSelfieUpload = () => {
    const checkValidate = validate();
    if (checkValidate) {
      const data = {
        document_image: photoUrl,
      };
      setLoading(true);
      dispatch(postSelfie(data))
        .unwrap()
        .then(() => {
          setLoading(false);
          toast.success("Document Sent Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCurrStage(4);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    }
  };
  return (
    <div className="selfieWithId">
      <h2 className="selfieWithId__title">
        <span>Step 3.</span> Photo Selfie with ID
      </h2>

      {/* <div onClick={() => setCurrStage(2)} className="verify__back">
        <img src={arrowIcon} alt="" /> Back to Personal Details
      </div> */}

      <h3>CONFIRM YOUR IDENTITY WITH A PHOTO OF YOURSELF HOLDING YOUR ID</h3>

      <div className="selfieWithId__main">
        <div className="selfieWithId__main__left">
          <div className="selfieWithId__main__left__upload">
            {showCamera ? (
              <Camera
                onTakePhoto={(dataUri) => {
                  handleTakePhoto(dataUri);
                }}
              />
            ) : photoUrl ? (
              <img src={photoUrl} alt="" />
            ) : (
              <>
                <img src={selfieIcon} alt="" />
                <p onClick={() => setshowCamera(true)}>Click to Open Camera</p>
              </>
            )}
          </div>
          <ErrorMessage show={inputError?.selfie}>
            {inputError?.selfie}
          </ErrorMessage>
        </div>

        <div className="selfieWithId__main__right">
          <p>
            Firstly you will need a smartphone, tablet or another digital device
            with a camera and your Identity document (ID) with a recent profile
            picture of yourself - such as your passport or photo driving license{" "}
          </p>
          <ul>
            <li>Take the photo in a room with enough light</li>
            Select the highest photo quality on the device you use to take the
            photo
            <li>
              Hold up the document next to your face, like in the image below
            </li>
            <li>
              Make sure both your face and ID are clear on the photo — nothing
              on the ID can be covered or censored
            </li>
            <li>
              If your photo is not of high enough quality (sufficiently sharp
              and not blurry), we will request a better copy.
            </li>
          </ul>
        </div>
      </div>

      <div className="selfieWithId__btn">
        <Button onClick={handleSelfieUpload} $fill>
          {loading ? (
            <ReactLoading color="white" width={30} height={30} type="spin" />
          ) : (
            "Continue"
          )}
        </Button>
      </div>
    </div>
  );
};

export default SelfieWithId;
