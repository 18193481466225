// import waveVideo from "../../assets/videos/WaveAnimation.mp4";
import waveVideo from "../../assets/videos/Wave Animation.webm";

const Mission = () => {
  return (
    <section id="mission" className="mission">
      <video
        className="hero__video"
        src={waveVideo}
        autoPlay
        loop
        muted
      ></video>
      <div className="mission__main">
        <h2>OUR MISSION</h2>
        <p>
          Our mission is create generational wealth for millions, and to
          eliminate or significantly reduce, generational poverty around the
          world.
        </p>
      </div>
    </section>
  );
};

export default Mission;
