import { useEffect, useState } from "react";
import SocialLinks from "../components/SocialLinks";
import { Button } from "../components/styledComponent/form/Buttons";
import {
  InputPin,
  InputUser,
} from "../components/styledComponent/form/textFields/styles";
import profileIcon from "../assets/image/svg/Profile.svg";
import faceIdIcon from "../assets/image/svg/faceId.svg";
import fingerPrintIcon from "../assets/image/svg/fingerPrint.svg";
import axios from "axios";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks/reduxHooks";
import { login, loginBiometric, loginToken } from "../store/slices/auth";
import GetHelp from "./GetHelp";
import GetHelpBio from "./GetHelpBio";
import { colorTheme } from "../assets/colors";
import { useModeContext } from "../store/modeContext";
import { useSocialMediaContext } from "../store/socialmediaContext";

const TwitterSignin = ({ theme }: { theme: string }) => {
  //   const auth = "login";
  const [step, setstep] = useState(2);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [btnDisable, setbtnDisable] = useState(true);
  // const [challengeStr, setchallengeStr] = useState("");
  const [userAuth, setUserAuth] = useState({
    username: "",
    sid: "",
  });
  const { setMode } = useModeContext();
  const { selectedSocialMedia } = useSocialMediaContext();
  const { updateSelectedSocialMedia } = useSocialMediaContext();
  const [bioloading, setBioLoading] = useState(false);
  const [biometricSupported, setBiometricSupported] = useState(false);

  const [channel, setChannel] = useState("");

  useEffect(() => {
    // Retrieve value from local storage during component mount
    const storedSocialMedia = localStorage.getItem("selectedSocialMedia");
    if (storedSocialMedia) {
      // Update state with the retrieved value
      console.log("getting a new channel")
      setChannel(storedSocialMedia);
     
    }
  }, [updateSelectedSocialMedia ]);

  function checkBiometricSupport() {
    if (
      window.PublicKeyCredential &&
      navigator.credentials &&
      (navigator.credentials.create || navigator.credentials.get)
    ) {
      setBiometricSupported(true);
    } else {
      setBiometricSupported(false);
    }
  }

  useEffect(() => {
    checkBiometricSupport();
  }, []);

  const handleBiometric = async (challenge: any, challengeStr: string) => {
    try {
      const credentialOptions: any = {
        challenge,
        timeout: 60000,
        rpId: window.location.hostname,
        userVerification: "required",
        // allowCredentials: [
        //   {
        //     id: randomId,
        //     type: "public-key",
        //     authenticatorSelection: {
        //       authenticatorAttachment: "platform",
        //       userVerification: "required",
        //     },
        //   },
        // ],
        authenticatorSelection: {
          authenticatorAttachment: "platform",
          userVerification: "required",
          authenticatorExtensions: {
            "ultainfinity/fingerprint": true,
            "ultainfinity/faceid": true,
          },
        },
        extensions: {
          "ultainfinity/fingerprint": {
            sensorType: "fingerprint",
          },
          "ultainfinity/faceid": {
            sensorType: "faceid",
          },
        },
      };
      const assertion = await navigator.credentials.get({
        publicKey: credentialOptions,
      });
      if (typeof assertion?.id === "string") {
        // setBiometricResultId(assertion?.id);

        const data = {
          biometrics_id: assertion?.id,
          challenge_id: challengeStr,
        };

        handleLoginBiometric(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoginBiometric = async (data: any) => {
    setBioLoading(true);

    dispatch(loginBiometric(data))
      .unwrap()
      .then(() => {
        setBioLoading(false);
        toast.success("Successfully Login", {
          position: toast.POSITION.TOP_RIGHT,
        });

        navigate("/dashboard");
      })
      .catch((err: any) => {
        // const message =
        //   (err.response && err.response.data && err.response.data.message) ||
        //   err.message ||
        //   err.toString();
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setBioLoading(false);
      });
  };

  const dispatch = useAppDispatch();

  let navigate = useNavigate();

  const handleVerifyOtp = async () => {
    const code = otp.toUpperCase();
    setLoading(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/users/v1/user-account/otp-verify`,
        { code, channel }
      );
      setLoading(false);
      if (response.data.type === "success") {
        if (response.data?.data?.user_status === "active") {
          setUserAuth((curr) => {
            return { ...curr, sid: response.data?.data?.sid };
          });
          // console.log("response.data.data", response.data.data);

          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setstep(3);
        } else if (response.data?.data?.user_status === "creating") {
          toast.warning("User Do Not exist, Signup", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/signup");
        } else {
          dispatch(loginToken(response.data.data));

          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/dashboard");
        }
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err: any) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      setLoading(false);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleGenerateChallange = async () => {
    setBioLoading(true);

    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/users/v1/biometrics/generate-challenge`
      );
      setBioLoading(false);
      const challenge = Uint8Array.from(atob(response.data?.challenge), (c) =>
        c.charCodeAt(0)
      );
      // const id = Uint8Array.from(atob(response.data?.random_id), (c) =>
      //   c.charCodeAt(0)
      // );

      handleBiometric(challenge, response.data?.challenge);
    } catch (err: any) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      setBioLoading(false);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleLogin = async () => {
    setLoading(true);

    const data = {
      sid: userAuth.sid,
      username: userAuth.username,
    };

    setLoading(true);
    dispatch(login(data))
      .unwrap()
      .then(() => {
        setLoading(false);
        toast.success("Successfully Login", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/dashboard");
      })
      .catch((err: any) => {
        setLoading(false);

        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleClick = () => {
    if (step === 2) {
      handleVerifyOtp();
    }
    if (step === 3) {
      handleLogin();
    }
  };

  useEffect(() => {
   
    if (otp?.length === 6) {
      setbtnDisable(false);
    } else {
      setbtnDisable(true);
    }
  }, [otp]);

  useEffect(() => {
    if (userAuth.username.length > 0) {
      setbtnDisable(false);
    } else {
      setbtnDisable(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <div className="authForm">
      {step === 1 && (
        <div className="authForm__otp">
          <h2 className="authForm__otp__title">Login to Dashboard</h2>
          <p className="authForm__otp__disc">
            Complete the Onestep verification to proceed. it is important for
            account verification
          </p>

          <div className="authForm__otp__choice">Use OneStep ID to login</div>
          <div className="authForm__otp__messanger">
            <p>Kindly select a messenger</p>
            <div className="authForm__otp__socialLinksCon">
              <div onClick={() => setstep(2)}>
                <SocialLinks />
              </div>
              <Link className="authForm__otp__socialLinksCon__recovery" to="#">
                <span>Recovery Centre</span>{" "}
              </Link>

              <div className="getHelp__main">
                <p className="getHelp__disc">
                  Having trouble using OneStep Verification?{" "}
                </p>
                <Link to="/help-centre">
                  <span className="getHelp__btn">Help Centre</span>
                </Link>
              </div>
            </div>
          </div>

          {biometricSupported && (
            <>
              <div className="authForm__otp__or">OR</div>
              <p className="authForm__otp__easy">
                <b>
                  Interested in easy Logins? <br />{" "}
                  <span>Register for Biometrics</span>
                </b>
                <br />
                Simply go to your Settings in the dashboard to Register for
                Biometrics in order to enjoy easier Logins in all your
                subsequent visits
              </p>
              <div className="authForm__otp__choice">
                Use Biometrics to Login
              </div>
              <div className="authForm__otp__biometric">
                <div
                  onClick={handleGenerateChallange}
                  className="authForm__otp__biometric__main"
                >
                  <div className="authForm__otp__biometric__item">
                    <img src={fingerPrintIcon} alt="" />
                    <span>Touch ID</span>
                  </div>
                  <div className="authForm__otp__biometric__item">
                    <img src={faceIdIcon} alt="" />
                    <span>Face ID</span>
                  </div>
                </div>
                {bioloading && (
                  <ReactLoading
                    color={theme === "light" ? colorTheme.primary : "white"}
                    width={30}
                    height={30}
                    type="spin"
                  />
                )}

                <div className="getHelp__main">
                  <p className="getHelp__disc">
                    Having troubles using Biometrics Verification?
                  </p>
                  <Link to="/help-centre">
                    <span className="getHelp__btn">Help Centre</span>
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {step === 2 && (
        <div className="authForm__verify">
          <h2 className="authForm__verify__title">OTP Verification Sign In</h2>
          <h3>channel: {channel}</h3>
          <p className="authForm__verify__subTitle">
            Complete the Onestep verification to proceed. it is important for
            account verification
          </p>

          <p className="authForm__verify__disc">
            Enter the OTP verification code received in your messenger. 2 Factor
            Authentications are essential identification and verification
            measures, that OneStep ID by Ultainfinity uses to protect you and
            your account 24/7.
          </p>

          <InputPin onChange={(e) => setOtp(e.target.value)} type="text" />
        </div>
      )}

      {step === 3 && (
        <div className="authForm__user">
          <h2 className="authForm__user__title">Enter Username</h2>
          <p className="authForm__user__disc">
            Complete the Onestep verification to proceed. it is important for
            account verification
          </p>
          <div className="authForm__user__input">
            <div className="authForm__user__input__icon">
              <img src={profileIcon} alt="profile icon" />
            </div>
            <InputUser
              onChange={(e) =>
                setUserAuth((curr) => {
                  return { ...curr, username: e.target.value };
                })
              }
              type="text"
              placeholder="Enter Username"
            />

            <Link to="/recovery/username">
              <span>Forgot username?</span>{" "}
            </Link>
            {/* <span>Forgot ?</span> */}
          </div>
        </div>
      )}

      <div className="authForm__btnCon">
        {step !== 1 && (
          <Button
            disabled={btnDisable}
            $disable={btnDisable}
            onClick={handleClick}
            width="250px"
            $fill
          >
            {loading ? (
              <ReactLoading color="white" width={30} height={30} type="spin" />
            ) : (
              "Continue"
            )}
          </Button>
        )}
      </div>
      <p className="authForm__terms">
        By logging in you agree to our{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://ultainfinitywealthlaunchpad.com/privacy"
        >
          Terms & Privacy Policy.
        </a>{" "}
      </p>
    </div>
  );
};

export default TwitterSignin;
