const Wealth = () => {
  return (
    <div className="wealth">
      <p>
        <b>Experience rapid wealth creation with </b> <br />
        Ultainfinity cryptocurrencies and projects, like none other in the
        world. Quite simply, there’s none to compare with Ultainfinity. Use
        Ultainfinity Instant CreditLine to amass millions faster, in little
        time. Get started straightaway at Ultainfinity Wealth LaunchPad.
      </p>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://ultainfinitywealthlaunchpad.com/"
      >
        <button> Ultainfinity Wealth Launchpad</button>
      </a>
    </div>
  );
};

export default Wealth;
