import { useEffect, useState } from "react";
import Carousel from "../../components/Carousel";
import ProgramsInfo from "../../components/ProgramsInfo";
import Payment from "../../components/Payment";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  checkBountyInvestUnlock,
  reactiveBountyInvest,
  // reactiveBountyInvest,
} from "../../store/slices/bounties";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { colorTheme } from "../../assets/colors";
import noDataIcon from "../../assets/image/svg/noData.svg";
import { useLocation, useParams } from "react-router-dom";
import Modal from "react-modal";
import PaymentCongratModal from "../../components/PaymentCongratModal";
import ProgramsDetails from "../../components/ProgramsDetails";

const Invest = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingUnlock, setLoadingUnlock] = useState(false);
  const [unlock, setUnlock] = useState<any>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = (): void => {
    setModalIsOpen(true);
  };
  const closeModal = (): void => {
    setModalIsOpen(false);
  };

  const { bountyInvestUnlock }: any = useAppSelector((state) => state.bounties);

  const { pathname } = useLocation();
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const data = {
    title: "Here’s what you need to do to Get 4x return and unlock  $3,500",
    steps: [
      "Click on the Unlock button",
      "Choose your prefered payment gateway",
      "invest in the Ultainfinity projects with a minimum $250, you get a 4x return (on investment) and also get rewarded like an elite.",
      "Click on the Reactivate button and repeat the above steps to earn unlimited times!!",
    ],
  };

  const handleUnlock = (data: any) => {
    setLoadingUnlock(true);
    dispatch(reactiveBountyInvest(data))
      .unwrap()
      .then(() => {
        setLoadingUnlock(false);
        setStep(2);
      })
      .catch((err: any) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });

        setLoadingUnlock(false);
      });
  };

  useEffect(() => {
    const handleCheckBountyUnlock = () => {
      setLoading(true);
      dispatch(checkBountyInvestUnlock())
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((err: any) => {
          if (err === "Bounty one has not been unlocked") {
            setUnlock(false);
          } else {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setLoading(false);
        });
    };

    handleCheckBountyUnlock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bountyInvestUnlock === "Bounty One has been unlocked") {
      setUnlock(true);
      setStep(2);
      // console.log("first");
    }
  }, [bountyInvestUnlock]);

  useEffect(() => {
    if (pathname.includes("processing")) {
      openModal();
    }
  }, [pathname, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="invest">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="home"
      >
        <PaymentCongratModal close={closeModal} id={id} />
      </Modal>{" "}
      <h2 className="submit__title">Bounty Programs </h2>
      <Carousel program="2" />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <ReactLoading
            color={colorTheme.primary}
            width={50}
            height={50}
            type="spin"
          />
        </div>
      ) : unlock !== null ? (
        step === 1 ? (
          <ProgramsInfo
            program="Bounty 1"
            info={data}
            handleUnlock={handleUnlock}
            loading={loadingUnlock}
          />
        ) : (
          <>
            <ProgramsDetails program="bounty1" />
            <Payment program="invest" />
          </>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <img src={noDataIcon} alt="" />
        </div>
      )}
    </div>
  );
};

export default Invest;
