import { useEffect } from "react";
// import AuthForm from "../components/AuthForm";
import AuthFormSignup from "../components/AuthFormSignup";

const Signup = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="signup">
      <AuthFormSignup />
    </div>
  );
};

export default Signup;
