import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import DrawerL from "../components/landingPage/DrawerL";
import Footer from "../components/landingPage/Footer";
import Navbar from "../components/landingPage/NavBar";
import { useAppSelector } from "../hooks/reduxHooks";

const LandingLayout = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { user } = useAppSelector((state) => state.auth);
  let navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <div className="landingLayout">
      <Navbar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      <DrawerL openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default LandingLayout;
