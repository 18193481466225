import fallingCoins from "../assets/image/fallingCoins.png";
import { ButtonAlt } from "./styledComponent/form/Buttons";
import closeIcon from "../assets/image/svg/close.svg";
import { Link } from "react-router-dom";
import { useAppSelector } from "../hooks/reduxHooks";
import { Tooltip } from "react-tooltip";

const WemaModal = ({ closeModal }: { closeModal: () => void }) => {
  const { balance }: any = useAppSelector((state) => state.bounties);

  const WemaButton = ({ disableBtn }: { disableBtn: boolean }) => {
    return (
      <ButtonAlt
        data-tooltip-id={disableBtn ? "tooltip-modal" : ""}
        data-tooltip-content="Activate any program to Access WEMA"
        width="250px"
        height="40px"
        radius="100px"
        style={{
          cursor: disableBtn ? "not-allowed" : "pointer",
          background: "linear-gradient(180deg, #eaa613 0%, #efc262 100%)",
        }}
        //   small="true"
        $fill
      >
        {disableBtn ? (
          <span>
            Go to <br /> WIMA Wealth Centre
          </span>
        ) : (
          <a
            style={{ color: "#fff" }}
            target="_blank"
            rel="noreferrer"
            href="https://bounties.ultainfinityairdropandbounty.com"
          >
            Go to <br /> WIMA Wealth Centre
          </a>
        )}
      </ButtonAlt>
    );
  };
  return (
    <div className="wemaModal">
      <Tooltip id="tooltip-modal" />

      <div className="wemaModal__close" onClick={closeModal}>
        <img src={closeIcon} alt="" />
      </div>
      <div className="wemaModal__header">
        <h2 className="wemaModal__header__title">WIMA Wealth Centre</h2>
        <img src={fallingCoins} alt="" />
      </div>
      <div className="wemaModal__main">
        <div className="wemaModal__main__left">
          <h3 className="wemaModal__main__left__title">STEP 1</h3>
          <p className="wemaModal__main__left__disc">
            We would love for you to go to WIMA Wealth Centre. However, before
            you can Log into WIMA Wealth Centre and enjoy the limitless wealth
            benefits, claims and wealth accounts, products, wallets etc, you
            must first, successfully satisfy the criteria of unlocking any of
            the Airdrops or Bounties programs on this platform. <br />
            After you’ve successfully Unlocked one or more of the Airdrops and
            Bounties programs on this platform, then you will be granted log in
            rights to WIMA Wealth Centre from this Dashboard. Revisit this
            Dashboard and click the button: Go to WIMA Wealth Centre{" "}
          </p>

          <div className="wemaModal__main__left__programs">
            <p style={{ marginBottom: "10px" }}>
              To get started, Unlock any program below:
            </p>

            <div className="wemaModal__main__left__programs__btns">
              <div className="wemaModal__main__left__programs__btns__item">
                <p>Airdrop 1 Program</p>
                <Link to="/dashboard">
                  <ButtonAlt width="150px" height="35px" $fill>
                    Unlock Now
                  </ButtonAlt>
                </Link>
              </div>
              <div className="wemaModal__main__left__programs__btns__item">
                <p>Airdrop 2 Program</p>
                <Link to="/dashboard">
                  <ButtonAlt width="150px" height="35px" $fill>
                    Unlock Now
                  </ButtonAlt>
                </Link>
              </div>
              <div className="wemaModal__main__left__programs__btns__item">
                <p>Bounty 1 Program</p>
                <Link to="/dashboard">
                  <ButtonAlt width="150px" height="35px" $fill>
                    Unlock Now
                  </ButtonAlt>
                </Link>
              </div>
              <div className="wemaModal__main__left__programs__btns__item">
                <p>Bounty 2 Program</p>
                <Link to="/dashboard">
                  <ButtonAlt width="150px" height="35px" $fill>
                    Unlock Now
                  </ButtonAlt>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="wemaModal__main__right">
          <h3 className="wemaModal__main__right__title">STEP 2</h3>
          <p className="wemaModal__main__right__disc">
            After successfully unlocking multiple and unlimited new programs,
            You can go to WIMA Wealth Centre to claim from those new airdrops
            and bounties programs that you’ve unlocked.
          </p>

          <p style={{ marginTop: "20px" }}>
            Go to WIMA Wealth Centre to enjoy all of these benefits;
          </p>
          <ol>
            <li>Make seamless successful claims </li>
            <li>Access to BACA Wealth Accounts</li>
            <li>Manage, convert and grow Wealth</li>
          </ol>

          <WemaButton
            disableBtn={balance?.total_balance === 0 || !balance?.total_balance}
          />
        </div>
      </div>
    </div>
  );
};

export default WemaModal;
