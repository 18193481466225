import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "../../components/Carousel";
import { ButtonAlt } from "../../components/styledComponent/form/Buttons";
import { PageTitle } from "../../components/styledComponent/headers/styles";
import Modal from "react-modal";
import EligibilityModal from "../../components/EligibilityModal";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { getWallet } from "../../store/slices/wallet";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { colorTheme } from "../../assets/colors";
import { checkUnlockAll } from "../../store/slices/bounties";
import noDataIcon from "../../assets/image/svg/noData.svg";
import ProfileComplete from "../../components/profileMain/ProfileComplete";
import { getProgress } from "../../store/slices/settings";

const programs = [
  {
    url: "refer",
    type: "airdrop_1",
    title: `Refer 3 friends. Get $3,000`,
    tag: `Airdrop Program`,
    disc: `Enjoy the advantage of having a network and make good money off it. When you successfully bring in three friends to Ultainfinity, you immediately unlock $3,000. This process can be repeatedly done and so too, the earnings never cease.`,
  },
  {
    url: "subscribe",
    type: "airdrop_2",
    title: `Subscribe or Follow SIX Ultainfinity Community Channels. Get $3,000`,
    tag: `Airdrop Program`,
    disc: `We have a unique, all-pervading presence on many social communities including Twitter, YouTube, Facebook, Instagram, Quora, LinkedIn etc. You could unlock $3,000 by subscribing to 6 of these social media communities or channels`,
  },
  {
    url: "invest",
    type: "bounty 1",
    title: `Invest minimum $250. Get 4x returns plus $3,500 giveaway`,
    tag: `Bounty Program`,
    disc: `The world is already cognizant of the transformations that Ultainfinity comes with and here is a once-in-a-lifetime opportunity to write your name in gold. You can invest a minimum of $250`,
  },
  {
    url: "submit",
    type: "bounty_2",
    title: `Submit minimum $25 of another crypto to Ultainfinity. Get 10x returns plus $3,000 giveaway`,
    tag: `Bounty Program`,
    disc: `With the Ultainfinity Airdrops and Bounties Program, there are no constraints to participating or to how much you can earn. By submitting a minimum of $25 worth of APE, BTC, BCH, DAI, DOGE, ETH, LTC, SHIB, USDT, USDC or SOL to Ultainfinity, you immediately get a 10x return. Completing this process means that you also unlock $3,000.`,
  },
];

const Home = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUnlock, setLoadingUnlock] = useState(false);
  const [loadingPro, setLoadingPro] = useState(false);

  const dispatch = useAppDispatch();
  const { wallet }: any = useAppSelector((state) => state.wallet);
  const { unlockStatusAll }: any = useAppSelector((state) => state.bounties);
  // let navigate = useNavigate();

  const closeModal = (): void => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const handleGetWallet = () => {
      setLoading(true);
      dispatch(getWallet())
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    };

    handleGetWallet();

    const handleCheckUnlockStatusAll = () => {
      setLoadingUnlock(true);
      dispatch(checkUnlockAll())
        .unwrap()
        .then(() => {
          setLoadingUnlock(false);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingUnlock(false);
        });
    };

    handleCheckUnlockStatusAll();

    const handleGetProgress = () => {
      setLoadingPro(true);
      dispatch(getProgress())
        .unwrap()
        .then(() => {
          setLoadingPro(false);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingPro(false);
        });
    };
    handleGetProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  useEffect(() => {

    window.scrollTo(0, 0);
       // Clear the 'selectedSocialMedia' from local storage
       console.log("clearing old channel")
       localStorage.removeItem('selectedSocialMedia');
  }, []);

  // const handleUnlock = (url?: string) => {
  //   if (wallet?.wallet_address?.address || wallet?.wallet_address[0].address) {
  //     navigate(`/dashboard/program/${url}`);
  //   } else {
  //     setModalIsOpen(true);
  //   }
  // };

  return (
    <div className="home">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="home"
      >
        <EligibilityModal />
      </Modal>{" "}
      <PageTitle>Airdrops & Bounties</PageTitle>
      <ProfileComplete loadingPro={loadingPro} />
      <Carousel />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <ReactLoading
            color={colorTheme.primary}
            width={50}
            height={50}
            type="spin"
          />
        </div>
      ) : wallet ? (
        <div className="home__programs">
          {programs.map((program, i) => {
            const { title, disc, tag, url, type } = program;
            return (
              <div key={i} className="home__programs__item">
                <div>
                  <h3 className="home__programs__item__title">{title}</h3>
                  <h4 className="home__programs__item__subTitle">{tag}</h4>
                  <p className="home__programs__item__disc">{disc}</p>
                </div>
                <div className="home__programs__item__btn">
                  {wallet?.wallet_address?.address ||
                  wallet?.wallet_address?.[0]?.address ? (
                    <Link to={`/dashboard/program/${url}`}>
                      <ButtonAlt $fill>
                        {loadingUnlock ? (
                          <ReactLoading
                            color="white"
                            width={30}
                            height={30}
                            type="spin"
                          />
                        ) : unlockStatusAll === null ? (
                          "Unlock Now"
                        ) : (
                          unlockStatusAll[type] + " now"
                        )}
                      </ButtonAlt>
                    </Link>
                  ) : (
                    <ButtonAlt onClick={() => setModalIsOpen(true)} $fill>
                      {loadingUnlock ? (
                        <ReactLoading
                          color="white"
                          width={30}
                          height={30}
                          type="spin"
                        />
                      ) : unlockStatusAll === null ? (
                        "Unlock Now"
                      ) : (
                        unlockStatusAll[type] + " now"
                      )}
                    </ButtonAlt>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <img src={noDataIcon} alt="" />
        </div>
      )}
    </div>
  );
};

export default Home;
