import { useState } from "react";
import Modal from "react-modal";
import closeIcon from "../assets/image/svg/close.svg";
import GH1Icon from "../assets/image/svg/GH1.svg";
import GH2Icon from "../assets/image/svg/GH2.svg";
import GH3Icon from "../assets/image/svg/GH3.svg";
import GH4Icon from "../assets/image/svg/GH4.svg";
import GH5Icon from "../assets/image/svg/GH5.svg";
import GH6Icon from "../assets/image/svg/GH6.svg";
import GH7Icon from "../assets/image/svg/GH7.svg";
import { Button } from "./styledComponent/form/Buttons";

const GetHelpBio = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [step, setStep] = useState(1);

  const openModal = (): void => {
    setModalIsOpen(true);
  };
  const closeModal = (): void => {
    setModalIsOpen(false);
  };

  const GetHelpModal = () => {
    const handlePreStep = () => {
      if (step !== 1) {
        setStep(step - 1);
      }
    };

    const handleNextStep = () => {
      if (step !== 7) {
        setStep(step + 1);
      }
    };
    return (
      <div className="getHelpBio__modal">
        <div onClick={closeModal} className="getHelpBio__modal__closeBtn">
          <img src={closeIcon} alt="" />
        </div>

        <div className="getHelpBio__modal__header">
          {/* <img src={getHelpSvgIcon} alt="" /> */}
          <h2>Biometrics Verification</h2>
          <h3>Step {step}</h3>
        </div>

        <div className="getHelpBio__modal__main">
          {step === 1 && (
            <>
              <p>Select the Biometrics screen to Login or Sign up</p>
              <img
                style={{ maxWidth: 500, marginBottom: 30 }}
                src={GH1Icon}
                alt=""
              />
            </>
          )}
          {step === 2 && (
            <>
              <p>
                Create a <span>PASSKEY</span> that will be saved on this device
                by clicking <span>CONTINUE</span>
              </p>
              <img
                style={{ maxWidth: 300, marginBottom: 60 }}
                src={GH2Icon}
                alt=""
              />
            </>
          )}
          {step === 3 && (
            <>
              <p>
                The Browser will try to verify your identity, or alternatively
                enter your password to give access.
              </p>

              <img style={{ marginBottom: 20 }} src={GH3Icon} alt="" />
            </>
          )}
          {step === 4 && (
            <>
              <p>Your Biometric has been registered successfully.</p>

              <img
                style={{ maxWidth: 250, marginBottom: 30 }}
                src={GH7Icon}
                alt=""
              />
            </>
          )}
          {step === 5 && (
            <>
              <p>You can Login to your dashboard by clicking this.</p>

              <img
                style={{ maxWidth: 400, marginBottom: 30 }}
                src={GH4Icon}
                alt=""
              />
            </>
          )}
          {step === 6 && (
            <>
              <p>Choose your registered PASSKEY to Login</p>

              <img
                style={{ maxWidth: 300, marginBottom: 30 }}
                src={GH5Icon}
                alt=""
              />
            </>
          )}
          {step === 7 && (
            <>
              <p>Your login process is successful.</p>

              <img
                style={{ maxWidth: 150, marginBottom: 30 }}
                src={GH6Icon}
                alt=""
              />
            </>
          )}
        </div>

        <div className="getHelpBio__modal__btns">
          <Button onClick={handlePreStep}>Previous</Button>
          <Button $disable={step === 7} onClick={handleNextStep} $fill>
            Next
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="getHelp">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="reactivate payment"
      >
        <GetHelpModal />
      </Modal>
      <div className="getHelp__main">
        <p className="getHelpBio__disc">
          Having troubles using Biometrics Verification?
        </p>
        <span onClick={openModal} className="getHelpBio__btn">
          Get Help for Biometrics Verification
        </span>
      </div>
    </div>
  );
};

export default GetHelpBio;
