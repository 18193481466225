import {
  ErrorMessage,
  Input,
  InputAlt,
  Label,
  LabelRadio,
  Radio,
  // Select,
  SelectAlt,
  SelectAlt2,
  Textarea,
  WrapperRadio,
} from "./styles";

interface TextFieldType {
  inputError?: string | number | undefined;
  name: string;
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  type?: string;
  width?: string;
}

export const TextField = ({
  inputError,
  name,
  label,
  value,
  onChange,
  type,
  width,
}: TextFieldType) => {
  return (
    <div className="creatHotel__main__inputCon">
      <Label htmlFor={name}>{label}</Label>
      <br />
      <Input
        width={width}
        error={inputError ? true : false}
        value={value}
        type={type}
        id={name}
        onChange={onChange}
      />
      <br />
      <ErrorMessage show={inputError ? true : false}>{inputError}</ErrorMessage>
    </div>
  );
};

export const TextFieldAlt = ({
  inputError,
  name,
  label,
  value,
  onChange,
  type,
  width,
}: TextFieldType) => {
  return (
    <div className="creatHotel__main__inputCon">
      <Label htmlFor={name}>{label}</Label>
      <br />
      <InputAlt
        width={width}
        error={inputError ? true : false}
        value={value}
        type={type}
        id={name}
        onChange={onChange}
      />
      <br />
      <ErrorMessage show={inputError ? true : false}>{inputError}</ErrorMessage>
    </div>
  );
};

interface SelectFieldType {
  inputError?: string | number | undefined;
  name: string;
  label: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  value?: string | number;
  width?: string;
  data?: { name: string; code: string }[];
  holder?: string;
  children?: any;
}

export const SelectField = ({
  inputError,
  name,
  label,
  value,
  onChange,
  width,
  data,
}: SelectFieldType) => {
  return (
    <div className="creatHotel__main__inputCon">
      <Label htmlFor={name}>{label}</Label>
      <br />

      <SelectAlt
        error={inputError ? true : false}
        width={width}
        onChange={onChange}
        name={name}
        id={name}
        value={value}
      >
        <option value="">Select Country</option>
        {data?.map((item: any) => {
          return (
            <option key={item.code} value={item.name}>
              {item.name}
            </option>
          );
        })}
      </SelectAlt>
      {/* <Input
        width={width}
        error={inputError ? true : false}
        value={value}
        type={type}
        id={name}
        onChange={onChange}
      /> */}
      <br />
      <ErrorMessage show={inputError ? true : false}>{inputError}</ErrorMessage>
    </div>
  );
};
export const SelectFieldAlt = ({
  inputError,
  name,
  label,
  value,
  onChange,
  width,
  holder,
  children,
}: SelectFieldType) => {
  return (
    <div className="creatHotel__main__inputCon">
      <Label htmlFor={name}>{label}</Label>
      <br />

      <SelectAlt2
        error={inputError ? true : false}
        width={width}
        onChange={onChange}
        name={name}
        id={name}
        value={value}
      >
        <option value="">{holder}</option>
        {children}
      </SelectAlt2>

      <br />
      <ErrorMessage show={inputError ? true : false}>{inputError}</ErrorMessage>
    </div>
  );
};

// interface TextareaFieldType extends TextFieldType {
//   labelSize?: string;
// }

interface TextareaFieldType {
  inputError?: string | number | undefined;
  name: string;
  label: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  value?: string | number;
  type?: string;
  width?: string;
  labelSize?: string;
}

export const TextareaField = ({
  inputError,
  name,
  label,
  value,
  onChange,
  width,
  labelSize,
}: TextareaFieldType) => {
  return (
    <div className="creatHotel__main__inputCon">
      <Label
        style={{
          fontSize: labelSize ? labelSize : "1.4rem",
        }}
        htmlFor={name}
      >
        {label}
      </Label>
      <br />
      <Textarea
        width={width}
        error={inputError ? true : false}
        value={value}
        id={name}
        rows={6}
        onChange={onChange}
      ></Textarea>
      <br />
      <ErrorMessage show={inputError ? true : false}>{inputError}</ErrorMessage>
    </div>
  );
};

interface RadioFieldTypes {
  value?: string;
  label: string;
  id: string;
  name: string;
  onChange: (value: string) => void;
  checked: string;
}

export function RadioField({
  value,
  label,
  id,
  name,
  onChange,
  checked,
}: RadioFieldTypes) {
  return (
    <WrapperRadio>
      <Radio
        type="radio"
        id={id}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        checked={checked === value}
      />
      <LabelRadio htmlFor={id}>{label}</LabelRadio>
    </WrapperRadio>
  );
}
