import { useEffect, useState } from "react";
import searchIcon from "../assets/image/svg/searchL.svg";
import boxPink from "../assets/image/boxPink.png";
import boxBlue from "../assets/image/boxBlue.png";
import boxPurple from "../assets/image/boxPurple.png";
import boxSky from "../assets/image/boxSky.png";
import boxYellow from "../assets/image/boxYellow.png";
import boxGreen from "../assets/image/boxGreen.png";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const FaqPage = () => {
  const [mainData, setMainData] = useState<any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const queryData = [
    {
      title: "What is the Ultainfiity Global Group?",
      disc: "We are a financial technology group spread across the world. We have a wealth ecosystem that aims to bring about our mission to kickstart a new era of cryptocurrency wealth. This ecosystem includes brands, platforms, state-of-the-art technologies, financial expertise and numerous other efficient wealth-driven products and offerings.",
    },
    {
      title:
        "What are the business units that make up the Ultainfinity Global Group?",
      disc: "The Ultainfinity Global Group is made up of successful business units/conglomerates, the Ultainfinity group, the Sumra group, the PeerWealth group and the SLAPA group. All four are working harmoniously to realise the wealth vision of the Ultainfinity Global Group.",
    },
    {
      title: "What are the Ultainfinity cryptocurrencies?",
      disc: "Ultainfinity cryptocurrencies currently comprise $UTTA Tokens, $DIVIT Tokens, and $SLAPA Tokens. All three cryptocurrencies shall be available on Ultainfinity PreMarket Exchange, Ultainfinity Exchange, Ultainfinity Wallet and other popular worldwide platforms.",
    },
    {
      title: "What sets the Ultainfinity cryptocurrencies apart?",
      disc: "Ultainfinity cryptocurrencies are forged from the best of financial technology, blockchain and security. They, therefore, guarantee price growth, are unaffected by the market’s irregularities, are highly scalable and liquid and bring about expeditious supercharged wealth.",
    },
    {
      title: "What do I stand to benefit from Ultainifinity?",
      disc: "We offer a magnificent wealth advantage and the financial power to create wealth in the Ultainfinity ecosystem and the crypto space at large. You can invest and get high returns, trade and make the best out of the cryptocurrency market as well as be lent funds to increase your return on investment and do many businesses on Ultainfinity.",
    },
    {
      title: "What is the Ultainfinity mission?",
      disc: "The cynosure of the Ultainfinity mission supercharged wealth creation. Our mission is first to raise a horde of millionaires and second to preferably, completely wipe poverty and every trace of it from every corner of the earth, or at the very least, to significantly reduce permanently generational poverty across every quadrant of the world.",
    },

    {
      title:
        "How do I participate in the Ultainfinity Airdrops and Bounties Program?",
      disc: "To participate, you will need to download and/or own a crypto wallet and as well, sign up on Ultainfinity Airdrops and Bounties platform using OneStep registration. Separately, investors in Ultainfinity Wealth LaunchPad get exclusive bigger benefits from the Ultainfinity Airdrops and Bounties programs, such as, bigger airdrops and access to Ultainfinity Instant CreditLine.",
    },
    {
      title:
        "How long would the Ultainfinity Airdrops and Bounties Program Last?",
      disc: "The Ultainfinity Airdrops and Bounties Program is set to run for 300 days.",
    },
    {
      title:
        "How can I invest or withdraw proceeds from Ultainfinity Airdrops and Bounties ?",
      disc: "You will be able to invest and or withdraw your airdrops and bounties in Ultainfinity Wealth LaunchPad, Ultainfinity PreMarket Exchange, Ultainfinity Wallet, Ultainfinity Exchange and Ultainfinity Wealthy.",
    },
    {
      title: "Can I double or triple my entries for the Airdrop?",
      disc: "Yes. You can enter many more times than double or triple. The earning process is completely iterative, hence accommodating unlimited entries and an uncapped airdropped wealth.",
    },
    {
      title: "What can I do with my airdropped tokens?",
      disc: "Your tokens received from the Ultainfinity Airdrops and Bounties Program can be traded, held or invested. They can also be used to do all crypto businesses such as debt servicing, payments etc.",
    },
    {
      title:
        "What will the Ultainfinity cryptocurrencies be given out in this Airdrops and Bounties Program?",
      disc: "The UTTA token, an Ultainfinity token, which has been described as the King of Inexorable price growth by traders and investors, is the cryptocurrency that the Ultainfinity Airdrops and Bounties shall start with. Subsequently, our trillion-dollar DIVIT token will be available in the Airdrops and Bounties Program.",
    },
    {
      title: "Can I apply for the Instant Creditline with my airdropped token?",
      disc: "The Instant Creditline opportunity is only open to direct Ultainfinity (Wealth Launchpad) users and not to airdrops and bounties users.",
    },

    {
      title: "What is happening in the Ultainfinity Cash for Charity Program?",
      disc: "The Ultainfinity mission to annihilate poverty in every form has already kicked off and we recognize that there are charities, nonprofits and NGOs that are working to achieve similar purposes. The Ultainfinity Cash for Charity Program seeks to support 3,000 of these organisations with a $1m donation each.",
    },
    {
      title: "How do I become a beneficiary?",
      disc: "First, only organisations are eligible to be a part of the Ultainfinity Cash for Charity program. Calls for applications are now open and organisations working in line with poverty eradication and making the world better are invited to apply. Successful applications would be announced after review and other filtering/screening processes are completed.",
    },
    {
      title: "Who is an Introducer?",
      disc: "An introducer is a referrer of a charity foundation for the Ultainfinity cash for Charity program. They also guide the organisations through the application process and get paid benefits for a great job done.",
    },
    {
      title: "Who is referred to as a sponsor?",
      disc: "A sponsor/CEO/founder or the chairman of the organisation applies for the Ultainfinity cash-for-charity program on behalf of the organisation.",
    },
    {
      title: "What is the mission of the Ultainfinity Foundation?",
      disc: "The Ultainfinity Foundation is created and managed by the Ultainfinity Global Group. The foundation works to meet the needs of the global community and amplify the Ultainfinity poverty obliteration and wealth missions.",
    },

    {
      title:
        "What drives the inexorable price growth of the Ultainfinity cryptocurrencies?",
      disc: "Quite simply; the solid SLAPA Model & Systems, the ULTAINFINITY tokenomics and the inexorably thriving ecosystem. All three of our cryptocurrencies will continue to increase rapidly in price and value due to their multiple use cases and expanding utility across the global network of ultainfinity and its affiliated platforms, products, services etc.",
    },
    {
      title: "What are the unique features of Ultainfinities?",
      disc: "The Ultainfinity cryptocurrencies are embedded in our unique, fascinating wealth innovation called the SLAPA model and systems. This means the Ultainfinity cryptocurrencies are STABLE, LIQUID, ASSET backed and guarantee PRICE ACCELERATION. Additionally, you will enjoy secure and fast transactions with the Ultainfinity cryptocurrencies.",
    },
    {
      title: "What else can I do with the Ultainfinity cryptocurrencies?",
      disc: "Ultainfinity cryptocurrencies are well famous for their unlimited use cases and vast tokenomics which include utilities, payments, store of value and rewards.",
    },
    {
      title: "How do you explain the Ultainfinity tokenomics?",
      disc: "The economics of the Ultainfinity cryptocurrencies is too vast to be detailed here, however, its superiority is made even more obvious in its boundless spread to every part of the world, its first-class price stability, the trillion-dollar tag of the UTTA and DIVIT tokens, governance and the Ultainfifnity Reserve Controller.",
    },
    {
      title: "What does the SLAPA Model & Systems do?",
      disc: "The SLAPA Model & System is a distinctive, unique and fascinating wealth acceleration innovation that ensures that Ultainfinity users enjoy the very best of cryptocurrency. SLAPA is eponymous for the chief features of the Ultainfinity cryptocurrencies - Stability, Liquidity, Assets and Price Appreciation. Additionally, it is estimated that two-thirds of the world will be lifted from poverty upon full implementation of the SLAPA model and systems.",
    },
    {
      title:
        "Are there any special crypto exchange that can handle the Ultainfinities token?",
      disc: "For a crypto like the Ultainfinities, it would be inappropriate to use a crypto exchange that cannot handle its glamor and epoch, hence the Ultainfinity PreMarket Exchange. The Ultainfinity PreMarket Exchange is specially designed to cater for trading, investing and all other crypto exchange functions of the Ultainfinity tokens.",
    },
    {
      title: "What does GRIP mean? Can I join?",
      disc: "GRIP is an eponym for Global Reach Implementation Program. They are a group of dedicated and positive Ultainfinity team that thrusts the Ultainfinity wealth to every corners of the earth. Their role is similar to the Ultainfinity Worldwide Partners’ (UWP). UWP include influencers, brands/companies, sales executives etc that work with Ultainfinity to promote Ultainfinity wealth and yes, you can join by completing an application/recruitment process.",
    },
    {
      title: "What does the Global Waitlist Centre do?",
      disc: "This is how we connect you to our multifarious, very engaged ecosystem in the most simple way possible. Through the Global Waitlist Centre, you can enroll in any of our wealth creation and management programs, see your dashboard and how you rank in the Global Earner’s Leaderboard, plus connect to withdraw your financial rewards.",
    },

    {
      title:
        "Would I automatically qualify for Ultainfinity Instant Creditline?",
      disc: "To receive the Ultainfinity Instant Creditline, one must apply for it. The application is open only to Ultainfinity users, meaning that the first criterion to be able to apply for the Instant Creditline is to have registered on the Ultainfinity wealth launchpad.",
    },
    {
      title: "Can I receive Instant Creditline more than once?",
      disc: "Multiple instant creditlines are allowed, plus you can run them simultaneously. Nonetheless, each of them must have a separate application.",
    },
    {
      title: "What is the Instant Creditline?",
      disc: "The Ultainfinity instant creditline provides an opportunity for Ultainfinity users to multiply their earnings. It is a VVIP ticket to supercharged and stable wealth creation. The Instant creditline is how we offer loans up to the tune of $1 million daily. Users get between $10,000 and $250,000 in loans on their first application and after good repayment, will automatically become eligible for $1 million daily. Imagine trading and/or investing with that amount!",
    },
    {
      title:
        "What is the credit score that must be reached or passed to receive the $1 million Instant Creditline?",
      disc: "Ultainfinity Instant Creditline is unlike any other kind of loan that requires a 670+ credit score. Our application process does not include a credit score or even a personal guarantee or collateral. To be eligible for the $1 million Instant Creditline, you must have first applied for and received the first-time loan of $10,000 - $250,000 and must have repaid this loan. Then you can automatically qualify to apply for a $1 million instant creditline.",
    },
    {
      title:
        "How does the Step Case Strategy create more millionaires and billionaires?",
      disc: "The Step case strategy is both efficacious and well-thought-out to breed the next generation of supercharged millionaires and billionaires. Investors can use some uncashed gains from their instant creditline to apply for the next higher Instant Creditline. An investor could, for example invest $1,000 to get $10,000 Instant Creditline and a 10,000% return or invest $9,000 to get $90,000 Instant Creditline and a 10,000% return.",
    },

    {
      title:
        "What are the myriads of opportunities available to Ultainfinity users?",
      disc: "There are several mega earning, money-making and stable wealth opportunities with Ultainfinity, a myriad is in the Ultainfinity Wealth Launchpad including 99,000% return on investment and high trading returns, sprees of rewards, the Ultainfinity Airdrops and Bounties, the Ultainfinity Instant Creditline, etc. Wealth creation is what we do and no one does it better",
    },
    {
      title: "Can I participate in all Ultainfinity wealth creation programs?",
      disc: "Absolutely. There are no restrictions to the programs that you can participate in. All the windows of Ultainfinity wealth are open to you",
    },
    {
      title:
        "I need more clarity on the 5 stage investment. What does it mean?",
      disc: `Considering the return on investment that Ultainfinity investors get at different times during their investment period, we categorised them into 5 stages to make it easier for the investor to follow up. These 5 stages are identified by their uncapped, titanic and gratifying return on investment. They include: <br /> 
        Stage 1 - 0% <br />
        Stage 2 - 950% <br />
        Stage 3 - 8550% <br />
        Stage 4 - 72600% <br />
        Stage 5 - 581 300%`,
    },
    {
      title: "What is the highest Ultainfinity earning possible?",
      disc: "With Ultainfinity, there is no maximum. This is one of the many reasons we stand. Your maximum earning is only determined by your imagination, but for us, we look forward to fulfilling all of your wealth goals. We start at helping you get your dream earning, and move towards supercharged and stable wealth unlike any other ever seen.",
    },
    {
      title: "Are there other wealth making opportunities?",
      disc: `The opportunity to become Ultainfinity wealthy is neither irregular nor one-off. For example, the next wave of massive wealth at Ultainfinity Wealth Launchpad comes from Whitelist, PreSales and Instant Creditline programs that will commence soon with the wave of monthly launches of a series of SLAPA Tokens, SLAPA LP Tokens, Synthetic SLAPA Tokens etc some of which could be purchased for cents, and projected to rise to thousands. <br />
        Now, imagine your breathtaking gains after buying at the early stages with long term price growth, and/or consider the multimillion multiplier effects of using leverage. <br />
        Visit Ultainfinity Wealth Launchpad and register as an investor for an early bird investment opportunity when the time comes. Ultainfinity Wealth Launchpad direct investors are scheduled to be part of the early birds investors and the biggest beneficiaries. <br />
        `,
    },
    {
      title: "Are multiple investments allowed?",
      disc: "Multiple investments are allowed as we encourage multiple entries, however it is unlawful to create multiple user accounts.",
    },
  ];

  const data = [
    {
      title: "General Information",
      slug: "general_Information",
      img: boxPink,
    },
    {
      title: "Ultainfinity Airdrops and Bounties",
      slug: "ultainfinity_airdrops_and_bounties",
      img: boxBlue,
    },
    {
      title: "Ultainfinity Cash for Charity Program",
      slug: "ultainfinity_cash_for_charity_program",
      img: boxPurple,
    },
    {
      title: "Global Ecosystem",
      slug: "global_ecosystem",
      img: boxSky,
    },
    {
      title: "Ultainfinity Instant CreditLine",
      slug: "ultainfinity_instant_creditLine",
      img: boxYellow,
    },
    {
      title: "Unlimited Entries & Uncapped Earnings",
      slug: "unlimited_entries_and_uncapped_earnings",
      img: boxGreen,
    },
  ];

  const handleChange = (e: any) => {
    const newData = queryData.filter((item) =>
      item.title.toLowerCase().includes(e.target.value.toLowerCase())
    );
    // console.log("newData", newData);
    if (e.target.value.length > 0) {
      setMainData(newData);
    } else {
      setMainData([]);
    }
  };

  return (
    <div className="faqPage">
      <div className="faqPage__header">
        <h1>Need a hand? We’ve got you.</h1>

        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div className="faqPage__header__input">
            <input type="text" onChange={handleChange} />
            <img src={searchIcon} alt="" />
          </div>
        </div>
      </div>

      {mainData.length > 0 ? (
        <div className="faqPage__group">
          <div className="faqPage__group__mainList">
            {mainData.map((item: any, i: number) => {
              return (
                <div key={i} className="faqPage__group__mainList__item">
                  <div className="faqPage__group__mainList__item__header">
                    <h3>{item.title}</h3>
                    <div className="faqPage__group__mainList__item__header__icon">
                      {" "}
                      <span>-</span>
                    </div>
                  </div>
                  <p>{parse(item.disc)} </p>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="faqPage__group">
          <div className="faqPage__group__list">
            {data.map((item, i) => {
              return (
                <Link
                  style={{ color: "#000" }}
                  key={i}
                  to={"/faq/" + item.slug}
                >
                  <div className="faqPage__group__list__item">
                    <img src={item.img} alt="" />
                    <h2>{item.title}</h2>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default FaqPage;
