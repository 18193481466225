import { useEffect, useState } from "react";
import parse from "html-react-parser";

const Carousel = ({ program, cfc }: { program?: string; cfc?: boolean }) => {
  const [index, setIndex] = useState(0);

  const calculateTimeLeft: any = () => {
    const difference = +new Date(`2023-9-2`) - +new Date();
    let timeLeft = {};

    // if (difference > 0) {
    //   timeLeft = {
    //     days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    //     hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    //     minutes: Math.floor((difference / 1000 / 60) % 60),
    //     seconds: Math.floor((difference / 1000) % 60),
    //   };
    // } else {
    timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      // };
    };

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const dataIndicator = [0, 1, 2, 3];
  const data = [
    {
      title: `Refer 3 friends. Get <span>$3,000</span>`,
      disc: `Enjoy the advantage of having a network and make good money off it. When you successfully bring in three friends to Ultainfinity, you immediately unlock $3,000. This process can be repeatedly done and so too, the earnings never cease.`,
    },
    {
      title: `Subscribe or Follow SIX Ultainfinity Community Channels. Get <span>$3,000</span>`,
      disc: `We have a unique, all-pervading presence on many social communities including Twitter, YouTube, Facebook, Instagram, Quora, LinkedIn etc. You could unlock $3,000 by subscribing to 6 of these social media communities or channels`,
    },
    {
      title: `Invest minimum $250. Get <span>4x</span> returns plus $3,500 giveaway`,
      disc: `The world is already cognizant of the transformations that Ultainfinity comes with and here is a once-in-a-lifetime opportunity to write your name in gold. You can invest a minimum of $250`,
    },
    {
      title: ` Submit minimum $25 of another crypto to Ultainfinity. Get <span>10x</span> returns plus $3,000 giveaway`,
      disc: `With the Ultainfinity Airdrops and Bounties Program, there are no constraints to participating or to how much you can earn. By submitting a minimum of $25 worth of APE, BTC, BCH, DAI, DOGE, ETH, LTC, SHIB, USDT, USDC or SOL to Ultainfinity, you immediately get a 10x return. Completing this process means that you also unlock $3,000.`,
    },
  ];

  const cashForCharity = {
    title: `Introduce Charities And Earn <span>$1,000</span>`,
    disc: `PHASE ONE FOR ULTAINFINITY CASH FOR CHARITY PROGRAM 
      APPLICATION CLOSES ON 22nd September 2023`,
  };

  useEffect(() => {
    if (!program) {
      let slider = setInterval(() => {
        setIndex((oldIndex) => {
          let index = oldIndex + 1;
          if (index > data.length - 1) {
            index = 0;
          }
          return index;
        });
      }, 4000);

      return () => {
        clearInterval(slider);
      };
    }
  }, [index, data.length, program]);

  return (
    <div className="carousel">
      <div className={!program ? "carousel__main" : "carousel__main program"}>
        {!program ? (
          data.map((item, itemIndex) => {
            const { disc, title } = item;

            let position = "nextSlide article";
            if (itemIndex === index) {
              position = "activeSlide article";
            }
            if (
              itemIndex === index - 1 ||
              (index === 0 && itemIndex === data.length - 1)
            ) {
              position = "lastSlide article";
            }

            return (
              <article className={position} key={itemIndex}>
                <h2 className="carousel__main__title">{parse(title)}</h2>
                <p className="carousel__main__disc">{disc}</p>
              </article>
            );
          })
        ) : cfc ? (
          <div style={{ width: "100%" }}>
            <h2 className="carousel__main__title">
              {parse(cashForCharity.title)}
            </h2>
            <p className="carousel__main__disc">{cashForCharity.disc}</p>
          </div>
        ) : (
          <div>
            <h2 className="carousel__main__title">
              {parse(data[parseInt(program)].title)}
            </h2>
            <p className="carousel__main__disc">
              {data[parseInt(program)].disc}
            </p>
          </div>
        )}
      </div>
      <div className="carousel__static">
        <p className="carousel__static__disc">
          Countdown to the greatest Airdrops and bounties program.
        </p>
        <div className="carousel__static__countDown">
          <div className="carousel__static__countDown__item">
            <h3>{timeLeft.days}</h3>
            <span>Days</span>
          </div>
          <div className="carousel__static__countDown__item">
            <h3>{timeLeft.hours}</h3>
            <span>Hours</span>
          </div>
          <div className="carousel__static__countDown__item">
            <h3>{timeLeft.minutes}</h3>
            <span>Minutes</span>
          </div>
          <div className="carousel__static__countDown__item">
            <h3>{timeLeft.seconds}</h3>
            <span>Seconds</span>
          </div>
        </div>
      </div>

      {!program && (
        <div className="carousel__indicator">
          {dataIndicator.map((num) => {
            return (
              <span
                key={num}
                className={
                  index === num
                    ? "carousel__indicator__item active"
                    : "carousel__indicator__item"
                }
              ></span>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Carousel;
