import { combineReducers } from "redux";
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import bountiesReducer from "./slices/bounties";
import airdropsReducer from "./slices/airdrops";
import leaderboardReducer from "./slices/leaderboard";
import walletReducer from "./slices/wallet";
import settingsReducer from "./slices/settings";
import cashForCharityReducer from "./slices/cashForCharity";

const rootReducer = combineReducers({
  auth: authReducer,
  bounties: bountiesReducer,
  leaderboard: leaderboardReducer,
  airdrops: airdropsReducer,
  wallet: walletReducer,
  settings: settingsReducer,
  cashForCharity: cashForCharityReducer,
  message: messageReducer,
});

export default rootReducer;
