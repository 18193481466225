import { useEffect } from "react";
import AuthForm from "../components/AuthForm";

const Login = ({ theme }: { theme: string }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="login">
      <AuthForm theme={theme} />
    </div>
  );
};

export default Login;
