// import arrowIcon from "../../assets/image/svg/arrowLeft.svg";
import kycIcon from "../../assets/image/svg/kyc.svg";
import kyc2Icon from "../../assets/image/svg/kyc2.svg";
import kyc3Icon from "../../assets/image/svg/kyc3.svg";
import documentKYCIcon from "../../assets/image/svg/documentKYC.svg";
import livePhotoIcon from "../../assets/image/svg/livePhoto.svg";
import kycExIcon from "../../assets/image/kycEx.png";
import { SelectFieldAlt } from "../styledComponent/form/textFields";
import { Button } from "../styledComponent/form/Buttons";
import Camera from "react-html5-camera-photo";
import { useRef, useState } from "react";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { postDocument } from "../../store/slices/settings";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { ErrorMessage } from "../styledComponent/form/textFields/styles";

const Verify = ({ setCurrStage }: any) => {
  const [showCamera, setshowCamera] = useState(false);
  const [photoUrl, setphotoUrl] = useState("");
  const [documentUrl, setdocumentUrl] = useState("");
  const [documentName, setdocumentName] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState<any>({});

  const document: any = useRef(null);
  const dispatch = useAppDispatch();

  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleChange = async (event: any) => {
    const fileUploaded = event.target.files[0];

    getBase64(fileUploaded, async (result: any) => {
      // console.log("result", result);
      setdocumentUrl(result);
    });
  };

  const handleClick = () => {
    document.current.click();
  };

  const handleTakePhoto = (dataUri: any) => {
    // Do stuff with the photo...
    setphotoUrl(dataUri);
    setshowCamera(false);
  };

  const validate = () => {
    let typeError = "";
    let documentError = "";
    let selfieError = "";

    if (!documentName) {
      typeError = "document type is required";
    }
    if (!documentUrl) {
      documentError = "document is required";
    }
    if (!photoUrl) {
      selfieError = "selfie is required";
    }

    if (typeError || documentError || selfieError) {
      setInputError((curr: any) => {
        return {
          ...curr,
          type: typeError,
          document: documentError,
          selfie: selfieError,
        };
      });

      return false;
    }
    return true;
  };

  const handleDocumentSelfieUpload = () => {
    const checkValidate = validate();
    if (checkValidate) {
      const data = {
        document_type: documentName,
        document_image: documentUrl,
        selfie_image: photoUrl,
      };
      setLoading(true);
      dispatch(postDocument(data))
        .unwrap()
        .then(() => {
          setLoading(false);
          toast.success("Document Sent Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCurrStage(3);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    }
  };

  return (
    <div className="verify">
      <h2 className="verify__title">
        <span>Step 2.</span> Verify Your Identity
      </h2>

      {/* <div onClick={() => setCurrStage(1)} className="verify__back">
        <img src={arrowIcon} alt="" /> Back to Personal Details
      </div> */}

      <div className="verify__dis">
        <div className="verify__dis__left">
          <h4>
            To avoid delays when verifying your account, please make sure:
          </h4>
          <p>
            <img src={kyc3Icon} alt="" /> Please upload the following personal
            document.
          </p>
          <p>
            <img src={kycIcon} alt="" /> Chosen document is visible and in good
            condition.
          </p>
          <p>
            <img src={kyc2Icon} alt="" /> Make sure that there is no light glare
            on the card.
          </p>
        </div>

        <div className="verify__dis__right">
          {" "}
          <img src={kycExIcon} alt="" />
        </div>
      </div>

      <h3>
        Upload all of the necessary documents below to verify your identity
      </h3>
      <div className="verify__main">
        <div className="verify__main__left">
          <h4>IDENTITY DOCUMENT</h4>

          <SelectFieldAlt
            name="document"
            label=""
            holder="Choose documennt"
            onChange={(e) => setdocumentName(e.target.value)}
            inputError={inputError?.type}
          >
            <option value="Passport">International Passport</option>
            <option value="Residence Permit">National ID</option>
            <option value="Drivers License">Driver’s Licence</option>
          </SelectFieldAlt>

          <div className="verify__main__left__upload">
            <input
              type="file"
              accept=".png,.jpeg,.jpg,.doc,.docx,.pdf"
              ref={document}
              onChange={(e) => handleChange(e)}
              style={{ display: "none" }}
            />

            {documentUrl ? (
              <img src={documentUrl} alt="" />
            ) : (
              <>
                <img src={documentKYCIcon} alt="" />
                <p onClick={handleClick}>
                  Click to upload document <br />
                  <span> Maximum file size: 5mb</span>
                </p>
              </>
            )}
          </div>
          <ErrorMessage show={inputError?.document}>
            {inputError?.document}
          </ErrorMessage>

          <div className="verify__main__left__disc">
            Only Government Issued Documents are allowed.
          </div>
          <div className="verify__main__left__disc">
            Document must have your photo.
          </div>
        </div>
        <div className="verify__main__right">
          <h4 style={{ marginBottom: 20 }}>SELFIE LIVE PHOTO</h4>

          <div className="verify__main__right__upload">
            {/* <Camera
              onTakePhoto={(dataUri) => {
                handleTakePhoto(dataUri);
              }}
            /> */}
            {showCamera ? (
              <Camera
                onTakePhoto={(dataUri) => {
                  handleTakePhoto(dataUri);
                }}
              />
            ) : photoUrl ? (
              <img src={photoUrl} alt="" />
            ) : (
              <>
                <img src={livePhotoIcon} alt="" />
                <p onClick={() => setshowCamera(true)}>Click to Open Camera</p>
              </>
            )}
          </div>
          <ErrorMessage show={inputError?.selfie}>
            {inputError?.selfie}
          </ErrorMessage>

          <div className="verify__main__right__disc">
            Live Photo must show full face. No wearing of masks, wearing of
            glasses, or anything to obscure your face.
          </div>
          <div className="verify__main__right__disc">
            Take photo in a well-lit room.
          </div>
        </div>
      </div>

      <div className="verify__btn">
        <Button onClick={handleDocumentSelfieUpload} $fill>
          {loading ? (
            <ReactLoading color="white" width={30} height={30} type="spin" />
          ) : (
            "Continue"
          )}
        </Button>
      </div>
    </div>
  );
};

export default Verify;
