import { useEffect, useRef, useState } from "react";
import { Button } from "../components/styledComponent/form/Buttons";
import uploadIcon from "../assets/image/svg/upload.svg";
import {
  RadioField,
  TextareaField,
  TextField,
} from "../components/styledComponent/form/textFields";
import {
  ErrorMessage,
  Label,
} from "../components/styledComponent/form/textFields/styles";
import ApplicationResponse from "../components/ApplicationResponse";
import Modal from "react-modal";
import { postApplicantApply } from "../store/slices/cashForCharity";
import { toast } from "react-toastify";
import { useAppDispatch } from "../hooks/reduxHooks";
import ReactLoading from "react-loading";
import BeneficiaryPart2 from "../components/BeneficiaryPart2";

const Beneficiary = () => {
  const [part, setPart] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [confirm, setconfirm] = useState(false);
  const [inputError, setInputError] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [filesName, setFilesName] = useState({
    business_registration_certificate: "",
    charter: "",
    logo: "",
    supporting_document: "",
  });
  const [formData, setFormData] = useState({
    type: "Charity organisation",
    telephone: "",
    website: "",
    fax: "",
    name: "",
    year_founded: "",
    location: "",
    other_location: "",
    email: "",
    linkedin: "",
    telegram: "",
    twitter: "",
    other_social_media: "",
    founder_name: "",
    registration_no: "",
    registration_date: "",
    mission: "",
    supporting_document: "",
    charter: "",
    business_registration_certificate: "",
    logo: "",
    referral_code: "",
    vision: "",
    organisation_culture: "",
    organisation_description: "",
    poverty_eradication_description: "",
    funds_utilization_plan: "",
    applicant_name: "",
    applicant_job_title: "",
    date: "",
    cv: "",
    operating_executive: {
      name: "",
      position: "",
      linkedin: "",
      website: "",
      other_social_media: "",
      resume: "",
    },
    registered_officers: [
      {
        name: "",
        position: "",
        other_social_media: "",
        phone_number: "",
        linkedin: "",
        website: "",
      },
      {
        name: "",
        position: "",
        other_social_media: "",
        phone_number: "",
        linkedin: "",
        website: "",
      },
    ],

    organisation_referees: [
      {
        name: "",
        email: "",
        other_social_media: "",
        phone_number: "",
        linkedin: "",
        website: "",
      },
      {
        name: "",
        email: "",
        other_social_media: "",
        phone_number: "",
        linkedin: "",
        website: "",
      },
      {
        name: "",
        email: "",
        other_social_media: "",
        phone_number: "",
        linkedin: "",
        website: "",
      },
    ],
  });
  const businessRCInput: any = useRef(null);
  const charterInput: any = useRef(null);
  const logoInput: any = useRef(null);
  const documentInput: any = useRef(null);

  const handleClick = (reference: any) => {
    reference.current.click();
  };
  const dispatch = useAppDispatch();

  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleChange = async (event: any, name: string) => {
    const fileUploaded = event.target.files[0];

    setFilesName((curr) => {
      return { ...curr, [name]: fileUploaded.name };
    });
    if (name === "resume") {
      getBase64(fileUploaded, async (result: any) => {
        setFormData((curr) => {
          return {
            ...curr,
            operating_executive: {
              ...curr.operating_executive,
              [name]: result,
            },
          };
        });
      });
    } else {
      getBase64(fileUploaded, async (result: any) => {
        setFormData((curr) => {
          return { ...curr, [name]: result };
        });
      });
    }
  };

  const checkDate = (date: string) => {
    if (!date) {
      return false;
    }

    const today = new Date();
    const mm = today.toLocaleString("en-US", { month: "2-digit" });
    const dd = today.toLocaleString("en-US", { day: "2-digit" });
    const yyyy = today.getFullYear();

    const selectedDate: any = date.match(/(\d+)/g);

    const addedSelectedDate =
      selectedDate[0] + selectedDate[1] + selectedDate[2];
    const addedDate = yyyy + mm + dd;

    if (parseInt(addedSelectedDate) < parseInt(addedDate)) {
      return true;
    } else {
      return false;
    }
  };

  const validate = () => {
    let typeError = "";
    let telephoneError = "";
    let websiteError = "";
    let nameError = "";
    let linkedinError = "";
    let year_foundedError = "";
    let locationError = "";
    let emailError = "";
    let founder_nameError = "";
    let registration_noError = "";
    let registration_dateError = "";
    let missionError = "";
    let charterError = "";
    let business_registration_certificateError = "";
    let logoError = "";
    let visionError = "";
    let organisation_cultureError = "";
    let organisation_descriptionError = "";
    let poverty_eradication_descriptionError = "";
    let funds_utilization_planError = "";
    let applicant_nameError = "";
    let applicant_job_titleError = "";
    let proceedError = "";
    let confirmError = "";

    let OEnameError = "";
    let OEpositionError = "";
    let OElinkedinError = "";
    let OEresumeError = "";

    let ROnameError = "";
    let ROpositionError = "";
    let ROlinkedinError = "";
    let ROphone_numberError = "";

    let OR1nameError = "";
    let OR1emailError = "";
    let OR1linkedinError = "";
    let OR1phone_numberError = "";

    let OR2nameError = "";
    let OR2emailError = "";
    let OR2linkedinError = "";
    let OR2phone_numberError = "";

    let OR3nameError = "";
    let OR3emailError = "";
    let OR3linkedinError = "";
    let OR3phone_numberError = "";

    if (!formData.type) {
      typeError = "organisation type is required";
    }
    if (!formData.website) {
      websiteError = "website is required";
    }
    if (!proceed) {
      proceedError = "website is required";
    }
    if (!confirm) {
      confirmError = "website is required";
    }
    if (!formData.name) {
      nameError = "name is required";
    }
    if (!formData.email) {
      emailError = "email is required";
    }
    if (!formData.linkedin) {
      linkedinError = "linkedin is required";
    }
    if (!formData.telephone) {
      telephoneError = "telephone is required";
    }
    if (formData.mission.length < 10) {
      missionError = "mission is required, minimum character is 10";
    }
    if (!formData.year_founded) {
      year_foundedError = "year founded is required";
    }
    if (!formData.location) {
      locationError = "location is required";
    }
    if (formData.founder_name.length < 3) {
      founder_nameError = "founder name is required, minimum character is 3";
    }
    if (!formData.registration_no) {
      registration_noError = "registration number is required";
    }
    if (!formData.registration_date) {
      registration_dateError = "registration date is required";
    }
    if (formData.registration_date && !checkDate(formData.registration_date)) {
      registration_dateError = "registration date must be in the past";
    }
    if (!formData.charter) {
      charterError = "charter is required";
    }
    if (!formData.logo) {
      logoError = "logo is required";
    }
    if (formData.vision.length < 10) {
      visionError = "vision is required, minimum character is 10";
    }
    if (!formData.business_registration_certificate) {
      business_registration_certificateError =
        "business registration certificate is required";
    }
    if (!formData.organisation_culture) {
      organisation_cultureError = "organisation culture is required";
    }
    if (formData.organisation_description.length < 350) {
      organisation_descriptionError =
        "organisation description is required, minimum character is 350";
    }
    if (formData.poverty_eradication_description.length < 250) {
      poverty_eradication_descriptionError =
        "poverty eradication description is required, minimum character is 250";
    }
    if (formData.funds_utilization_plan.length < 500) {
      funds_utilization_planError =
        "funds utilization description is required, minimum character is 500";
    }
    if (!formData.applicant_name) {
      applicant_nameError = "applicant name is required";
    }
    if (!formData.applicant_job_title) {
      applicant_job_titleError = "applicant job title is required";
    }

    if (!formData.operating_executive.name) {
      OEnameError = "name is required";
    }
    if (!formData.operating_executive.position) {
      OEpositionError = "position is required";
    }
    if (!formData.operating_executive.resume) {
      OEresumeError = "resume is required";
    }
    if (!formData.operating_executive.linkedin) {
      OElinkedinError = "linkedin is required";
    }

    if (!formData.registered_officers[0].name) {
      ROnameError = "name is required";
    }
    if (!formData.registered_officers[0].position) {
      ROpositionError = "position is required";
    }
    if (!formData.registered_officers[0].phone_number) {
      ROphone_numberError = "phone number is required";
    }
    if (!formData.registered_officers[0].linkedin) {
      ROlinkedinError = "linkedin is required";
    }

    if (!formData.organisation_referees[0].name) {
      OR1nameError = "name is required";
    }
    if (!formData.organisation_referees[0].email) {
      OR1emailError = "email is required";
    }
    if (!formData.organisation_referees[0].phone_number) {
      OR1phone_numberError = "phone number is required";
    }
    if (!formData.organisation_referees[0].linkedin) {
      OR1linkedinError = "linkedin is required";
    }

    if (!formData.organisation_referees[1].name) {
      OR2nameError = "name is required";
    }
    if (!formData.organisation_referees[1].email) {
      OR2emailError = "email is required";
    }
    if (!formData.organisation_referees[1].phone_number) {
      OR2phone_numberError = "phone number is required";
    }
    if (!formData.organisation_referees[1].linkedin) {
      OR2linkedinError = "linkedin is required";
    }

    if (!formData.organisation_referees[2].name) {
      OR3nameError = "name is required";
    }
    if (!formData.organisation_referees[2].email) {
      OR3emailError = "email is required";
    }
    if (!formData.organisation_referees[2].phone_number) {
      OR3phone_numberError = "phone number is required";
    }
    if (!formData.organisation_referees[2].linkedin) {
      OR3linkedinError = "linkedin is required";
    }

    if (
      typeError ||
      telephoneError ||
      websiteError ||
      nameError ||
      year_foundedError ||
      linkedinError ||
      locationError ||
      emailError ||
      founder_nameError ||
      registration_noError ||
      registration_dateError ||
      missionError ||
      charterError ||
      business_registration_certificateError ||
      logoError ||
      visionError ||
      organisation_cultureError ||
      organisation_descriptionError ||
      poverty_eradication_descriptionError ||
      funds_utilization_planError ||
      applicant_nameError ||
      applicant_job_titleError ||
      proceedError ||
      confirmError ||
      OEnameError ||
      OEpositionError ||
      OElinkedinError ||
      OEresumeError ||
      ROnameError ||
      ROpositionError ||
      ROlinkedinError ||
      ROphone_numberError ||
      OR1nameError ||
      OR1emailError ||
      OR1linkedinError ||
      OR1phone_numberError ||
      OR2nameError ||
      OR2emailError ||
      OR2linkedinError ||
      OR2phone_numberError ||
      OR3nameError ||
      OR3emailError ||
      OR3linkedinError ||
      OR3phone_numberError
    ) {
      let newOrgRef: any = [
        {
          name: "",
          email: "",
          phone_number: "",
          linkedin: "",
        },
        {
          name: "",
          email: "",
          phone_number: "",
          linkedin: "",
        },
        {
          name: "",
          email: "",
          phone_number: "",
          linkedin: "",
        },
      ];

      newOrgRef[0].name = OR1nameError;
      newOrgRef[0].email = OR1emailError;
      newOrgRef[0].linkedin = OR1linkedinError;
      newOrgRef[0].phone_number = OR1phone_numberError;

      newOrgRef[1].name = OR2nameError;
      newOrgRef[1].email = OR2emailError;
      newOrgRef[1].linkedin = OR2linkedinError;
      newOrgRef[1].phone_number = OR2phone_numberError;

      newOrgRef[2].name = OR3nameError;
      newOrgRef[2].email = OR3emailError;
      newOrgRef[2].linkedin = OR3linkedinError;
      newOrgRef[2].phone_number = OR3phone_numberError;

      setInputError((curr: any) => {
        return {
          ...curr,
          type: typeError,
          telephone: telephoneError,
          website: websiteError,
          name: nameError,
          year_founded: year_foundedError,
          location: locationError,
          email: emailError,
          linkedin: linkedinError,
          founder_name: founder_nameError,
          registration_no: registration_noError,
          registration_date: registration_dateError,
          mission: missionError,
          charter: charterError,
          business_registration_certificate:
            business_registration_certificateError,
          logo: logoError,
          vision: visionError,
          organisation_culture: organisation_cultureError,
          organisation_description: organisation_descriptionError,
          poverty_eradication_description: poverty_eradication_descriptionError,
          funds_utilization_plan: funds_utilization_planError,
          applicant_name: applicant_nameError,
          applicant_job_title: applicant_job_titleError,
          proceed: proceedError,
          confirm: confirmError,
          operating_executive: {
            name: OEnameError,
            position: OEpositionError,
            linkedin: OElinkedinError,
            resume: OEresumeError,
          },
          registered_officers: {
            name: ROnameError,
            position: ROpositionError,
            linkedin: ROlinkedinError,
            phone_number: ROphone_numberError,
          },
          organisation_referees: newOrgRef,
        };
      });

      return false;
    }
    return true;
  };

  // console.log(inputError);
  const handleApply = () => {
    const checkValidate = validate();
    if (checkValidate) {
      setLoading(true);
      dispatch(postApplicantApply(formData))
        .unwrap()
        .then(() => {
          setLoading(false);
          openModal();
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setLoading(false);
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const openModal = (): void => {
    setModalIsOpen(true);
  };
  function closeModal(): void {
    setModalIsOpen(false);
  }

  const handleRadioChange = (value: string) => {
    setFormData((curr) => {
      return { ...curr, type: value };
    });
  };

  return (
    <div className="beneficiary">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Application Modal"
      >
        <ApplicationResponse />
      </Modal>

      <div className="beneficiary__header">
        <h1>Ultainfinity Cash For Charity: Application for Beneficiaries </h1>
        <p>
          Fill out the form to be an introducer of the $3 billion cash for
          charities by the Ultainfinity Foundation
        </p>
      </div>

      <p className="beneficiary__disc">
        The Ultainfinity Foundation of the Ultainfinity Global Group is
        impacting the world again, as it has always done, this time with a $3
        billion donation to charities, non-profits, NGOs and similar
        organisations that are working to obliterate poverty, just like the
        Ultainfinity. To be a part of the Ultainfinity Cash for Charity, please
        understand that you will need to fill out this form. Your application
        will be reviewed by our team and we will contact you if you are selected
        as a beneficiary.
      </p>

      <div className="beneficiary__checkbox">
        <div className="beneficiary__checkbox__item">
          <input
            onChange={() => setProceed(!proceed)}
            checked={proceed}
            type="checkbox"
            id="proceed"
          />{" "}
          <label htmlFor="proceed">
            I understand this and and I am willing to proceed*
          </label>
        </div>
        <ErrorMessage show={inputError?.proceed}>
          check box to submit
        </ErrorMessage>
      </div>
      <div className="beneficiary__form">
        {part === 1 ? (
          <div className="beneficiary__form__part1">
            <h3 className="beneficiary__form__part1__title">Part One</h3>

            <div className="beneficiary__form__part1__orginization">
              <Label>
                Which of the following best describes your organization?*
              </Label>
              <div className="beneficiary__form__part1__orginization__main">
                <div className="beneficiary__checkbox__item">
                  <RadioField
                    id="Charity organisation"
                    name="employmentType"
                    value="Charity organisation"
                    label="Charity organisation"
                    onChange={handleRadioChange}
                    checked={formData.type}
                  />
                </div>
                <div className="beneficiary__checkbox__item">
                  <RadioField
                    id="Non-profit organisation"
                    name="type"
                    value="Non-profit organisation"
                    label="Non-profit organisation"
                    onChange={handleRadioChange}
                    checked={formData.type}
                  />
                </div>
                <div className="beneficiary__checkbox__item">
                  <RadioField
                    id="Non Governmental Organisation"
                    name="type"
                    value="Non Governmental Organisation"
                    label="Non Governmental Organisation"
                    onChange={handleRadioChange}
                    checked={formData.type}
                  />
                </div>
                <div className="beneficiary__checkbox__item">
                  <RadioField
                    id="Interest group"
                    name="type"
                    value="Interest group"
                    label="Interest group"
                    onChange={handleRadioChange}
                    checked={formData.type}
                  />
                </div>
                <div className="beneficiary__checkbox__item">
                  <RadioField
                    id="Others"
                    name="type"
                    value="Others"
                    label="Others"
                    onChange={handleRadioChange}
                    checked={formData.type}
                  />
                </div>
              </div>
            </div>

            <TextField
              width="100%"
              name="name"
              label="What is the name of your organisation?*"
              onChange={(e) =>
                setFormData((curr) => {
                  return { ...curr, name: e.target.value };
                })
              }
              value={formData.name}
              inputError={inputError.name}
            />

            <div className="beneficiary__form__part1__operations">
              <TextField
                width="100%"
                name="location"
                label="Organisation office address?*"
                onChange={(e) =>
                  setFormData((curr) => {
                    return { ...curr, location: e.target.value };
                  })
                }
                value={formData.location}
                inputError={inputError.location}
              />

              <TextField
                width="100%"
                name="website"
                label="Organisation Website*"
                onChange={(e) =>
                  setFormData((curr) => {
                    return { ...curr, website: e.target.value };
                  })
                }
                value={formData.website}
                inputError={inputError.website}
              />
              <TextField
                width="100%"
                name="other_location"
                label="Other offices (if any)"
                onChange={(e) =>
                  setFormData((curr) => {
                    return { ...curr, other_location: e.target.value };
                  })
                }
                value={formData.other_location}
                inputError={inputError.other_location}
              />
              {/* <div style={{ marginTop: "7px" }}>
                <TextField width="100%" name="offices2" label="" />
              </div> */}
            </div>

            <div className="beneficiary__form__part1__contactInfo">
              <h4>Contact Information</h4>
              <div className="beneficiary__form__part1__contactInfo__main">
                <TextField
                  width="100%"
                  name="email"
                  label="Email*"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, email: e.target.value };
                    })
                  }
                  value={formData.email}
                  inputError={inputError.email}
                />
                <TextField
                  width="100%"
                  name="telephone"
                  label="Telephone*"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, telephone: e.target.value };
                    })
                  }
                  value={formData.telephone}
                  inputError={inputError.telephone}
                />
                {/* <TextField width="100%" name="number" label="Mobile Number"  /> */}
                <TextField
                  width="100%"
                  name="fax"
                  label="FAX"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, fax: e.target.value };
                    })
                  }
                  value={formData.fax}
                  inputError={inputError.fax}
                />
                <TextField
                  width="100%"
                  name="linkedin"
                  label="LinkedIn*"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, linkedin: e.target.value };
                    })
                  }
                  value={formData.linkedin}
                  inputError={inputError.linkedin}
                />
                <TextField
                  width="100%"
                  name="twitter"
                  label="Twitter"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, twitter: e.target.value };
                    })
                  }
                  value={formData.twitter}
                  inputError={inputError.twitter}
                />
                <TextField
                  width="100%"
                  name="telegram"
                  label="Telegram"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, telegram: e.target.value };
                    })
                  }
                  value={formData.telegram}
                  inputError={inputError.telegram}
                />
                <TextField
                  width="100%"
                  name="other_social_media"
                  label="Others"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, other_social_media: e.target.value };
                    })
                  }
                  value={formData.other_social_media}
                  inputError={inputError.other_social_media}
                />
              </div>
            </div>

            <div className="beneficiary__form__part1__founder">
              <TextField
                width="100%"
                name="founder_name"
                label="Name of Founder (Include most appropriate prefix)*"
                onChange={(e) =>
                  setFormData((curr) => {
                    return { ...curr, founder_name: e.target.value };
                  })
                }
                value={formData.founder_name}
                inputError={inputError.founder_name}
              />
              <TextField
                onChange={(e) =>
                  setFormData((curr) => {
                    return { ...curr, year_founded: e.target.value };
                  })
                }
                value={formData.year_founded}
                inputError={inputError.year_founded}
                width="100%"
                name="foundedYear"
                label="Year Founded*"
              />
            </div>

            <div className="beneficiary__form__part1__registration">
              <h4>Registration Details</h4>
              <div className="beneficiary__form__part1__registration__main">
                <TextField
                  width="100%"
                  name="registration_no"
                  label="Number*"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, registration_no: e.target.value };
                    })
                  }
                  value={formData.registration_no}
                  inputError={inputError.registration_no}
                />
                <TextField
                  width="100%"
                  name="registration_date"
                  type="date"
                  label="Date*"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, registration_date: e.target.value };
                    })
                  }
                  value={formData.registration_date}
                  inputError={inputError.registration_date}
                />
              </div>
            </div>

            <div className="beneficiary__form__part1__about">
              <h4>About Organisation</h4>
              <div className="beneficiary__form__part1__about__main">
                <TextareaField
                  name="vision"
                  label="Vision of Organisation*"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, vision: e.target.value };
                    })
                  }
                  value={formData.vision}
                  inputError={inputError.vision}
                />
                <TextareaField
                  name="mission"
                  label="Mission and/or objectives of the organistion*"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, mission: e.target.value };
                    })
                  }
                  value={formData.mission}
                  inputError={inputError.mission}
                />
                <TextareaField
                  name="organisation_culture"
                  label="What are the 5 most revered culture of your organisation*"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, organisation_culture: e.target.value };
                    })
                  }
                  value={formData.organisation_culture}
                  inputError={inputError.organisation_culture}
                />
              </div>
            </div>

            <div className="beneficiary__form__part1__upload">
              <h4>Upload the following</h4>

              <div className="beneficiary__form__part1__upload__main">
                <div className="beneficiary__form__part1__upload__main__item">
                  <p>Business Registration Certificate*</p>
                  <div
                    onClick={() => handleClick(businessRCInput)}
                    className="beneficiary__form__part1__upload__main__item__click"
                  >
                    <input
                      type="file"
                      accept=".png,.jpeg,.jpg,.doc,.docx,.pdf"
                      ref={businessRCInput}
                      onChange={(e) =>
                        handleChange(e, "business_registration_certificate")
                      }
                      style={{ display: "none" }}
                    />
                    <img src={uploadIcon} alt="" /> Click to Upload
                  </div>
                  <div className="beneficiary__form__part1__upload__main__item__name">
                    {filesName.business_registration_certificate}
                  </div>
                </div>
                <ErrorMessage
                  show={inputError?.business_registration_certificate}
                >
                  business registration certificate is required
                </ErrorMessage>
                <div className="beneficiary__form__part1__upload__main__item">
                  <p>Charter*</p>
                  <div
                    onClick={() => handleClick(charterInput)}
                    className="beneficiary__form__part1__upload__main__item__click"
                  >
                    <img src={uploadIcon} alt="" /> Click to Upload
                    <input
                      type="file"
                      accept=".png,.jpeg,.jpg,.doc,.docx,.pdf"
                      ref={charterInput}
                      onChange={(e) => handleChange(e, "charter")}
                      style={{ display: "none" }}
                    />
                  </div>
                  <div className="beneficiary__form__part1__upload__main__item__name">
                    {filesName.charter}
                  </div>
                </div>
                <ErrorMessage show={inputError?.charter}>
                  charter is required
                </ErrorMessage>
                <div className="beneficiary__form__part1__upload__main__item">
                  <p>Organisation logo*</p>
                  <div
                    onClick={() => handleClick(logoInput)}
                    className="beneficiary__form__part1__upload__main__item__click"
                  >
                    <input
                      type="file"
                      accept=".png,.jpeg,.jpg,.doc,.docx,.pdf"
                      ref={logoInput}
                      onChange={(e) => handleChange(e, "logo")}
                      style={{ display: "none" }}
                    />
                    <img src={uploadIcon} alt="" /> Click to Upload
                  </div>
                  <div className="beneficiary__form__part1__upload__main__item__name">
                    {filesName.logo}
                  </div>
                </div>
                <ErrorMessage show={inputError?.logo}>
                  logo is required
                </ErrorMessage>
                <div className="beneficiary__form__part1__upload__main__item">
                  <p>Other supporting documents</p>
                  <div
                    onClick={() => handleClick(documentInput)}
                    className="beneficiary__form__part1__upload__main__item__click"
                  >
                    <img src={uploadIcon} alt="" /> Click to Upload
                    <input
                      type="file"
                      accept=".png,.jpeg,.jpg,.doc,.docx,.pdf"
                      ref={documentInput}
                      onChange={(e) => handleChange(e, "supporting_document")}
                      style={{ display: "none" }}
                    />
                  </div>
                  <div className="beneficiary__form__part1__upload__main__item__name">
                    {filesName.supporting_document}
                  </div>
                </div>
                <ErrorMessage show={inputError?.supporting_document}>
                  supporting documents is required
                </ErrorMessage>
              </div>
            </div>

            <div className="beneficiary__form__part1__btn">
              <Button onClick={() => setPart(2)} $fill radius="10px">
                Next
              </Button>
            </div>
          </div>
        ) : part === 2 ? (
          <BeneficiaryPart2
            setPart={setPart}
            filesName={filesName}
            inputError={inputError}
            handleChange={handleChange}
            setFormData={setFormData}
            formData={formData}
          />
        ) : (
          <div className="beneficiary__form__part3">
            <h3 className="beneficiary__form__part3__title">Part Three</h3>

            <div className="beneficiary__form__part3__disc">
              <div className="beneficiary__form__part3__disc__item">
                <TextareaField
                  name="organisation_description"
                  label="Write a 350-word essay describing your organisation’s vision and bailiwick*"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return {
                        ...curr,
                        organisation_description: e.target.value,
                      };
                    })
                  }
                  value={formData.organisation_description}
                  inputError={inputError.organisation_description}
                />
                <span>Word Count - 250</span>
              </div>
              <div className="beneficiary__form__part3__disc__item">
                <TextareaField
                  name="poverty_eradication_description"
                  label="How has your organisation helped to eradicate poverty in its local community?*"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return {
                        ...curr,
                        poverty_eradication_description: e.target.value,
                      };
                    })
                  }
                  value={formData.poverty_eradication_description}
                  inputError={inputError.poverty_eradication_description}
                />
                <span>Word Count - 250</span>
              </div>
              <div className="beneficiary__form__part3__disc__item">
                <TextareaField
                  name="utilise"
                  label="If you are successful in your application for the Ultainfinity cash for charity, you will receive a donation of $1M. How does your 
                organisation plan to utilise this sum?*"
                  onChange={(e) =>
                    setFormData((curr) => {
                      return {
                        ...curr,
                        funds_utilization_plan: e.target.value,
                      };
                    })
                  }
                  value={formData.funds_utilization_plan}
                  inputError={inputError.funds_utilization_plan}
                />
                <span>Word Count - 500</span>
              </div>
            </div>

            <div className="beneficiary__form__part3__line"></div>
            <div className="beneficiary__checkbox__item">
              {/* <input type="checkbox" id="proceed" />{" "} */}
              <label htmlFor="confirm">
                I confirm that I have filled this application form for the
                Ultainfinity cash for charity on behalf of my organisation,
                named in 1 above*
              </label>
              <input
                onChange={() => setconfirm(!confirm)}
                checked={confirm}
                type="checkbox"
                id="confirm"
              />{" "}
              {/* </div> */}
            </div>
            <ErrorMessage show={inputError?.confirm}>
              check box to submit
            </ErrorMessage>

            <div className="beneficiary__form__part3__filled">
              <TextField
                width="100%"
                name="applicant_name"
                label="Full Name*"
                onChange={(e) =>
                  setFormData((curr) => {
                    return {
                      ...curr,
                      applicant_name: e.target.value,
                    };
                  })
                }
                value={formData.applicant_name}
                inputError={inputError.applicant_name}
              />
              <TextField
                width="100%"
                name="applicant_job_title"
                label="Title/Position in organisation*"
                onChange={(e) =>
                  setFormData((curr) => {
                    return {
                      ...curr,
                      applicant_job_title: e.target.value,
                    };
                  })
                }
                value={formData.applicant_job_title}
                inputError={inputError.applicant_job_title}
              />
            </div>

            <div className="beneficiary__form__part3__introduced">
              <h4>
                If you have been introduced, please enter details of introducer
                below:
              </h4>
              <TextField
                width="100%"
                name="referral_code"
                label="Referral Code"
                onChange={(e) =>
                  setFormData((curr) => {
                    return {
                      ...curr,
                      referral_code: e.target.value,
                    };
                  })
                }
                value={formData.referral_code}
                inputError={inputError.referral_code}
              />
            </div>

            <div className="beneficiary__form__part3__btn">
              <Button onClick={() => setPart(2)} $fill radius="10px">
                Back
              </Button>
              <Button onClick={handleApply} $fill radius="10px">
                {loading ? (
                  <ReactLoading
                    color="white"
                    width={30}
                    height={30}
                    type="spin"
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Beneficiary;
