import { Link } from "react-router-dom";
import logo from "../assets/image/svg/logo.svg";
import logoLight from "../assets/image/svg/logoLight.svg";
import { Button } from "../components/styledComponent/form/Buttons";
import coinsImg from "../assets/image/3coins.png";

const SignupSuccess = ({ theme }: { theme: string }) => {
  return (
    <div className="authLayout">
      <div className="authLayout__header">
        <img
          src={theme === "light" ? logoLight : logo}
          alt=""
          className="authLayout__header__logo"
        />
      </div>
      <div className="authLayout__main">
        <div className="authForm__user">
          <img style={{ marginBottom: "10px" }} src={coinsImg} alt="" />
          <h2 className="authForm__user__title">
            Welcome to Ultainfinity Airdrops & Bounties 🎉
          </h2>
          <p className="authForm__user__disc">
            To qualify and unlock any Ultainfinity Airdrops and Bounties
            programs, you must first go to the wallet section in the dashboard
            and register crypto wallet
          </p>

          <Link to="/dashboard/wallet">
            <Button width="250px" $fill>
              Continue to Dashboard
            </Button>
          </Link>
          {/* <Link to="/recovery/username">
            <span>Forgot username?</span>{" "}
          </Link> */}
        </div>
      </div>
      {/* <div className="authLayout__footer">
        <p className="authLayout__footer__disc">
          By using either Sign Up or Login you agree to our{" "}
        </p>
        <span className="authLayout__footer__link">
          Terms & Privacy Policy.
        </span>
      </div> */}
    </div>
  );
};

export default SignupSuccess;
