import React, { useEffect } from "react";

const Theme = ({ children, theme, setTheme }: any) => {
  const localStorage = window.localStorage;

  useEffect(() => {
    const savedThemeLocal = localStorage.getItem("globalTheme");
    if (savedThemeLocal) {
      setTheme(savedThemeLocal);
    }
  }, [localStorage, setTheme]);

  useEffect(() => {
    localStorage.setItem("globalTheme", theme);
  }, [theme, localStorage]);

  return <div className={`theme--${theme}`}>{children}</div>;
};

export default Theme;
