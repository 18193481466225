import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import airdropsSevice from "../services/airdrops.service";
import { setMessage } from "./message";

export const getReferralCode: any = createAsyncThunk(
  "airdrop/getReferralCode",
  async (id: string, thunkAPI) => {
    try {
      const data = await airdropsSevice.getReferralCode();
      return { referralCode: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reactivateReferralCode: any = createAsyncThunk(
  "airdrop/reactivateReferralCode",
  async (data: any, thunkAPI) => {
    try {
      const dataMain = await airdropsSevice.reactivateReferralCode(data);
      return { referralCode: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getReferrals: any = createAsyncThunk(
  "airdrop/getReferrals",
  async (id: string, thunkAPI) => {
    try {
      const data = await airdropsSevice.getReferrals();
      return { referrals: data.invited_users };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTotalCode: any = createAsyncThunk(
  "airdrop/getTotalCode",
  async (id: string, thunkAPI) => {
    try {
      const data = await airdropsSevice.getTotalCode();
      return { totalCode: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const storeReferralCode: any = createAsyncThunk(
  "airdrop/storeReferralCode",
  async (data: any, thunkAPI) => {
    try {
      const dataMain = await airdropsSevice.storeReferralCode(data);
      return { storeReferralCode: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const checkReferralUnlock: any = createAsyncThunk(
  "airdrop/checkReferralUnlock",
  async (id, thunkAPI) => {
    try {
      const data = await airdropsSevice.checkReferralUnlock();
      return { referralUnlock: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const checkReferralReactivate: any = createAsyncThunk(
  "airdrop/checkReferralReactivate",
  async (id, thunkAPI) => {
    try {
      const data = await airdropsSevice.checkReferralReactivate();
      return { referralUnlock: data.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const checkUserReferral: any = createAsyncThunk(
  "airdrop/checkUserReferral",
  async (id, thunkAPI) => {
    try {
      const data = await airdropsSevice.checkUserReferral();
      return { userReferral: data.message };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState: any = {
  referralCode: null,
  reactivate: false,
  totalCode: null,
  referrals: null,
  storeReferralCode: false,
  referralUnlock: null,
  referralReactivate: false,
  userReferral: null,
};

export const airdropsSlice = createSlice({
  name: "airdrops",
  initialState,
  reducers: {},
  extraReducers: {
    [getReferralCode.fulfilled]: (state, action) => {
      state.referralCode = action.payload.referralCode;
    },
    [getReferralCode.rejected]: (state, action) => {
      state.referralCode = null;
    },
    [reactivateReferralCode.fulfilled]: (state, action) => {
      state.referralCode = action.payload.referralCode;
      state.reactivate = true;
    },
    [reactivateReferralCode.rejected]: (state, action) => {
      state.reactivate = false;
    },
    [getReferrals.fulfilled]: (state, action) => {
      state.referrals = action.payload.referrals;
    },
    [getReferrals.rejected]: (state, action) => {
      state.referrals = null;
    },
    [getTotalCode.fulfilled]: (state, action) => {
      state.totalCode = action.payload.totalCode;
    },
    [getTotalCode.rejected]: (state, action) => {
      state.totalCode = null;
    },
    [storeReferralCode.fulfilled]: (state, action) => {
      state.storeReferralCode = true;
    },
    [storeReferralCode.rejected]: (state, action) => {
      state.storeReferralCode = false;
    },
    [checkReferralUnlock.fulfilled]: (state, action) => {
      state.referralUnlock = action.payload.referralUnlock;
    },
    [checkReferralUnlock.rejected]: (state, action) => {
      state.referralUnlock = null;
    },
    [checkReferralReactivate.fulfilled]: (state, action) => {
      state.referralReactivate = action.payload.referralReactivate;
    },
    [checkReferralReactivate.rejected]: (state, action) => {
      state.referralReactivate = false;
    },
    [checkUserReferral.fulfilled]: (state, action) => {
      state.userReferral = action.payload.userReferral;
    },
    [checkUserReferral.rejected]: (state, action) => {
      state.userReferral = null;
    },
  },
});

const { reducer } = airdropsSlice;
export default reducer;
