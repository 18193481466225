import cryptoWallet from "../assets/image/cryptoWallet.png";
import { Button } from "./styledComponent/form/Buttons";
import closeIcon from "../assets/image/svg/close.svg";
import { Link } from "react-router-dom";

interface WalletModalType {
  closeModal: () => void;
  wallet: {
    address: string;
    name: string;
    type: string;
    img: any;
  };
  showCodeLink: boolean;
}
const WalletModal = ({ closeModal, wallet, showCodeLink }: WalletModalType) => {
  return (
    <div className="walletModal">
      <div className="walletModal__close" onClick={closeModal}>
        <img src={closeIcon} alt="" />
      </div>
      <h3 className="walletModal__title">Wallet Registered Successfully</h3>
      <img className="walletModal__img" src={cryptoWallet} alt="" />
      <div className="walletModal__walletType">
        <h4 className="walletModal__walletType__title">{wallet.type}</h4>
        <div className="walletModal__walletType__main">
          {" "}
          <img src={wallet.img} alt="" /> {wallet.name}
        </div>
      </div>
      <div className="walletModal__address">
        {/* <h5>Wallet Address </h5> */}
        <div className="walletModal__address__main">
          {wallet?.address?.substring(0, 20)}*****
        </div>
      </div>

      <div className="walletModal__btnCon">
        <Link to="/dashboard">
          <Button width="250px">Go To Dashboard</Button>
        </Link>
        {showCodeLink && (
          <Link to="/enter-referral-code">
            <Button width="250px" $fill>
              Input Referral/Promo Code
            </Button>
          </Link>
        )}
      </div>
      {/* <div className="walletModal__line"></div> */}
    </div>
  );
};

export default WalletModal;
