import styled from "styled-components";
import { colorTheme } from "../../../../assets/colors";
import { ErrorMessageType, InputType, SelectType } from "../../../../types";

export const Input = styled.input<InputType>`
  height: 4.8rem;
  width: ${(props) => (props.width ? props.width : "100%")};
  border-radius: 5px;
  outline: none;
  background: ${({ theme }) => theme.input};
  color: ${({ theme }) => theme.textAlt};
  border: ${(props) =>
    props.error
      ? `2px solid ${colorTheme.error}`
      : `2px solid ${props.theme.input}`};
  margin-bottom: 1rem;
  padding: 0 1rem;
  transition: 0.3s;

  &:hover {
    border: ${(props) =>
      props.error
        ? `2px solid ${colorTheme.error}`
        : `2px solid ${colorTheme.primary}`};
  }

  &:focus {
    border: ${(props) =>
      props.error
        ? `2px solid ${colorTheme.error}`
        : `2px solid ${colorTheme.primary}`};
  }
`;

export const InputAlt = styled.input<InputType>`
  height: 4.8rem;
  width: ${(props) => (props.width ? props.width : "100%")};
  border-radius: 5px;
  outline: none;
  color: ${({ theme }) => theme.textAlt};
  border: ${(props) =>
    props.error ? `2px solid ${colorTheme.error}` : `2px solid #353954`};
  margin-bottom: 1rem;
  background-color: transparent;
  padding: 0 1rem;
  transition: 0.3s;

  &:hover {
    border: ${(props) =>
      props.error
        ? `2px solid ${colorTheme.error}`
        : `2px solid ${colorTheme.primary}`};
  }

  &:focus {
    border: ${(props) =>
      props.error
        ? `2px solid ${colorTheme.error}`
        : `2px solid ${colorTheme.primary}`};
  }
`;

export const SelectAlt = styled.select<InputType>`
  height: 4.8rem;
  width: ${(props) => (props.width ? props.width : "100%")};
  border-radius: 5px;
  outline: none;
  background: ${({ theme }) => theme.input};
  color: ${({ theme }) => theme.textAlt};
  border: ${(props) =>
    props.error
      ? `2px solid ${colorTheme.error}`
      : `2px solid ${props.theme.input}`};
  margin-bottom: 1rem;
  padding: 0 1rem;
  transition: 0.3s;

  &:hover {
    border: ${(props) =>
      props.error
        ? `2px solid ${colorTheme.error}`
        : `2px solid ${colorTheme.primary}`};
  }

  &:focus {
    border: ${(props) =>
      props.error
        ? `2px solid ${colorTheme.error}`
        : `2px solid ${colorTheme.primary}`};
  }
`;
export const SelectAlt2 = styled.select<InputType>`
  height: 4.8rem;
  width: ${(props) => (props.width ? props.width : "100%")};
  border-radius: 5px;
  outline: none;
  background: transparent;
  color: ${({ theme }) => theme.textAlt};
  border: ${(props) =>
    props.error ? `2px solid ${colorTheme.error}` : `2px solid #353954`};
  margin-bottom: 1rem;
  padding: 0 1rem;
  transition: 0.3s;

  &:hover {
    border: ${(props) =>
      props.error
        ? `2px solid ${colorTheme.error}`
        : `2px solid ${colorTheme.primary}`};
  }

  &:focus {
    border: ${(props) =>
      props.error
        ? `2px solid ${colorTheme.error}`
        : `2px solid ${colorTheme.primary}`};
  }
`;

export const Textarea = styled.textarea<InputType>`
  /* height: 4.8rem; */
  width: ${(props) => (props.width ? props.width : "100%")};
  border-radius: 5px;
  outline: none;
  background: ${({ theme }) => theme.input};
  color: ${({ theme }) => theme.textAlt};
  border: ${(props) =>
    props.error
      ? `2px solid ${colorTheme.error}`
      : `2px solid ${props.theme.input}`};
  margin-bottom: 1rem;
  padding: 2rem;
  transition: 0.3s;
  resize: none;

  &:hover {
    border: ${(props) =>
      props.error
        ? `2px solid ${colorTheme.error}`
        : `2px solid ${colorTheme.primary}`};
  }

  &:focus {
    border: ${(props) =>
      props.error
        ? `2px solid ${colorTheme.error}`
        : `2px solid ${colorTheme.primary}`};
  }
`;

export const InputPin = styled.input<InputType>`
  height: 6.8rem;
  width: 30rem;
  border-radius: 13px;
  outline: none;
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  color: ${({ theme }) => theme.text};
  letter-spacing: 7px;
  text-align: center;
  background-color: ${({ theme }) => theme.bg};
  border: ${(props) =>
    props.error
      ? `1px solid ${colorTheme.error}`
      : `1px solid rgba(157, 165, 178, 0.5)`};
  margin-bottom: 1rem;
  padding: 0 1rem;
  transition: 0.3s;

  &:hover {
    border: ${(props) =>
      props.error
        ? `1px solid ${colorTheme.error}`
        : `1px solid ${colorTheme.primary}`};
  }

  &:focus {
    border: ${(props) =>
      props.error
        ? `1px solid ${colorTheme.error}`
        : `1px solid ${colorTheme.primary}`};
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const InputUser = styled.input<InputType>`
  height: 6rem;
  width: ${(props) => (props.width ? props.width : "36rem")};
  border-radius: 8px;
  outline: none;
  color: ${({ theme }) => theme.text};
  padding: 0 1rem;
  padding-left: 8rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  background-color: ${({ theme }) => theme.bg};
  border: ${(props) =>
    props.error
      ? `1px solid ${colorTheme.error}`
      : `1px solid rgba(157, 165, 178, 0.5)`};
  margin-bottom: 1rem;
  transition: 0.3s;

  &:hover {
    border: ${(props) =>
      props.error
        ? `1px solid ${colorTheme.error}`
        : `1px solid ${colorTheme.primary}`};
  }

  &:focus {
    border: ${(props) =>
      props.error
        ? `1px solid ${colorTheme.error}`
        : `1px solid ${colorTheme.primary}`};
  }

  &::placeholder {
    color: #b1b5c3;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const Select = styled.select<SelectType>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "default")};

  height: 3rem;
  width: 25rem;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
  border: ${(props) =>
    props.error
      ? `2px solid ${colorTheme.error}`
      : `2px solid ${colorTheme.lightGray}`};
  margin-bottom: 1rem;
  padding: 0 1rem;
  transition: 0.3s;

  &:hover {
    border: ${(props) =>
      props.error
        ? `2px solid ${colorTheme.error}`
        : `2px solid ${colorTheme.primary}`};
  }

  &:focus {
    border: ${(props) =>
      props.error
        ? `2px solid ${colorTheme.error}`
        : `2px solid ${colorTheme.primary}`};
  }
`;

export const ErrorMessage = styled.div<ErrorMessageType>`
  color: ${colorTheme.error};
  font-size: 1.2rem;
  font-weight: 400;
  display: ${(props) => (props.show ? "inline-block" : "none")};
  position: relative;
  top: ${(props) => (props.top ? props.top : "-1rem")};
`;

export const Label = styled.label`
  font-size: 1.4rem;
  margin-bottom: 8px;
  display: inline-block;
`;

export const LabelRadio = styled.label<{ disabled?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: StabilGrotesk, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
   `}
`;

export const WrapperRadio = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const Radio = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid #262626;
  border-radius: 50%;
  transition: 0.3s;
  ::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 0.75em;
    height: 0.75em;
    margin: 3px;
  }

  :hover {
    border: 2px solid ${colorTheme.primary};
  }
  :checked {
    border: 2px solid ${colorTheme.primary};

    ::after {
      background-color: ${colorTheme.primary};
    }
  }
`;
