import userIcon from "../assets/image/svg/userL.svg";
// import facebookIcon from "../assets/image/svg/facebookC.svg";
// import twitterIcon from "../assets/image/svg/twitterC.svg";
// import whatsappIcon from "../assets/image/svg/whatsappC.svg";
import linkIcon from "../assets/image/svg/linkC.svg";
import blogImg from "../assets/image/blogImg.png";
import { useEffect, useState } from "react";
import blogImg2 from "../assets/image/blogImg2.png";
import blogImg3 from "../assets/image/blogImg3.png";
import blogImg4 from "../assets/image/blogImg4.png";
import blogImg5 from "../assets/image/blogImg5.png";
import { useParams } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const BlogItemPage = () => {
  const [currBlog, setcurrBlog] = useState<any>(null);
  const [copy, setCopy] = useState(false);
  let { id } = useParams();

  const blogs = [
    {
      id: "sdjhfbfuy7w85hje7fsd",
      img: blogImg,
      discShort:
        "Ultainfinity has a series of Staking products to release to the World for investors on its PreMarket Exchange. The stability of Ultainfinity native cryptocurrency tokens, including UTTA tokens, will detract and discourage dumps, quick sellers, etc.",
      title:
        "The Barrs Model & Systems - An Airdrop & Bounties Invention To Be Unrivalled",
      subTitle:
        "A rich and unique underpin of the $300 Billion+ Ultainfinity Airdrops & Bounties Programs",
      date: "Nov. 17, 2022",
      author: "Ultainfinity Editorial Board",
      body: (
        <div>
          <p>
            Ultainfinity has a series of Staking products to release to the
            World for investors on its PreMarket Exchange. The stability of
            Ultainfinity native cryptocurrency tokens, including UTTA tokens,
            will detract and discourage dumps, quick sellers, etc.
          </p>
          <p>
            The array of rewards, intricacies, and complexities in the
            architectural design of The BARRS MODEL & SYSTEMS will elicit and
            embed enthusiastic die-hard followings and encourage engagement,
            significant involvement, and long-term ownership of Ultainfinity
            tokens by the Ultainfinities communities.
          </p>
          <p>
            The compelling construct of rewards and inbuilt prohibitions
            discourage dumps and detract first-day sellers, group thinking, or
            group sales.
          </p>
          <p>
            In effect, the invention by Mr Michael has either obviated all
            historical obstacles or eliminated all encumbrances in Airdropping
            programs hitherto. His design will single-handedly reverse the sad
            and miserly trajectory of airdrops and bounties.
          </p>
          <p>
            Mr Michael with Ultainfinity aims to create a crypto nirvana and
            transfer wealth from the rich to the poor in all quadrants. This
            vision aligns squarely with and extends the egalitarian entrée of
            the great Satoshi Nakamoto.
          </p>
          <p>
            The airdrops and bounties aim to create hordes of Ultainfinity
            millionaires, permeate long-term awareness and worldwide adoption of
            the Ultainfinities tokens, and certainly not create crypto jokers or
            memes, seekers, and first-day sellers.
          </p>
          <p>
            Ultainfinity is giving away over $300 Billion + in Airdrops &
            Bounties in its initial four programs and will, without any doubt,
            surpass the previous successes of 6 million participants by Bit2Me
            based on a miserly €400k giveaway.
          </p>
          <p>
            Ultainfinity expects many more millions of participants over the
            first 12 months after the commencement of its first Airdrop.
          </p>
          {/* </p> */}
          <h2>
            WHAT DOES THE BARRS MODEL MEAN FOR ULTAINFINITY AND THE CRYPTO
            SECTOR?
          </h2>
          <p>
            The BARRS Model enables and facilitates the creation of hordes of
            Ultainfinity millionaires worldwide.
          </p>
          <p>
            The Ultainfinity Airdrop & Bounties Programs each Create an endemic
            awareness of Ultainfinity.
          </p>
          <p>Furthermore,</p>
          <p>
            Each makes Ultainfinity platforms, including Exchanges to acquire
            Crypto Enthusiasts, Crypto Newbies, etc., and those invested in
            cryptocurrencies and already own crypto wallets to become Users of
            Ultainfinity and fervent patrons of Ultainfinity Super-Ecosystem.
          </p>
          <p>
            Each makes Ultainfinity tokens, various complementary and
            wealth-acceleration products to be adopted, such as Staking, Instant
            CreditLine, SACA By DASA, Synthetic Slapa, Wealth Pass, etc.
          </p>
          <p>
            The BARRS Model's comprehensive coverage of market and price risks
            is in recognition of historical value destruction by past purveyors
            and participants of Airdrops and Bounties. These negatives drove the
            inventor to erect indestructible defences against decanters,
            dumpers, and crypto delinquents. These risks dissipate indisputably,
            reduced to almost zero.
          </p>
          <p>
            The net effect of The BARRS Model for the crypto sector is
            overwhelmingly propitious and, for the whole World, produces an
            enmeshed generational wealth distribution.
          </p>
          <h2> ABOUT THE AUTHOR AND INVENTOR</h2>
          <p>
            The author and inventor are Mr M Michael, Founder, Group CEO &
            Chairman of Ultainfinity Global Group, owners of the pioneering
            Ultainfinity Wealth LaunchPad, Ultainfinity PreMarket Exchange, and
            a stable of other global platforms, including the ingenious OneStep
            ID.
          </p>
          <h2>ABOUT THE ULTAINFINITIES TOKENS & ULTAINFINITY GLOBAL GROUP</h2>
          <p>
            Find out why The Ultainfinities Tokens are unstoppable and have
            relentlessly risen in the past two months from $0.001 and $0.0001 to
            $31 and $3.78, respectively.
          </p>{" "}
          <p>
            Learn more about URC-Ultainfinity Reserve Controller, The SLAPA, and
            BARRS Models & Systems. They are just three, amongst others, that
            invariably underpin and act as propellers for the price rises,
            growth, stability, and liquidity of The Ultainfinities Tokens and
            the Super-Ecosystem pioneered by Ultainfinity Global Group. Read
            Ultainfinity Whitepapers, Medium, and CoinMonks articles, papers,
            and publications, and subscribe to Ultainfinity Community Channels.
          </p>
          <p>
            {" "}
            To get started and share in Ultainfinity's wealth, Visit
            Ultainfinity Wealth LaunchPad.
          </p>
        </div>
      ),
    },
    {
      id: "jlfsfyhryue76438hfjw",
      img: blogImg2,
      discShort:
        "The Ultainfinities airdrops and bounties programs comprise two airdrops and two bounties programs, all containing a giveaway of $12,500. The airdropped tokens program starts with UTTA Tokens. For most people, all required to receive the free Ultainfinities tokens is either to undertake minor services such as referrals, starting with as little as referring three friends to Ultainfinity or simply subscribing to a minimum of six Ultainfinity's community of messenger and social media channels",
      title: "Ultainfinity Airdrops And Bounties Programs.",
      subTitle: "The Ultainfinities $12,500+ Giveaways start with UTTA Token.",
      date: "Nov. 17, 2022",
      author: "Ultainfinity Editorial Board",
      body: (
        <div>
          <h2>What Are The Ultainfinities Airdrops & Bounties Programs?</h2>
          <p>
            The Ultainfinities airdrops and bounties programs comprise two
            airdrops and two bounties programs, all containing a giveaway of
            $12,500. The airdropped tokens program starts with UTTA Tokens. For
            most people, all required to receive the free Ultainfinities tokens
            is either to undertake minor services such as referrals, starting
            with as little as referring three friends to Ultainfinity or simply
            subscribing to a minimum of six Ultainfinity's community of
            messenger and social media channels. Ultainfinity expects crypto
            enthusiasts to join in its egalitarian mission.{" "}
          </p>{" "}
          <p>
            {" "}
            It also believes new communities will enter the cryptocurrency
            market. The new community will help to develop, enrich and trade the
            Ultainfinities and other cryptocurrencies in Ultainfinity Exchanges
            and other Exchanges around the World.
          </p>
          <h2>
            What Does The Barrs Model Mean For Ultainfinity And The Crypto
            Sector?
          </h2>
          <p>
            The reasons for the airdrops and bounties programs are to create
            awareness and adoption of the Ultainfinities, a series of native
            cryptocurrency tokens by Ultainfinity, and to introduce its mission
            statement, projects, and purpose for existence to the World.
          </p>
          <h2>
            What Are The Criteria For Receiving The Ultainfinities Tokens?
          </h2>
          <p>
            All you have to do to start collecting free $12,500 worth of
            Ultainfinity native cryptocurrencies, such as UTTA Tokens, is to
            first register through Ultainfinity's invented simple sign-up and
            identification system called: OneStep ID. A unique, one-step global
            ecosystem and World class technological invention for secure sign-up
            and identification.{" "}
          </p>{" "}
          <p>
            The criteria for receiving Ultainfinity native cryptocurrency
            airdrops and bounties is the willingness to refer a few friends to
            Ultainfinity, to subscribe to its community channels, or support the
            excellent mission of Ultainfinity with one of a selected eleven
            cryptos held in established and secure crypto wallets.
          </p>{" "}
          <p>
            Active members of various blockchain communities, such as Bitcoin,
            Ethereum, Solana, etc., will receive significant amounts of the
            Ultainfinities tokens. You will receive 4x or 10x for each committed
            cryptocurrency in support of the Ultainfinities native
            cryptocurrencies. These supporters benefit even more in huge returns
            of a minimum 4x or 10x $USD worth of free Ultainfinity native
            cryptocurrency tokens, which can be unlocked, invested, traded, and
            withdrawn at Ultainfinity Wealth LaunchPad, Ultainfinity PreMarket
            Exchange and the leading Ultainfinity Exchange.
          </p>
          <h2>Are There Any Downsides Or Catches?</h2>
          <p>
            It is important to note that as Ultainfinity is undertaking a
            legitimate crypto airdrop, all recipients of free tokens will be
            required to identify themselves.{" "}
          </p>{" "}
          <p>
            You will be required to unlock the free tokens worth four thousand
            five hundred dollars or more by referring a few friends, subscribing
            to Ultainfinity community channels, exchanging crypto, or investing
            to show your project support. Investment in the Ultainfinities
            tokens is not compulsory and not necessary to receive free
            Ultainfinity native cryptocurrency tokens worth a minimum of $12,500
            spread broadly across the four airdrops and bounties programs.{" "}
          </p>
          <p>
            There are no limitations to the number of airdrops or bounties
            programs you can partake in with Ultainfinity after registering
            through Ultainfinity's simple sign-up and identification system,
            OneStep ID.
          </p>
          <h2>What Should I Do Now?</h2>
          <p>
            Learn more about Ultainfinity, read its various whitepapers, and
            familiarise yourself with its mission, projects, and purpose. You
            can register with any of Ultainfinity's platforms, including
            Ultainfinity Wealth LaunchPad, and join its 20+ community channels.
            Ultainfinity will be in contact just before the commencement of
            humanity's biggest airdrops and bounties programs.
          </p>
        </div>
      ),
    },
    {
      id: "oadkaerwakjrn76qfq",
      img: blogImg,
      discShort:
        "Throughout the crypto landscape, no other organization has super-wealth-spur-series-of-sub-ecosystems fathered by a Super-Ecosystem that can turn an ordinary investor into a millionaire within days and weeks and can repeat the same to progressive proliferation",
      title:
        "The Ultainfinity Global Group Super-ecosystem - A Superlative Wealth Factory.",
      subTitle: "",
      date: "Nov. 17, 2022",
      author: "Ultainfinity Editorial Board",
      body: (
        <div>
          <p>
            Throughout the crypto landscape, no other organization has
            super-wealth-spur-series-of-sub-ecosystems fathered by a
            Super-Ecosystem that can turn an ordinary investor into a
            millionaire within days and weeks and can repeat the same to
            progressive proliferation. <br />
            Below are very brief highlights of some of our impeccable
            foundations:
          </p>

          <ul>
            <li>
              Our bedrock models & systems, including algorithmic models, act in
              unison to drive users to be successful and to be systemically and
              irreversibly housed in our platforms with inexorably increasing
              daily verifiable blockchain volumes and tandem profits.
            </li>
            <li>
              Our models & systems perpetually suck up and embed enthusiasts and
              new users and, more importantly, global crypto liquidity, thereby
              objectively obliterating any destructive arbitrage opportunity for
              our native cryptocurrencies and repels black swan events, despite
              the listings of our native cryptocurrencies in external exchanges,
              in addition to their core and premium listings with greater
              liquidity on Ultainfinity Exchanges.
            </li>
            <li>
              Our models & systems uniquely create an endemic Virality with
              inexorable ecosystem protection and unrelenting exponential
              Network Effects in users, products, platforms, revenues,
              liquidity, volumes, profits, etc.
            </li>
          </ul>

          <p>
            Reasonably and rightly described, we are the only, or in
            conservative parlance, indeed one of the rarest organizations, an
            assembly of global projects housed as a compendium ecosystem in the
            crypto landscape and, indeed, the international financial realm
          </p>
          <p>
            There’s genuinely none to compare to Ultainfinity Global Group and
            its Superlative Super-Ecosystem
          </p>
          <h2>Are you ready to be wealthy?</h2>
          <p>
            To get started and share Ultainfinity's wealth and become a
            Ultainfinity Millionaire, you must first register and invest by
            purchasing UTTA or DIVIT Tokens in Ultainfinity Wealth LaunchPad
            before applying for instant CreditLines.
          </p>
        </div>
      ),
    },
    {
      id: "msdhslbs7qhq7eh7q902h",
      img: blogImg3,
      discShort:
        "Cryptocurrency has become a household term and is officially one of the most searched words on Google since 2020. The emergence of the pandemic in later 2019 proved to be a blessing that should ordinarily catapult digitalization to the zenith of",
      title: "Ultainfinities - The Greatest Amongst All",
      subTitle:
        "Your high-growth investment and hedge against currency devaluation and inflation",
      date: "Nov. 17, 2022",
      author: "Ultainfinity Editorial Board",
      body: (
        <div>
          <p>
            Cryptocurrency has become a household term and is officially one of
            the most searched words on Google since 2020. The emergence of the
            pandemic in later 2019 proved to be a blessing that should
            ordinarily catapult digitalization to the zenith of achievements.
            Although there was significant progress recorded, we cannot say that
            they have bore scrutiny.
          </p>
          <p>
            Bitcoin, adorned as the most prominent crypto has failed to
            replicate the fate that the public has shown it. The immutable
            creation of cryptocurrency using blockchain technology, a marvellous
            work by Satoshi paved the way for the almost 12,000 cryptocurrencies
            launched after 2009, including Ultainfinity cryptocurrencies. The
            continuous drop in the price of bitcoin in 2022 and the obliteration
            of FTX are some of the many merits that see Ultainfinity
            cryptocurrencies remain the greatest among all.
          </p>
          <p>
            Coin telegraph reports Sam Bankman-Fried (SBF) asking investors for
            emergency funding to cover an $8 billion shortfall during a Nov. 9
            investors call. This is coming on the back of SBF deleting a Twitter
            thread where he tried to assure customers that FTX and the assets on
            the platform were “fine.” The real problem is that SBF of FTX failed
            to understand the reasons why Blackrock, Goldman Sachs, and JP
            Morgan are titans…or even Binance.
          </p>
          <p>
            Ultainfinity cryptocurrencies represent modern-day digital
            currencies, full-proof against the unending fluctuations of the
            cryptocurrency market and well guarded against the scavengers of
            wealth - scammers and fraudsters - plus inexorable growth. Our
            prolific innovation of Ultainfinity’s bedrock SLAPA model and system
            dichotomises the Ultainfinity cryptocurrencies amongst others.
            Ultainfinity is super stable, highly liquid, asset-backed and
            guaranteed more than 99,000% price appreciation. Even at the points
            where Bitcoin experienced a 3-month low between July and October
            2022, the UTTA and DIVIT tokens have not stopped rising. UTTA and
            DIVIT tokens were only launched in August 2022 at $0.001 and $0.0001
            for UTTA and DIVIT tokens respectively, but have now risen to
            $161.09 and $32.15. Our robust ecosystem is one girded with
            efficacious encryptions that provide unpenetrable security,
            unabating liquidity and a blissful user experience. Indeed, there
            are no ecosystems anywhere in the whole world, like the Ultainfinity
            super ecosystem.
          </p>
          <p>
            Ultainfinity has as its core mission and purposes generational
            poverty elimination or its deadening degradation and to spur
            generational wealth creation, including wealth transfer to every
            quadrant of the World, and to be utterly devoid of discrimination
            irrespective of race, religion, sexual orientation, etc.
          </p>
          <p>
            UTTA and DIVIT tokens are indeed your investment solution to the
            current worldwide economic uncertainties, currency devaluations, and
            inflationary pressures. Investing in UTTA and DIVIT tokens now,
            ensures you have thousands of percentage returns on investment. The
            prices of UTTA and DIVIT tokens are projected to be in excess of
            $2900 and $461 respectively after the launch of Ultainfinity
            Airdrops & Bounties and Ultainfinity Exchanges
          </p>
        </div>
      ),
    },
    {
      id: "dljdkfcnjhdsa7374jhe2",
      img: blogImg,
      discShort:
        "Ultainfinity exits will become a reality in Ultainfinity PreMarket Exchange and the upcoming central Ultainfinity Exchange. The Ultainfinities prices are rising and unstoppably keep growing daily. The strong insuppressible",
      title: "The Unstoppable Rise of The Ultainfinities",
      date: "Nov. 17, 2022",
      author: "Ultainfinity Editorial Board",
      body: (
        <div>
          <p>
            Ultainfinity exits will become a reality in Ultainfinity PreMarket
            Exchange and the upcoming central Ultainfinity Exchange. <br />
            The Ultainfinities prices are rising and unstoppably keep growing
            daily. The strong insuppressible demand is getting stronger and
            stronger.
          </p>
          <div>
            There's more to come. Unsurprisingly, Ultainfinity has launched an
            essential global program
          </div>

          <h3>Ultainfinity cash for charity, by ultainfinity foundation.</h3>
          <ul>
            <li>$3 Billion to be donated to charities worldwide</li>
            <li>3000 Charities all around the World</li>
            <li>100 Countries and all continents represented.</li>
            <li>Zero discrimination in awards and disbursement</li>
          </ul>
          <p>
            Ultainfinity Cash for Charity complements the $300 Billion+
            Ultainfinity Airdrops & Bounties.
          </p>
          <h3>Ultainfinity Airdrops</h3>
          <div>
            Look out for more information on Ultainfinity Airdrops in the days
            to come, preceding the central platform and related sets of
            Dashboards for unlocking and claiming this November.
          </div>
          <h2>How Uniquely Solid is the Ultainfinity Ecosystem?</h2>
          <p>
            For investors, the Ultainfinity Wealth LaunchPad is unique in the
            World. The only one of its kind to have the following:
            <ul>
              <li>
                Ultainfinity Instant CreditLine, a unique and overwhelmingly
                beneficial lending sub-platform to our investors
              </li>
              <li>
                A plethora of exit points and desks; OTC Desk, Auto-Trade, and
                P2P and Merchant services within Ultainfinity PreMarket Exchange
                and Ultainfinity Exchange
              </li>
              <li>
                DASA Trader, a guaranteed purchase sub-platform within
                Ultainfinity PreMarket Exchange and Ultainfinity Exchange.
              </li>
            </ul>
            No other crypto organization in the World has a comprehensive and
            beneficial ecosystem as we do. None whatsoever <br />
            The distinctive features, programs, products, and platforms set
            Ultainfinity apart from all others. Read about these in its many
            WHITEPAPERS.
          </p>
          <h2>Are you ready to be wealthy?</h2>
          <p>
            To get started and share Ultainfinity's wealth and become a
            Ultainfinity Millionaire, you must first register and invest by
            purchasing UTTA or DIVIT Tokens in Ultainfinity Wealth LaunchPad
            before applying for instant CreditLines.
          </p>
          <p>
            DO NOT MISS THIS OPPORTUNITY OF A LIFETIME. <br />
            UTTA AND DIVIT TOKENS ARE GOING TO BE BIGGER THAN BITCOIN <br />
            <b style={{ color: "red" }}>IMPORTANT NOTICE</b> <br />
            Instant CreditLine offers are not open to Ultainfinity Airdrop &
            Bounties participants but only to Ultainfinity Wealth LaunchPad
            direct investors.
          </p>
        </div>
      ),
    },
    {
      id: "kjLijdhwqu6384hj3hjwy",
      img: blogImg4,
      discShort:
        "Ultainfinity Instant CreditLine has extraordinary effects on wealth creation and accumulation. Here are a few examples of how you can benefit from and use instant CreditLine and maximize your wealth accumulation. First, by simply using one instant",
      title: "Ultainfinity Instant Creditline",
      subTitle: "Using the Step-Case Strategy for Massive Wealth Accumulation",
      date: "Nov. 17, 2022",
      author: "Ultainfinity Editorial Board",
      body: (
        <div>
          <p>
            Ultainfinity Instant CreditLine has extraordinary effects on wealth
            creation and accumulation. <br />
            Here are a few examples of how you can benefit from and use instant
            CreditLine and maximize your wealth accumulation. <br />
            First, by simply using one instant CreditLine, you can target
            substantial quick returns within days, weeks, and maximum few
            months. <br />
            Second, you can view and utilize multiple and simultaneous instant
            CreditLines as a RAPID STAIRWAY TO WEALTH. <br />
            Third, you can use instant CreditLine STEP-CASE STRATEGY and
            massively increase your WEALTH ACCUMULATION.
          </p>
          <h2>What is a Step-Case Strategy?</h2>
          <p>
            It is the purposeful practice of making subsequent higher instant
            creditline applications using only some of the cash returns from
            previous lower instant creditline applications. <br />
            For example, <br />
            Starting with a $10,000 instant creditline and moving to $250,000
            with only some of the uncashed gains and, after that, moving unto a
            $1m instant creditline and further up the higher step levels of
            instant creditlines up to $100 million.
          </p>
          <h2>Are You Ready to be Wealthy?</h2>
          <p>
            To get started and share Ultainfinity's wealth and become a
            Ultainfinity Millionaire, you must first register and invest by
            purchasing UTTA or DIVIT Tokens in Ultainfinity Wealth LaunchPad
            before applying for instant CreditLines.
          </p>
          <p>
            DO NOT MISS THIS OPPORTUNITY OF A LIFETIME. <br />
            UTTA AND DIVIT TOKENS ARE GOING TO BE BIGGER THAN BITCOIN <br />
            <b style={{ color: "red" }}>IMPORTANT NOTICE</b> <br />
            Instant CreditLine offers are not open to Ultainfinity Airdrop &
            Bounties participants but only to Ultainfinity Wealth LaunchPad
            direct investors.
          </p>
        </div>
      ),
    },
    {
      id: "jnd8734h8743jhekdyw",
      img: blogImg5,
      discShort:
        "Let me immediately save you from analysis paralysis. Yes, The answer is: Invest in The Ultainfinities Tokens [currently, UTTA and DIVIT Tokens] and do this investing straightaway Do not waste your time thinking and driving yourself into poverty. Do it quickly. Time is money I mean big money lost",
      title: "How do I make $1 Million by 2023?",
      subTitle: "Using the Step-Case Strategy for Massive Wealth Accumulation",
      date: "Nov. 17, 2022",
      author: "Ultainfinity Editorial Board",
      body: (
        <div>
          <p>
            Let me immediately save you from analysis paralysis. Yes, <br />
            The answer is: Invest in The Ultainfinities Tokens [currently, UTTA
            and DIVIT Tokens] and do this investing straightaway <br />
            Do not waste your time thinking and driving yourself into poverty.{" "}
            <br />
            Do it quickly. Time is money <br />I mean big money lost if you do
            not invest quickly in UTTA or DIVIT Tokens.
          </p>

          <p>
            <b> Why?</b> <br />
            UTTA and DIVIT Tokens, by Ultainfinity, have risen from $0.001 and
            $0.0001 to $91.368 and $17.449, respectively, from 22nd August 2022
            to 1st November 2022. <br />
            Let that sink in for a moment. <br />
            If you invested $1000 in UTTA and DIVIT tokens each, you would have
            appx $91 Million and $174 Million by 1st November 2022,
            respectively, in just ten weeks!
          </p>
          <h2>UTTA and DIVIT Tokens will rise</h2>
          <p>
            UTTA and DIVIT Tokens will rise by even more percentages in the next
            three to six months <br />
            So hurry up. <br />
            You could have even made more money if you also took out
            Ultainfinity Instant CreditLine, an outstanding wealth accelerator
            from which you can instantly receive $10,000 to $1 Million to enable
            you to purchase more Ultainfinities Tokens.
          </p>

          <p>
            Therefore, <br />
            Do not waste your valuable time analyzing your diminished and
            hopeless investment opportunity options during this inflationary and
            recessionary period that is evident all around the World right now.
            The World's economic environment is currently pathetically poor in
            investment opportunity options. Let me explain why. <br />I am
            starting with just three of the most popular investment
            opportunities.
          </p>

          <p>
            <b> Bank savings</b> <br />
            Putting your money in a bank savings account is a poor Idea. Why?{" "}
            <br />
            Inflation would eat up faster, whatever your short gains are.
          </p>

          <p>
            <b> Property investment</b> <br />
            Property investment is ridiculous right now. Do not even think of
            it. Why? <br />
            Increasing interest rates worldwide, rising unemployment,
            impossible-to-meet lending criteria, zero affordability ratio, and
            fewer or non-existent mortgage products are available because no
            bank can successfully securitize its poor-performing mortgage debt
            portfolio.
          </p>
          <p>
            <b> Trading in the stock market</b> <br />
            You can profit from the stock market because of volatility and
            trading strategies. But, you require expensive and time-consuming
            trading psychology and technical analysis training that can last
            6-36 months! br Of course, it's a long-term option, and you can
            create costly hedging strategies, but they are only available for an
            already wealthy investor. In any event, They significantly reduce or
            wipe out any possible profit; because of Inflation, hedging, and
            trading costs. <br />
            So what is left for you to do?
            <br />
            You need to invest in the best investment opportunity in the World
            over the last ten years.
          </p>

          <p>
            <b>Cryptocurrencies</b> <br />
            Yes, Bitcoin has risen over 76.7 Million percent from July 2010 to
            November 2021. <br />
            If you had invested $1000 in Bitcoin in 2010 at $0.09 and sold in
            2021 when it was $69,000, you would have had $767 Million in about
            12 years. <br />
            However, with The Ultainfinities Tokens, you don't need to wait 12
            years but less than 12 weeks.
          </p>
          <h2>Are You Ready to be Wealthy?</h2>
          <p>
            To get started and share Ultainfinity's wealth and become a
            Ultainfinity Millionaire, you must first register and invest by
            purchasing UTTA or DIVIT Tokens in Ultainfinity Wealth LaunchPad
            before applying for instant CreditLines.
          </p>
          <p>
            DO NOT MISS THIS OPPORTUNITY OF A LIFETIME. <br />
            UTTA AND DIVIT TOKENS ARE GOING TO BE BIGGER THAN BITCOIN <br />
            <b style={{ color: "red" }}>IMPORTANT NOTICE</b> <br />
            Instant CreditLine offers are not open to Ultainfinity Airdrop &
            Bounties participants but only to Ultainfinity Wealth LaunchPad
            direct investors.
          </p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const filteredBlog = blogs.filter((blog) => blog.id === id);
    setcurrBlog(filteredBlog[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (copy) {
      toast.success("Copied!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setCopy(false);
    }
  }, [copy]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="blogItemPage">
      <div className="blogItemPage__backBtn">
        <Link to="/blog">
          <button>
            {" "}
            <span>{"<"}</span> Back
          </button>
        </Link>
      </div>

      {currBlog && (
        <div className="blogItemPage__main">
          <h1>{currBlog?.title}</h1>
          <p>
            <b className="blogItemPage__main__subtitle">{currBlog?.subTitle}</b>
          </p>

          <div className="blogItemPage__user">
            <img src={userIcon} alt="" />
            <div className="blogItemPage__user__main">
              <h5>{currBlog?.author}</h5>
              <span>{currBlog?.date}</span>
            </div>
          </div>

          <img className="blogItemPage__img" src={currBlog.img} alt="" />

          {currBlog?.body}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://ultainfinitywealthlaunchpad.com/"
          >
            <button> Ultainfinity Wealth Launchpad</button>
          </a>
        </div>
      )}
      <div className="blogItemPage__links">
        <FacebookShareButton
          url={"https://ultainfinityairdropandbounty.com/blog/" + currBlog?.id}
          quote={currBlog?.title}
        >
          <FacebookIcon size={37} borderRadius={10} />
        </FacebookShareButton>
        <WhatsappShareButton
          url={"ultainfinityairdropandbounty.com/blog/" + currBlog?.id}
        >
          <WhatsappIcon size={37} borderRadius={10} />
        </WhatsappShareButton>
        <LinkedinShareButton
          url={"ultainfinityairdropandbounty.com/blog/" + currBlog?.id}
        >
          <LinkedinIcon size={37} borderRadius={10} />
        </LinkedinShareButton>
        <TwitterShareButton
          url={"ultainfinityairdropandbounty.com/blog/" + currBlog?.id}
        >
          <TwitterIcon size={37} borderRadius={10} />
        </TwitterShareButton>
        {/* <img src={facebookIcon} alt="" /> */}
        {/* <img
          style={{
            width: "38px",
            position: "relative",
            left: "5px",
            margin: "5px 0",
            boxShadow: "2.22222px 2.22222px 7.77778px rgba(28, 51, 80, 0.2)",
          }}
          src={whatsappIcon}
          alt=""
        />
        <img src={LinkedInIcon} alt="" />
        <img src={twitterIcon} alt="" /> */}
        {/* <a
          href={"https://ultainfinityairdropandbounty.com/blog/" + currBlog?.id}
        > */}
        <CopyToClipboard
          text={"https://ultainfinityairdropandbounty.com/blog/" + currBlog?.id}
          onCopy={() => setCopy(true)}
        >
          <img src={linkIcon} alt="" />
        </CopyToClipboard>
        {/* </a> */}
      </div>
    </div>
  );
};

export default BlogItemPage;
