import { useEffect } from "react";
import creditLineIcon from "../assets/image/svg/creditLine.svg";
import { Button } from "../components/styledComponent/form/Buttons";

const CreditLine = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="creditLine">
      <div className="creditLine__header">
        <div className="creditLine__header__icon">
          <img src={creditLineIcon} alt="" />
        </div>
        <h1>Welcome to Instant CreditLine</h1>
      </div>
      <h2 className="creditLine__title">
        Do not miss out on the multitudinous high-ended returns through one of
        Ultainfinity’s most foremost wealth multiplicative innovations, the
        Instant Creditline.
      </h2>
      <p className="creditLine__disc">
        The Ultainfinity Instant Creditline provides loans to Ultainfinity
        users. The loan amount provided to a first-time applicant is between
        $10,000 and $250,000, however, after successfully repaying the loan, the
        user automatically qualifies for $1 million daily. These loans can be
        used to trade or invest in the Ultainfinity cryptocurrencies thereby
        multiplying possible returns, for example, User John would typically
        receive more than a 99,000% return on a $250 investment in the
        Ultainfinity tokens. This means after a remarkable and incomparable
        short period of investing, User John receives a $247,500 return on
        investment. With the Instant Creditline, User John could receive a
        minimum first-time loan of $10,000 and suppose he invests the amount, he
        gets $9,900,000. The Ultainfinity Instant Creditline profoundly
        provides, unheard-of before now, an opportunity for financial freedom.
      </p>
      <p className="creditLine__disc">
        The application process is simple and devoid of complexities. No
        collateral is required, no creditscoresorchecksandnothreatsofbankruptcy.
        Thearchitecturaldesignissimpleand void of complexities - it is
        purposeful, comparably painless, and profitable. It spurs and supports
        ecosystems within the Ultainfinity family of wealth platforms and
        universally. You only need to visit Ultainfinity Wealth Launchpad to
        apply. For a first-time application, you can only receive between
        $10,000 and $250,000 in loans, however, upon repayment, you
        automatically become eligible for $1 million daily.
      </p>
      <h3>Why You Should Consider the Ultainfinity Instant Creditline</h3>
      <ul>
        <li>
          Ultainfinity leads the march towards supercharged and stable wealth
          and as per its mission to raise millionaires and annihilate poverty
          completely, it has developed the Instant Creditline to make this
          mission realisable faster and more efficiently. Receiving the Instant
          Creditline therefore means that you have received a VVIP ticket to
          supercharged and stable wealth and also puts you in the forefront of
          magnificent earning.
        </li>
        <li>
          Ultainfinity earnings are magnificent, but earnings with the Instant
          Creditlines are super-magnificent. It is designed to give you 10x
          more, just like User John. Both the UTTA and DIVIT tokens return
          hundreds of thousands of per cent on investment, now imagine investing
          $1 million daily. This is Instant-Creditline-possible.
        </li>
        <li>
          The entire process of Instant Creditline application, transfers and
          withdrawals are simple and quick. All of the can be done from the
          comfort of your mobile or desktop device
        </li>
      </ul>

      <h3>Get started today. Visit Ultainfinity Wealth Launchpad</h3>

      <div className="creditLine__btn">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://dashboard.ultainfinitywealthlaunchpad.com/"
        >
          <Button $fill width="220px">
            Go To UWL Dashboard
          </Button>
        </a>
      </div>
    </div>
  );
};

export default CreditLine;
