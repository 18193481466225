import { Button } from "../components/styledComponent/form/Buttons";
import serchIcon from "../assets/image/svg/search.svg";
// import whatsAppIcon from "../assets/image/svg/whatappMain.svg";
// import telegramIcon from "../assets/image/svg/telegramMain.svg";
// import twitterIcon from "../assets/image/svg/twitterMain.svg";
// import instagramIcon from "../assets/image/svg/instagramMain.svg";
// import tiktokIcon from "../assets/image/svg/tiktokMain.svg";
// import facebookIcon from "../assets/image/svg/facebookMain.svg";
// import profileImg from "../assets/image/profileimg.png";
import star1Icon from "../assets/image/svg/Star1.svg";
import star2Icon from "../assets/image/svg/Star2.svg";
import star3Icon from "../assets/image/svg/Star3.svg";
import { PageTitle } from "../components/styledComponent/headers/styles";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { toast } from "react-toastify";
import { getLeaderboard } from "../store/slices/leaderboard";
import ReactLoading from "react-loading";
import { colorTheme } from "../assets/colors";
import { countires } from "../assets/data/countries";
import noDataIcon from "../assets/image/svg/noData.svg";

const Leaderboard = () => {
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");

  const { message }: any = useAppSelector((state) => state.message);
  const { leaderboard }: any = useAppSelector((state) => state.leaderboard);

  const dispatch = useAppDispatch();
  useEffect(() => {
    const handleGetLeaderboard = () => {
      setLoading(true);
      dispatch(getLeaderboard(country))
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    };

    handleGetLeaderboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="leaderboard">
      <PageTitle>Leaderboard</PageTitle>

      <div className="leaderboard__check">
        <h2 className="leaderboard__check__title">
          Check your position on <br /> the leaderboard daily
        </h2>
        <Button $fill radius="5px" width="180px">
          Check my position
        </Button>
      </div>

      <div className="leaderboard__filter">
        <div className="leaderboard__filter__search">
          <input type="text" placeholder="Search by ID" name="" id="" />
          <img src={serchIcon} alt="" />
        </div>

        <div className="leaderboard__filter__earning">
          <span>Earnings:</span>
          <select name="earning" id="earning">
            <option value="Highest to Lowest">Highest to Lowest</option>
          </select>
        </div>

        <select
          onChange={(e) => setCountry(e.target.value)}
          name="country"
          id="country"
        >
          <option value="">Select Country</option>
          {countires.map((country) => {
            return (
              <option key={country.code} value={country.name}>
                {country.name}
              </option>
            );
          })}
        </select>
        <select className="last" name="Geography" id="Geography">
          <option value="">Select Geography</option>
        </select>
      </div>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "50px 0",
          }}
        >
          <ReactLoading
            color={colorTheme.primary}
            width={50}
            height={50}
            type="spin"
          />
        </div>
      ) : leaderboard ? (
        <div className="leaderboard__table">
          <div className="leaderboard__table__body">
            <div className="leaderboard__table__row">
              <div
                style={{ width: "8%" }}
                className="leaderboard__table__row__head"
              >
                <div className="leaderboard__table__row__head__num">#</div>
              </div>
              <div
                style={{ width: "22%" }}
                className="leaderboard__table__row__head"
              >
                NAME
              </div>
              <div
                style={{ width: "25%" }}
                className="leaderboard__table__row__head"
              >
                CHANNELS
              </div>
              <div
                style={{ width: "15%" }}
                className="leaderboard__table__row__head"
              >
                INVITED USERS
              </div>
              <div
                style={{ width: "15%" }}
                className="leaderboard__table__row__head"
              >
                TOTAL EARNINGS
              </div>
              <div
                style={{ width: "15%" }}
                className="leaderboard__table__row__head"
              >
                COUNTRY
              </div>
            </div>

            {leaderboard.map((item: any, i: number) => {
              return (
                <div key={i} className="leaderboard__table__row">
                  <div
                    style={{ width: "8%" }}
                    className="leaderboard__table__row__data"
                  >
                    <div className="leaderboard__table__row__data__num">
                      {i === 0 ? (
                        <img src={star1Icon} alt="" />
                      ) : i === 1 ? (
                        <img src={star2Icon} alt="" />
                      ) : i === 2 ? (
                        <img src={star3Icon} alt="" />
                      ) : (
                        1 + i
                      )}
                    </div>
                  </div>
                  <div
                    style={{ width: "22%" }}
                    className="leaderboard__table__row__data"
                  >
                    <div className="leaderboard__table__row__data__icon">
                      {/* <img src={profileImg} alt="" /> */}
                      {item.name?.length > 20
                        ? `${item.name.substr(0, 20)}...`
                        : item.name}
                    </div>
                  </div>
                  <div
                    style={{ width: "25%" }}
                    className="leaderboard__table__row__data"
                  >
                    {item.channels}
                    {/* <div className="leaderboard__table__row__data__iconCon">
                        <img src={twitterIcon} alt="" />
                        <img src={facebookIcon} alt="" />
                        <img src={telegramIcon} alt="" />
                        <img src={whatsAppIcon} alt="" />
                        <img src={tiktokIcon} alt="" />
                        <img src={instagramIcon} alt="" />
                      </div> */}
                  </div>
                  <div
                    style={{ width: "15%" }}
                    className="leaderboard__table__row__data"
                  >
                    {item.referrals}
                  </div>
                  <div
                    style={{ width: "15%" }}
                    className="leaderboard__table__row__data"
                  >
                    {item.endOfYearCashPrize}
                  </div>
                  <div
                    style={{ width: "15%" }}
                    className="leaderboard__table__row__data"
                  >
                    {item.country?.length > 20
                      ? `${item.country.substr(0, 20)}...`
                      : item.country}
                    {}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "50px 0",
          }}
        >
          <img src={noDataIcon} alt="" />
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
