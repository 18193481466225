
import { useEffect, useState } from "react";
import SocialLinks from "../components/SocialLinks";
import { Button } from "../components/styledComponent/form/Buttons";
import {
  InputPin,
  InputUser,
} from "../components/styledComponent/form/textFields/styles";
import profileIcon from "../assets/image/svg/Profile.svg";
import faceIdIcon from "../assets/image/svg/faceId.svg";
import fingerPrintIcon from "../assets/image/svg/fingerPrint.svg";
import axios from "axios";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { useAppDispatch } from "../hooks/reduxHooks";
import { signup } from "../store/slices/auth";
import GetHelp from "./GetHelp";
import { Link } from "react-router-dom";
import { login, loginBiometric, loginToken } from "../store/slices/auth";
import { useModeContext } from "../store/modeContext";
import { colorTheme } from "../assets/colors";
import TwitterSignup from "./TwitterSignup";
import TwitterSignin from "./TwitterSignin";

const VerifyToken = ({ theme }: { theme: string }) => {

    const { mode } = useModeContext(); 
    let location = useLocation();
    useEffect(() => {
            // Function to extract OAuth tokens from the URL
    const extractOAuthTokens = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const oauthCode = urlParams.get('code');
        
  
        if (oauthCode) {
          // Tokens are available, you can use them to fetch user data
          fetchUserData(oauthCode);
        } else {
          console.log('OAuth tokens not found in the URL');
        }
      };
      // Call the function to extract OAuth tokens when the component mounts
      extractOAuthTokens();
    
   
      }, []);

          // Function to fetch user data after extracting OAuth tokens
    const fetchUserData = async ( oauthCode:any) => {
      const callback_url = process.env.REACT_APP_ENV === 'staging'
      ? 'https://staging.ultainfinityairdropandbounty.com/auth/verify-token'
      : 'https://ultainfinityairdropandbounty.com/auth/verify-token';
        try {
          const response = await fetch( `${process.env.REACT_APP_SOCIALS_BOTS_TWITTER}/v1/onestep/twitter/user/access/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
            
              code: oauthCode,
              callback_url: callback_url
             }),
          });
    
          if (response.ok) {
            const userData = await response.json();
            console.log(userData);
          } else {
            console.log('Error fetching user data');
          }
        } catch (error) {
          console.log('Network error');
        }
      };
      return (
        <div>
            {
                mode === 'signup' ? <TwitterSignup/> : <TwitterSignin theme={theme} />
            }
        </div>

      );

};

export default VerifyToken;
