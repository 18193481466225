import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useRef } from "react";
import blogImg from "../../assets/image/blogImg.png";
import calendarIcon from "../../assets/image/svg/calendarM.svg";
import timeIcon from "../../assets/image/svg/time.svg";
import { Link } from "react-router-dom";
import blogImg2 from "../../assets/image/blogImg2.png";
import blogImg3 from "../../assets/image/blogImg3.png";
import blogImg4 from "../../assets/image/blogImg4.png";
import blogImg5 from "../../assets/image/blogImg5.png";

// import axios from "axios";

const Blog = () => {
  //   const url = process.env.REACT_APP_API_URL + "/news/news";

  //   useEffect(() => {
  //     const fetchBlogs = async () => {
  //       try {
  //         const res = await axios.get(url);
  //         console.log("res", res);
  //       } catch (err) {}
  //     };

  //     fetchBlogs();
  //   }, []);
  const blogs = [
    {
      id: "sdjhfbfuy7w85hje7fsd",
      img: blogImg,
      discShort:
        "Ultainfinity has a series of Staking products to release to the World for investors on its PreMarket Exchange. The stability of Ultainfinity native cryptocurrency tokens, including UTTA tokens, will detract and discourage dumps, quick sellers, etc.",
      title:
        "The Barrs Model & Systems - An Airdrop & Bounties Invention To Be Unrivalled",
      date: "Nov. 17, 2022",
      author: "Ultainfinity Editorial Board",
    },
    {
      id: "jlfsfyhryue76438hfjw",

      img: blogImg2,
      discShort:
        "The Ultainfinities airdrops and bounties programs comprise two airdrops and two bounties programs, all containing a giveaway of $4,500. The airdropped tokens program starts with UTTA Tokens. For most people, all required to receive the free Ultainfinities tokens is either to undertake minor services such as referrals, starting with as little as referring three friends to Ultainfinity or simply subscribing to a minimum of six Ultainfinity's community of messenger and social media channels",
      title: "Ultainfinity Airdrops And Bounties Programs.",
      date: "Nov. 17, 2022",
      author: "Ultainfinity Editorial Board",
    },
    {
      id: "oadkaerwakjrn76qfq",

      img: blogImg,
      discShort:
        "Throughout the crypto landscape, no other organization has super-wealth-spur-series-of-sub-ecosystems fathered by a Super-Ecosystem that can turn an ordinary investor into a millionaire within days and weeks and can repeat the same to progressive proliferation",
      title:
        "The Ultainfinity Global Group Super-ecosystem - A Superlative Wealth Factory.",
      date: "Nov. 17, 2022",
      author: "Ultainfinity Editorial Board",
    },
    {
      id: "msdhslbs7qhq7eh7q902h",

      img: blogImg3,
      discShort:
        "Cryptocurrency has become a household term and is officially one of the most searched words on Google since 2020. The emergence of the pandemic in later 2019 proved to be a blessing that should ordinarily catapult digitalization to the zenith of",
      title: "Ultainfinities - The Greatest Amongst All",
      date: "Nov. 17, 2022",
      author: "Ultainfinity Editorial Board",
    },
    {
      id: "dljdkfcnjhdsa7374jhe2",

      img: blogImg,
      discShort:
        "Ultainfinity exits will become a reality in Ultainfinity PreMarket Exchange and the upcoming central Ultainfinity Exchange. The Ultainfinities prices are rising and unstoppably keep growing daily. The strong insuppressible",
      title: "The Unstoppable Rise of The Ultainfinities",
      date: "Nov. 17, 2022",
      author: "Ultainfinity Editorial Board",
    },
    {
      id: "kjLijdhwqu6384hj3hjwy",
      img: blogImg4,
      discShort:
        "Ultainfinity Instant CreditLine has extraordinary effects on wealth creation and accumulation. Here are a few examples of how you can benefit from and use instant CreditLine and maximize your wealth accumulation. First, by simply using one instant",
      title: "Ultainfinity Instant Creditline",
      date: "Nov. 17, 2022",
      author: "Ultainfinity Editorial Board",
    },
    {
      id: "jnd8734h8743jhekdyw",
      img: blogImg5,
      discShort:
        "Let me immediately save you from analysis paralysis. Yes, The answer is: Invest in The Ultainfinities Tokens [currently, UTTA and DIVIT Tokens] and do this investing straightaway Do not waste your time thinking and driving yourself into poverty. Do it quickly. Time is money I mean big money lost",
      title: "How do I make $1 Million by 2023?",
      date: "Nov. 17, 2022",
      author: "Ultainfinity Editorial Board",
    },
  ];
  //   const blogs = [
  //     {
  //       img: blogImg,
  //       title:
  //         "The Barrs Model & Systems - An Airdrop & Bounties Invention To Be Unrivalled",
  //       disc: "The Ultainfinities airdrops and bounties programs comprise two airdrops and two bounties programs, all containing a giveaway of $4,500. The airdropped tokens program starts with UTTA Tokens.",
  //       date: "15/07/2021",
  //       min: "5",
  //     },
  //     {
  //       img: blogImg,
  //       title:
  //         "The Barrs Model & Systems - An Airdrop & Bounties Invention To Be Unrivalled",
  //       disc: "The Ultainfinities airdrops and bounties programs comprise two airdrops and two bounties programs, all containing a giveaway of $4,500. The airdropped tokens program starts with UTTA Tokens.",
  //       date: "15/07/2021",
  //       min: "5",
  //     },
  //     {
  //       img: blogImg,
  //       title:
  //         "The Barrs Model & Systems - An Airdrop & Bounties Invention To Be Unrivalled",
  //       disc: "The Ultainfinities airdrops and bounties programs comprise two airdrops and two bounties programs, all containing a giveaway of $4,500. The airdropped tokens program starts with UTTA Tokens.",
  //       date: "15/07/2021",
  //       min: "5",
  //     },
  //     {
  //       img: blogImg,
  //       title:
  //         "The Barrs Model & Systems - An Airdrop & Bounties Invention To Be Unrivalled",
  //       disc: "The Ultainfinities airdrops and bounties programs comprise two airdrops and two bounties programs, all containing a giveaway of $4,500. The airdropped tokens program starts with UTTA Tokens.",
  //       date: "15/07/2021",
  //       min: "5",
  //     },
  //   ];

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="blog">
      <div className="blog__header">
        <div className="blog__header__left">
          <h2>Ultainfinity Blog</h2>
          <p>Follow up on the latest in Ultainfinity Ecosystem</p>
        </div>
        <div className="blog__header__right">
          <Link to="/blog">
            <button>View All</button>
          </Link>
        </div>
      </div>

      <div className="blog__main">
        {blogs && blogs.length > 0 && (
          <Swiper
            onInit={(swiper: any) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            slidesPerView={2.5}
            spaceBetween={10}
            slidesPerGroup={1}
            loop={true}
            loopFillGroupWithBlank={true}
            // pagination={{
            //   clickable: true,
            // }}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            // onSlideChange={(swiperProps) => console.log(swiperProps.realIndex)}
            // slidesPerView={"auto"}
            // spaceBetween={10}
            //   centeredSlides={true}
            // // freeMode={true}
            // pagination={{
            //   clickable: true,
            // }}
            breakpoints={{
              1180: {
                slidesPerView: 2.5,
                spaceBetween: 10,
              },

              900: {
                slidesPerView: 2,
                spaceBetween: 10,
              },

              700: {
                slidesPerView: 1.5,
                spaceBetween: 10,
              },
              600: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
              500: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
              450: {
                slidesPerView: 1,
                spaceBetween: 5,
              },
              280: {
                slidesPerView: 1,
                spaceBetween: 5,
              },
              100: {
                slidesPerView: 1,
                spaceBetween: 5,
              },
            }}
          >
            {blogs.map((item) => {
              return (
                <SwiperSlide key={item.id}>
                  <div className="blog__main__item">
                    <img
                      className="blog__main__item__img"
                      src={item.img}
                      alt=""
                    />
                    <Link style={{ color: "#000" }} to={"/blog/" + item.id}>
                      <h3>
                        {item?.title?.length > 60
                          ? `${item?.title.substring(0, 60)}...`
                          : item?.title}
                      </h3>
                    </Link>

                    <p>
                      {item?.discShort?.length > 148
                        ? `${item?.discShort.substring(0, 148)}...`
                        : item?.discShort}
                    </p>
                    <div className="blog__main__item__footer">
                      <div className="blog__main__item__footer__left">
                        <img src={calendarIcon} alt="" />
                        {item.date}
                      </div>
                      <div className="blog__main__item__footer__left">
                        <img src={timeIcon} alt="" />
                        <span>5 Min to read</span>
                      </div>
                    </div>
                  </div>{" "}
                </SwiperSlide>
              );
            })}
            <div className="swiperBtn">
              <div className="swiperBtn__prev" ref={prevRef}>
                {"<"}
              </div>
              <div className="swiperBtn__next" ref={nextRef}>
                {">"}
              </div>
            </div>
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default Blog;
