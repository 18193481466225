import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Toggle from "react-toggle";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { saveSettings } from "../store/slices/settings";
import { Button } from "./styledComponent/form/Buttons";
import ReactLoading from "react-loading";

const Notification = ({
  fetchNewSettings,
}: {
  fetchNewSettings: () => void;
}) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const { settings }: any = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  const handleChange = (num: number) => {
    if (num === 0) {
      return 1;
    }
    if (num === 1) {
      return 0;
    }
  };

  const handleSaveSettings = () => {
    setLoading(true);
    dispatch(saveSettings(data))
      .unwrap()
      .then(() => {
        setLoading(false);
        toast.success("Settings Saved Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchNewSettings();
      })
      .catch((err: any) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (settings) {
      setData(settings[0]);
    }
  }, [settings]);

  return (
    <div className="notification">
      {data && (
        <>
          <h2 className="notification__title">Notification</h2>

          <div className="notification__main">
            <div className="notification__main__item">
              <div className="notification__main__item__disc">
                <h3>Notification Language</h3>
                <p>Email push notification language settings.</p>
              </div>
              <Toggle
                checked={Boolean(data?.notification_language)}
                icons={false}
                onChange={() =>
                  setData((curr: any) => {
                    return {
                      ...curr,
                      notification_language: handleChange(
                        data?.notification_language
                      ),
                    };
                  })
                }
              />
            </div>
            <div className="notification__main__item">
              <div className="notification__main__item__disc">
                <h3>On-site Notifications</h3>
                <p>
                  Once enabled, you will receive relevant notifications within
                  the website.
                </p>
              </div>
              <Toggle
                checked={Boolean(data?.on_site_notification)}
                icons={false}
                onChange={() =>
                  setData((curr: any) => {
                    return {
                      ...curr,
                      on_site_notification: handleChange(
                        data?.on_site_notification
                      ),
                    };
                  })
                }
              />
            </div>
            <div className="notification__main__item">
              <div className="notification__main__item__disc">
                <h3>Marketing Emails</h3>
                <p>
                  Select whether you want to receive marketing emails from us.
                </p>
              </div>
              <Toggle
                checked={Boolean(data?.marketing_email)}
                onChange={() =>
                  setData((curr: any) => {
                    return {
                      ...curr,
                      marketing_email: handleChange(data?.marketing_email),
                    };
                  })
                }
                icons={false}
              />
            </div>
          </div>

          <div className="notification__btn">
            <Button
              onClick={handleSaveSettings}
              radius="90px"
              $fill
              height="48px"
              width="150px"
            >
              {loading ? (
                <ReactLoading
                  color="white"
                  width={30}
                  height={30}
                  type="spin"
                />
              ) : (
                "Save settings"
              )}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Notification;
