import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { NavbarType } from "../../types/index";

const DrawerL = ({ openDrawer, setOpenDrawer }: NavbarType) => {
  return (
    <div className={openDrawer ? "drawerL open" : "drawerL"}>
      <ul className="drawerL__links">
        <li>
          <HashLink
            onClick={() => setOpenDrawer(!openDrawer)}
            smooth
            to="/#mission"
          >
            Mission
          </HashLink>
        </li>
        <li>
          <HashLink
            onClick={() => setOpenDrawer(!openDrawer)}
            smooth
            to="/#about"
          >
            About
          </HashLink>
        </li>
        <li>
          <a
            onClick={() => setOpenDrawer(!openDrawer)}
            target="_blank"
            rel="noreferrer"
            href="https://brochure.ultainfinityglobalgroup.com/"
          >
            Investor Brochure
          </a>
        </li>
        <li>
          <Link onClick={() => setOpenDrawer(!openDrawer)} to="/blog">
            {" "}
            Blog
          </Link>
        </li>
        <li>
          <Link onClick={() => setOpenDrawer(!openDrawer)} to="/faq">
            {" "}
            FAQ
          </Link>
        </li>
        <li>
          <div style={{ display: "flex", gap: "10px" }}>
            <Link onClick={() => setOpenDrawer(!openDrawer)} to="/login">
              <button className="navbar__buttons__alt">Login</button>
            </Link>
            <Link onClick={() => setOpenDrawer(!openDrawer)} to="/signup">
              <button className="navbar__buttons__main">Sign up</button>
            </Link>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DrawerL;
