import CopyToClipboard from "react-copy-to-clipboard";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { Button, ButtonAlt } from "./styledComponent/form/Buttons";
import { ReactComponent as ReloadIcon } from "../assets/image/svg/reload.svg";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import ReactivateModal from "./ReactivateModal";
import { toast } from "react-toastify";
import {
  checkReferralReactivate,
  getReferralCode,
  reactivateReferralCode,
} from "../store/slices/airdrops";
import { colorTheme } from "../assets/colors";
import ReactLoading from "react-loading";
import { generateReference } from "../store/slices/bounties";
import { getIntroducerReferralCode } from "../store/slices/cashForCharity";
import noDataIcon from "../assets/image/svg/noData.svg";

const ReferAction = ({ cfc }: { cfc?: boolean }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [copy, setCopy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingGen, setLoadingGen] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [loadingReactivate, setLoadingReactivate] = useState(false);
  const [mainReferralCode, setMainReferralCode] = useState("");
  const { reference } = useAppSelector((state) => state.bounties);

  const { referralCode } = useAppSelector((state) => state.airdrops);
  const dispatch = useAppDispatch();
  const { referralReactivate } = useAppSelector((state) => state.airdrops);
  const { introducerCode } = useAppSelector((state) => state.cashForCharity);

  const openModal = (): void => {
    setModalIsOpen(true);
  };
  function closeModal(): void {
    setModalIsOpen(false);
  }

  useEffect(() => {
    if (cfc) {
      const handleGetIntroducerReferralCode = () => {
        setLoading(true);
        dispatch(getIntroducerReferralCode())
          .unwrap()
          .then(() => {
            setLoading(false);
          })
          .catch((err: any) => {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          });
      };
      handleGetIntroducerReferralCode();
    } else {
      const handleGetReferralCode = () => {
        setLoading(true);
        dispatch(getReferralCode())
          .unwrap()
          .then(() => {
            setLoading(false);
          })
          .catch((err: any) => {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          });
      };
      handleGetReferralCode();

      const handleCheckReferralReactivate = async () => {
        setLoadingCheck(true);
        dispatch(checkReferralReactivate())
          .unwrap()
          .then(() => {
            setLoadingCheck(false);
          })
          .catch((err: any) => {
            if (err !== "false") {
              toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            setLoadingCheck(false);
          });
      };

      handleCheckReferralReactivate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGenerateReference = () => {
    setLoadingGen(true);
    dispatch(generateReference())
      .unwrap()
      .then(() => {
        setLoadingGen(false);
        openModal();
      })
      .catch((err: any) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoadingGen(false);
      });
  };

  const handleReactivate = () => {
    setLoadingReactivate(true);
    dispatch(reactivateReferralCode())
      .unwrap()
      .then(() => {
        setLoadingReactivate(false);
        closeModal();
        toast.success("Successfully Reactivated code", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err: any) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoadingReactivate(false);
      });
  };

  useEffect(() => {
    if (cfc && introducerCode) {
      setMainReferralCode(introducerCode.code);
    }
    if (!cfc && referralCode) {
      setMainReferralCode(referralCode);
    }
  }, [referralCode, introducerCode, cfc]);

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 3000);
    }
  }, [copy]);

  return (
    <div className="refer__action">
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReactLoading
            color={colorTheme.primary}
            width={50}
            height={50}
            type="spin"
          />
        </div>
      ) : mainReferralCode ? (
        <>
          <Modal
            className="modalMain"
            overlayClassName="modal-overlay"
            ariaHideApp={false}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Create chain Modal"
          >
            <ReactivateModal
              handleReactivate={handleReactivate}
              loadingReactivate={loadingReactivate}
              close={closeModal}
              program="Airdrop 1"
              reference={reference}
            />
          </Modal>
          <div className="refer__action__header">
            <div className="refer__action__header__info">
              <h3>
                {cfc ? (
                  <>
                    {" "}
                    <span>Cash For Charity Code</span>{" "}
                  </>
                ) : (
                  <>
                    Invite 3 friends to earn <span>$3000</span>
                  </>
                )}
              </h3>
              <p>
                {cfc
                  ? "Invite charities to apply and unlock the donation of maximum $1M"
                  : `You're earning $1000 worth of Ultainfinity cryptocurrency.`}{" "}
              </p>
            </div>
            {!cfc &&
              (loadingCheck ? (
                <ReactLoading
                  color={colorTheme.primary}
                  width={40}
                  height={40}
                  type="spin"
                />
              ) : (
                <ButtonAlt
                  disabled={referralReactivate ? false : true}
                  $disable={referralReactivate ? false : true}
                  $fill
                  onClick={handleGenerateReference}
                >
                  {loadingGen ? (
                    <ReactLoading
                      color="white"
                      width={30}
                      height={30}
                      type="spin"
                    />
                  ) : (
                    "Reactivate"
                  )}
                </ButtonAlt>
              ))}
          </div>
          <div className="refer__action__referral">
            <div className="refer__action__referral__link">
              <h4>
                Referral link <span>(Click to copy referral link)</span>
              </h4>
              <p>https://ultainfinityairdropandbounty.com/{mainReferralCode}</p>
            </div>
            <div className="refer__action__referral__code">
              <h4>Referral code</h4>
              <p>{mainReferralCode}</p>
              <div className="refer__action__referral__code__icon">
                <ReloadIcon />
              </div>
            </div>

            <CopyToClipboard
              text={mainReferralCode}
              onCopy={() => setCopy(true)}
            >
              <Button
                style={{ borderRadius: "10px", alignSelf: "flex-end" }}
                $fill
              >
                {copy === true ? "Copied!" : "Copy Code"}
              </Button>
            </CopyToClipboard>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <img src={noDataIcon} alt="" />
        </div>
      )}
    </div>
  );
};

export default ReferAction;
