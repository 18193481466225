import dollarIcon from "../assets/image/svg/dollar.svg";

export const selectJsx1 = (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <img
      style={{ marginRight: "10px" }}
      src={"https://commerce.coinbase.com/landing/asset-symbols/eth.png"}
      alt="eth"
      width={20}
      height={20}
    />{" "}
    ETH <span style={{ color: "#777E90", marginLeft: "10px" }}>Ethereum</span>
  </div>
);

export const selectJsx3 = (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <img
      style={{ marginRight: "10px" }}
      src={
        "https://dynamic-assets.coinbase.com/3c15df5e2ac7d4abbe9499ed9335041f00c620f28e8de2f93474a9f432058742cdf4674bd43f309e69778a26969372310135be97eb183d91c492154176d455b8/asset_icons/9d67b728b6c8f457717154b3a35f9ddc702eae7e76c4684ee39302c4d7fd0bb8.png"
      }
      width={20}
      height={20}
      alt="USD Coin"
    />{" "}
    USD Coin <span style={{ color: "#777E90", marginLeft: "10px" }}>USDC</span>
  </div>
);
export const selectJsx4 = (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <img
      style={{ marginRight: "10px" }}
      src={
        "https://dynamic-assets.coinbase.com/e785e0181f1a23a30d9476038d9be91e9f6c63959b538eabbc51a1abc8898940383291eede695c3b8dfaa1829a9b57f5a2d0a16b0523580346c6b8fab67af14b/asset_icons/b57ac673f06a4b0338a596817eb0a50ce16e2059f327dc117744449a47915cb2.png"
      }
      width={20}
      height={20}
      alt="bitcoin"
    />{" "}
    Bitcoin <span style={{ color: "#777E90", marginLeft: "10px" }}>BTC</span>
  </div>
);
export const selectJsx5 = (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <img
      style={{ marginRight: "10px" }}
      src={
        "https://dynamic-assets.coinbase.com/41f6a93a3a222078c939115fc304a67c384886b7a9e6c15dcbfa6519dc45f6bb4a586e9c48535d099efa596dbf8a9dd72b05815bcd32ac650c50abb5391a5bd0/asset_icons/1f8489bb280fb0a0fd643c1161312ba49655040e9aaaced5f9ad3eeaf868eadc.png"
      }
      width={20}
      height={20}
      alt="bitcoin"
    />{" "}
    Tether <span style={{ color: "#777E90", marginLeft: "10px" }}>USDT</span>
  </div>
);
export const selectJsx6 = (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <img
      style={{ marginRight: "10px" }}
      src={
        "https://dynamic-assets.coinbase.com/3803f30367bb3972e192cd3fdd2230cd37e6d468eab12575a859229b20f12ff9c994d2c86ccd7bf9bc258e9bd5e46c5254283182f70caf4bd02cc4f8e3890d82/asset_icons/1597d628dd19b7885433a2ac2d7de6ad196c519aeab4bfe679706aacbf1df78a.png"
      }
      width={20}
      height={20}
      alt="dogecoin"
    />{" "}
    Dogecoin <span style={{ color: "#777E90", marginLeft: "10px" }}>DOGE</span>
  </div>
);
export const selectJsx7 = (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <img
      style={{ marginRight: "10px" }}
      src={
        "https://dynamic-assets.coinbase.com/f018870b721574ef7f269b9fd91b36042dc05ebed4ae9dcdc340a1bae5b359e8760a8c224bc99466db704d10a3e23cf1f4cd1ff6f647340c4c9c899a9e6595cd/asset_icons/984a4fe2ba5b2c325c06e4c2f3ba3f1c1fef1f157edb3b8ebbfe234340a157a5.png"
      }
      width={20}
      height={20}
      alt="litecoin"
    />{" "}
    Litecoin <span style={{ color: "#777E90", marginLeft: "10px" }}>LTC</span>
  </div>
);
export const selectJsx8 = (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <img
      style={{ marginRight: "10px" }}
      src={
        "https://dynamic-assets.coinbase.com/90184cca292578d533bb00d9ee98529b889c15126bb120582309286b9129df9886781b30c85c21ee9cae9f2db6dc11e88633c7361fdd1ba5046ea444e101ae15/asset_icons/ebc24b163bf1f58a9732a9a1d2faa5b2141b041d754ddc2260c5e76edfed261e.png"
      }
      width={20}
      height={20}
      alt="litecoin"
    />{" "}
    Dai <span style={{ color: "#777E90", marginLeft: "10px" }}>DAI</span>
  </div>
);
export const selectJsx9 = (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <img
      style={{ marginRight: "10px" }}
      src={
        "https://dynamic-assets.coinbase.com/93a4303d1b0410b79bb1feac01020e4e7bdf8e6ece68282d0af2c7d0b481c5f5c44c0cec1d7071ae8f84674dbd139e290d50a038a6a4c1bbc856ec0871b5f3e2/asset_icons/3af4b33bde3012fd29dd1366b0ad737660f24acc91750ee30a034a0679256d0b.png"
      }
      width={20}
      height={20}
      alt="bitcoin cash"
    />{" "}
    Bitcoin Cash{" "}
    <span style={{ color: "#777E90", marginLeft: "10px" }}>BCH</span>
  </div>
);
export const selectJsx10 = (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <img
      style={{ marginRight: "10px" }}
      src={
        "https://dynamic-assets.coinbase.com/71d2a21895c80ade641c5e18d1cf2f7fa9c9ab47775ee6e771c98d51bd97419c9041dfb7c661f125a7f663ab7653534c16dca476dacb340197750ce378926c36/asset_icons/c23c607a3e4479ff21f66cfece0e096d673f847c46b873329ac1760dd72dc0a2.png"
      }
      width={20}
      height={20}
      alt="apecoin"
    />{" "}
    ApeCoin <span style={{ color: "#777E90", marginLeft: "10px" }}>APE</span>
  </div>
);
export const selectJsx11 = (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <img
      style={{ marginRight: "10px" }}
      src={
        "https://dynamic-assets.coinbase.com/c14c8dc36c003113c898b56dfff649eb0ff71249fd7c8a9de724edb2dedfedde5562ba4a194db8433f2ef31a1e879af0727e6632751539707b17e66d63a9013b/asset_icons/a7309384448163db7e3e9fded23cd6ecf3ea6e1fb3800cab216acb7fc85f9563.png"
      }
      width={20}
      height={20}
      alt="shiba inu"
    />{" "}
    Shiba Inu <span style={{ color: "#777E90", marginLeft: "10px" }}>SHIB</span>
  </div>
);

export const selectJsx12 = (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <img
      style={{ marginRight: "10px" }}
      src={dollarIcon}
      width={20}
      height={20}
      alt="dollar"
    />{" "}
    Dollar
  </div>
);
