import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Drawer from "../components/Drawer";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { logout } from "../store/slices/auth";

const DashboardLayout = ({
  theme,
  changeTheme,
  sessionTimeOut,
}: {
  theme: string;
  changeTheme: () => void;
  sessionTimeOut: boolean;
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const { user }: any = useAppSelector((state) => state.auth);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user?.id) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (sessionTimeOut) {
      dispatch(logout());
      navigate("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionTimeOut]);
  return (
    <div className="dashboardLayout">
      <div className="dashboardLayout__navbar">
        <Topbar
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          theme={theme}
          changeTheme={changeTheme}
        />
        <Sidebar />
        <Drawer setOpenDrawer={setOpenDrawer} openDrawer={openDrawer} />
      </div>
      <div className="dashboardLayout__main">
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;
