import { useEffect } from "react";
import exchangeIcon from "../assets/image/svg/exchange.svg";
import { Button } from "../components/styledComponent/form/Buttons";

const Exchange = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="exchange">
      <div className="exchange__header">
        <div className="exchange__header__icon">
          <img src={exchangeIcon} alt="" />
        </div>
        <h1>Welcome to Premarket Exchange</h1>
      </div>
      <h2 className="exchange__title">
        Exciting times are here! If you are a crypto user and have always wanted
        to be unaffected by the crumbles, displacements and destabilisation of
        the crypto market, then you have reached the right place. Welcome to the
        Ultainfinity PreMarket Exchange
      </h2>
      <p className="exchange__disc">
        Ultainfinity PreMarket Exchange is a cryptocurrency exchange platform
        specially designed for Ultainfinity cryptocurrencies. Here, you can
        trade, invest or do all crypto business using any UTTA, DIVIT and SLAPA
        tokens. The motivation for the Ultainfinity PreMarket Exchange is
        simple, “only the best is good enough for the best.” In other words,
        supercharged wealth should be accompanied by a supercharged exchange.
        The superiority of Ultainfinity Global Group’s financial, blockchain,
        user orientation/experience and technological prowess is made ostensible
        in the PreMarket Exchange, hence Ultainfinity users have never reserved
        or concealed their encomium for the PreMarket Exchange.
      </p>

      <h3>
        What is your worry about cryptocurrency exchanges? Let’s show you how we
        have quelled them and why you should jump at the Ultainfinity PreMarket
        Exchange
      </h3>
      <ul>
        <li>
          Safety? Ultainfinity PreMarket Exchange has a 2-factor authentication,
          cold storage and intrusion-detection features, plus the LAPA subsystem
          provides a defence against hacks and thefts.
        </li>
        <li>
          Ease of Navigation? Ultainfinity PreMarket Exchange has got an
          exciting user experience, even a first-time user would not only enjoy
          it but would also find it easy to navigate.
        </li>
        <li>
          Large trading volumes? How do 70,000 transactions per second sound?
          This is a minimum.
        </li>
        <li>
          High Liquidity? No worries. We have a huge array and deep levels of
          liquidity. Our resources are vast and include DASA Trader which
          provides backstop and guaranteed auto crypto purchases. Ultainfinity
          native assets also come with high liquidity and auto purchase. This is
          one of the many qualities that the special, unique and fascinating
          SLAPA model and systems bring
        </li>
        <li>
          Trading fees? We provide so much wealth that you would not even blink
          or think about the trading fees, Ultainfinity charges are fair and
          supportive.
        </li>
        <li>
          Ultainfinity does not discriminate in creating wealth for its
          community, regardless of race, religion and orientation in every
          continent of the world.
        </li>
      </ul>

      <h3>
        Now you can start enjoying the complete bliss of digital currencies as
        it should be. Visit Ultainfinity Wealth Launchpad
      </h3>

      <div className="exchange__btn">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://dashboard.ultainfinitywealthlaunchpad.com/"
        >
          <Button $fill width="220px">
            Go To UWL Dashboard
          </Button>
        </a>
      </div>
    </div>
  );
};

export default Exchange;
