import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Carousel from "../../components/Carousel";
import ProgramsInfo from "../../components/ProgramsInfo";
import ReferAction from "../../components/ReferAction";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  checkReferralUnlock,
  reactivateReferralCode,
} from "../../store/slices/airdrops";
import ReactLoading from "react-loading";
import { colorTheme } from "../../assets/colors";
import noDataIcon from "../../assets/image/svg/noData.svg";
import ProgramsDetails from "../../components/ProgramsDetails";

const Refer = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingUnlock, setLoadingUnlock] = useState(false);
  const [unlock, setUnlock] = useState<any>(null);

  const { referralUnlock }: any = useAppSelector((state) => state.airdrops);

  const dispatch = useAppDispatch();

  const data = {
    title: "Here’s what you need to do to Unlock each $3,000",
    steps: [
      "Click on the Unlock button",
      "Use this special unique Referral Airdrop Code",
      "Refer 3 friends/family/contacts, each must be true real identifiable people to register and use this Airdrop platform.",
      "After your 3 friends have registered then your $3,000 for the Airdrop will be automatically UNLOCKED for you and this will give you access to claim the $3,000 in UTTA tokens in another dashboard created for you in Ultainfinity Wealth LaunchPad.",
    ],
  };

  const handleUnlock = (data: any) => {
    setLoadingUnlock(true);
    dispatch(reactivateReferralCode(data))
      .unwrap()
      .then(() => {
        setLoadingUnlock(false);
        setStep(2);
      })
      .catch((err: any) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });

        setLoadingUnlock(false);
      });
  };

  useEffect(() => {
    const handleCheckReferralUnlock = () => {
      setLoading(true);
      dispatch(checkReferralUnlock())
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((err: any) => {
          if (err === "Referral has not been unlocked") {
            setUnlock(false);
          } else {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setLoading(false);
        });
    };

    handleCheckReferralUnlock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (referralUnlock === "true") {
      setUnlock(true);
      setStep(2);
      // console.log("first");
    }
  }, [referralUnlock]);
  // console.log("referralUnlock", referralUnlock);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="refer">
      <h2 className="refer__title">Airdrop Programs </h2>

      <Carousel program="0" />

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <ReactLoading
            color={colorTheme.primary}
            width={50}
            height={50}
            type="spin"
          />
        </div>
      ) : unlock !== null ? (
        step === 1 ? (
          <ProgramsInfo
            program="Airdrop 1"
            info={data}
            handleUnlock={handleUnlock}
            loading={loadingUnlock}
          />
        ) : (
          <>
            <ProgramsDetails program="airdrop1" />
            <ReferAction />
          </>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <img src={noDataIcon} alt="" />
        </div>
      )}
    </div>
  );
};

export default Refer;
