import safeIcon from "../assets/image/Safe.png";
import coinbaseIcon from "../assets/image/svg/Coinbase.svg";
import { Button } from "./styledComponent/form/Buttons";
import closeIcon from "../assets/image/svg/close.svg";

const PaymentSuccess = ({ close }: { close: () => void }) => {
  return (
    <div className="paymentSuccess">
      <div onClick={close} className="paymentSuccess__closeBtn">
        <img src={closeIcon} alt="" />
      </div>

      <div className="paymentSuccess__header">
        Congratulations <img src={safeIcon} alt="" />
      </div>

      <div className="paymentSuccess__info">
        <p>You have successfully received an amount of</p>
        <h4>$151,000 of UTTA</h4>

        <div className="paymentSuccess__info__id">
          Transaction ID: <span>0123456789</span>
        </div>
      </div>

      <div className="paymentSuccess__summary">
        <div className="paymentSuccess__summary__main">
          <div className="paymentSuccess__summary__main__method">
            <img src={coinbaseIcon} alt="" /> Coinbase Commerce
          </div>
          <div className="paymentSuccess__summary__main__item">
            Payment Amount (BTC)<span>$15,000.00</span>
          </div>
          <div className="paymentSuccess__summary__main__item">
            To Receive <span>$150,000 of UTTA</span>
          </div>
          <div className="paymentSuccess__summary__main__details">
            <div className="paymentSuccess__summary__main__details__item">
              Time <span>09:00</span>
            </div>
            <div className="paymentSuccess__summary__main__details__item">
              Paid Invoice confirmation for <span>John Smith</span>
            </div>
            <div className="paymentSuccess__summary__main__details__item">
              Invoice Number <span>12345</span>
            </div>
          </div>
        </div>
      </div>

      <div className="paymentSuccess__btnCon">
        <Button radius="5px" height="50px" width="100%">
          Download Receipt
        </Button>
        <Button radius="5px" $fill height="50px" width="100%">
          Continue to Dashboard
        </Button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
