import { Button } from "../components/styledComponent/form/Buttons";
import logo from "../assets/image/svg/logo.svg";
import logoLight from "../assets/image/svg/logoLight.svg";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppSelector } from "../hooks/reduxHooks";

const AuthLayout = ({ theme }: { theme: string }) => {
  let { pathname } = useLocation();
  const { user } = useAppSelector((state) => state.auth);
  let navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <div className="authLayout">
      <div className="authLayout__header">
        <Link to="/">
          <img
            src={theme === "light" ? logoLight : logo}
            alt=""
            className="authLayout__header__logo"
          />
        </Link>
        {pathname === "/login" ? (
          <Link to="/signup">
            <Button className="authLayout__header__btn" $fill={true}>
              Sign Up
            </Button>
          </Link>
        ) : (
          <Link to="/login">
            <Button className="authLayout__header__btn">Login</Button>
          </Link>
        )}
      </div>
      <div className="authLayout__main">
        <Outlet />
      </div>
      {/* <div className="authLayout__footer">
        <p className="authLayout__footer__disc">
          By using either Sign Up or Login you agree to our{" "}
        </p>
        <span className="authLayout__footer__link">
          Terms & Privacy Policy.
        </span>
      </div> */}
    </div>
  );
};

export default AuthLayout;
