import { Link } from "react-router-dom";
import coinsImg from "../assets/image/3coins.png";
import { Button } from "./styledComponent/form/Buttons";

const EligibilityModal = () => {
  return (
    <div className="eligibilityModal">
      <img className="eligibilityModal__img" src={coinsImg} alt="" />{" "}
      <h5 className="eligibilityModal__title">
        Sorry, you are not eligible for the program
      </h5>
      <p className="eligibilityModal__disc">
        To qualify and unlock any Ultainfinity Airdrops and Bounties programs,
        you must first go to the wallet section in the dashboard and register
        your crypto wallet
      </p>
      <Link to={"/dashboard/wallet"}>
        <Button $fill width="220px">
          Register Crypto Wallet
        </Button>
      </Link>
    </div>
  );
};

export default EligibilityModal;
