import { ButtonAlt } from "../styledComponent/form/Buttons";
import { CircularProgressbar } from "react-circular-progressbar";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useEffect, useState } from "react";
import { getProgress } from "../../store/slices/settings";

const ProfileComplete = ({ loadingPro }: { loadingPro: boolean }) => {
  const [progressPer, setProgressPer] = useState(10);

  const dispatch = useAppDispatch();

  let navigate = useNavigate();
  const { progress }: any = useAppSelector((state) => state.settings);

  useEffect(() => {
    const handleGetProgress = () => {
      dispatch(getProgress())
        .unwrap()
        .then(() => {})
        .catch((err: any) => {});
    };

    handleGetProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (progress) {
      let total = 0;

      if (progress?.profile) total = total + 25;
      if (progress?.identity_verification) total = total + 25;
      if (progress?.selfie_with_id) total = total + 25;
      if (progress?.verification_status?.overall_status) total = total + 25;

      setProgressPer(total === 0 ? 10 : total);
    }
  }, [progress]);

  return (
    <>
      {progressPer !== 100 && (
        <div className="profileComplete">
          <div className="profileComplete__left">
            <h3>Complete your profile to get the most out of our platform</h3>
            <p>
              For account security, authorisations, authentications secured KYC
              purposes, we require you to complete your Profile. Visit the
              profile page in your dashboard at your convenience{" "}
            </p>

            <ButtonAlt onClick={() => navigate("/dashboard/settings")} $fill>
              Complete your Profile
            </ButtonAlt>
          </div>
          <div className="profileComplete__right">
            <CircularProgressbar value={progressPer} text={`${progressPer}%`} />
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileComplete;
