import {
  RadioField,
  SelectField,
  TextareaField,
  TextField,
} from "../components/styledComponent/form/textFields";
import uploadIcon from "../assets/image/svg/upload.svg";
import eyeIcon from "../assets/image/svg/eye.svg";
import guideIcon from "../assets/image/svg/guide.svg";
import arrowIcon from "../assets/image/svg/arrowLeft.svg";

import { Button } from "../components/styledComponent/form/Buttons";
import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import ApplicationResponse from "../components/ApplicationResponse";
import { ErrorMessage } from "../components/styledComponent/form/textFields/styles";
import { toast } from "react-toastify";
import { useAppDispatch } from "../hooks/reduxHooks";
import { postIntroducerApply } from "../store/slices/cashForCharity";
import ReactLoading from "react-loading";
import { countires } from "../assets/data/countries";
import TimePicker from "react-time-picker";

const Introducer = () => {
  const [part, setPart] = useState(1);
  const [time, setTime] = useState<any>("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [inputError, setInputError] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [filesName, setFilesName] = useState({
    passportImg: "",
    driversLienceImg: "",
    photoImg: "",
    resumeImg: "",
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    nationality: "",
    country: "",
    state: "",
    dob: "",
    email: "",
    telephone: "",
    phone: "",
    linkedin: "",
    twitter: "",
    nationalId: "",
    passportNo: "",
    passportImg: "",
    driversLienceImg: "",
    photoImg: "",
    resumeImg: "",
    employerName: "",
    employerAddress: "",
    startDate: "",
    endDate: "",
    employmentType: "Full time",
    mission: "",
    strenth: "",
    interview_schedule: [
      {
        date: "",
        time: "",
      },
      {
        date: "",
        time: "",
      },
      {
        date: "",
        time: "",
      },
    ],
    video_url: "",
  });

  const dispatch = useAppDispatch();

  const passportInput: any = useRef(null);
  const drivingInput: any = useRef(null);
  const photoInput: any = useRef(null);
  const resumeInput: any = useRef(null);

  const handleClick = (reference: any) => {
    reference.current.click();
  };

  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleChange = async (event: any, name: string) => {
    const fileUploaded = event.target.files[0];

    setFilesName((curr) => {
      return { ...curr, [name]: fileUploaded.name };
    });
    getBase64(fileUploaded, async (result: any) => {
      setFormData((curr) => {
        return { ...curr, [name]: result };
      });
    });
  };

  const isValidUrl = (string: string) => {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  };

  const checkDate = (date: string) => {
    if (!date) {
      return false;
    }

    const today = new Date();
    const mm = today.toLocaleString("en-US", { month: "2-digit" });
    const dd = today.toLocaleString("en-US", { day: "2-digit" });
    const yyyy = today.getFullYear();

    const selectedDate: any = date.match(/(\d+)/g);

    const addedSelectedDate =
      selectedDate[0] + selectedDate[1] + selectedDate[2];
    const addedDate = yyyy + mm + dd;

    if (parseInt(addedSelectedDate) < parseInt(addedDate)) {
      return true;
    } else {
      return false;
    }
  };

  // console.log('first', first)

  const validate = () => {
    let firstNameError = "";
    let lastNameError = "";
    let nationalityError = "";
    let countryError = "";
    let stateError = "";
    let dobError = "";
    let emailError = "";
    let phoneError = "";
    let linkedinError = "";
    let twitterError = "";
    let passportNoError = "";
    let passportImgError = "";
    let photoImgError = "";
    let resumeImgError = "";
    let employerNameError = "";
    let employerAddressError = "";
    let startDateError = "";
    let endDateError = "";
    // let employmentTypeError = "";
    let missionError = "";
    let strenthError = "";
    let proceedError = "";
    let videoUrlError = "";

    let date1Error = "";
    let time1Error = "";
    let date2Error = "";
    let time2Error = "";
    let date3Error = "";
    let time3Error = "";

    if (formData.firstName.length < 2) {
      firstNameError = "first name is required, minimum 2 characters";
    }
    if (formData.lastName.length < 2) {
      lastNameError = "last name is required, minimum 2 characters";
    }

    if (!formData.nationality) {
      nationalityError = "nationality is required";
    }
    if (!isValidUrl(formData.twitter)) {
      twitterError = "twitter is required, must be a url";
    }
    if (!formData.country) {
      countryError = "country is required";
    }
    if (!formData.state) {
      stateError = "state is required";
    }
    if (formData.dob && !checkDate(formData.dob)) {
      dobError = "date of birth must be in the past";
    }
    if (!formData.dob) {
      dobError = "date of birth is required";
    }
    if (!formData.email) {
      emailError = "email is required";
    }

    if (formData.phone.length < 6) {
      phoneError = "phone number is required, minimum 2 characters";
    }
    if (!isValidUrl(formData.linkedin)) {
      linkedinError = "linkedin is required, must be a url";
    }

    if (!formData.passportNo && !formData.nationalId) {
      passportNoError =
        "international passport or national id number is required";
    }
    if (!formData.passportImg && !formData.driversLienceImg) {
      passportImgError =
        "international passport or national id/driver’s Licence image is required";
    }

    if (!formData.photoImg) {
      photoImgError = "photo image is required";
    }
    if (!formData.resumeImg) {
      resumeImgError = "resume image is required";
    }
    if (!formData.employerName) {
      employerNameError = "employer name is required";
    }
    if (formData.employerAddress.length < 10) {
      employerAddressError =
        "employer address is required, minimum 10 characters";
    }
    if (formData.startDate && !checkDate(formData?.startDate)) {
      startDateError = "start date must be in the past";
    }
    if (!formData.startDate) {
      startDateError = "start date is required";
    }
    if (!formData.endDate) {
      endDateError = "end date is required";
    }
    if (formData.mission.length < 100) {
      missionError = "mission is required, minimum 100 characters";
    }
    if (formData.strenth.length < 100) {
      strenthError = "strenth is required, minimum 100 characters";
    }
    if (!proceed) {
      proceedError = "proceed is required";
    }
    if (!formData.video_url) {
      videoUrlError = "Video URL is required";
    }
    if (!formData.interview_schedule[0].date) {
      date1Error = "date is required";
    }
    if (!formData.interview_schedule[0].time) {
      time1Error = "time is required";
    }
    if (!formData.interview_schedule[1].date) {
      date2Error = "date is required";
    }
    if (!formData.interview_schedule[1].time) {
      time2Error = "time is required";
    }
    if (!formData.interview_schedule[2].date) {
      date3Error = "date is required";
    }
    if (!formData.interview_schedule[2].time) {
      time3Error = "time is required";
    }

    if (
      firstNameError ||
      lastNameError ||
      emailError ||
      stateError ||
      endDateError ||
      startDateError ||
      missionError ||
      strenthError ||
      nationalityError ||
      phoneError ||
      resumeImgError ||
      employerAddressError ||
      employerNameError ||
      photoImgError ||
      passportImgError ||
      linkedinError ||
      twitterError ||
      dobError ||
      countryError ||
      passportNoError ||
      proceedError ||
      videoUrlError ||
      time1Error ||
      time2Error ||
      time3Error ||
      date1Error ||
      date2Error ||
      date3Error
    ) {
      setInputError((curr: any) => {
        return {
          ...curr,
          firstName: firstNameError,
          lastName: lastNameError,
          email: emailError,
          strenth: strenthError,
          state: stateError,
          endDate: endDateError,
          startDate: startDateError,
          mission: missionError,
          nationality: nationalityError,
          phone: phoneError,
          resumeImg: resumeImgError,
          employerAddress: employerAddressError,
          employerName: employerNameError,
          photoImg: photoImgError,
          passportImg: passportImgError,
          linkedin: linkedinError,
          twitter: twitterError,
          dob: dobError,
          country: countryError,
          passportNo: passportNoError,
          proceed: proceedError,
          video_url: videoUrlError,
          date1: date1Error,
          date2: date2Error,
          date3: date3Error,
          time1: time1Error,
          time2: time2Error,
          time3: time3Error,
        };
      });

      return false;
    }
    return true;
  };
  const handleApply = () => {
    const checkValidate = validate();
    if (checkValidate) {
      const data = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        middle_name: formData.middleName,
        email: formData.email,
        nationality: formData.nationality,
        phone: formData.phone,
        country: formData.country,
        state: formData.state,
        dob: formData.dob,
        telephone: formData.telephone,
        twitter: formData.twitter,
        linkedin: formData.linkedin,
        national_identity_no: formData.nationalId,
        international_passport_no: formData.passportNo,
        employer_name: formData.employerName,
        employer_address: formData.employerAddress,
        employment_start_date: formData.startDate,
        employment_end_date: formData.endDate,
        employment_type: formData.employmentType,
        drivers_license_image: formData.driversLienceImg,
        passport_image: formData.passportImg,
        photo_image: formData.photoImg,
        resume: formData.resumeImg,
        mission_fit: formData.mission,
        strenght_fit: formData.strenth,
        video_url: formData.video_url,
        interview_schedule: formData.interview_schedule,
      };
      setLoading(true);
      dispatch(postIntroducerApply(data))
        .unwrap()
        .then(() => {
          setLoading(false);
          openModal();
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });

          setLoading(false);
        });
    }
  };

  const openModal = (): void => {
    setModalIsOpen(true);
  };
  function closeModal(): void {
    setModalIsOpen(false);
  }
  const handleRadioChange = (value: string) => {
    setFormData((curr) => {
      return { ...curr, employmentType: value };
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log("formData", formData);
  const handleDateAndTimeChange = (
    value: string,
    num: 0 | 1 | 2,
    name: "date" | "time"
  ) => {
    let newData = [...formData.interview_schedule];

    newData[num][name] = value;

    setFormData({ ...formData, interview_schedule: newData });
  };

  return (
    <div className="introducer">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Application Modal"
      >
        <ApplicationResponse />
        {/* {success ? (
          <ApplicationResponse type="success" />
        ) : ( */}
        {/* )} */}
      </Modal>
      <div className="introducer__header">
        <h1>Ultainfinity Cash For Charity: Application for Introducer </h1>
        <p>
          Fill out the form to be an introducer of the $3 billion cash for
          charities by the Ultainfinity Foundation
        </p>
      </div>

      <p className="introducer__disc">
        The Ultainfinity Foundation of the Ultainfinity Global Group is
        impacting the world again, as it has always done, this time with a $3
        billion donation to charities, non-profits, NGOs and similar
        organisations that are working to obliterate poverty, just like the
        Ultainfinity. To be a part of the Ultainfinity Cash for Charity, please
        understand that you will need to fill out this form. Your application
        will be reviewed by our team and we will contact you if you are selected
        as a beneficiary.
      </p>

      <div className="introducer__checkbox">
        <div className="introducer__checkbox__item">
          <input
            onChange={() => setProceed(!proceed)}
            checked={proceed}
            type="checkbox"
            id="proceed"
          />{" "}
          <label htmlFor="proceed">
            I understand this and and I am willing to proceed*
          </label>
        </div>
        <ErrorMessage show={inputError?.proceed}>
          check box to submit
        </ErrorMessage>
        {/* <div className="introducer__checkbox__item">
          <input type="checkbox" id="dontProceed" />{" "}
          <label htmlFor="dontProceed">I do not wish to proceed</label>
        </div> */}
      </div>

      <div className="introducer__form">
        {part === 1 ? (
          <div className="introducer__form__part1">
            <h3 className="introducer__form__part1__title">Part One</h3>

            <div className="introducer__form__part1__userinfo">
              <div className="introducer__form__part1__userinfo__main">
                <TextField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, firstName: e.target.value };
                    })
                  }
                  value={formData.firstName}
                  inputError={inputError.firstName}
                  name="firstName"
                  label="First Name*"
                />
                <TextField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, lastName: e.target.value };
                    })
                  }
                  inputError={inputError.lastName}
                  name="lastName"
                  label="Last Name*"
                  value={formData.lastName}
                />
                <TextField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, middleName: e.target.value };
                    })
                  }
                  inputError={inputError.middleName}
                  name="middleName"
                  label="Middle Name"
                  value={formData.middleName}
                />
                <TextField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, nationality: e.target.value };
                    })
                  }
                  inputError={inputError.nationality}
                  name="nationality"
                  label="Nationality*"
                  value={formData.nationality}
                />
                {/* <TextField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, country: e.target.value };
                    })
                  }
                  inputError={inputError.country}
                  name="country"
                  label="Country of Residence"
                  value={formData.country}
                /> */}
                <SelectField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, country: e.target.value };
                    })
                  }
                  inputError={inputError.country}
                  name="country"
                  label="Country of Residence*"
                  value={formData.country}
                  data={countires}
                />
                <TextField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, state: e.target.value };
                    })
                  }
                  inputError={inputError.state}
                  name="state"
                  label="State of Residence*"
                  value={formData.state}
                />
                <TextField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, dob: e.target.value };
                    })
                  }
                  type="date"
                  inputError={inputError.dob}
                  name="dob"
                  label="Date of Birth*"
                  value={formData.dob}
                />
              </div>
            </div>

            <div className="introducer__form__part1__userinfo">
              <h4>Contact Information</h4>
              <div className="introducer__form__part1__userinfo__main">
                <TextField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, email: e.target.value };
                    })
                  }
                  inputError={inputError.email}
                  name="email"
                  label="Email*"
                  value={formData.email}
                />
                <TextField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, telephone: e.target.value };
                    })
                  }
                  inputError={inputError.telephone}
                  name="telephone"
                  label="Telephone"
                  value={formData.telephone}
                />
                <TextField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, phone: e.target.value };
                    })
                  }
                  inputError={inputError.phone}
                  name="phone"
                  label="Mobile Number*"
                  value={formData.phone}
                />
                <TextField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, linkedin: e.target.value };
                    })
                  }
                  inputError={inputError.linkedin}
                  name="linkedin"
                  label="LinkedIn*"
                  value={formData.linkedin}
                />
                <TextField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, twitter: e.target.value };
                    })
                  }
                  inputError={inputError.twitter}
                  name="twitter"
                  label="Twitter*"
                  value={formData.twitter}
                />
              </div>
            </div>
            <div className="introducer__form__part1__userinfo">
              <h4>Government Issued ID*</h4>
              <div className="introducer__form__part1__userinfo__main">
                <TextField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, passportNo: e.target.value };
                    })
                  }
                  // inputError={inputError.passportNo}
                  name="passportNo"
                  label="International Passport Number"
                  value={formData.passportNo}
                />
                <TextField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, nationalId: e.target.value };
                    })
                  }
                  // inputError={inputError.nationalId}
                  name="nationalId"
                  label="National ID Number"
                  value={formData.nationalId}
                />
              </div>
              <ErrorMessage show={inputError.passportNo}>
                {inputError.passportNo}
              </ErrorMessage>
            </div>

            <div className="introducer__form__part1__upload">
              <h4>Upload the following</h4>

              <div className="introducer__form__part1__upload__main">
                <div className="introducer__form__part1__upload__main__item">
                  <p>International Passport</p>
                  <div
                    onClick={() => handleClick(passportInput)}
                    className="introducer__form__part1__upload__main__item__click"
                  >
                    <input
                      type="file"
                      accept=".png,.jpeg,.jpg,.doc,.docx,.pdf"
                      ref={passportInput}
                      onChange={(e) => handleChange(e, "passportImg")}
                      style={{ display: "none" }}
                    />
                    <img src={uploadIcon} alt="" /> Click to Upload
                  </div>
                  <div className="introducer__form__part1__upload__main__item__name">
                    {filesName.passportImg}
                  </div>
                </div>
                <ErrorMessage show={inputError?.passportImg}>
                  {inputError?.passportImg}
                </ErrorMessage>
                <div className="introducer__form__part1__upload__main__item">
                  <p>National ID/Driver’s Licence</p>
                  <div
                    onClick={() => handleClick(drivingInput)}
                    className="introducer__form__part1__upload__main__item__click"
                  >
                    <input
                      type="file"
                      accept=".png,.jpeg,.jpg,.doc,.docx,.pdf"
                      ref={drivingInput}
                      onChange={(e) => handleChange(e, "driversLienceImg")}
                      style={{ display: "none" }}
                    />
                    <img src={uploadIcon} alt="" /> Click to Upload
                  </div>
                  <div className="introducer__form__part1__upload__main__item__name">
                    {filesName.driversLienceImg}
                  </div>
                </div>
                <ErrorMessage show={inputError?.driversLienceImg}>
                  national id/driver’s licence is required
                </ErrorMessage>
                <div className="introducer__form__part1__upload__main__item">
                  <p>Photo Image*</p>
                  <div
                    onClick={() => handleClick(photoInput)}
                    className="introducer__form__part1__upload__main__item__click"
                  >
                    <input
                      type="file"
                      accept=".png,.jpeg,.jpg,.doc,.docx,.pdf"
                      ref={photoInput}
                      onChange={(e) => handleChange(e, "photoImg")}
                      style={{ display: "none" }}
                    />
                    <img src={uploadIcon} alt="" /> Click to Upload
                  </div>
                  <div className="introducer__form__part1__upload__main__item__name">
                    {filesName.photoImg}
                  </div>
                </div>
                <ErrorMessage show={inputError?.photoImg}>
                  photo image is required
                </ErrorMessage>
                <div className="introducer__form__part1__upload__main__item">
                  <p>CV/Resume*</p>
                  <div
                    onClick={() => handleClick(resumeInput)}
                    className="introducer__form__part1__upload__main__item__click"
                  >
                    <input
                      type="file"
                      accept=".png,.jpeg,.jpg,.doc,.docx,.pdf"
                      ref={resumeInput}
                      onChange={(e) => handleChange(e, "resumeImg")}
                      style={{ display: "none" }}
                    />
                    <img src={uploadIcon} alt="" /> Click to Upload
                  </div>
                  <div className="introducer__form__part1__upload__main__item__name">
                    {filesName.resumeImg}
                  </div>
                </div>
                <ErrorMessage show={inputError?.resumeImg}>
                  cv/resume is required
                </ErrorMessage>
                {/* <div className="introducer__form__part1__upload__main__item">
                  <p>Charity Certificate</p>
                  <div
                    onClick={() => handleClick(resumeInput)}
                    className="introducer__form__part1__upload__main__item__click"
                  >
                    <input
                      type="file"
                      accept=".png,.jpeg,.jpg,.doc,.docx,.pdf"
                      ref={resumeInput}
                      onChange={(e) => handleChange(e, "resumeImg")}
                      style={{ display: "none" }}
                    />
                    <img src={uploadIcon} alt="" /> Click to Upload
                  </div>
                  <div className="introducer__form__part1__upload__main__item__name">
                    {filesName.resumeImg}
                  </div>
                </div>
                <ErrorMessage show={inputError?.resumeImg}>
                  cv/resume is required
                </ErrorMessage> */}
              </div>
            </div>

            <div className="introducer__form__part1__btn">
              <Button onClick={() => setPart(2)} $fill radius="10px">
                Next
              </Button>
            </div>
          </div>
        ) : part === 2 ? (
          <div className="introducer__form__part2">
            <h3 className="introducer__form__part2__title">Part Two</h3>

            <h4 className="introducer__form__part2__subTitle">
              Most Recent Employment
            </h4>

            <div className="introducer__form__part2__employer">
              <TextField
                name="employerName"
                label="Name of Employer/Business*"
                onChange={(e) =>
                  setFormData((curr) => {
                    return { ...curr, employerName: e.target.value };
                  })
                }
                inputError={inputError.employerName}
                value={formData.employerName}
              />
              <TextField
                onChange={(e) =>
                  setFormData((curr) => {
                    return { ...curr, employerAddress: e.target.value };
                  })
                }
                inputError={inputError.employerAddress}
                name="employerAddress"
                label="Address of Employer/Business*"
                value={formData.employerAddress}
              />
            </div>

            <div className="introducer__form__part2__date">
              <TextField
                type="date"
                onChange={(e) =>
                  setFormData((curr) => {
                    return { ...curr, startDate: e.target.value };
                  })
                }
                inputError={inputError.startDate}
                name="startDate"
                label="Start Date*"
                value={formData.startDate}
              />
              <TextField
                onChange={(e) =>
                  setFormData((curr) => {
                    return { ...curr, endDate: e.target.value };
                  })
                }
                type="date"
                inputError={inputError.endDate}
                name="endDate"
                label="End Date"
                value={formData.endDate}
              />
            </div>

            <div className="introducer__form__part2__employment">
              <h5 className="introducer__form__part2__employment__title">
                Employment Type*
              </h5>
              <div className="introducer__form__part2__employment__main">
                <div className="introducer__checkbox__item">
                  <RadioField
                    id="Full time"
                    name="employmentType"
                    value="Full time"
                    label="Full time"
                    onChange={handleRadioChange}
                    checked={formData.employmentType}
                  />
                </div>
                <div className="introducer__checkbox__item">
                  <RadioField
                    id="Part time"
                    name="employmentType"
                    value="Part time"
                    label="Part time"
                    onChange={handleRadioChange}
                    checked={formData.employmentType}
                  />
                </div>
                <div className="introducer__checkbox__item">
                  <RadioField
                    id="Contract"
                    name="employmentType"
                    value="Contract"
                    label="Contract"
                    onChange={handleRadioChange}
                    checked={formData.employmentType}
                  />
                </div>
                <div className="introducer__checkbox__item">
                  <RadioField
                    id="volunteer"
                    name="employmentType"
                    value="volunteer"
                    label="volunteer"
                    onChange={handleRadioChange}
                    checked={formData.employmentType}
                  />
                </div>
                <div className="introducer__checkbox__item">
                  <RadioField
                    id="Self Employed"
                    name="employmentType"
                    value="Self Employed"
                    label="Self Employed"
                    onChange={handleRadioChange}
                    checked={formData.employmentType}
                  />
                </div>
              </div>
            </div>

            <div className="introducer__form__part2__inputDisc">
              <div className="introducer__form__part2__inputDisc__item">
                <TextareaField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, mission: e.target.value };
                    })
                  }
                  inputError={inputError.mission}
                  labelSize="17px"
                  value={formData.mission}
                  name="mission"
                  label="How well do you think you fit into the Ultainfinity mission?*"
                />
                <span>Word Count - 250</span>
              </div>
              <div className="introducer__form__part2__inputDisc__item">
                <TextareaField
                  onChange={(e) =>
                    setFormData((curr) => {
                      return { ...curr, strenth: e.target.value };
                    })
                  }
                  value={formData.strenth}
                  inputError={inputError.strenth}
                  labelSize="17px"
                  name="strength"
                  label="What strengths of yours do you think will be most important in introducing and guiding 
                  organisation through the Ultainifinity cash for charity application*"
                />
                <span>Word Count - 250</span>
              </div>
            </div>

            <div className="introducer__form__part2__btn">
              <Button onClick={() => setPart(1)} $fill radius="10px">
                Back
              </Button>
              <Button onClick={() => setPart(3)} $fill radius="10px">
                Next
              </Button>
            </div>
          </div>
        ) : (
          <div className="introducer__form__part3">
            <h3 className="introducer__form__part3__title">Part Three</h3>
            {!viewDetails ? (
              <>
                <h4 className="introducer__form__part3__subTitle">
                  Video Showcase
                </h4>
                <p className="introducer__form__part3__disc">
                  You are required to submit a self-recording 60-minutes (one
                  hour) minimum video pitch as a part of your application
                  process. The Ultainfinity Cash for charity is sensitively
                  spread to organizations that operate in poverty eradication,
                  human empowerment and financial transformation bailiwicks in
                  every part of the world. We consider these organizations to be
                  operating in coherence with the Ultainfinity mission of
                  extirpating poverty in every trace or at least significantly
                  reducing it from every part of the world. We, therefore, urge
                  you to complete the video showcase task with your A-game.
                </p>

                <div className="introducer__form__part3__guide">
                  <div className="introducer__form__part3__guide__left">
                    <img src={guideIcon} alt="" />
                  </div>
                  <div className="introducer__form__part3__guide__right">
                    <h5>Guidelines</h5>
                    <button onClick={() => setViewDetails(true)}>
                      <img src={eyeIcon} alt="" /> Click here to view
                    </button>
                  </div>
                </div>

                <div className="introducer__form__part3__input">
                  <TextField
                    onChange={(e) =>
                      setFormData((curr) => {
                        return { ...curr, video_url: e.target.value };
                      })
                    }
                    type="text"
                    inputError={inputError.video_url}
                    name="video"
                    label="Add link to the Video file"
                    value={formData.video_url}
                  />
                  <span> (Recommended; Vimeo link)</span>
                </div>

                <h4
                  style={{ marginTop: 40 }}
                  className="introducer__form__part3__subTitle"
                >
                  Live Interview
                </h4>
                <p>
                  Specify your available date and time over 3 months for your
                  Live Interview.
                </p>
                <div className="introducer__form__part3__date">
                  <h5>Month</h5>

                  <div className="introducer__form__part3__date__main">
                    <TextField
                      type="date"
                      name="date"
                      label="Date"
                      onChange={(e) =>
                        handleDateAndTimeChange(e.target.value, 0, "date")
                      }
                      inputError={inputError.date1}
                      value={formData.interview_schedule[0].date}
                    />

                    <div className="introducer__form__part3__date__main__time">
                      <label htmlFor="">Time</label>
                      <TimePicker
                        value={time}
                        onChange={(value: any) =>
                          handleDateAndTimeChange(value, 0, "time")
                        }
                      />
                      <ErrorMessage top="0px" show={inputError?.time1}>
                        {inputError?.time1}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>
                <div className="introducer__form__part3__date">
                  <h5>Month</h5>

                  <div className="introducer__form__part3__date__main">
                    <TextField
                      type="date"
                      name="date"
                      label="Date"
                      onChange={(e) =>
                        handleDateAndTimeChange(e.target.value, 1, "date")
                      }
                      inputError={inputError.date2}
                      value={formData.interview_schedule[1].date}
                    />

                    <div className="introducer__form__part3__date__main__time">
                      <label htmlFor="">Time</label>
                      <TimePicker
                        value={time}
                        onChange={(value: any) =>
                          handleDateAndTimeChange(value, 1, "time")
                        }
                      />
                      <ErrorMessage top="0px" show={inputError?.time2}>
                        {inputError?.time2}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>
                <div className="introducer__form__part3__date">
                  <h5>Month</h5>

                  <div className="introducer__form__part3__date__main">
                    <TextField
                      type="date"
                      name="date"
                      label="Date"
                      onChange={(e) =>
                        handleDateAndTimeChange(e.target.value, 2, "date")
                      }
                      inputError={inputError.date3}
                      value={formData.interview_schedule[2].date}
                    />

                    <div className="introducer__form__part3__date__main__time">
                      <label htmlFor="">Time</label>
                      <TimePicker
                        value={time}
                        onChange={(value: any) =>
                          handleDateAndTimeChange(value, 2, "time")
                        }
                      />
                      <ErrorMessage top="0px" show={inputError?.time3}>
                        {inputError?.time3}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>

                <div className="introducer__form__part3__btn">
                  <Button onClick={() => setPart(2)} $fill radius="10px">
                    Back
                  </Button>
                  <Button onClick={handleApply} $fill radius="10px">
                    {loading ? (
                      <ReactLoading
                        color="white"
                        width={30}
                        height={30}
                        type="spin"
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div
                  className="introducer__form__part3__back"
                  onClick={() => setViewDetails(false)}
                >
                  <img src={arrowIcon} alt="" /> Video Showcase
                </div>

                <div className="introducer__form__part3__details">
                  <div className="introducer__form__part3__details__left">
                    <img src={guideIcon} alt="" />
                  </div>
                  <div className="introducer__form__part3__details__right">
                    <h5>About the Video;</h5>
                    <ol>
                      <li>
                        {" "}
                        Your video should be clear, and with high resolution. We
                        recommend a resolution of at least 1280x720 for a video
                        that has a 16:9 aspect ratio and a resolution of at
                        least 640x480 for a video that has a 4:3 aspect ratio.
                        See YouTube standard videos for examples of these.
                      </li>
                      <li>
                        Find a quiet space with zero interruptions, background
                        noise and other sounds that could interfere with your
                        presentation.
                      </li>
                      <li>
                        Find somewhere you can sit that does not have anything
                        distracting in the background. Make sure you have good
                        natural light or use a lamp.
                      </li>
                      <li>
                        Avoid locations with environmental glitches e.g wind
                        disturbances, inadequate or too much lighting, etc.
                      </li>
                    </ol>

                    <h5>About the Presentation;</h5>
                    <ol>
                      <li>
                        Ensure that you speak clearly. We recommend that you use
                        a microphone or a noise-filtering headset. Do speak in
                        English. Please note that transcriptions are not
                        allowed.
                      </li>
                      <li>
                        Be concise with your presentation. Start your
                        presentation by introducing yourself (name, position),
                        your organization and why the cash for charity will
                        impact the world through your organization. See
                        presentation bullet points.
                      </li>
                      <li>
                        Do ensure to sit up straight, keep up good eye contact –
                        look at your camera rather than at the screen –, and
                        have good body language.
                      </li>
                      <li>
                        You should be sitting in the video presentation, facing
                        the camera and wearing a good look
                      </li>
                    </ol>

                    <h5>Presentation Bullet Points;</h5>
                    <ol>
                      <li>
                        Start with an introduction. It suffices to say your name
                        (with titles) and your position in your organization.
                      </li>
                      <li>
                        Introduce your organization. Your introduction should
                        also cover the name of the organization, location(s),
                        number of staff , vision and mission and operations.
                      </li>
                      <li>
                        Speak clearly about what projects the organization has
                        done in the past and the limitations it had.● Speak
                        about the project of the organisations that are ongoing
                        and those to come, and a precise of the organization’s
                        schematics to be used in completing the project(s).
                      </li>
                      <li>
                        Answer the questions -
                        <div>
                          How will Ultainfinity cash for charity’s $1M be
                          significant to the success of these projects and the
                          mission of the organization?
                        </div>
                        <div>
                          What is the organization’s operational plan for the
                          disbursement of the Ultainfinity cash for charity when
                          it is finally won?
                        </div>
                        <div>
                          Is your organization willing to partake in promoting
                          Ultainfinity Global Group charity giving and her
                          products through, but not limited to, prints ads, TV
                          ads, social media posts, etc?
                        </div>
                      </li>
                      <li>
                        Speak, in good detail, about how your organization have
                        been involved in charities and charitable projects
                        within the last 5 years. Include some data to explain
                        the effects and success of the charities.
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="introducer__form__part3__btn">
                  <Button
                    onClick={() => setViewDetails(false)}
                    $fill
                    radius="10px"
                  >
                    Back
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Introducer;
