import chartImg from "../../assets/image/charts.png";

const ChartL = () => {
  return (
    <div className="chartL">
      <img src={chartImg} alt="" />
    </div>
  );
};

export default ChartL;
