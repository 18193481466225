import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import settingsSevice from "../services/settings.service";
import { setMessage } from "./message";

export const saveSettings: any = createAsyncThunk(
  "settings/saveSettings",
  async (data: any, thunkAPI) => {
    try {
      const dataMain = await settingsSevice.saveSettings(data);
      return { savedSettings: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSettings: any = createAsyncThunk(
  "settings/getSettings",
  async (id: any, thunkAPI) => {
    try {
      const dataMain = await settingsSevice.getSettings();
      return { settings: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getProfileDetails: any = createAsyncThunk(
  "settings/getProfileDetails",
  async (id: any, thunkAPI) => {
    try {
      const dataMain = await settingsSevice.getProfileDetails();
      return { profileDetails: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postProfileDetails: any = createAsyncThunk(
  "settings/postProfileDetails",
  async (data: any, thunkAPI) => {
    try {
      const dataMain = await settingsSevice.postProfileDetails(data);
      return { postProfileDetails: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getProgress: any = createAsyncThunk(
  "settings/getProgress",
  async (id: any, thunkAPI) => {
    try {
      const dataMain = await settingsSevice.getProgress();
      return { progress: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postDocument: any = createAsyncThunk(
  "settings/postDocument",
  async (data: any, thunkAPI) => {
    try {
      const dataMain = await settingsSevice.postDocument(data);
      return { document: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postSelfie: any = createAsyncThunk(
  "settings/postSelfie",
  async (data: any, thunkAPI) => {
    try {
      const dataMain = await settingsSevice.postSelfie(data);
      return { selfie: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getStat: any = createAsyncThunk(
  "settings/getStat",
  async (id: any, thunkAPI) => {
    try {
      const dataMain = await settingsSevice.getStat();
      return { stat: dataMain.data };
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState: any = {
  savedSettings: null,
  settings: null,
  profileDetails: null,
  postProfileDetails: null,
  progress: null,
  document: null,
  selfie: null,
  stat: null,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: {
    [saveSettings.fulfilled]: (state, action) => {
      state.savedSettings = action.payload.savedSettings;
    },
    [saveSettings.rejected]: (state, action) => {
      state.savedSettings = null;
    },
    [getSettings.fulfilled]: (state, action) => {
      state.settings = action.payload.settings;
    },
    [getSettings.rejected]: (state, action) => {
      state.settings = null;
    },
    [getProfileDetails.fulfilled]: (state, action) => {
      state.profileDetails = action.payload.profileDetails;
    },
    [getProfileDetails.rejected]: (state, action) => {
      state.profileDetails = null;
    },
    [postProfileDetails.fulfilled]: (state, action) => {
      state.postProfileDetails = action.payload.postProfileDetails;
    },
    [postProfileDetails.rejected]: (state, action) => {
      state.postProfileDetails = null;
    },
    [getProgress.fulfilled]: (state, action) => {
      state.progress = action.payload.progress;
    },
    [getProgress.rejected]: (state, action) => {
      state.progress = null;
    },
    [postDocument.fulfilled]: (state, action) => {
      state.document = action.payload.document;
    },
    [postDocument.rejected]: (state, action) => {
      state.document = null;
    },
    [postSelfie.fulfilled]: (state, action) => {
      state.selfie = action.payload.selfie;
    },
    [postSelfie.rejected]: (state, action) => {
      state.selfie = null;
    },
    [getStat.fulfilled]: (state, action) => {
      state.stat = action.payload.stat;
    },
    [getStat.rejected]: (state, action) => {
      state.stat = null;
    },
  },
});

const { reducer } = settingsSlice;
export default reducer;
