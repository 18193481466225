import axios from "axios";
import authHeader from "./authHeader";

const API_URL = process.env.REACT_APP_API_URL + "/airdrop-bounty/";

const postIntroducerApply = async (data: any) => {
  return await axios({
    url: API_URL + "app/introducer/apply",
    headers: {
      "user-id": "856c4ddc-64de-463b-8656-94b70deb94a5",
    },
    data,
    method: "post",
  }).then((response) => {
    return response.data;
  });
};
const postApplicantApply = async (data: any) => {
  return await axios({
    url: API_URL + "app/charity-applicant/apply",
    headers: {
      "user-id": "98c22116-1409-4fcf-92a1-4f74565e0116",
    },
    data,
    method: "post",
  }).then((response) => {
    return response.data;
  });
};
const verifytIntroducerStatus = async () => {
  return await axios({
    url: API_URL + "app/introducer/verify-status",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const verifytApplicantStatus = async () => {
  return await axios({
    url: API_URL + "app/charity-applicant/verify-status",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const getIntroducerReferralCode = async () => {
  return await axios({
    url: API_URL + "app/introducer/referral-code",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const cashForCharitySevice = {
  postIntroducerApply,
  verifytIntroducerStatus,
  verifytApplicantStatus,
  postApplicantApply,
  getIntroducerReferralCode,
};

export default cashForCharitySevice;
