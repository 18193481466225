import { ButtonAlt } from "./styledComponent/form/Buttons";
import whatsAppIcon from "../assets/image/svg/whatappMain.svg";
import telegramIcon from "../assets/image/svg/telegramMain.svg";
import twitterIcon from "../assets/image/svg/twitterMain.svg";
import instagramIcon from "../assets/image/svg/instagramMain.svg";
import tiktokIcon from "../assets/image/svg/tiktokMain.svg";
import facebookIcon from "../assets/image/svg/facebookMain.svg";
import lineIcon from "../assets/image/svg/lineMain.svg";
import snapchatIcon from "../assets/image/svg/snapchatMain.svg";
import messangerIcon from "../assets/image/svg/messangerMain.svg";
import vkIcon from "../assets/image/svg/vkMain.svg";
import signalIcon from "../assets/image/svg/signalMain.svg";
import pinterestIcon from "../assets/image/svg/pinterestMain.svg";
import discordIcon from "../assets/image/svg/discordMain.svg";
import quoraIcon from "../assets/image/svg/quoraMain.svg";
import tumblrIcon from "../assets/image/svg/tumblrMain.svg";
import viberIcon from "../assets/image/svg/viberMain.svg";
import linkedinIcon from "../assets/image/svg/linkedinMain.svg";
import flickrIcon from "../assets/image/svg/flickrMain.svg";
import youtubeIcon from "../assets/image/svg/youtubeMain.svg";
import mediumIcon from "../assets/image/svg/mediumMain.svg";
import Modal from "react-modal";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import ReactLoading from "react-loading";

import { useEffect, useState } from "react";
import ReactivateModal from "./ReactivateModal";
import { getBrand } from "../store/slices/bounties";
import { toast } from "react-toastify";
import { BrandType } from "../types";

const SubscribeChannel = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [loadingBrand, setLoadingBrand] = useState(false);

  const dispatch = useAppDispatch();
  // const { user } = useAppSelector((state) => state.auth);
  const { message }: any = useAppSelector((state) => state.message);
  const { brand }: { brand: BrandType[] | null } = useAppSelector(
    (state) => state.bounties
  );

  const openModal = (): void => {
    setModalIsOpen(true);
  };
  function closeModal(): void {
    setModalIsOpen(false);
  }

  useEffect(() => {
    // const handleGetSubscription = () => {
    //   setLoading(true);
    //   dispatch(getSubscription())
    //     .unwrap()
    //     .then(() => {
    //       setLoading(false);
    //     })
    //     .catch(() => {
    //       toast.error(message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //       setLoading(false);
    //     });
    // };

    // handleGetSubscription();

    const handleGetBrand = () => {
      setLoadingBrand(true);
      dispatch(getBrand())
        .unwrap()
        .then(() => {
          setLoadingBrand(false);
        })
        .catch(() => {
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingBrand(false);
        });
    };

    handleGetBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dataCurrent] = useState({
    name: "Ultainfinity Wealth Launchpad",
    subs: [
      { icon: whatsAppIcon, iconName: "WhatsApp" },
      { icon: telegramIcon, iconName: "Telegram" },
      { icon: twitterIcon, iconName: "Twitter" },
      { icon: instagramIcon, iconName: "Instagram" },
      { icon: tiktokIcon, iconName: "Tiktok" },
      { icon: facebookIcon, iconName: "Facebook" },
      { icon: youtubeIcon, iconName: "Youtube" },
      { icon: linkedinIcon, iconName: "Linkedin" },
      { icon: snapchatIcon, iconName: "Snapchat" },
      { icon: messangerIcon, iconName: "Messanger" },
      { icon: pinterestIcon, iconName: "Pinterest" },
      { icon: quoraIcon, iconName: "Quora" },
      { icon: viberIcon, iconName: "Viber" },
      { icon: lineIcon, iconName: "Line" },
      { icon: signalIcon, iconName: "Signal" },
      { icon: vkIcon, iconName: "VK" },
      { icon: discordIcon, iconName: "Discord" },
      { icon: tumblrIcon, iconName: "Tumblr" },
      { icon: flickrIcon, iconName: "Flickr" },
      { icon: mediumIcon, iconName: "Medium" },
    ],
  });

  // const data = {
  //   uwl: {
  //     name: "Ultainfinity Wealth Launchpad",
  //     //   subs: [
  //     //     { icon: <DashboardIcon />, iconName: "WhatsApp" },
  //     //     { icon: <DashboardIcon />, iconName: "Telegram" },
  //     //     { icon: <DashboardIcon />, iconName: "Twitter" },
  //     //   ],
  //   },
  //   uab: {
  //     name: "Ultainfinity Airdrop and Bounty",
  //     //   subs: [
  //     //     { icon: <DashboardIcon />, iconName: "WhatsApp" },
  //     //     { icon: <DashboardIcon />, iconName: "Telegram" },
  //     //     { icon: <DashboardIcon />, iconName: "Twitter" },
  //     //   ],
  //   },
  //   uic: {
  //     name: "Ultainfinity Instant Creditline",
  //     //   subs: [
  //     //     { icon: <DashboardIcon />, iconName: "WhatsApp" },
  //     //     { icon: <DashboardIcon />, iconName: "Telegram" },
  //     //     { icon: <DashboardIcon />, iconName: "Twitter" },
  //     //   ],
  //   },
  //   ugg: {
  //     name: "Ultainfinity Global Group",
  //     //   subs: [
  //     //     { icon: <DashboardIcon />, iconName: "WhatsApp" },
  //     //     { icon: <DashboardIcon />, iconName: "Telegram" },
  //     //     { icon: <DashboardIcon />, iconName: "Twitter" },
  //     //   ],
  //   },
  // };

  // const handelTabChange = (name: "uwl" | "ugg" | "uic" | "uab") => {
  //   const newData = {
  //     name: data[name].name,
  //     subs: dataCurrent.subs,
  //   };
  //   setdataCurrent(newData);
  // };

  // interface BtnData {
  //   name: string;
  //   short: "uwl" | "ugg" | "uic" | "uab";
  // }

  // const btnData: BtnData[] = [
  //   { name: "Ultainfinity Wealth Launchpad", short: "uwl" },
  //   { name: "Ultainfinity Airdrop and Bounty", short: "uab" },
  //   { name: "Ultainfinity Instant Creditline", short: "uic" },
  //   { name: "Ultainfinity Global Group", short: "ugg" },
  // ];

  return (
    <div className="subscribeChannel">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Create chain Modal"
      >
        <ReactivateModal program="Airdrop 2" close={closeModal} />
      </Modal>

      <div className="subscribeChannel__header">
        <h3 className="subscribeChannel__header__title">
          Subscribe to our social media channels
        </h3>
        <ButtonAlt onClick={openModal} width="150px" $fill>
          Reactivate
        </ButtonAlt>
      </div>

      <div className="subscribeChannel__platformBtns">
        {!loadingBrand ? (
          brand &&
          brand.map((item, i) => {
            return (
              <button
                key={item.id}
                className={
                  i === 0
                    ? "subscribeChannel__platformBtns__item active"
                    : "subscribeChannel__platformBtns__item"
                }
              >
                {item.name.toLowerCase()}
              </button>
            );
          })
        ) : (
          <ReactLoading color="white" width={50} height={50} type="spin" />
        )}
      </div>

      <div className="subscribeChannel__table">
        <div className="subscribeChannel__table__body">
          <div className="subscribeChannel__table__row">
            <div
              style={{ width: "10%" }}
              className="subscribeChannel__table__row__head"
            >
              #
            </div>
            <div
              style={{ width: "25%" }}
              className="subscribeChannel__table__row__head"
            >
              Channels
            </div>
            <div
              style={{ width: "40%" }}
              className="subscribeChannel__table__row__head"
            >
              Platform
            </div>
            <div
              style={{ width: "25%" }}
              className="subscribeChannel__table__row__head"
            >
              Status
            </div>
          </div>

          {dataCurrent.subs.map((sub, i) => {
            return (
              <div key={i} className="subscribeChannel__table__row">
                <div
                  style={{ width: "10%" }}
                  className="subscribeChannel__table__row__data"
                >
                  {i + 1}
                </div>
                <div
                  style={{ width: "25%" }}
                  className="subscribeChannel__table__row__data"
                >
                  <div className="subscribeChannel__table__row__data__icon">
                    <img src={sub.icon} alt="" />
                    {sub.iconName}
                  </div>
                </div>
                <div
                  style={{ width: "40%" }}
                  className="subscribeChannel__table__row__data"
                >
                  {dataCurrent.name}
                </div>
                <div
                  style={{ width: "25%" }}
                  className="subscribeChannel__table__row__data"
                >
                  <button
                    className={
                      i < 5
                        ? "subscribeChannel__table__row__data__btn subscribed"
                        : "subscribeChannel__table__row__data__btn "
                    }
                  >
                    {i < 5 ? "Subscribed" : "Subscribe"}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SubscribeChannel;
