import logo from "../assets/image/svg/logo.svg";
import { Link, Outlet, useNavigate } from "react-router-dom";
import logoLight from "../assets/image/svg/logoLight.svg";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { Button } from "../components/styledComponent/form/Buttons";
import { logout } from "../store/slices/auth";

const FormLayout = ({
  theme,
  sessionTimeOut,
}: {
  theme: string;
  sessionTimeOut: boolean;
}) => {
  const { user }: any = useAppSelector((state) => state.auth);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user?.id) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (sessionTimeOut) {
      dispatch(logout());
      navigate("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionTimeOut]);
  return (
    <div className="formLayout">
      <div className="formLayout__header">
        <Link to="/dashboard">
          <img
            src={theme === "dark" ? logo : logoLight}
            alt=""
            className="formLayout__header__logo"
          />
        </Link>

        <Link to="/dashboard/cash-for-charity">
          <Button width="230px">Go back to dashboard</Button>
        </Link>
      </div>
      <div className="formLayout__main">
        <Outlet />
      </div>
    </div>
  );
};

export default FormLayout;
