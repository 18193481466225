import styled from "styled-components";

export const PageTitle = styled.h1`
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  color: ${({ theme }) => theme.text};
  margin-bottom: 20px;

  @media screen and (max-width: 500px) {
    font-size: 28px;
  }
`;
