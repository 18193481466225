import { useState } from "react";
import Modal from "react-modal";
import closeIcon from "../assets/image/svg/close.svg";
import getHelpSvgIcon from "../assets/image/svg/getHelpSvg.svg";
import GHstep1Icon from "../assets/image/svg/GHstep1.svg";
import GHstep2Icon from "../assets/image/svg/GHstep2.svg";
import GHstep3Icon from "../assets/image/svg/GHstep3.svg";
import GHstep4Icon from "../assets/image/svg/GHstep4.svg";
import GHstep5Icon from "../assets/image/svg/GHstep5.svg";
import GHstep6Icon from "../assets/image/svg/GHstep6.svg";
import { Button } from "./styledComponent/form/Buttons";

const GetHelp = ({ closeModal }: { closeModal: () => void }) => {
  const [step, setStep] = useState(1);

  const GetHelpModal = () => {
    const handlePreStep = () => {
      if (step !== 1) {
        setStep(step - 1);
      }
    };

    const handleNextStep = () => {
      if (step !== 6) {
        setStep(step + 1);
      }
    };
    return (
      <div className="getHelp__modal">
        <div onClick={closeModal} className="getHelp__modal__closeBtn">
          <img src={closeIcon} alt="" />
        </div>

        <div className="getHelp__modal__header">
          <img src={getHelpSvgIcon} alt="" />
          <h2>How OneStep verification works?</h2>
          <h3>
            {step === 1
              ? "STEP 1"
              : step === 2
              ? "STEP 2"
              : "FOLLOW INSTRUCTION"}{" "}
          </h3>
        </div>

        <div className="getHelp__modal__main">
          {step === 1 && (
            <>
              <p>
                Select the Messenger Link to begin the process to get OTP Code.{" "}
              </p>
              <img style={{ maxWidth: 300 }} src={GHstep1Icon} alt="" />
            </>
          )}
          {step === 2 && (
            <>
              <p>
                Type <span>"Hello"</span> . Please note that for telegram you
                will need to type "/start". For Whatsapp, you will be giving a
                keyword to subscribe to the bot. Send the keyword
              </p>
              <img src={GHstep2Icon} alt="" />
            </>
          )}
          {step === 3 && (
            <>
              <p>
                If you are a new User or you are not using the same Messenger,
                you will be asked to submit a LINK KEY. <span>A LINK KEY</span>{" "}
                is a mechanism to give you the freedom to use any of the
                messengers we provide to get OTP Code. Each time you get an OTP
                Code, your LINK KEY is also shared.
              </p>

              <span className="getHelp__modal__main__sp"></span>
              <img style={{ maxWidth: 300 }} src={GHstep3Icon} alt="" />
            </>
          )}
          {step === 4 && (
            <>
              <p>
                Create a New LINK KEY by typing <span>"NO"</span> if you don't
                have one or type the LINK Key from a previous Messenger. Make
                sure you save this LINK KEY if you need to use another Messenger
              </p>

              <img style={{ maxWidth: 300 }} src={GHstep4Icon} alt="" />
            </>
          )}
          {step === 5 && (
            <>
              <p>
                Once your Link Key is confirmed , follow the instruction to get
                OTP Code
              </p>

              <img style={{ maxWidth: 200 }} src={GHstep5Icon} alt="" />
            </>
          )}
          {step === 6 && (
            <>
              <p>Great! Your OTP Code will be sent to you</p>

              <img style={{ maxWidth: 150 }} src={GHstep6Icon} alt="" />
            </>
          )}
        </div>

        <div className="getHelp__modal__btns">
          <Button onClick={handlePreStep}>Previous</Button>
          <Button $disable={step === 6} onClick={handleNextStep} $fill>
            Next
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="getHelp">
      <GetHelpModal />
      {/* <Modal
        className="modalMain"
        overlayClassName="modal-overlay-alt"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="reactivate payment"
      > */}
      {/* </Modal>
      <div className="getHelp__main">
        <p className="getHelp__disc">
          Having trouble using OneStep Verification?{" "}
        </p>
        <span onClick={openModal} className="getHelp__btn">
          Get Help for OneStep Verification
        </span>
      </div> */}
    </div>
  );
};

export default GetHelp;
