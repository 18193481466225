import axios from "axios";
import authHeader from "./authHeader";

const API_URL = process.env.REACT_APP_API_URL;

const getLeaderboard = async (country: string) => {
  return await axios({
    url: API_URL + `/referrals/leaderboard?limit=10&page=1&country=${country}`,
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const leaderboardSevice = {
  getLeaderboard,
};

export default leaderboardSevice;
