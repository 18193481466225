import styled from "styled-components";
import { colorTheme } from "../../../assets/colors";
import { ButtonType } from "../../../types";

export const Button = styled.button<ButtonType>`
  background: ${(props) =>
    props.$disable
      ? colorTheme.lightGray
      : props?.$fill
      ? colorTheme.primaryGradient
      : "transparent"};
  color: ${(props) =>
    props.$disable
      ? colorTheme.gray
      : props.$fill
      ? "white"
      : colorTheme.primary};
  width: ${(props) =>
    props.width ? props?.width : props?.small === "true" ? "8rem" : "15rem"};
  height: ${(props) =>
    props.height
      ? props?.height
      : props?.small === "true"
      ? "2.5rem"
      : "4.8rem"};
  border: ${(props) =>
    props.$disable
      ? `1px solid ${colorTheme.lightGray}`
      : props?.$fill
      ? `1px solid ${colorTheme.primary}`
      : `1px solid ${colorTheme.primary}`};
  border-radius: ${(props) => (props.radius ? props.radius : "50px")};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-weight: 600;
  gap: 1rem;
  font-size: ${(props) => (props.small === "true" ? "1.2rem" : "1.6rem")};
  cursor: ${(props) => (props.$disable ? "not-allowed" : "pointer")};
`;

export const ButtonAlt = styled.button<ButtonType>`
  background: ${(props) =>
    props.$disable
      ? colorTheme.lightGray
      : props?.$fill
      ? colorTheme.secondaryGradient
      : "transparent"};
  color: ${(props) =>
    props.$disable
      ? colorTheme.gray
      : props.$fill
      ? "white"
      : colorTheme.primary};
  width: ${(props) =>
    props.width ? props?.width : props?.small === "true" ? "8rem" : "20rem"};
  height: ${(props) =>
    props.height
      ? props?.height
      : props?.small === "true"
      ? "2.5rem"
      : "4.4rem"};
  border: none;
  border-radius: ${(props) => (props.radius ? props.radius : "10px")};
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-weight: 600;
  gap: 1rem;
  font-size: ${(props) => (props.small === "true" ? "1.2rem" : "1.6rem")};
  cursor: ${(props) => (props.$disable ? "not-allowed" : "pointer")};
`;

export const ButtonAction = styled.button`
  display: inline-block;
  width: 3.5rem;
  border-radius: 50%;
  height: 3.5rem;
  border: 2px solid ${colorTheme.lightGray};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  background-color: #fff;

  &:hover {
    background-color: ${colorTheme.lightGray};
  }
`;
