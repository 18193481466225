import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { countires } from "../../assets/data/countries";
// import info2Icon from "../../assets/image/svg/info2.svg";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  getProfileDetails,
  postProfileDetails,
} from "../../store/slices/settings";
import { Button } from "../styledComponent/form/Buttons";
import {
  SelectFieldAlt,
  TextFieldAlt,
} from "../styledComponent/form/textFields";
import ReactLoading from "react-loading";

interface profileDetails {
  first_name: string;
  last_name: string;
  email: string;
  address_county: string;
  address_country: string;
  address_city: string;
  address_line1: string;
  address_zip: string;
  address_line2: string;
}

const PersonalDetail = ({ setCurrStage, main, setShowProfileDetails }: any) => {
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState<any>({});
  const [data, setData] = useState<profileDetails>({
    first_name: "",
    last_name: "",
    email: "",
    address_country: "",
    address_county: "",
    address_city: "",
    address_line1: "",
    address_zip: "",
    address_line2: "",
  });

  const dispatch = useAppDispatch();
  const { profileDetails }: any = useAppSelector((state) => state.settings);

  useEffect(() => {
    const handleGetProfileDetails = () => {
      setLoading(true);
      dispatch(getProfileDetails())
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    };

    handleGetProfileDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileDetails) {
      const newProfileDetails = {
        first_name:
          profileDetails?.first_name !== null ? profileDetails?.first_name : "",
        last_name:
          profileDetails?.last_name !== null ? profileDetails?.last_name : "",
        email: profileDetails?.email !== null ? profileDetails?.email : "",
        address_country:
          profileDetails?.address_country !== null
            ? profileDetails?.address_country
            : "",
        address_county:
          profileDetails?.address_country !== null
            ? profileDetails?.address_country
            : "",
        address_city:
          profileDetails?.address_city !== null
            ? profileDetails?.address_city
            : "",
        address_line1:
          profileDetails?.address_line1 !== null
            ? profileDetails?.address_line1
            : "",
        address_zip:
          profileDetails?.address_zip !== null
            ? profileDetails?.address_zip
            : "",
        address_line2:
          profileDetails?.address_line2 !== null
            ? profileDetails?.address_line2
            : "",
      };
      setData(newProfileDetails);
    }
  }, [profileDetails]);

  const validate = () => {
    let firstNameError = "";
    let lastNameError = "";
    let emailError = "";
    let countryError = "";
    let cityError = "";
    let addressError = "";
    let zipError = "";

    if (!data.first_name) {
      firstNameError = "first name is required";
    }
    if (!data.last_name) {
      lastNameError = "last name is required";
    }
    if (!data.email) {
      emailError = "email is required";
    }
    if (!data.address_country) {
      countryError = "country is required";
    }
    if (!data.address_city) {
      cityError = "city is required";
    }
    if (!data.address_line1) {
      addressError = "address is required";
    }
    if (!data.address_zip) {
      zipError = "zip is required";
    }

    if (
      firstNameError ||
      lastNameError ||
      emailError ||
      countryError ||
      cityError ||
      addressError ||
      zipError
    ) {
      setInputError((curr: any) => {
        return {
          ...curr,
          first_name: firstNameError,
          last_name: lastNameError,
          email: emailError,
          address_country: countryError,
          address_city: cityError,
          address_line1: addressError,
          address_zip: zipError,
        };
      });

      return false;
    }
    return true;
  };

  const handleProfileUpdate = () => {
    const checkValidate = validate();
    if (checkValidate) {
      setLoading(true);
      dispatch(postProfileDetails(data))
        .unwrap()
        .then(() => {
          setLoading(false);
          toast.success("Personal Details Updated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          if (!main) setCurrStage(2);
          if (main) setShowProfileDetails(false);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    }
  };

  // console.log("profileDetails", profileDetails);
  return (
    <div className="personalDetail">
      <h2 className="personalDetail__title">
        {!main && <span>Step 1.</span>}
        Personal Details
      </h2>

      {/* <p className="personalDetail__disc">
        <img src={info2Icon} alt="" /> Carefully fill out the form. The details
        can’t be edited after you’ve submitted the form,
      </p> */}

      <div className="personalDetail__form">
        <TextFieldAlt
          name="firstName"
          label="FIRST NAME"
          onChange={(e) =>
            setData((curr) => {
              return { ...curr, first_name: e.target.value };
            })
          }
          value={data.first_name}
          inputError={inputError.first_name}
        />
        <TextFieldAlt
          name="lastName"
          label="LAST NAME"
          onChange={(e) =>
            setData((curr) => {
              return { ...curr, last_name: e.target.value };
            })
          }
          value={data.last_name}
          inputError={inputError.last_name}
        />
        <TextFieldAlt
          name="email"
          label="E-MAIL ADDRESS"
          onChange={(e) =>
            setData((curr) => {
              return { ...curr, email: e.target.value };
            })
          }
          value={data.email}
          inputError={inputError.email}
        />
        <SelectFieldAlt
          name="country"
          label="COUNTRY OF RESIDENCE"
          holder="Choose country"
          onChange={(e) =>
            setData((curr) => {
              return {
                ...curr,
                address_country: e.target.value,
                address_county: e.target.value,
              };
            })
          }
          value={data.address_country}
          inputError={inputError.address_country}
        >
          {countires.map((country) => {
            return (
              <option key={country.code} value={country.name}>
                {country.name}
              </option>
            );
          })}
        </SelectFieldAlt>
        <TextFieldAlt
          name="city"
          label="CITY OF RESIDENCE"
          onChange={(e) =>
            setData((curr) => {
              return { ...curr, address_city: e.target.value };
            })
          }
          value={data.address_city}
          inputError={inputError.address_city}
        />
        <TextFieldAlt
          name="address"
          label="ADDRESS"
          onChange={(e) =>
            setData((curr) => {
              return { ...curr, address_line1: e.target.value };
            })
          }
          value={data.address_line1}
          inputError={inputError.address_line1}
        />
        <TextFieldAlt
          name="zip"
          label="ZIP / POST CODE"
          onChange={(e) =>
            setData((curr) => {
              return { ...curr, address_zip: e.target.value };
            })
          }
          value={data.address_zip}
          inputError={inputError.address_zip}
        />
        <TextFieldAlt
          name="address2"
          label="ADDRESS #2 (optional)"
          onChange={(e) =>
            setData((curr) => {
              return { ...curr, address_line2: e.target.value };
            })
          }
          value={data.address_line2}
        />
      </div>

      <div className="personalDetail__btn">
        <Button onClick={handleProfileUpdate} $fill>
          {loading ? (
            <ReactLoading color="white" width={30} height={30} type="spin" />
          ) : main ? (
            "Update"
          ) : (
            "Continue"
          )}
        </Button>
      </div>
    </div>
  );
};

export default PersonalDetail;
