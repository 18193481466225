import React, { createContext, useContext, useState, ReactNode } from "react";

interface SocialMediaContextProps {
  selectedSocialMedia: string;
  updateSelectedSocialMedia: (socialMedia: string) => void;
}

const initialSocialMediaContext: SocialMediaContextProps = {
  selectedSocialMedia: "",
  updateSelectedSocialMedia: () => {},
};

export const SocialMediaContext = createContext<SocialMediaContextProps>(
  initialSocialMediaContext
);

export const useSocialMediaContext = () => useContext(SocialMediaContext);

interface SocialMediaProviderProps {
  children: ReactNode;
}

export const SocialMediaProvider: React.FC<SocialMediaProviderProps> = ({
  children,
}: SocialMediaProviderProps) => {
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<string>("");

  const updateSelectedSocialMedia = (socialMedia: string) => {
    setSelectedSocialMedia(socialMedia);
    localStorage.setItem("selectedSocialMedia", socialMedia);
  };

  const contextValue: SocialMediaContextProps = {
    selectedSocialMedia,
    updateSelectedSocialMedia,
  };

  return (
    <SocialMediaContext.Provider value={contextValue}>
      {children}
    </SocialMediaContext.Provider>
  );
};
