export const colorTheme = {
  primary: "#666DFF",
  secondary: "#eaa613",

  primaryAlt: "#5057E4",
  primaryGradient: "linear-gradient(180deg, #666DFF 0%, #5057E4 100%)",
  secondaryGradient: "linear-gradient(180deg, #eaa613 0%, #efc262 100%)",

  bgColor: "#111114",
  bgColorAlt: "#171b23",
  bgColorAlt2: "#202531",

  error: "#a92424",
  textColor: "#B1B5C3",
  gray: "#AEAEAE",
  lightGray: "#E2E4ED",
};
