import { useEffect, useState } from "react";
import Carousel from "../components/Carousel";
import { ButtonAlt } from "../components/styledComponent/form/Buttons";
import { PageTitle } from "../components/styledComponent/headers/styles";
import Modal from "react-modal";
import ApplicationResponse from "../components/ApplicationResponse";
import {
  verifytApplicantStatus,
  verifytIntroducerStatus,
} from "../store/slices/cashForCharity";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";

const CashForCharity = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [loadingApp, setLoadingApp] = useState(false);
  const { introducerStatus, applicantStatus }: any = useAppSelector(
    (state) => state.cashForCharity
  );

  // const openModal = (): void => {
  //   setModalIsOpen(true);
  // };
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  function closeModal(): void {
    setModalIsOpen(false);
  }
  function openModal(): void {
    setModalIsOpen(true);
  }

  const handleIntroducerStatus = () => {
    setLoading(true);
    dispatch(verifytIntroducerStatus())
      .unwrap()
      .then(() => {
        setLoading(false);
      })
      .catch((error: any) => {
        if (error === "Ops, you have not applied to be an introducer") {
          navigate("/application/introducer");
        } else {
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setLoading(false);
      });
  };

  const handleApplicantStatus = () => {
    setLoadingApp(true);
    dispatch(verifytApplicantStatus())
      .unwrap()
      .then(() => {
        setLoadingApp(false);
      })
      .catch((error: any) => {
        if (error === "Ops, you have not applied to be a charity applicant") {
          navigate("/application/applicant");
        } else {
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        setLoadingApp(false);
      });
  };

  useEffect(() => {
    if (introducerStatus?.status === "processing") {
      setStatus("");
      openModal();
    }
    if (introducerStatus?.status === "approved") {
      navigate("/cash-for-charity/introducer");
    }
    if (introducerStatus?.status === "declined") {
      setStatus("fail");
      openModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [introducerStatus]);

  useEffect(() => {
    if (applicantStatus?.status === "processing") {
      setStatus("");
      openModal();
    }
    if (applicantStatus?.status === "approved") {
      setStatus("success");
      openModal();
    }
    if (applicantStatus?.status === "declined") {
      setStatus("fail");
      openModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="cashForCharity">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Application Information"
      >
        <ApplicationResponse type={status} />
      </Modal>
      <PageTitle>Cash for Charity</PageTitle>
      <Carousel cfc={true} program="cfc" />

      <div className="cashForCharity__programs">
        <h3 className="cashForCharity__programs__title">
          Cash for Charity Program
        </h3>
        <div className="cashForCharity__programs__main">
          <div className="cashForCharity__programs__main__item">
            <div>
              <h4 className="cashForCharity__programs__main__item__title">
                Introducer
              </h4>
              <p>
                The introducer is an important part of the Ultainfinity
                $3billion cash donation to charities, non-profits and other well
                meaning organisations from around the world working in
                concomitance with the Ultainfinity mission to annihilate poverty
                from the world. The introducer will expand Ultainfinity cash for
                charities by bringing in organizations to apply, and guiding
                them through the application process.
              </p>
            </div>
            <div className="cashForCharity__programs__main__item__btn">
              <ButtonAlt $fill onClick={handleIntroducerStatus}>
                {loading ? (
                  <ReactLoading
                    color="white"
                    width={30}
                    height={30}
                    type="spin"
                  />
                ) : (
                  " Claim Now"
                )}
              </ButtonAlt>
            </div>
          </div>
          <div className="cashForCharity__programs__main__item">
            <div>
              <h4 className="cashForCharity__programs__main__item__title">
                Applicant
              </h4>
              <p className="cashForCharity__programs__main__item__disc">
                The Ultainfinity Foundation of the Ultainfinity Global Group is
                impacting the world again, as it has always done, this time with
                a $3 billion donation to charities, non-profits, NGOs and
                similar organisations that are working to obliterate poverty,
                just like the Ultainfinity.
              </p>
            </div>

            <div className="cashForCharity__programs__main__item__btn">
              <ButtonAlt onClick={handleApplicantStatus} $fill>
                {" "}
                {loadingApp ? (
                  <ReactLoading
                    color="white"
                    width={30}
                    height={30}
                    type="spin"
                  />
                ) : (
                  " Join Now"
                )}
              </ButtonAlt>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashForCharity;
