// import arrowIcon from "../../assets/image/svg/arrowAlt2.svg";
// import wallet from "../../assets/image/wallet.png";

import { Link } from "react-router-dom";

const BARRS = () => {
  return (
    <div className="barrs">
      <div className="barrs__main">
        <div className="barrs__main__left">
          <h1>Our Super-innovation - BARRS Model & System</h1>
          <p>
            Structural and predictable underpins of the embedded BARRS Model &
            Systems. The BARRS Model is and will remain a record scientific,
            economic, marketing, and generational wealth invention with a
            seismic shift in society and paradigm shift in related corporate
            functions, strategies and valuation, and sectoral perception.
          </p>

          <Link to={"/blog/sdjhfbfuy7w85hje7fsd"}>
            <button>Learn more</button>
          </Link>
        </div>
        {/* <div className="barrs__main__right">
          <img src={wallet} alt="" />
        </div> */}
      </div>
    </div>
  );
};

export default BARRS;
