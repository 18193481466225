import infoIcon from "../assets/image/svg/info.svg";
import arrowupIcon from "../assets/image/svg/arrowup.svg";
import chatIcon from "../assets/image/svg/stock.svg";
import { Button } from "../components/styledComponent/form/Buttons";
import reloadIcon from "../assets/image/svg/reload.svg";
import { PageTitle } from "../components/styledComponent/headers/styles";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import {
  checkReferralUnlock,
  getReferralCode,
  getReferrals,
  getTotalCode,
} from "../store/slices/airdrops";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { colorTheme } from "../assets/colors";
import { getBalance } from "../store/slices/bounties";
import noDataIcon from "../assets/image/svg/noData.svg";
import { getIntroducerReferralCode } from "../store/slices/cashForCharity";

const Referral = () => {
  const [copy, setCopy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBal, setLoadingBal] = useState(false);
  const [loadingReferrals, setLoadingReferrals] = useState(false);
  const [loadingTotal, setLoadingTotal] = useState(false);
  const [loadingCheck, setloadingCheck] = useState(false);
  const [unlock, setUnlock] = useState<any>(null);

  const { referralUnlock, referralCode, referrals, totalCode } = useAppSelector(
    (state) => state.airdrops
  );
  const { balance } = useAppSelector((state) => state.bounties);
  const { introducerCode } = useAppSelector((state) => state.cashForCharity);
  // const { user } = useAppSelector((state) => state.auth);
  // const { message }: any = useAppSelector((state) => state.message);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleGetReferralCode = () => {
      setLoading(true);
      dispatch(getReferralCode())
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    };
    handleGetReferralCode();

    const handleGetReferrals = () => {
      setLoadingReferrals(true);
      dispatch(getReferrals())
        .unwrap()
        .then(() => {
          setLoadingReferrals(false);
        })
        .catch((err: any) => {
          // console.log("err", err);

          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingReferrals(false);
        });
    };
    handleGetReferrals();

    const handleGetTotalCode = () => {
      setLoadingTotal(true);
      dispatch(getTotalCode())
        .unwrap()
        .then(() => {
          setLoadingTotal(false);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingTotal(false);
        });
    };
    handleGetTotalCode();

    const handleGetBalance = () => {
      setLoadingBal(true);
      dispatch(getBalance())
        .unwrap()
        .then(() => {
          setLoadingBal(false);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingBal(false);
        });
    };

    handleGetBalance();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleCheckReferralUnlock = () => {
      setloadingCheck(true);
      dispatch(checkReferralUnlock())
        .unwrap()
        .then(() => {
          setloadingCheck(false);
        })
        .catch((err: any) => {
          if (err === "Referral has not been unlocked") {
            setUnlock(false);
          } else {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setloadingCheck(false);
        });
    };

    handleCheckReferralUnlock();

    const handleGetIntroducerReferralCode = () => {
      dispatch(getIntroducerReferralCode())
        .unwrap()
        .then(() => {})
        .catch((err: any) => {
          // toast.error(err, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        });
    };
    handleGetIntroducerReferralCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (referralUnlock === "true") {
      setUnlock(true);
    }
  }, [referralUnlock]);

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 3000);
    }
  }, [copy]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const numberFormat = (num: number) => {
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  return (
    <div className="referral">
      <PageTitle>Referrals Code</PageTitle>

      <div className="referral__info">
        <h2 className="referral__info__title">
          Referral & Code Summary <img src={infoIcon} alt="" />
        </h2>

        <div className="referral__info__main">
          <div className="referral__info__main__item">
            {loadingReferrals ? (
              <ReactLoading
                color={colorTheme.primary}
                width={50}
                height={50}
                type="spin"
              />
            ) : typeof referrals === "number" ? (
              <>
                <div className="referral__info__main__item__img">
                  {" "}
                  <img src={arrowupIcon} alt="" />
                </div>
                <div className="referral__info__main__item__data">
                  <h3>{numberFormat(referrals)}</h3>
                  <p>Total Referrals</p>
                </div>
              </>
            ) : (
              <img src={noDataIcon} style={{ width: "40px" }} alt="" />
            )}
          </div>
          <div className="referral__info__main__item">
            {loadingTotal ? (
              <ReactLoading
                color={colorTheme.primary}
                width={50}
                height={50}
                type="spin"
              />
            ) : typeof referrals === "number" ? (
              <>
                <div className="referral__info__main__item__img">
                  {" "}
                  <img src={arrowupIcon} alt="" />
                </div>
                <div className="referral__info__main__item__data">
                  <h3>{numberFormat(totalCode)}</h3>
                  <p>Codes Generated</p>
                </div>
              </>
            ) : (
              <img src={noDataIcon} style={{ width: "40px" }} alt="" />
            )}
          </div>
          <div className="referral__info__main__item">
            {" "}
            {loadingBal ? (
              <ReactLoading
                color={colorTheme.primary}
                width={50}
                height={50}
                type="spin"
              />
            ) : balance ? (
              <>
                <div className="referral__info__main__item__img">
                  {" "}
                  <img src={chatIcon} alt="" />
                </div>
                <div className="referral__info__main__item__data">
                  <h3>${numberFormat(balance.airdrop_1)}</h3>
                  <p>Top Referral Bonus</p>
                </div>
              </>
            ) : (
              <img src={noDataIcon} style={{ width: "40px" }} alt="" />
            )}
          </div>
          <div className="referral__info__main__item">
            {loadingBal ? (
              <ReactLoading
                color={colorTheme.primary}
                width={50}
                height={50}
                type="spin"
              />
            ) : balance ? (
              <>
                <div className="referral__info__main__item__img">
                  {" "}
                  <img src={chatIcon} alt="" />
                </div>
                <div className="referral__info__main__item__data">
                  <h3>${numberFormat(balance.airdrop_1)}</h3>
                  <p>Amount earned</p>
                </div>
              </>
            ) : (
              <img src={noDataIcon} style={{ width: "40px" }} alt="" />
            )}
          </div>
        </div>
      </div>

      {introducerCode?.code && (
        <div className="referral__action">
          <div className="referral__action__header">
            <div className="referral__action__header__info">
              <h3>Cash For Charity Code</h3>
              <p>
                Invite charities to apply and unlock the donation of maximum $1M
              </p>
            </div>
          </div>
          <div className="referral__action__referral">
            <div className="referral__action__referral__link">
              <h4>
                Referral link <span>(Click to copy referral link)</span>
              </h4>
              <p>
                https://ultainfinityairdropandbounty.com/{introducerCode?.code}
              </p>
            </div>
            <div className="referral__action__referral__code">
              <h4>Referral code</h4>
              <p>{introducerCode?.code}</p>
              <div className="referral__action__referral__code__icon">
                <img src={reloadIcon} alt="" />
              </div>
            </div>
            <CopyToClipboard
              text={introducerCode?.code}
              onCopy={() => setCopy(true)}
            >
              <Button
                style={{ borderRadius: "10px", alignSelf: "flex-end" }}
                $fill
              >
                {copy === true ? "Copied!" : "Copy Code"}
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      )}

      <div className="referral__action">
        {loadingCheck ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReactLoading
              color={colorTheme.primary}
              width={50}
              height={50}
              type="spin"
            />
          </div>
        ) : unlock === true ? (
          <>
            {loading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ReactLoading
                  color={colorTheme.primary}
                  width={50}
                  height={50}
                  type="spin"
                />
              </div>
            ) : referralCode ? (
              <>
                <div className="referral__action__header">
                  <div className="referral__action__header__info">
                    <h3>Referral Airdrop Code</h3>
                    <p>
                      You're earning $3000 worth of Ultainfinity cryptocurrency.
                    </p>
                  </div>
                </div>
                <div className="referral__action__referral">
                  <div className="referral__action__referral__link">
                    <h4>
                      Referral link <span>(Click to copy referral link)</span>
                    </h4>
                    <p>
                      https://ultainfinityairdropandbounty.com/{referralCode}
                    </p>
                  </div>
                  <div className="referral__action__referral__code">
                    <h4>Referral code</h4>
                    <p>{referralCode}</p>
                    <div className="referral__action__referral__code__icon">
                      <img src={reloadIcon} alt="" />
                    </div>
                  </div>
                  <CopyToClipboard
                    text={referralCode}
                    onCopy={() => setCopy(true)}
                  >
                    <Button
                      style={{ borderRadius: "10px", alignSelf: "flex-end" }}
                      $fill
                    >
                      {copy === true ? "Copied!" : "Copy Code"}
                    </Button>
                  </CopyToClipboard>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // marginTop: "40px",
                }}
              >
                <img src={noDataIcon} alt="" />
              </div>
            )}
          </>
        ) : unlock === false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // marginTop: "40px",
            }}
          >
            Go to dashboard to unlock referral code
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // marginTop: "40px",
            }}
          >
            <img src={noDataIcon} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Referral;
