// import { InputUser } from "../components/styledComponent/form/textFields/styles";
// import profileIcon from "../assets/image/svg/Profile.svg";
import recoverySuccess from "../assets/image/svg/recoverySuccess.svg";
import { Button } from "../components/styledComponent/form/Buttons";
import PhoneInput from "react-phone-input-2";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { InputUser } from "../components/styledComponent/form/textFields/styles";
import profileIcon from "../assets/image/svg/Profile.svg";

const Recovery = ({ type }: { type: "messenger" | "phone" | "username" }) => {
  const [success, setsuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");

  const handleSubmit = async () => {
    setLoading(true);

    let data = {};
    // console.log("type", type);
    if (type === "messenger") {
      data = {
        forgot: "messanger",
        phone: phone,
        channel: "sms",
      };
    }
    // if (type === "phone") {
    //   data = {
    //     forgot: "phone",
    //     phone: "",
    //     username: "",
    //     channel: "messenger",
    //   };
    // }
    if (type === "username") {
      data = {
        username: "",
        forgot: "username",
        phone: phone,
        channel: "sms",
      };
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/users/v1/user-account/recovery`,
        data
      );
      setLoading(false);
      if (response.data.type === "success") {
        setsuccess(true);
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err: any) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      setLoading(false);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div className="recovery">
      {!success ? (
        <div className="recovery__main">
          <h2 className="recovery__main__title">
            {type === "username"
              ? "Username"
              : type === "phone"
              ? "Phone Number"
              : "Messenger"}{" "}
            Recovery
          </h2>
          <p className="recovery__main__disc">
            Kindly provide the following details for your account,
          </p>

          <div className="recovery__main__input">
            <div className="authForm__user__input">
              <div className="authForm__user__input__icon">
                <img src={profileIcon} alt="profile icon" />
              </div>
              <InputUser
                width="100%"
                // onChange={(e) =>
                //   setUserAuth((curr) => {
                //     return { ...curr, username: e.target.value };
                //   })
                // }
                type="text"
                placeholder="Enter Username"
              />
              <PhoneInput
                country={"us"}
                onChange={(phone) => setPhone(phone)}
              />
            </div>
          </div>

          <div className="authForm__btnCon">
            <Button onClick={handleSubmit} width="250px" $fill>
              {loading ? (
                <ReactLoading
                  color="white"
                  width={30}
                  height={30}
                  type="spin"
                />
              ) : (
                "Send"
              )}
            </Button>
          </div>
          <div className="recovery__main__line"></div>
          <p className="recovery__main__bottomDisc">
            The{" "}
            {type === "messenger"
              ? "Messenger"
              : type === "phone"
              ? "Phone number"
              : "Username"}{" "}
            details would be sent to your phone number
            {/* {type === "messenger" ? "phone number" : "messenger"} */}
          </p>
        </div>
      ) : (
        <div className="recovery__success">
          <h2 className="recovery__success__title">Recovery Successful</h2>
          <div className="recovery__success__img">
            <img src={recoverySuccess} alt="" />
          </div>
          <div className="recovery__main__line"></div>
          <p className="recovery__main__bottomDisc">
            We have sent the{" "}
            {type === "messenger"
              ? "Messenger"
              : type === "phone"
              ? "Phone number"
              : "Username"}{" "}
            details to your phone number
            {/* {type === "messenger" ? "phone number" : "messenger"} */}.
            Kindly check your text message
            {/* {type === "messenger" ? "text message" : "messenger"} */}
            to recover the{" "}
            {type === "messenger"
              ? "Messenger"
              : type === "phone"
              ? "Phone number"
              : "Username"}
            .
          </p>
        </div>
      )}
    </div>
  );
};

export default Recovery;
