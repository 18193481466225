import ReactLoading from "react-loading";
import coinsImg from "../assets/image/3coins.png";
import { Button } from "./styledComponent/form/Buttons";
// import coinbaseIcon from "../assets/image/svg/Coinbase.svg";
// import binanceIcon from "../assets/image/svg/Binance.svg";
// import cloverIcon from "../assets/image/svg/Clover.svg";
// import metamaskIcon from "../assets/image/svg/Metamask.svg";
// import coinPaymentsIcon from "../assets/image/svg/CoinPayments.svg";
// import zenGoIcon from "../assets/image/svg/Zengo.svg";
// import trustWalletIcon from "../assets/image/svg/Trust Wallet.svg";
// import cryptoIcon from "../assets/image/crypto.png";
// import ledgerIcon from "../assets/image/svg/Ledger.svg";
// import guardaIcon from "../assets/image/guarda.png";
// import lunoIcon from "../assets/image/svg/Luno.svg";
// import exodusIcon from "../assets/image/svg/Exodus.svg";
// import phantomIcon from "../assets/image/svg/Phantom.svg";
// import solletIcon from "../assets/image/svg/Sollet.svg";
// import solflareIcon from "../assets/image/svg/Solflare.svg";
// import atomicIcon from "../assets/image/atomic.png";

interface ReactivateModalType {
  close: () => void;
  handleReactivate?: (data: any) => void;
  loadingReactivate?: boolean;
  program: string;
  reference?: any;
}

const ReactivateModal = ({
  handleReactivate,
  loadingReactivate,
  program,
  reference,
}: ReactivateModalType) => {
  // const cryptoImage = (crypto: any) => {
  //   switch (crypto) {
  //     case "Coinbase":
  //       return coinbaseIcon;
  //     case "Binance":
  //       return binanceIcon;
  //     case "Metamask":
  //       return metamaskIcon;
  //     case "Clover":
  //       return cloverIcon;
  //     case "CoinPayments":
  //       return coinPaymentsIcon;
  //     case "ZenGo":
  //       return zenGoIcon;
  //     case "Trust Wallet":
  //       return trustWalletIcon;
  //     case "Crypto.com":
  //       return cryptoIcon;
  //     case "Ledger":
  //       return ledgerIcon;

  //     case "Guarda":
  //       return guardaIcon;

  //     case "Luno":
  //       return lunoIcon;

  //     case "Exodus":
  //       return exodusIcon;
  //     case "Phantom":
  //       return phantomIcon;
  //     case "Sollet":
  //       return solletIcon;
  //     case "Solflare":
  //       return solflareIcon;
  //     case "Atomic":
  //       return atomicIcon;

  //     default:
  //       return "";
  //   }
  // };

  return (
    <div className="reactivateModal">
      <div className="reactivateModal__circle">
        {" "}
        <img src={coinsImg} alt="" />{" "}
      </div>
      <h5 className="reactivateModal__title">Reactivate {program} Program</h5>
      <p className="reactivateModal__disc">
        You are about to unlock this airdrop program to claim $3000 unlimited
        times. Do you wish to continue?
      </p>

      <div className="reactivateModal__wallet">
        {/* <div className="reactivateModal__wallet__item">
          Wallet address{" "}
          <span>
            <img
              src={cryptoImage(
                reference ? reference?.crypto_Wallet?.platform : ""
              )}
              alt=""
            />{" "}
            {reference?.crypto_Wallet?.address.substring(0, 20) + "..."}
          </span>
        </div> */}
        <div className="reactivateModal__wallet__item">
          Transaction ID <span>{reference?.transaction_no}</span>
        </div>
        <div className="reactivateModal__wallet__item">
          Reference Account Number <span>{reference?.reference}</span>
        </div>
      </div>

      {reference && handleReactivate && (
        <Button
          onClick={() =>
            handleReactivate({
              transaction_no: reference?.transaction_no,
              reference: reference?.reference,
            })
          }
          $fill
        >
          {loadingReactivate ? (
            <ReactLoading color="white" width={30} height={30} type="spin" />
          ) : (
            "continue"
          )}
        </Button>
      )}
      {/* 

      <div className="reactivateModal__btnCon">
        <button className="reactivateModal__btnCon__left" onClick={close}>
          Cancel
        </button>
        <button
          onClick={handleReactivate}
          className="reactivateModal__btnCon__right"
        >
          {loadingReactivate ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ReactLoading
                color={colorTheme.primary}
                width={30}
                height={30}
                type="spin"
              />
            </div>
          ) : (
            "Accept"
          )}
        </button>
      </div> */}
    </div>
  );
};

export default ReactivateModal;
