import axios from "axios";
import authHeader from "./authHeader";

const API_URL = process.env.REACT_APP_API_URL + "/airdrop-bounty/";

const getReferralCode = async () => {
  return await axios({
    url: API_URL + "app/referral-code-url",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const getReferrals = async () => {
  return await axios({
    url: API_URL + "app/referrals",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const getTotalCode = async () => {
  return await axios({
    url: API_URL + "app/airdrop-codes",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const storeReferralCode = async (data: any) => {
  return await axios({
    url: API_URL + "app/referral",
    headers: authHeader(),
    data,
    method: "post",
  }).then((response) => {
    return response.data;
  });
};

const checkReferralUnlock = async () => {
  return await axios({
    url: API_URL + "app/check-unlock-referral",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const checkReferralReactivate = async () => {
  return await axios({
    url: API_URL + "app/check-referral-code",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const reactivateReferralCode = async (data: any) => {
  return await axios({
    url: API_URL + "app/referral-code",
    headers: authHeader(),
    data,
    method: "post",
  }).then((response) => {
    return response.data;
  });
};

const checkUserReferral = async () => {
  return await axios({
    url: API_URL + "app/check-user-referral",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const airdropsSevice = {
  getReferralCode,
  reactivateReferralCode,
  getReferrals,
  getTotalCode,
  storeReferralCode,
  checkReferralUnlock,
  checkReferralReactivate,
  checkUserReferral,
};

export default airdropsSevice;
