import { useEffect, useState } from "react";
import Carousel from "../../components/Carousel";
import ProgramsInfo from "../../components/ProgramsInfo";
import Payment from "../../components/Payment";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  checkBountySubmitUnlock,
  reactiveBountySubmit,
} from "../../store/slices/bounties";
import { toast } from "react-toastify";
import noDataIcon from "../../assets/image/svg/noData.svg";
import { colorTheme } from "../../assets/colors";
import ReactLoading from "react-loading";
import ProgramsDetails from "../../components/ProgramsDetails";

const Submit = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingUnlock, setLoadingUnlock] = useState(false);
  const [unlock, setUnlock] = useState<any>(null);
  const data = {
    title: "Here’s what you need to do to Unlock each $3,000",
    steps: [
      "Click on the Unlock button",
      "Choose your prefered payment gateway",
      "Submit  any of these cryptos(APE, BTC, BCH, DAI, DOGE, ETH, LTC, SHIB, USDT, USDC and SOL) to Ultainfinity with a minimum of $25, you would automatically receive 10x the value of its value, Plus $3,000.",
      "Click on the Reactivate button and repeat the above steps to earn unlimited times!!",
    ],
  };
  const dispatch = useAppDispatch();
  const { bountySubmitUnlock }: any = useAppSelector((state) => state.bounties);

  // const handleStepChange = () => {
  //   setStep(1);
  // };
  const handleUnlock = (data: any) => {
    setLoadingUnlock(true);
    dispatch(reactiveBountySubmit(data))
      .unwrap()
      .then(() => {
        setLoadingUnlock(false);
        setStep(2);
      })
      .catch((err: any) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });

        setLoadingUnlock(false);
      });
  };

  useEffect(() => {
    const handleCheckBountyUnlock = () => {
      setLoading(true);
      dispatch(checkBountySubmitUnlock())
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };

    handleCheckBountyUnlock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bountySubmitUnlock?.locked === true) {
      setUnlock(false);
    }
    if (bountySubmitUnlock?.locked === false) {
      setUnlock(true);
      setStep(2);
    }
  }, [bountySubmitUnlock]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="submit">
      <h2 className="submit__title">Bounty Programs </h2>
      <Carousel program="3" />

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <ReactLoading
            color={colorTheme.primary}
            width={50}
            height={50}
            type="spin"
          />
        </div>
      ) : unlock !== null ? (
        step === 1 ? (
          <ProgramsInfo
            program="Bounty 2"
            info={data}
            handleUnlock={handleUnlock}
            loading={loadingUnlock}
          />
        ) : (
          <>
            <ProgramsDetails program="bounty2" />
            <Payment program="submit" />
          </>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <img src={noDataIcon} alt="" />
        </div>
      )}
    </div>
  );
};

export default Submit;
