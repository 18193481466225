import { TopbarType } from "../types";
import Sidebar from "./Sidebar";

const Drawer = ({ openDrawer, setOpenDrawer }: TopbarType) => {
  return (
    <div className={openDrawer ? "drawer open" : "drawer"}>
      <div
        style={{ display: "flex" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <Sidebar drawer="true" />
      </div>
    </div>
  );
};

export default Drawer;
