import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { colorTheme } from "../assets/colors";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { getTransaction } from "../store/slices/bounties";
import ReactLoading from "react-loading";
import noDataIcon from "../assets/image/svg/noData.svg";

const TransactionHistory = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { transaction } = useAppSelector((state) => state.bounties);
  const itemsPerPage = 5;

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = transaction?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(transaction?.length / itemsPerPage);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % transaction?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const handleGetTransaction = () => {
      setLoading(true);
      dispatch(getTransaction())
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    };
    handleGetTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="transactionHistory">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <ReactLoading
            color={colorTheme.primary}
            width={50}
            height={50}
            type="spin"
          />
        </div>
      ) : transaction && currentItems?.length > 0 ? (
        <>
          <div className="transactionHistory__header">
            <h2>Transaction History</h2>
            {/* <div className="transactionHistory__header__btnCon">
          <button className="transactionHistory__header__btnCon__item active">
            Monthly
          </button>
          <button className="transactionHistory__header__btnCon__item">
            Yearly
          </button>
        </div> */}
          </div>

          <div className="transactionHistory__table">
            <div className="transactionHistory__table__body">
              <div className="transactionHistory__table__row">
                <div
                  style={{ width: "8%" }}
                  className="transactionHistory__table__row__head"
                ></div>
                <div
                  style={{ width: "12%" }}
                  className="transactionHistory__table__row__head"
                >
                  Programs
                </div>
                <div
                  style={{ width: "12%" }}
                  className="transactionHistory__table__row__head"
                >
                  status
                </div>
                <div
                  style={{ width: "15%" }}
                  className="transactionHistory__table__row__head"
                >
                  Date
                </div>
                <div
                  style={{ width: "20%" }}
                  className="transactionHistory__table__row__head"
                >
                  Transaction ID
                </div>
                <div
                  style={{ width: "20%" }}
                  className="transactionHistory__table__row__head"
                >
                  Reference Account Number
                </div>
                <div
                  style={{ width: "13%" }}
                  className="transactionHistory__table__row__head"
                >
                  Balance
                </div>
              </div>

              {currentItems.map((item: any, i: number) => {
                return (
                  <div key={i} className="transactionHistory__table__row">
                    <div
                      style={{ width: "8%" }}
                      className="transactionHistory__table__row__data"
                    >
                      {itemOffset === 0 ? i + 1 : itemOffset + i + 1}
                    </div>
                    <div
                      style={{ width: "12%" }}
                      className="transactionHistory__table__row__data"
                    >
                      {item?.programs}
                    </div>
                    <div
                      style={{ width: "12%" }}
                      className="transactionHistory__table__row__data"
                    >
                      {item?.status}
                    </div>
                    <div
                      style={{ width: "15%" }}
                      className="transactionHistory__table__row__data"
                    >
                      {item?.date?.substring(0, 10)}
                    </div>
                    <div
                      style={{ width: "20%" }}
                      className="transactionHistory__table__row__data"
                    >
                      {item?.transaction_no}
                    </div>
                    <div
                      style={{ width: "20%" }}
                      className="transactionHistory__table__row__data"
                    >
                      {item?.reference}
                    </div>
                    <div
                      style={{ width: "13%" }}
                      className="transactionHistory__table__row__data"
                    >
                      {item.bonus ? item.bonus : 0}
                    </div>
                  </div>
                );
              })}

              {pageCount > 1 && (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  breakLabel="..."
                  //   renderOnZeroPageCount={null}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <img src={noDataIcon} alt="" />
        </div>
      )}
    </div>
  );
};

export default TransactionHistory;
