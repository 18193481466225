import { useEffect, useState } from "react";
import Carousel from "../../components/Carousel";
import ProgramsDetails from "../../components/ProgramsDetails";
import ProgramsInfo from "../../components/ProgramsInfo";
import SubscribeChannel from "../../components/SubscribeChannel";

const Subscribe = () => {
  const [step, setStep] = useState(1);
  const [comingSoon] = useState(true);

  const data = {
    title: "Here’s what you need to do to Unlock each $3,000",
    steps: [
      "Click on the Unlock button",
      "Subscribe to any six of the following community channels from ourbrands.",
      "Click on the link to go to our social media channel page to subscribe.",
      "Reactivate and earn unlimited times",
    ],
  };
  const handleStepChage = () => {
    setStep(1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="subscribe">
      {" "}
      <h2 className="refer__title">Airdrop Programs </h2>
      <Carousel program="1" />
      {comingSoon ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "40px",
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          Coming Soon...
        </div>
      ) : step === 1 ? (
        <ProgramsInfo
          program="Airdrop 2"
          info={data}
          handleUnlock={handleStepChage}
        />
      ) : (
        <>
          <ProgramsDetails program="airdrop1" />
          <SubscribeChannel />
        </>
      )}
    </div>
  );
};

export default Subscribe;
