import axios from "axios";
import authHeader from "./authHeader";

const API_URL =
  process.env.REACT_APP_API_URL + "/users/v1/user-account/send-userdata";
// const API_URL_ALT = "https://api-staging.ultainfinity.com/v1/users/biometrics";
const API_URL_ALT = process.env.REACT_APP_API_URL + "/users/v1/biometrics";

const login = async (sid: string, username: string) => {
  return axios
    .post(API_URL, {
      sid,
      username,
    })
    .then((response) => {
      if (response.data.data.token) {
        localStorage.setItem("userUlta", JSON.stringify(response.data.data));
      }
      return response.data;
    });
};

const signup = async (
  sid: string,
  username: string,
  phone: string,
  role: string
) => {
  return axios
    .post(API_URL, {
      sid,
      username,
      phone,
    })
    .then((response) => {
      if (response.data.data.token) {
        localStorage.setItem("userUlta", JSON.stringify(response.data.data));
      }
      return response.data;
    });
};

// const registerBiometric = async (
//   sid: string,
//   challenge_id: any,
//   biometrics_id: string
// ) => {
//   return axios
//     .post(API_URL_ALT + "/store-id", {
//       sid,
//       challenge_id,
//       biometrics_id,
//     })
//     .then((response) => {
//       return response.data;
//     });
// };

const registerBiometric = async (
  sid: string,
  challenge_id: any,
  biometrics_id: string
) => {
  return await axios({
    url: API_URL_ALT + "/store-id",
    headers: authHeader(),
    method: "post",
    data: {
      sid,
      challenge_id,
      biometrics_id,
    },
  }).then((response) => {
    return response.data;
  });
};

const loginBiometric = async (challenge_id: any, biometrics_id: string) => {
  return axios
    .post(API_URL_ALT + "/authenticate", {
      challenge_id,
      biometrics_id,
    })
    .then((response) => {
      if (response.data.token) {
        const data = {
          user: response.data.data,
          token: response.data.token,
        };
        localStorage.setItem("userUlta", JSON.stringify(data));
      }
      return response.data;
    });
};

const checkBiometric = async () => {
  return await axios({
    url: API_URL_ALT + "/check",
    headers: authHeader(),
    method: "get",
  }).then((response) => {
    return response.data;
  });
};

const authService = {
  login,
  signup,
  registerBiometric,
  loginBiometric,
  checkBiometric,
};

export default authService;
