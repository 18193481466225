import uttaCoin from "../assets/image/uttaCoinAlt.png";
import arrowIcon from "../assets/image/svg/arrowRight.svg";
import balanceIcon from "../assets/image/svg/balance.svg";
import earningIcon from "../assets/image/svg/earning.svg";
import Chart from "../components/Chart";
import { useEffect, useState } from "react";
import { getBalance } from "../store/slices/bounties";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import ReactLoading from "react-loading";
import { colorTheme } from "../assets/colors";
import TransactionHistory from "../components/TransactionHistory";
import noDataIcon from "../assets/image/svg/noData.svg";
// import stackCoinsIcon from "../assets/image/stackCoins.png";
import Modal from "react-modal";
import WemaModal from "../components/WemaModal";

const Balance = ({ theme }: { theme: string }) => {
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const dispatch = useAppDispatch();
  // const { message }: any = useAppSelector((state) => state.message);
  const { balance }: any = useAppSelector((state) => state.bounties);

  function closeModal(): void {
    setModalIsOpen(false);
  }
  function openModal(): void {
    setModalIsOpen(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleGetBalance = () => {
      setLoading(true);
      dispatch(getBalance())
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    };

    handleGetBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numberFormat = (num: number | string | undefined) => {
    if (typeof num !== "undefined") {
      return num.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  };

  return (
    <div className="balance">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="WEMA Wealth Center"
      >
        <WemaModal closeModal={closeModal} />
      </Modal>
      <h1 className="balance__title">
        <div className="balance__title__img">
          <img src={balanceIcon} alt="" />
        </div>
        Total Balance
      </h1>

      <div className="balance__cfc">
        {loading ? (
          <ReactLoading
            color={colorTheme.primary}
            width={50}
            height={50}
            type="spin"
          />
        ) : balance ? (
          <>
            <div className="balance__cfc__main">
              <h2>Current Balance</h2>
              <h3>
                {numberFormat(balance?.total_balance)}
                <span>UTTA</span>
              </h3>
              <p>${numberFormat(balance?.total_balance)}</p>
              {/* <div className="balance__cfc__main__status">
                4.342.5 EUR <span>+12%</span>
              </div> */}
            </div>
            <img className="balance__cfc__img" src={uttaCoin} alt="" />
          </>
        ) : (
          <img src={noDataIcon} alt="" style={{ width: "60px" }} />
        )}
      </div>

      <h1 className="balance__title">
        <div className="balance__title__img">
          <img src={balanceIcon} alt="" />
        </div>{" "}
        Airdrops & Bounties Balance
      </h1>
      <div className="balance__info">
        <div className="balance__info__programs">
          <div className="balance__info__programs__item">
            {loading ? (
              <ReactLoading
                color={colorTheme.primary}
                width={50}
                height={50}
                type="spin"
              />
            ) : balance ? (
              <>
                <img src={uttaCoin} alt="" />
                <div className="balance__info__programs__item__main">
                  <h3>Airdrop 1</h3>
                  <div className="balance__info__programs__item__main__bal">
                    {numberFormat(balance?.airdrop_1)} <span>UTTA</span>
                  </div>
                  <p>${numberFormat(balance?.airdrop_1)}</p>
                </div>
              </>
            ) : (
              <img src={noDataIcon} alt="" style={{ width: "60px" }} />
            )}
          </div>
          <div className="balance__info__programs__item">
            {loading ? (
              <ReactLoading
                color={colorTheme.primary}
                width={50}
                height={50}
                type="spin"
              />
            ) : balance ? (
              <>
                <img src={uttaCoin} alt="" />
                <div className="balance__info__programs__item__main">
                  <h3>Airdrop 2</h3>
                  <div className="balance__info__programs__item__main__bal">
                    {numberFormat(balance?.airdrop_2)} <span>UTTA</span>
                  </div>
                  <p>${numberFormat(balance?.airdrop_2)}</p>
                </div>
              </>
            ) : (
              <img src={noDataIcon} alt="" style={{ width: "60px" }} />
            )}
          </div>
          <div className="balance__info__programs__item">
            {loading ? (
              <ReactLoading
                color={colorTheme.primary}
                width={50}
                height={50}
                type="spin"
              />
            ) : balance ? (
              <>
                <img src={uttaCoin} alt="" />
                <div className="balance__info__programs__item__main">
                  <h3>Bounty 1</h3>
                  <div className="balance__info__programs__item__main__bal">
                    {numberFormat(balance?.["bounty_1_bonus "])}{" "}
                    <span>UTTA</span>
                  </div>
                  <p>${numberFormat(balance?.["bounty_1_bonus "])}</p>
                </div>
              </>
            ) : (
              <img src={noDataIcon} alt="" style={{ width: "60px" }} />
            )}
          </div>
          <div className="balance__info__programs__item">
            {loading ? (
              <ReactLoading
                color={colorTheme.primary}
                width={50}
                height={50}
                type="spin"
              />
            ) : balance ? (
              <>
                <img src={uttaCoin} alt="" />
                <div className="balance__info__programs__item__main">
                  <h3>Bounty 2</h3>
                  <div className="balance__info__programs__item__main__bal">
                    {numberFormat(balance?.["bounty_2_bonus"])}{" "}
                    <span>UTTA</span>
                  </div>
                  <p>${numberFormat(balance?.["bounty_2_bonus"])}</p>
                </div>
              </>
            ) : (
              <img src={noDataIcon} alt="" style={{ width: "60px" }} />
            )}
          </div>
        </div>

        <div className="balance__info__uwl">
          <h3>WEALTH LAUNCHPAD</h3>
          <div className="balance__info__uwl__disc">
            <div className="balance__info__uwl__disc__main">
              <h4>to gain access to</h4>
              <p>Claim</p>
              <p>Convert</p>
              <p>Withdraw</p>
              <p>Deposit</p>
            </div>
            <img
              className="balance__info__uwl__disc__img"
              src={uttaCoin}
              alt=""
            />
          </div>
          <span
            className="balance__info__uwl__link"
            onClick={openModal}
            // target="_blank"
            // rel="noopener noreferrer"
            // href="https://bounties.ultainfinityairdropandbounty.com"
          >
            Go to WIMA Wealth Centre <img src={arrowIcon} alt="" />
          </span>
        </div>
      </div>

      <h1 className="balance__title">
        <div className="balance__title__img">
          <img src={balanceIcon} alt="" />
        </div>
        Other Balance
      </h1>

      <div className="balance__other">
        <div className="balance__other__left">
          {loading ? (
            <ReactLoading
              color={colorTheme.primary}
              width={50}
              height={50}
              type="spin"
            />
          ) : balance ? (
            <>
              <div className="balance__other__left__disc">
                <div className="balance__other__left__disc__header">
                  <span></span> Cash For Charity Balance
                </div>
                <div className="balance__other__left__disc__amount">
                  <h4>${numberFormat(balance?.cash_for_charity)}</h4>
                  <p>${numberFormat(balance?.cash_for_charity)} UTTA</p>
                </div>
              </div>
              <img src={uttaCoin} alt="" />
            </>
          ) : (
            <img src={noDataIcon} alt="" style={{ width: "60px" }} />
          )}
        </div>
        <div className="balance__other__right">
          <div className="balance__other__right__disc">
            <div className="balance__other__right__disc__header">
              <span></span> PARP Balance
            </div>
            <div className="balance__other__right__disc__amount">
              <h4>$0.00</h4>
              <p>$0.00 UTTA</p>
            </div>
          </div>
          <img src={uttaCoin} alt="" />
        </div>
      </div>

      <h1 className="balance__title">
        <div className="balance__title__img">
          <img src={earningIcon} alt="" />
        </div>{" "}
        Transaction
      </h1>
      <TransactionHistory />

      <h1 className="balance__title">
        <div className="balance__title__img">
          <img src={earningIcon} alt="" />
        </div>{" "}
        Earnings
      </h1>
      <Chart theme={theme} />
    </div>
  );
};

export default Balance;
