import dashboardIcon from "../assets/image/svg/Frame3.svg";
import balanceIcon from "../assets/image/svg/Frame6.svg";
import referralIcon from "../assets/image/svg/Frame5.svg";
import leaderboardIcon from "../assets/image/svg/Frame4.svg";
import creditIcon from "../assets/image/svg/Frame2.svg";
import exchangeIcon from "../assets/image/svg/Frame1.svg";
import cashForCharityIcon from "../assets/image/svg/CFC.svg";
import walletIcon from "../assets/image/svg/Wallet.svg";
import settingIcon from "../assets/image/svg/setting.svg";
import logoutIcon from "../assets/image/svg/logout.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "./styledComponent/form/Buttons";
import { useEffect } from "react";
import { getBalance } from "../store/slices/bounties";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import Drawer from "./Drawer";
import { logout } from "../store/slices/auth";

const Sidebar = ({ drawer }: { drawer?: string }) => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { balance }: any = useAppSelector((state) => state.bounties);
  let navigate = useNavigate();

  const data = [
    { icon: dashboardIcon, name: "Dashboard", url: "" },
    { icon: balanceIcon, name: "Balances", url: "balances" },
    { icon: walletIcon, name: "Wallet", url: "wallet" },
    { icon: referralIcon, name: "Referrals & Code", url: "referral" },
    { icon: leaderboardIcon, name: "Leaderboard", url: "leaderboard" },
    {
      icon: settingIcon,
      name: "Settings",
      url: "settings",
    },
    {
      icon: cashForCharityIcon,
      name: "Cash For Charity",
      url: "cash-for-charity",
    },
    { icon: creditIcon, name: "Instant Creditline", url: "instant-creditline" },
    {
      icon: exchangeIcon,
      name: "Premarket Exchange",
      url: "premarket-exchange",
    },
  ];

  useEffect(() => {
    const handleGetBalance = () => {
      dispatch(getBalance())
        .unwrap()
        .then(() => {})
        .catch((err: any) => {});
    };

    handleGetBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const WemaButton = ({ disableBtn }: { disableBtn: boolean }) => {
    return (
      <Button
        data-tooltip-id={disableBtn ? "my-tooltip" : ""}
        data-tooltip-content="Activate any program to Access WEMA"
        style={{
          background: "linear-gradient(180deg, #8D0C0C -115.62%, #B83A3A 100%)",
          border: "none",
          color: "#EFC262",
          cursor: disableBtn ? "not-allowed" : "pointer",
        }}
        width="100%"
        $fill
        height="68px"
      >
        {disableBtn ? (
          <span>
            Go to <br /> WIMA Wealth Centre
          </span>
        ) : (
          <a
            style={{ color: "#EFC262" }}
            target="_blank"
            rel="noreferrer"
            href="https://bounties.ultainfinityairdropandbounty.com"
          >
            Go to <br /> WIMA Wealth Centre
          </a>
        )}
      </Button>
    );
  };

  return (
    <div className={drawer ? "sidebar drawerOpen" : "sidebar"}>
      <ul className="sidebar__list">
        {data.map((item, i) => {
          const { icon, name, url } = item;

          return (
            <div key={i}>
              <Link to={url ? "/dashboard/" + url : "/dashboard"}>
                <li
                  className={
                    i === 0
                      ? pathname === "/dashboard" ||
                        pathname.includes("/dashboard/program")
                        ? "sidebar__list__item active"
                        : "sidebar__list__item"
                      : pathname.includes(url)
                      ? "sidebar__list__item active"
                      : "sidebar__list__item"
                  }
                >
                  <img src={icon} alt="" /> <div>{name}</div>{" "}
                  {!drawer && <span></span>}
                </li>
              </Link>
              {i === 5 && <div className="sidebar__list__line"></div>}
            </div>
          );
        })}
      </ul>

      <div style={{ margin: "20px" }}>
        <WemaButton
          disableBtn={balance?.total_balance === 0 || !balance?.total_balance}
        />
      </div>

      <div className="sidebar__logout">
        <Button $fill radius="4px" width="185px" onClick={handleLogout}>
          Logout <img src={logoutIcon} alt="" />
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
