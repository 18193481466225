import safeIcon from "../assets/image/Safe.png";
import coinbaseIcon from "../assets/image/svg/Coinbase.svg";
import solanaIcon from "../assets/image/svg/solana.svg";
import { Button } from "./styledComponent/form/Buttons";
import closeIcon from "../assets/image/svg/close.svg";
import { useState } from "react";
import axios from "axios";
import ReactLoading from "react-loading";

const Invoice = ({
  handleSubmit,
  close,
  pay,
  recieve,
  program,
  wallet,
  paymentMethod,
  tokenAmount,
}: {
  handleSubmit: () => void;
  close: () => void;
  pay: string;
  recieve: number;
  program?: string;
  wallet: string;
  paymentMethod: string;
  tokenAmount: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [document, setDocument] = useState<any>({});

  function numberWithCommas(x: number | string) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  const user: any = JSON.parse(localStorage.getItem("userUlta") || "{}");

  const headers = {
    Authorization: `Bearer ${user?.token}`,
    "user-id": user?.user?.id,
  };
  // console.log("tokenAmount", tokenAmount);
  const handlePreOrder = async () => {
    setLoading(true);
    setShowButton(false);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/payments/app/orders/charge`,
        {
          gateway:
            paymentMethod === "Coinbase Commerce"
              ? "coinbase-commerce"
              : "network-solana",
          amount: tokenAmount.payment_amount,
          currency: wallet,
          ridrect_url:
            `https://ultainfinityairdropandbounty.com/dashboard/program/${program}/processing/` +
            tokenAmount.amount,
          cancel_url: `https://ultainfinityairdropandbounty.com/dashboard/program/${program}`,
          document: tokenAmount.document,
        },
        { headers }
      );
      // console.log("response", response);

      setLoading(false);
      setShowButton(true);
      setDocument(response.data.data);

      // console.log(response.data.data);
    } catch (error: any) {
      // console.log(error.response);
      setLoading(false);
    }

    // try {
    //   const res = await axios.post(
    //     process.env.REACT_APP_API_URL + `/launchpad/app/purchases`,
    //     {
    //       product_id: "969ff58b-5d48-4de4-8e9e-cb6bb39e6041",
    //       payment_amount: parseInt(pay),
    //       currency_ticker: wallet,
    //     },
    //     { headers }
    //   );
    //   console.log("res", res);

    // } catch (error: any) {
    //   console.log(error.response);
    //   setLoading(false);
    // }
  };

  return (
    <div className="invoice">
      <div onClick={close} className="invoice__closeBtn">
        <img src={closeIcon} alt="" />
      </div>

      <div className="invoice__header">
        Invoice <img src={safeIcon} alt="" />
      </div>

      <div className="invoice__info">
        <div className="invoice__info__item">
          <span className="invoice__info__item__name">Pay</span>
          <div className="invoice__info__item__value">
            ${numberWithCommas(pay)} worth of {wallet.toUpperCase()}
          </div>
        </div>
        <div className="invoice__info__item">
          <span className="invoice__info__item__name">Receive</span>
          <div className="invoice__info__item__value">
            ${numberWithCommas(recieve)}
            {/* <span>ULTA</span> */}
          </div>
        </div>
      </div>

      <div className="invoice__summary">
        <h4 className="invoice__summary__title">Payment Summary</h4>

        <div className="invoice__summary__main">
          <div className="invoice__summary__main__method">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={
                  paymentMethod === "Coinbase Commerce"
                    ? coinbaseIcon
                    : solanaIcon
                }
                alt=""
              />{" "}
              {paymentMethod}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              ( {numberWithCommas(pay)} USD{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                width="18px"
                // class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                />
              </svg>{" "}
              {`${tokenAmount.payment_amount} ${wallet.toUpperCase()}`} )
            </div>
          </div>
          <div className="invoice__summary__main__item">
            Payment Amount ({wallet.toUpperCase()})
            <span>${numberWithCommas(pay)}</span>
          </div>
          <div className="invoice__summary__main__item">
            To Receive <span>${numberWithCommas(recieve)} </span>
          </div>
          <div className="invoice__summary__main__item">
            Bonus Reward{" "}
            <span>{program === "invest" ? "$3,500" : "$3,000"} </span>
          </div>
          <div className="invoice__summary__main__total">
            TOTAL{" "}
            <span>
              $
              {numberWithCommas(recieve + (program === "invest" ? 3500 : 3000))}{" "}
            </span>
          </div>
        </div>
      </div>

      {!showButton ? (
        <Button
          onClick={handlePreOrder}
          radius="5px"
          $fill
          height="50px"
          width="100%"
        >
          {loading ? (
            <ReactLoading color="white" width={30} height={30} type="spin" />
          ) : (
            "Continue to Payment"
          )}
        </Button>
      ) : paymentMethod === "Coinbase Commerce" ? (
        <a
          style={{ width: "100%" }}
          href={document.payment_order_url}
          target="_blank"
          rel="noreferrer"
        >
          <Button radius="5px" $fill height="50px" width="100%">
            Proceed to Payment
          </Button>
        </a>
      ) : (
        <a
          // onClick={handlePayment}
          style={{ width: "100%" }}
          href={`https://solanapay.ultainfinity.com/pending?amount=${tokenAmount.payment_amount}&label=Ultainfinity+Launchpad&recipient=${document.recipient_address}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button radius="5px" $fill height="50px" width="100%">
            Proceed to Payment
          </Button>
        </a>
      )}
    </div>
  );
};

export default Invoice;
