import buildingVideo from "../../assets/videos/tower.mp4";
import arrowIcon from "../../assets/image/svg/arrowRightAlt.svg";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="hero">
      <video
        className="hero__video"
        src={buildingVideo}
        autoPlay
        loop
        muted
      ></video>

      <div className="hero__main">
        <h2>$300 BILLION+ </h2>
        <h1>THE LARGEST AIRDROPS IN HISTORY</h1>
        <p>
          Unlimited entries. <br /> Uncapped earnings. <br /> Untrammelled
          wealth accelerator
        </p>
        <Link to="/signup">
          <button className="hero__main__btn">
            Get Started <img src={arrowIcon} alt="" />
          </button>
        </Link>
      </div>
      <svg
        className="hero__curve"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 220"
      >
        <path
          fill="#0e0f1f"
          fillOpacity="1"
          d="M0,64L60,64C120,64,240,64,360,80C480,96,600,128,720,122.7C840,117,960,75,1080,64C1200,53,1320,75,1380,85.3L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
};

export default Hero;
