import { Button, ButtonAlt } from "./styledComponent/form/Buttons";
import { RadioField } from "./styledComponent/form/textFields";
import Select from "react-select";
// import { ReactComponent as SwapIcon } from "../assets/image/svg/swap.svg";
import {
  selectJsx1,
  selectJsx3,
  selectJsx4,
  selectJsx5,
  selectJsx6,
  selectJsx7,
  selectJsx8,
  selectJsx9,
  selectJsx10,
  selectJsx11,
  // selectJsx12,
} from "../components/selectComponents";
import solanaIcon from "../assets/image/svg/solana.svg";

import { useEffect, useState } from "react";
import PaymentSuccess from "./PaymentSuccess";
import Invoice from "./Invoice";
import Modal from "react-modal";
import { ErrorMessage } from "./styledComponent/form/textFields/styles";
// import { colorTheme } from "../assets/colors";
import axios from "axios";
import ReactLoading from "react-loading";
import {
  checkBountyInvestReactivate,
  checkBountySubmitReactivate,
  generateReference,
  reactiveBountyInvest,
  reactiveBountySubmit,
} from "../store/slices/bounties";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import ReactivateModal from "./ReactivateModal";

const coinOptions = [
  { label: selectJsx1, value: "eth", name: "Ethereum" },
  { label: selectJsx3, value: "usdc", name: "USDC" },
  { label: selectJsx4, value: "btc", name: "Bitcoin" },
  { label: selectJsx5, value: "usdt", name: "USDT" },
  { label: selectJsx6, value: "doge", name: "Dogecoin" },
  { label: selectJsx7, value: "ltc", name: "Litecoin" },
  { label: selectJsx8, value: "dai", name: "Dai" },
  { label: selectJsx9, value: "bch", name: "Bitcoin Cash" },
  { label: selectJsx10, value: "ape", name: "ApeCoin" },
  { label: selectJsx11, value: "shib", name: "Shiba Inu" },
];

const selectJsx2 = (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <img
      style={{ marginRight: "10px" }}
      src={solanaIcon}
      width={20}
      height={20}
      alt="sol"
    />{" "}
    SOL <span style={{ color: "#777E90", marginLeft: "10px" }}>Solana</span>
  </div>
);

const solanaOption = [{ label: selectJsx2, value: "sol", name: "Solana" }];

const Payment = ({ program }: { program: string }) => {
  const [selectedRadio, setselectedRadio] = useState("Coinbase Commerce");
  const [walletSelectValue, setWalletSelectValue] = useState(coinOptions[2]);
  const [wallet, setWallet] = useState("btc");
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [amountToRecieve, setAmountToRecieve] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenSuc, setModalIsOpenSuc] = useState(false);
  const [modalIsOpenGen, setModalIsOpenGen] = useState(false);
  const [disableBtn, setdisableBtn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingGen, setLoadingGen] = useState(false);
  const [loadingReactivate, setLoadingReactivate] = useState(false);
  const [disableBtnReactivate, setDisableBtnReactivate] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [tokenAmount, setTokenAmount] = useState({
    payment_amount: "",
    // bonus: "",
    // currency_ticker: "",
    // main_payment_amount: "",
    // rate: "",
    // token_amount: "",
    // total_token: "",
  });

  const user: any = JSON.parse(localStorage.getItem("userUlta") || "{}");
  const dispatch = useAppDispatch();
  const {
    reference,
    bountySubmitReactivateCheck,
    bountyInvestReactivateCheck,
  } = useAppSelector((state) => state.bounties);

  const headers = {
    Authorization: `Bearer ${user?.token}`,
    "user-id": user?.user?.id,
  };

  const handleWallet = (value: any) => {
    setWallet(value.value.value);
    setWalletSelectValue(value.value);
  };

  const openModal = (): void => {
    setModalIsOpen(true);
  };
  const closeModal = (): void => {
    setModalIsOpen(false);
  };

  const openModalSuc = (): void => {
    setModalIsOpenSuc(true);
    // setModalIsOpen(false);
  };
  const closeModalSuc = (): void => {
    setModalIsOpenSuc(false);
  };
  const openModalGen = (): void => {
    setModalIsOpenGen(true);
    // setModalIsOpen(false);
  };
  const closeModalGen = (): void => {
    setModalIsOpenGen(false);
  };

  // const coinOptionsInvest = [
  //   { label: selectJsx12, value: "dollar", name: "Dollar" },
  // ];

  const handleRadioChange = (value: string) => {
    setselectedRadio(value);
    if (value === "Coinbase Commerce") {
      setWalletSelectValue(coinOptions[2]);
      setWallet("btc");
    } else {
      setWalletSelectValue(solanaOption[0]);
      setWallet("sol");
    }
  };

  const handleReactivate = () => {
    if (program === "invest") {
      setLoadingReactivate(true);
      dispatch(
        reactiveBountyInvest({
          transaction_no: reference?.transaction_no,
          reference: reference?.reference,
        })
      )
        .unwrap()
        .then(() => {
          setLoadingReactivate(false);
          closeModalGen();
          toast.success("Successfully Reactivated", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setRefresh(!refresh);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingReactivate(false);
        });
    } else {
      setLoadingReactivate(true);
      dispatch(
        reactiveBountySubmit({
          transaction_no: reference?.transaction_no,
          reference: reference?.reference,
        })
      )
        .unwrap()
        .then(() => {
          setLoadingReactivate(false);
          closeModalGen();
          toast.success("Successfully Reactivated", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setRefresh(!refresh);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingReactivate(false);
        });
    }
  };

  const handleGenerateReference = () => {
    setLoadingGen(true);
    dispatch(generateReference())
      .unwrap()
      .then(() => {
        setLoadingGen(false);
        openModalGen();
      })
      .catch((err: any) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoadingGen(false);
      });
  };

  const handleInitiatePayment = async () => {
    if (!amount || parseInt(amount) < (program === "invest" ? 250 : 25)) {
      setAmountError(true);
    } else {
      setLoading(true);
      setAmountError(false);
      try {
        const res = await axios.post(
          process.env.REACT_APP_API_URL + `/launchpad/app/token-calculate`,
          {
            product: "969ff58b-5d48-4de4-8e9e-cb6bb39e6041",
            investment_amount: parseInt(amount),
            currency: wallet,
          },
          { headers }
        );
        setTokenAmount((curr) => {
          return { ...curr, payment_amount: res?.data?.data?.payment_amount };
        });

        if (program === "invest") {
          try {
            const response = await axios.post(
              process.env.REACT_APP_API_URL +
                `/airdrop-bounty/app/bounty-one/4xreward`,
              {
                // investment_amount: parseInt(amount),
                currency: wallet,
                amount: parseInt(amount),
              },
              { headers }
            );
            setTokenAmount((curr) => {
              return { ...curr, ...response.data.data };
            });
            // setTokenAmount(response.data.data);
            setLoading(false);
            openModal();
          } catch (err: any) {
            const message =
              (err.response &&
                err.response.data &&
                err.response.data.message) ||
              err.message ||
              err.toString();
            // console.log(error.response);
            setLoading(false);
            toast.error(message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          try {
            const response = await axios.post(
              process.env.REACT_APP_API_URL +
                `/airdrop-bounty/app/bounty-two/pay`,
              {
                // investment_amount: parseInt(amount),
                currency: wallet,
                amount: parseInt(amount),
              },
              { headers }
            );
            setTokenAmount((curr) => {
              return { ...curr, ...response.data.data };
            });
            // setTokenAmount(response.data.data);
            setLoading(false);
            openModal();
          } catch (err: any) {
            setLoading(false);
            const message =
              (err.response &&
                err.response.data &&
                err.response.data.message) ||
              err.message ||
              err.toString();
            // console.log(error.response);
            toast.error(message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      } catch (err: any) {
        setLoading(false);
        const message =
          (err.res && err.res.data && err.res.data.message) ||
          err.message ||
          err.toString();
        // console.log(error.response);
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleAmountChange = (value: string) => {
    setAmount(value);

    if (value && parseInt(value) >= (program === "invest" ? 250 : 25)) {
      setAmountError(false);
      setdisableBtn(false);
      if (program === "invest") {
        const newAmount = parseInt(value) * 4;
        setAmountToRecieve(newAmount);
      }

      if (program === "submit") {
        const newAmount = parseInt(value) * 10;
        setAmountToRecieve(newAmount);
      }
    } else {
      setdisableBtn(true);
      setAmountError(true);
      // const activateAmountError = () => setAmountError(true);
      // const debouncedTest = debounce(activateAmountError, 2000);
      // debouncedTest();
      setAmountToRecieve(0);
    }
  };

  useEffect(() => {
    if (program === "invest") {
      const handleCheckInvestReactivate = async () => {
        // setLoadingCheck(true);
        dispatch(checkBountyInvestReactivate())
          .unwrap()
          .then(() => {
            // setLoadingCheck(false);
          })
          .catch((err: any) => {
            if (err !== "Bounty has already been generated") {
              toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            // setLoadingCheck(false);
          });
      };

      handleCheckInvestReactivate();
    } else {
      const handleCheckSubmitReactivate = async () => {
        // setLoadingCheck(true);
        dispatch(checkBountySubmitReactivate())
          .unwrap()
          .then(() => {
            // setLoadingCheck(false);
          })
          .catch((err: any) => {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
            });
            // if (err !== "false") {
            // }
            // setLoadingCheck(false);
          });
      };

      handleCheckSubmitReactivate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (bountySubmitReactivateCheck?.can_reactivate === true) {
      setDisableBtnReactivate(false);
    }
    if (
      bountyInvestReactivateCheck &&
      bountyInvestReactivateCheck ===
        "Bounty One has not been generated for User"
    ) {
      setDisableBtnReactivate(false);
    }
  }, [bountySubmitReactivateCheck, bountyInvestReactivateCheck]);

  return (
    <div className="payment">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        isOpen={modalIsOpenGen}
        onRequestClose={closeModalGen}
        contentLabel="reactivate payment"
      >
        <ReactivateModal
          handleReactivate={handleReactivate}
          loadingReactivate={loadingReactivate}
          close={closeModalGen}
          program={program === "invest" ? "Bounty 2" : "Bounty 1"}
          reference={reference}
        />
      </Modal>
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Invoice"
      >
        <Invoice
          paymentMethod={selectedRadio}
          wallet={wallet}
          pay={amount}
          recieve={amountToRecieve}
          close={closeModal}
          handleSubmit={openModalSuc}
          program={program}
          tokenAmount={tokenAmount}
        />
      </Modal>{" "}
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        isOpen={modalIsOpenSuc}
        onRequestClose={closeModalSuc}
        contentLabel="Invoice Successful"
      >
        <PaymentSuccess close={closeModalSuc} />
      </Modal>{" "}
      <div className="payment__header">
        <h3 className="payment__header__title">
          {program === "invest"
            ? "Invest and Get 4x Return Plus $3500"
            : "Submit Non-Ultainfinity Crypto for 10x Return Plus $3000"}
        </h3>
        <ButtonAlt
          disabled={disableBtnReactivate}
          $disable={disableBtnReactivate}
          onClick={handleGenerateReference}
          $fill
        >
          {loadingGen ? (
            <ReactLoading color="white" width={30} height={30} type="spin" />
          ) : (
            "Reactivate"
          )}
        </ButtonAlt>
      </div>
      <div className="payment__form">
        <div className="payment__form__radio">
          <h5>Choose payment method</h5>
          <div className="payment__form__radio__main">
            <RadioField
              id="coinbaseCommerce"
              name="paymentMethod"
              value="Coinbase Commerce"
              label="Coinbase Commerce"
              onChange={handleRadioChange}
              checked={selectedRadio}
            />
            <RadioField
              id="solanaPay"
              name="paymentMethod"
              value="Solana Pay"
              label="Solana Pay"
              onChange={handleRadioChange}
              checked={selectedRadio}
            />
          </div>
        </div>

        <div className="payment__form__amount">
          <h5>Proceed to payment</h5>
          <div className="payment__form__amount__inputs">
            {/* <div className="payment__form__amount__inputs__icon">
              <SwapIcon />
            </div> */}
            <div>
              <Select
                classNamePrefix="react-select"
                className="react-select-container"
                value={walletSelectValue}
                options={
                  selectedRadio === "Solana Pay" ? solanaOption : coinOptions
                }
                onChange={(value) => handleWallet({ value })}
                placeholder="Select crypocurrency"
              />
            </div>
            {/* <div className="payment__form__amount__inputs__token">
              UTTA Tokens
            </div> */}
            <div className="payment__form__amount__inputs__coin">
              <input
                onChange={(e) => handleAmountChange(e.target.value)}
                type="number"
                className="payment__form__amount__inputs__coin__main"
                placeholder="amount"
              />
              <span>USD </span>
              <div>{program === "invest" ? "x4" : "x10"} </div>
            </div>
            {/* <div className="payment__form__amount__inputs__usd">
              <div>${amountToRecieve}</div>
              <span>USD</span>
            </div> */}
          </div>
          <ErrorMessage show={amountError}>
            minimum amount is ${program === "invest" ? 250 : 25}
          </ErrorMessage>

          <Button
            $disable={disableBtn}
            disabled={disableBtn}
            onClick={handleInitiatePayment}
            radius="5px"
            height="48px"
            width="220px"
            $fill
          >
            {/* <SwapIcon stroke={disableBtn ? colorTheme.gray : "#E2E4ED"} /> */}
            {loading ? (
              <ReactLoading color="white" width={30} height={30} type="spin" />
            ) : program === "invest" ? (
              "INVEST"
            ) : (
              "PAY"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Payment;
