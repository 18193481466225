import faceIdIcon from "../assets/image/svg/faceId.svg";
import fingerPrintIcon from "../assets/image/svg/fingerPrint.svg";
import closeIcon from "../assets/image/svg/close.svg";
import { Button } from "./styledComponent/form/Buttons";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../hooks/reduxHooks";
import { toast } from "react-toastify";
import { registerBiometric } from "../store/slices/auth";
import axios from "axios";
import ReactLoading from "react-loading";

interface BiometricRegisterModalType {
  close: () => void;
  username: string;
  setBiometricRegisterSuc: any;
  biometricRegisterSuc: boolean;
}

const BiometricRegisterModal = ({
  close,
  username,
  setBiometricRegisterSuc,
  biometricRegisterSuc,
}: BiometricRegisterModalType) => {
  const [biometricSupported, setBiometricSupported] = useState(false);
  const [bioLoading, setBioLoading] = useState(false);

  // const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  function checkBiometricSupport() {
    if (
      window.PublicKeyCredential &&
      navigator.credentials &&
      (navigator.credentials.create || navigator.credentials.get)
    ) {
      setBiometricSupported(true);
    } else {
      setBiometricSupported(false);
    }
  }

  const handleBiometric = async (
    challenge: any,
    id: any,
    challengeStr: string
  ) => {
    try {
      const credentialOptions: any = {
        rp: {
          name: "Ultainfinity",
          id: window.location.hostname,
        },
        user: {
          displayName: username ? username : "Ultainfinity User",
          id,
          name: username ? username : "Ultainfinity User",
        },
        challenge,
        pubKeyCredParams: [
          {
            type: "public-key",
            alg: -7,
          },
        ],
        timeout: 1800000,
        attestation: "direct",
        authenticatorSelection: {
          userVerification: "required",
          authenticatorAttachment: "platform",
          requireResidentKey: true,
          residentKey: "required",
          authenticatorTransport: ["usb", "nfc", "ble"],
          extensions: {
            "ultainfinity:biometric-authentication": {
              supportedModes: ["fingerprint", "face"],
            },
          },
        },
        // authenticatorSelection: {
        //   residentKey: "required",
        //   userVerification: "required",
        //   authenticatorAttachment: "platform",
        // },
      };
      const credential = await navigator.credentials.create({
        publicKey: credentialOptions,
      });

      // console.log(credential);
      if (typeof credential?.id === "string") {
        // setBiometricRegisterSuc(credential?.id);
        const data = {
          // sid: user.id,
          // challenge_id: challengeStr,
          biometrics_id: credential?.id,
        };
        handleRegisterBiometric(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRegisterBiometric = async (data: any) => {
    setBioLoading(true);

    dispatch(registerBiometric(data))
      .unwrap()
      .then(() => {
        setBioLoading(false);
        toast.success("Successfully Registered Biometrics", {
          position: toast.POSITION.TOP_RIGHT,
        });
        close();
        setBiometricRegisterSuc(true);
      })
      .catch((err: any) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setBioLoading(false);
      });
  };

  const handleGenerateChallange = async () => {
    setBioLoading(true);

    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/users/v1/biometrics/generate-challenge`
      );
      setBioLoading(false);
      // console.log("response.data.type", response.data.type);
      const challenge = Uint8Array.from(atob(response.data?.challenge), (c) =>
        c.charCodeAt(0)
      );
      const id = Uint8Array.from(atob(response.data?.random_id), (c) =>
        c.charCodeAt(0)
      );

      handleBiometric(challenge, id, response.data?.challenge);
    } catch (err: any) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      setBioLoading(false);
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    checkBiometricSupport();
  }, []);

  return (
    <div className="biometricRegisterModal">
      <div className="biometricRegisterModal__close" onClick={close}>
        <img src={closeIcon} alt="" />
      </div>
      {/* <p>Use Biometrics to Login</p> */}

      <div className="biometricRegisterModal__biometric">
        {biometricSupported ? (
          <>
            <div className="biometricRegisterModal__biometric__item">
              <img src={fingerPrintIcon} alt="" />
              <span>Touch ID</span>
            </div>
            <div className="biometricRegisterModal__biometric__item">
              <img src={faceIdIcon} alt="" />
              <span>Face ID</span>
            </div>
          </>
        ) : (
          <p style={{ color: "#eb001b" }}>
            Biometric authentication is not supported on this device
          </p>
        )}
      </div>

      {biometricSupported && (
        <Button
          $fill
          radius="7px"
          width="200px"
          onClick={handleGenerateChallange}
        >
          {" "}
          {bioLoading ? (
            <ReactLoading color="white" width={30} height={30} type="spin" />
          ) : biometricRegisterSuc ? (
            "Update Biometrics"
          ) : (
            "Register Biometrics"
          )}
        </Button>
      )}
    </div>
  );
};

export default BiometricRegisterModal;
