// import profileIcon from "../assets/image/svg/Profile.svg";
import { useEffect, useRef, useState } from "react";
import imageIcon from "../assets/image/svg/image.svg";
import { Button } from "./styledComponent/form/Buttons";
import profileIcon from "../assets/image/svg/Profile.svg";
import { toast } from "react-toastify";
import { saveSettings } from "../store/slices/settings";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import ReactLoading from "react-loading";
import { CircularProgressbar } from "react-circular-progressbar";
import KYC from "./profileMain/KYC";
import PersonalDetail from "./profileMain/PersonalDetail";
import Modal from "react-modal";
import BiometricRegisterModal from "./BiometricRegisterModal";
import { checkBiometric as getCheckBiometric } from "../store/slices/auth";

const Profile = ({
  fetchNewSettings,
  setShowProfileDetails,
  showProfileDetails,
}: {
  fetchNewSettings: () => void;
  setShowProfileDetails: any;
  showProfileDetails: boolean;
}) => {
  const [edit, setEdit] = useState(false);
  // const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingBio, setLoadingBio] = useState(false);
  const [type, setType] = useState("kyc");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [progressPer, setProgressPer] = useState(10);
  const [progressPerPD, setProgressPerPD] = useState(10);
  const [biometricRegisterSuc, setBiometricRegisterSuc] = useState(false);

  const { user }: any = useAppSelector((state) => state.auth);
  const { checkBiometric }: any = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const { settings }: any = useAppSelector((state) => state.settings);
  const [data, setData] = useState<any>(null);

  const imageInput: any = useRef(null);

  const handleClick = (reference: any) => {
    reference.current.click();
  };
  function closeModal(): void {
    setModalIsOpen(false);
  }

  const { progress }: any = useAppSelector((state) => state.settings);

  useEffect(() => {
    if (progress) {
      let total = 0;

      if (progress?.profile) total = total + 25;
      if (progress?.identity_verification) total = total + 25;
      if (progress?.selfie_with_id) total = total + 25;
      if (progress?.verification_status?.overall_status) total = total + 25;

      setProgressPer(total === 0 ? 10 : total);
      setProgressPerPD(progress?.profile ? 100 : 25);
    }
  }, [progress]);

  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleChange = async (event: any) => {
    const fileUploaded = event.target.files[0];

    getBase64(fileUploaded, async (result: any) => {
      setData((curr: any) => {
        return {
          ...curr,
          avatar: result,
        };
      });
    });
  };

  const handleSaveSettings = () => {
    setLoading(true);
    dispatch(saveSettings(data))
      .unwrap()
      .then(() => {
        setLoading(false);
        toast.success("Settings Saved Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchNewSettings();
      })
      .catch((err: any) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    const handleCheckBiometric = () => {
      setLoadingBio(true);
      dispatch(getCheckBiometric())
        .unwrap()
        .then(() => {
          setLoadingBio(false);
        })
        .catch((err: any) => {
          // toast.error(err, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          setLoadingBio(false);
        });
    };

    handleCheckBiometric();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (checkBiometric === "Biometrics data exists") {
      setBiometricRegisterSuc(true);
    } else {
      setBiometricRegisterSuc(false);
    }
  }, [checkBiometric]);

  useEffect(() => {
    if (settings) {
      setData(settings[0]);
    }
  }, [settings]);
  return (
    <div className="profile">
      <Modal
        className="modalMain"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="WEMA Wealth Center"
      >
        <BiometricRegisterModal
          setBiometricRegisterSuc={setBiometricRegisterSuc}
          username={user?.display_name}
          close={closeModal}
          biometricRegisterSuc={biometricRegisterSuc}
        />
      </Modal>
      {!showProfileDetails ? (
        data && (
          <>
            <h2 className="profile__title">Profile</h2>
            <div className="profile__biometric">
              <div className="profile__biometric__left">
                <h5>Register Biometrics</h5>
                <p>
                  {" "}
                  For account security, authorizations, authentications secured
                  KYC purposes, we require you to complete your profile.{" "}
                </p>
              </div>
              <div className="profile__biometric__right">
                <Button
                  onClick={() => setModalIsOpen(true)}
                  radius="10px"
                  $fill
                  height="32px"
                  width="120px"
                >
                  {biometricRegisterSuc ? "Update" : "Get Started"}
                </Button>
              </div>
            </div>

            <div className="profile__main">
              <div className="profile__main__item">
                <h4 className="profile__main__item__title">Profile image</h4>
                <div className="profile__main__item__disc">
                  {edit ? (
                    <div className="profile__main__item__left__upload">
                      <div className="profile__main__item__left__upload__img">
                        <input
                          type="file"
                          ref={imageInput}
                          onChange={(e) => handleChange(e)}
                          style={{ display: "none" }}
                          accept=".png,.jpeg,.jpg"
                        />
                        <img
                          src={data?.avatar ? data?.avatar : profileIcon}
                          alt=""
                        />
                      </div>
                      <div className="profile__main__item__left__upload__btnCon">
                        <Button
                          onClick={() => handleClick(imageInput)}
                          width="100px"
                          height="30px"
                          $fill
                        >
                          {" "}
                          Change
                        </Button>
                        <Button
                          onClick={() =>
                            setData((curr: any) => {
                              return {
                                ...curr,
                                avatar: "",
                              };
                            })
                          }
                          width="100px"
                          height="30px"
                        >
                          {" "}
                          Remove
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="profile__main__item__left">
                      <img src={imageIcon} alt="" />
                      <div className="profile__main__item__left__disc">
                        <h5>Avatar</h5>
                        <p> Select an avatar to persoanlized your account</p>
                      </div>
                    </div>
                  )}
                  <div className="profile__main__item__right">
                    {/* <input type="text" value="John Smith" /> */}
                    <Button
                      onClick={() => setEdit(!edit)}
                      radius="10px"
                      $fill
                      height="32px"
                      width="100px"
                    >
                      Edit
                    </Button>
                  </div>
                </div>
              </div>

              <div className="profile__main__item">
                {/* <h4 className="profile__main__item__title">Username</h4> */}
                <div className="profile__main__item__disc">
                  <div className="profile__main__item__left">
                    <img src={imageIcon} alt="" />
                    <div className="profile__main__item__left__disc">
                      <h5>Personal Details</h5>
                      <p> Submit your KYC document for account verifivation </p>
                    </div>
                  </div>
                  <div className="profile__main__item__right">
                    <div
                      style={{ width: 60 }}
                      className="profile__main__item__right__progress"
                    >
                      <CircularProgressbar
                        value={progressPerPD}
                        text={`${progressPerPD}%`}
                      />
                    </div>
                    <Button
                      onClick={() => {
                        setShowProfileDetails(true);
                        setType("details");
                      }}
                      radius="10px"
                      $fill
                      height="32px"
                      width="100px"
                    >
                      Edit
                    </Button>
                  </div>
                </div>
              </div>
              <div className="profile__main__item">
                {/* <h4 className="profile__main__item__title">Username</h4> */}
                <div className="profile__main__item__disc">
                  <div className="profile__main__item__left">
                    <img src={imageIcon} alt="" />
                    <div className="profile__main__item__left__disc">
                      <h5>KYC Document</h5>
                      <p> Submit your KYC document for account verifivation </p>
                    </div>
                  </div>
                  <div className="profile__main__item__right">
                    <div
                      style={{ width: 60 }}
                      className="profile__main__item__right__progress"
                    >
                      <CircularProgressbar
                        value={progressPer}
                        text={`${progressPer}%`}
                      />
                    </div>
                    <Button
                      onClick={() => {
                        setShowProfileDetails(true);
                        setType("kyc");
                      }}
                      radius="10px"
                      $fill
                      height="32px"
                      width="100px"
                    >
                      Upload
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile__btn">
              <Button
                disabled={!edit}
                $disable={!edit}
                onClick={handleSaveSettings}
                radius="90px"
                $fill
                height="48px"
                width="150px"
              >
                {loading ? (
                  <ReactLoading
                    color="white"
                    width={30}
                    height={30}
                    type="spin"
                  />
                ) : (
                  "Save settings"
                )}
              </Button>
            </div>
          </>
        )
      ) : type === "kyc" ? (
        <KYC setShowProfileDetails={setShowProfileDetails} />
      ) : (
        <div className="kyc__main">
          <div
            className="kyc__back"
            onClick={() => setShowProfileDetails(false)}
          >
            <span>{"<"}</span> Back to profile{" "}
          </div>
          <PersonalDetail
            main={true}
            setShowProfileDetails={setShowProfileDetails}
          />
        </div>
      )}
    </div>
  );
};

export default Profile;
