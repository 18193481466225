import whatsAppIcon from "../../assets/image/svg/whatappMain.svg";
import telegramIcon from "../../assets/image/svg/telegramMain.svg";
import twitterIcon from "../../assets/image/svg/twitterMain.svg";
import instagramIcon from "../../assets/image/svg/instagramMain.svg";
import tiktokIcon from "../../assets/image/svg/tiktokMain.svg";
import facebookIcon from "../../assets/image/svg/facebookMain.svg";
import lineIcon from "../../assets/image/svg/lineMain.svg";
import snapchatIcon from "../../assets/image/svg/snapchatMain.svg";
import messangerIcon from "../../assets/image/svg/messangerMain.svg";
import vkIcon from "../../assets/image/svg/vkMain.svg";
import signalIcon from "../../assets/image/svg/signalMain.svg";
import pinterestIcon from "../../assets/image/svg/pinterestMain.svg";
import discordIcon from "../../assets/image/svg/discordMain.svg";
import quoraIcon from "../../assets/image/svg/quoraMain.svg";
import tumblrIcon from "../../assets/image/svg/tumblrMain.svg";
import viberIcon from "../../assets/image/svg/viberMain.svg";
import linkedinIcon from "../../assets/image/svg/linkedinMain.svg";
import flickrIcon from "../../assets/image/svg/flickrMain.svg";
import youtubeIcon from "../../assets/image/svg/youtubeMain.svg";
import mediumIcon from "../../assets/image/svg/mediumMain.svg";

const Footer = () => {
  const year = new Date().getFullYear();
  const LinksIcon = [
    {
      link: "https://wa.me/4407500483970",
      icon: whatsAppIcon,
      iconName: "WhatsApp",
    },
    {
      link: "https://t.me/ultainfinity_channel",
      icon: telegramIcon,
      iconName: "Telegram",
    },
    {
      link: "https://twitter.com/ultainfinityWL",
      icon: twitterIcon,
      iconName: "Twitter",
    },
    {
      link: "https://www.instagram.com/ultainfinitywealthlaunchpad",
      icon: instagramIcon,
      iconName: "Instagram",
    },
    {
      link: "https://www.tiktok.com/@ultainfinitywl",
      icon: tiktokIcon,
      iconName: "Tiktok",
    },
    {
      link: "https://web.facebook.com/ultainfinitywealthlaunchpad",
      icon: facebookIcon,
      iconName: "Facebook",
    },
    {
      link: "https://www.youtube.com/channel/UCnfScaKAlEOEgyULkQKltGAs",
      icon: youtubeIcon,
      iconName: "Youtube",
    },
    {
      link: "https://www.linkedin.com/company/ultainfinity-global-group/",
      icon: linkedinIcon,
      iconName: "Linkedin",
    },
    {
      link: "https://www.snapchat.com/add/ultainfinitywl",
      icon: snapchatIcon,
      iconName: "Snapchat",
    },
    { link: "", icon: messangerIcon, iconName: "Messanger" },
    {
      link: "http://www.pinterest.com/ultainfinitywealthlaunchpad",
      icon: pinterestIcon,
      iconName: "Pinterest",
    },
    {
      link: "https://ultainfinitywealthlaunchpad.quora.com/",
      icon: quoraIcon,
      iconName: "Quora",
    },
    {
      link: "https://invite.viber.com/?g2=AQBudjpI1wFQLE8nhFQjaOy7oxAXYjOUu8PJwrdE5gRxfiTPB3IbSmAaWxloZTTj",
      icon: viberIcon,
      iconName: "Viber",
    },
    { link: "", icon: lineIcon, iconName: "Line" },
    { link: "", icon: signalIcon, iconName: "Signal" },
    {
      link: "https://vk.com/ultainfinitywealthlaunchpad",
      icon: vkIcon,
      iconName: "VK",
    },
    { link: "", icon: discordIcon, iconName: "Discord" },
    { link: "", icon: tumblrIcon, iconName: "Tumblr" },
    { link: "", icon: flickrIcon, iconName: "Flickr" },
    {
      link: "https://ultainfinitywealthlaunchpad.medium.com/",
      icon: mediumIcon,
      iconName: "Medium",
    },
  ];

  return (
    <div className="footer">
      <div className="footer__main">
        <h2>Join Our Community</h2>
        <h3>Subscribe</h3>
        <p>
          Subscribe to our Community Channels to get regular updates, our group
          developments and Airdrops & Bounties of Cryptocurrencies.
        </p>
        <div className="footer__main__socialLinks">
          {LinksIcon.map((link, i) => {
            return (
              <div key={i}>
                {link.link ? (
                  <a href={link.link}>
                    <div className="footer__main__socialLinks__item">
                      <img src={link.icon} alt={link.iconName} />
                    </div>
                  </a>
                ) : (
                  <div className="footer__main__socialLinks__item">
                    <img src={link.icon} alt={link.iconName} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className="footer__bottom">
        <p>
          Important to note, trading in cryptocurrencies and in particular
          synthetic assets comes with risks. You can lose all your investment.
          Furthermore, trading in cryptocurrencies generally and trading on
          ULTAINFINITY Exchange UTTA, DIVIT or any assets on our platforms are
          not regulated by any Financial Authority.
        </p>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ultainfinitywealthlaunchpad.com/privacy"
            >
              Privacy Policy
            </a>{" "}
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ultainfinitywealthlaunchpad.com/terms"
            >
              Terms of use
            </a>{" "}
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ultainfinitywealthlaunchpad.com/roadmaps"
            >
              Roadmaps
            </a>{" "}
          </li>

          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ultainfinitywealthlaunchpad.com/whitepaper"
            >
              Whitepaper
            </a>{" "}
          </li>

          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ultainfinitywealthlaunchpad.com/resources"
            >
              Resources
            </a>{" "}
          </li>
        </ul>
      </div>
      <div className="footer__copy">
        © Copyright Ultainfinity Global Group {year}
      </div>
    </div>
  );
};

export default Footer;
