import chatIcon from "../assets/image/svg/stock.svg";
import arrowupIcon from "../assets/image/svg/arrowup.svg";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { getBalance } from "../store/slices/bounties";
import { toast } from "react-toastify";
import { colorTheme } from "../assets/colors";
import ReactLoading from "react-loading";
import noDataIcon from "../assets/image/svg/noData.svg";

const ProgramsDetails = ({
  program,
}: {
  program: "airdrop1" | "airdrop2" | "bounty1" | "bounty2";
}) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { balance } = useAppSelector((state) => state.bounties);

  const data = {
    airdrop1: {
      title: "Airdrop 1 Program",
      desc: "Refer & Earn Unlimited times",
      att: "Total Referrals",
    },
    airdrop2: {
      title: "Airdrop 2 Program",
      desc: "Subscribe & Earn Unlimited times",
      att: "Total Subscribtions",
    },
    bounty1: {
      title: "Bounty 1 Program",
      desc: "Invest & Earn Unlimited times",
      att: "Total Investment",
    },
    bounty2: {
      title: "Bounty 2 Program",
      desc: "Subscribe & Earn Unlimited times",
      att: "Total Submission",
    },
  };

  useEffect(() => {
    const handleGetBalance = () => {
      setLoading(true);
      dispatch(getBalance())
        .unwrap()
        .then(() => {
          setLoading(false);
        })
        .catch((err: any) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    };

    handleGetBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const numberFormat = (num: number | string | undefined) => {
    if (typeof num !== "undefined") {
      return num.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
  };

  return (
    <div className="programsDetails">
      {loading ? (
        <ReactLoading
          color={colorTheme.primary}
          width={50}
          height={50}
          type="spin"
        />
      ) : balance ? (
        <>
          <div className="programsDetails__info">
            <h3>{data[program].title}</h3>
            <p>{data[program].desc}</p>
          </div>
          <div className="programsDetails__bonus">
            <div className="programsDetails__bonus__img">
              <img src={chatIcon} alt="" />
            </div>
            <div className="programsDetails__bonus__info">
              <h3>
                $
                {program === "airdrop1"
                  ? numberFormat(balance?.airdrop_1)
                  : program === "airdrop2"
                  ? numberFormat(balance?.airdrop_2)
                  : program === "bounty1"
                  ? numberFormat(balance?.["bounty_1_bonus "])
                  : numberFormat(balance?.["bounty_2_bonus"])}
              </h3>

              <p>Reward earned</p>
            </div>
          </div>
          <div className="programsDetails__att">
            <div className="programsDetails__bonus__img">
              <img src={arrowupIcon} alt="" />
            </div>
            <div className="programsDetails__bonus__info">
              <h3>
                {program !== "airdrop1" && program !== "airdrop2" && "$"}
                {program === "airdrop1"
                  ? numberFormat(balance?.airdrop_1_users)
                  : program === "airdrop2"
                  ? numberFormat(balance?.airdrop_2_channels)
                  : program === "bounty1"
                  ? numberFormat(balance?.["bounty_1_amount"])
                  : numberFormat(balance?.["airdrop_2_channels"])}
              </h3>
              <p>{data[program].att}</p>
            </div>
          </div>
        </>
      ) : (
        <img src={noDataIcon} alt="" style={{ width: "60px" }} />
      )}
    </div>
  );
};

export default ProgramsDetails;
