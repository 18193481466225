import { Link } from "react-router-dom";
import pendingIcon from "../../assets/image/svg/pending.svg";
import { Button } from "../styledComponent/form/Buttons";
import arrowIcon from "../../assets/image/svg/arrowLeft.svg";
// import kycIcon from "../../assets/image/svg/kyc.svg";
import kyc2Icon from "../../assets/image/svg/kyc2.svg";
import kyc3Icon from "../../assets/image/svg/kyc3.svg";
import checkIcon from "../../assets/image/svg/check.svg";
import { useEffect, useState } from "react";

const VerificationStatus = ({ setCurrStage, statusData, approved }: any) => {
  const [status, setstatus] = useState("rejected");

  useEffect(() => {
    if (approved) {
      setstatus("success");
    } else {
      if (statusData) {
        if (
          statusData?.approval_status?.identity_verification === "PENDING" ||
          statusData?.approval_status?.selfie_with_id === "PENDING" ||
          statusData?.approval_status?.identity_verification ===
            "NOT UPLOADED" ||
          statusData?.approval_status?.selfie_with_id === "NOT UPLOADED"
        ) {
          setstatus("pending");
        } else {
          setstatus("rejected");
        }
      }
    }
  }, [approved, statusData]);

  return (
    <div className="verificationStatus">
      {status === "pending" ? (
        <div className="verificationStatus__pending">
          <img src={pendingIcon} alt="" />
          <h2>Verification Pending</h2>
          <p>
            This may take a few hours to a couple of business days, depending on
            many factors (e.g. your country of application, nationality, quality
            of the pictures, etc.){" "}
          </p>
          <p>
            If your status does not change within 3 business days, you may be
            asked to provide us with additional information.
          </p>
          <p>
            Please be mindful of our instructions and never send us any personal
            information we did not request (including pictures, scans, PDFs,
            etc.)
          </p>
        </div>
      ) : status === "success" ? (
        <div className="verificationStatus__success">
          <div className="verificationStatus__success__icon">
            <img src={checkIcon} alt="" />
          </div>

          <h3>Your Account has been verified successfully</h3>
          <p>You can continue to unlock unlimited entries and earnings.</p>
        </div>
      ) : (
        <div className="verificationStatus__rejected">
          <div
            onClick={() => setCurrStage(2)}
            className="verificationStatus__rejected__back"
          >
            <img src={arrowIcon} alt="" /> Back to Personal Details
          </div>

          <p>
            An issue occurred with one or more of the listed elements in your
            application and we need you to repeat the process
          </p>

          <ul>
            <li>
              Tap "Resubmit" and follow the Document submission process again.
            </li>
          </ul>

          <div className="verificationStatus__rejected__doc">
            {statusData?.approval_status?.identity_verification ===
              "REJECTED" && (
              <div className="verificationStatus__rejected__doc__item">
                <div className="verificationStatus__rejected__doc__item__left">
                  <img src={kyc3Icon} alt="" /> Document uploaded, International
                  passport
                </div>

                <button onClick={() => setCurrStage(2)}>Resubmit</button>
              </div>
            )}

            {/* <div className="verificationStatus__rejected__doc__item">
              <div className="verificationStatus__rejected__doc__item__left">
                <img src={kycIcon} alt="" /> Personal details
              </div>

              <button onClick={() => setCurrStage(1)}>Resubmit</button>
            </div> */}
            {statusData?.approval_status?.selfie_with_id === "REJECTED" && (
              <div className="verificationStatus__rejected__doc__item">
                <div className="verificationStatus__rejected__doc__item__left">
                  <img src={kyc2Icon} alt="" /> Photo Selfie with ID
                </div>

                <button onClick={() => setCurrStage(3)}>Resubmit</button>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="verificationStatus__btn">
        <Link to="/dashboard">
          <Button $fill>Continue</Button>
        </Link>
      </div>
    </div>
  );
};

export default VerificationStatus;
